import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from "react-i18next";

import classes from "./ScreenshotUnavailable.module.scss";

function ScreenshotUnavailable() {
  const { t } = useTranslation("channel-list");

  return (
    <div className={classes["no-screenshot"]}>
      <InfoOutlinedIcon classes={{ root: classes["no-screenshot-icon"] }} />
      <div className={classes["no-screenshot-text"]}>
        <div>{t("screenshot-unavailable.top-line")}</div>
        <div>{t("screenshot-unavailable.bottom-line")}</div>
      </div>
    </div>
  );
}

export default ScreenshotUnavailable;
