export const DASHBOARD = {
  IDTS: "/dashboard/locations",
  HEALTH_CHECK: "/dashboard/health-check",
};

export const ENDPOINT_GROUPS = {
  LIST: "/endpoints/groups/list",
  ASSIGN: "/endpoints/groups/assign",
};

export const ENDPOINT_CONTROL = {
  DEFAULT: "/endpoints/control",
  TV_CONTROL: {
    DEFAULT: "/endpoints/control/tv-control",
    REMOTE: "/endpoints/control/tv-control/remote",
    FORCE_CHANNEL_CHANGE: "/endpoints/control/tv-control/force-channel-change",
  },
  MESSAGES: "/endpoints/control/messages",
  ALERTS: "/endpoints/control/alerts",
  TEMPLATES: "/endpoints/control/templates",
  SETTINGS: "/endpoints/control/settings",
};

export const CONTENT = {
  MANAGE_VIDEOS: "/content/video-playlist-channels/videos",
  MANAGE_PLAYLISTS: "/content/video-playlist-channels/playlists",
  MANAGE_CHANNELS: "/content/video-playlist-channels/channels",
  CHANNELS_LIST: "/content/channel-list",
};

export const ACCESS_MANAGEMENT = {
  USERS: "/access/users",
  COMPANIES: "/access/companies",
};
