import React from "react";
import clsx from "clsx";

import classes from "../ChannelDnDList.module.scss";

function ChannelDnDListHeader({ t }) {
  return (
    <thead>
      <tr className={classes["table-head"]}>
        <th
          className={clsx(
            classes["fixed-header"],
            classes["table-col-width-5"]
          )}
        >
          &nbsp;
        </th>
        <th
          align="center"
          className={clsx(
            classes["fixed-header"],
            classes["table-col-width-5"]
          )}
        >
          {t("number")}
        </th>
        <th
          className={clsx(
            classes["fixed-header"],
            classes["p-left"],
            classes["table-col-width-10"]
          )}
        >
          {t("logo")}
        </th>
        <th
          className={clsx(
            classes["fixed-header"],
            classes["p-left"],
            classes["table-col-width-10"]
          )}
        >
          {t("preview")}
        </th>
        <th
          className={clsx(
            classes["fixed-header"],
            classes["p-left"],
            classes["table-col-width-10"]
          )}
        >
          Type
        </th>
        <th
          className={clsx(
            classes["fixed-header"],
            classes["p-left"],
            classes["table-col-width-30"]
          )}
        >
          {t("details")}
        </th>
      </tr>
    </thead>
  );
}

export default ChannelDnDListHeader;
