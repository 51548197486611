import React from "react";
import { func, object } from "prop-types";
import { useTranslation } from "react-i18next";

import { getInputError, validateIDTName, validateRequired } from "utils/utils";
import { MAX_IDTNAME_LEN } from "data/constants";

import Form from "components/ui/form/Form";

function AddGroupForm({ register, errors, handlePressEnter }) {
  const { t } = useTranslation("endpoint-groups");

  return (
    <Form>
      <Form.Field>
        <Form.Label htmlFor="name">{t("name")}</Form.Label>
        <Form.Input
          // @ts-ignore
          type="text"
          id="name"
          name="name"
          ref={register({
            ...validateRequired,
            ...validateIDTName
          })}
          error={getInputError(errors.name, t, MAX_IDTNAME_LEN)}
          onKeyPress={handlePressEnter}
          data-cy="endpoint-group-name-input"
        />
      </Form.Field>
    </Form>
  );
}

AddGroupForm.propTypes = {
  register: func,
  errors: object,
  handlePressEnter: func
};

export default AddGroupForm;
