import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { baseUrl } from "data/constants";
import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";

import { ServerContext } from "contexts/ServerContext";
import useIntervalScreenshots from "hooks/useIntervalScreenshots";

import ChannelDnDListHeader from "./ChannelDnDListHeader/ChannelDnDListHeader";
import ChannelDnDRow from "./ChannelDnDRow/ChannelDnDRow";
import Table from "components/ui/table/Table";

import { reorder } from "utils/utils";
import useApi from "hooks/useApi";
import ListDrawer from "components/ui/drawers/ListDrawer";

function ChannelDnDList({
  channels,
  channelToEdit,
  onSelectChannelToEdit,
  open
}) {
  const [channelList, setChannelList] = useState([]);
  const [screenshotsURLs, setScreenshotsURLs] = useState({});
  const { t } = useTranslation("channel-list");
  const { serverInUse } = useContext(ServerContext);

  const { screenshots } = useIntervalScreenshots(
    {
      intervalTime: 30000,
      makeRequestOnMount: true
    },
    channels
  );

  const [, , , reorderChannels] = useApi(
    ChannelListApi.updateListOrderAfterDnD,
    {
      requestOnMount: false
    }
  );

  useEffect(() => {
    setChannelList(channels);
  }, [channels]);

  useEffect(() => {
    const channelsScreensURLs = Object.entries(screenshots).reduce(
      (acc, [channelId, { path }]) => {
        acc[channelId] = `${baseUrl}${path}`;
        return acc;
      },
      {}
    );

    setScreenshotsURLs(channelsScreensURLs);
  }, [screenshots]);

  const onDragEnd = result => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    const items = reorder(channelList, source.index, destination.index);

    const body = {
      current: items[destination.index].id,
      neighbour:
        source.index > destination.index
          ? items[destination.index + 1].id
          : items[destination.index - 1].id,
      aboveLast: destination.index < items.length - 1
    };

    reorderChannels(body);
    setChannelList(items);
  };

  return (
    <ListDrawer open={open}>
      <Table>
        <ChannelDnDListHeader t={t} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {channelList.map((channel, index) => {
                  const screenshotUrlId = `${channel.id}-${serverInUse.id}`;
                  return (
                    <ChannelDnDRow
                      key={`${index}-${channel.id}`}
                      index={index}
                      channel={channel}
                      screenshotURL={screenshotsURLs[screenshotUrlId]}
                      screenshot={screenshots[screenshotUrlId]}
                      onSelectChannel={onSelectChannelToEdit}
                      rowSelected={channel.id === channelToEdit.id}
                      editFormActive={!!channelToEdit.id}
                    />
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </ListDrawer>
  );
}

export default ChannelDnDList;
