import React from "react";
import { func } from "prop-types";
import { Tooltip } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import clsx from "clsx";

import { playlistChannelType } from "types";

import ToolTipTitle from "./components/tooltip-title/TooltipTitle";

import classes from "./ChannelSettingsIcon.module.scss";

function ChannelSettingsIcon({
  channel,
  toggleBroadcastModalOpen,
  setChannelToBeModified
}) {
  const handleSettingsClick = e => {
    e.stopPropagation();
    if (!channel.enabled) {
      toggleBroadcastModalOpen();
      setChannelToBeModified(channel);
    }
  };

  return (
    <Tooltip
      title={<ToolTipTitle enabled={channel.enabled} />}
      placement="left-start"
      classes={{ tooltip: classes.tooltip }}
    >
      <SettingsIcon
        className={clsx(classes.icon, {
          [classes.disabled]: channel.enabled
        })}
        onClick={handleSettingsClick}
      />
    </Tooltip>
  );
}

ChannelSettingsIcon.propTypes = {
  channel: playlistChannelType,
  toggleBroadcastModalOpen: func.isRequired,
  setChannelToBeModified: func.isRequired
};

export default ChannelSettingsIcon;
