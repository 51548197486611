import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { func } from "prop-types";
import {
  Paper,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";

import { styleType } from "types";

import { ChromePicker } from "react-color";

import CustomButton from "components/ui/buttons/custom-button/CustomButton";
import NumberInput from "components/ui/inputs/number-input/NumberInput";
import PrimaryInput from "components/ui/inputs/primary-input/PrimaryInput";

import classes from "../AlertPreview.module.scss";

function AlertSettings({
  styles,
  toTop,
  toBottom,
  setAlignLeft,
  setAlignCenter,
  setAlignRight,
  setFontSize,
  setColor,
  setFontFamily,
  setFontWeight,
}) {
  const { t } = useTranslation("control");
  return (
    <Paper className={classes["alert-preview-settings"]}>
      <Accordion className={classes["expansion-panel"]}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes["expansion-panel-summary"]}
          classes={{
            content: classes["expansion-panel-summary-content"],
            expanded: classes["expansion-panel-summary-expanded"],
            expandIcon: classes["expansion-panel-summary-expand-icon"],
          }}
        >
          {t("alerts.control.advanced")}
        </AccordionSummary>
        <AccordionDetails className={classes["details"]}>
          <div className={classes["controller"]}>
            <div className={classes["controller-section"]}>
              <div className={classes["controller-section-label"]}>
                {t("alerts.control.vertical-align")}
              </div>
              <div className={classes["controller-actions"]}>
                <CustomButton variant="contained" onClick={toTop}>
                  <KeyboardArrowUpIcon className={classes["btn-icon"]} />
                </CustomButton>
                <CustomButton variant="contained" onClick={toBottom}>
                  <KeyboardArrowDownIcon className={classes["btn-icon"]} />
                </CustomButton>
              </div>
            </div>
            <div className={classes["controller-section"]}>
              <div className={classes["controller-section-label"]}>
                {t("alerts.control.text-align")}
              </div>
              <div className={classes["controller-actions"]}>
                <CustomButton variant="contained" onClick={setAlignLeft}>
                  <FormatAlignLeftIcon
                    className={clsx(
                      classes["btn-icon"],
                      classes["btn-icon-small"]
                    )}
                  />
                </CustomButton>
                <CustomButton variant="contained" onClick={setAlignCenter}>
                  <FormatAlignCenterIcon
                    className={clsx(
                      classes["btn-icon"],
                      classes["btn-icon-small"]
                    )}
                  />
                </CustomButton>
                <CustomButton variant="contained" onClick={setAlignRight}>
                  <FormatAlignRightIcon
                    className={clsx(
                      classes["btn-icon"],
                      classes["btn-icon-small"]
                    )}
                  />
                </CustomButton>
              </div>
            </div>
            <div className={classes["controller-section"]}>
              <div className={classes["controller-section-label"]}>
                {t("alerts.control.font-size")}
              </div>
              <div className={classes["controller-actions"]}>
                px
                <NumberInput
                  name="font-size"
                  value={styles["font-size"]}
                  onChange={setFontSize}
                  step={1}
                />
              </div>
            </div>
            <div className={classes["controller-section"]}>
              <div className={classes["controller-section-label"]}>
                {t("alerts.control.color")}
              </div>
              <div className={classes["controller-actions"]}>
                <ChromePicker
                  color={styles.color || "#fff"}
                  onChangeComplete={setColor}
                />
              </div>
            </div>
            <div className={classes["controller-section"]}>
              <div className={classes["controller-section-label"]}>
                {t("alerts.control.font-family")}
              </div>
              <div className={classes["controller-actions"]}>
                <Select
                  classes={{
                    select: classes["controller-select"],
                    icon: classes["controller-select-icon"],
                  }}
                  className={classes["controller-select"]}
                  value={styles["font-family"]}
                  onChange={setFontFamily}
                  input={<PrimaryInput />}
                >
                  <MenuItem value="auto">Auto</MenuItem>
                  <MenuItem value="cursive">Cursive</MenuItem>
                  <MenuItem value="monospace">Monospace</MenuItem>
                  <MenuItem value="sans-serif">Sans-Serif</MenuItem>
                  <MenuItem value="serif">Serif</MenuItem>
                  <MenuItem value="unset">Unset</MenuItem>
                </Select>
              </div>
            </div>
            <div className={classes["controller-section"]}>
              <div className={classes["controller-section-label"]}>
                {t("alerts.control.font-weight")}
              </div>
              <div className={classes["controller-actions"]}>
                <Select
                  classes={{
                    select: classes["controller-select"],
                    icon: classes["controller-select-icon"],
                  }}
                  className={classes["controller-select"]}
                  value={styles["font-weight"]}
                  onChange={setFontWeight}
                  input={<PrimaryInput />}
                >
                  <MenuItem value="normal">Normal</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="200">200</MenuItem>
                  <MenuItem value="300">300</MenuItem>
                  <MenuItem value="400">400</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                  <MenuItem value="600">600</MenuItem>
                  <MenuItem value="700">700</MenuItem>
                  <MenuItem value="800">800</MenuItem>
                  <MenuItem value="900">900</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}

AlertSettings.propTypes = {
  styles: styleType.isRequired,
  toTop: func.isRequired,
  toBottom: func.isRequired,
  setAlignLeft: func.isRequired,
  setAlignCenter: func.isRequired,
  setAlignRight: func.isRequired,
  setFontSize: func.isRequired,
  setColor: func.isRequired,
  setFontFamily: func.isRequired,
  setFontWeight: func.isRequired,
};

export default AlertSettings;
