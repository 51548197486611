import React, { useState } from "react";
import { string, arrayOf, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Slider } from "@material-ui/core";

import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import TvControlApi from "services/api/tv-control-api/tv-control-api";
import useApi from "hooks/useApi";
import { requestPayloadType } from "types";
import { tvControlSetRequestsMessages } from "pages/endpoints/control/control-panel/utils/utils";

import SetActionLoader from "../loader/SetActionLoader";

import classes from "../TvControl.module.scss";

function ChangeVolume({ requestPayload, setGroups }) {
  const [volume, setVolume] = useState(50);
  const { t } = useTranslation("control");

  const { SET_VOLUME_SUCCESS } = tvControlSetRequestsMessages(t);

  const handleVolumeChange = (_, newVolume) => {
    setVolume(newVolume);
  };

  const setGroupsOnSuccess = () => {
    setGroups("volume", "" + volume);
  };

  const [, channelVolumeLoading, , changeVolume] = useApi(
    TvControlApi.setVolume,
    {
      requestOnMount: false,
      onSuccess: setGroupsOnSuccess,
      successMessage: SET_VOLUME_SUCCESS,
    }
  );

  const handleApply = () => {
    const payload = {
      ...requestPayload,
      volume: "" + volume, // cast to string
    };

    changeVolume(payload);
  };
  return (
    <Paper className={classes["box"]}>
      <div className={classes["box-title"]}>
        <span>{t("tv-control.change-volume")}</span>
        <SetActionLoader loading={channelVolumeLoading} />
      </div>
      <div className={classes["box-content"]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            {volume === 0 ? (
              <VolumeOffIcon
                className={classes["volume-icon"]}
                onClick={() => setVolume(50)}
              />
            ) : (
              <VolumeDown
                className={classes["volume-icon"]}
                onClick={() => setVolume(0)}
              />
            )}
          </Grid>
          <Grid item xs>
            <Slider
              classes={{
                root: classes["slider"],
                thumb: classes["slider-track"],
                track: classes["slider-track"],
                rail: classes["slider-track"],
                valueLabel: classes["slider-value-label"],
              }}
              value={volume}
              onChange={handleVolumeChange}
              min={0}
              max={100}
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item>
            <VolumeUp
              className={classes["volume-icon"]}
              onClick={() => {
                if (volume !== 100) {
                  setVolume(100);
                } else {
                  setVolume(50);
                }
              }}
            />
          </Grid>
          <Grid item>
            <PrimaryButton onClick={handleApply}>
              {t("common:apply")}
            </PrimaryButton>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

ChangeVolume.propTypes = {
  requestPayload: requestPayloadType,
  setGroups: func.isRequired,
};

export default ChangeVolume;
