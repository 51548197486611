import React from "react";
import { object } from "prop-types";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { convertBytes } from "utils/utils";

import classes from "./DiskUsage.module.scss";

function DiskUsage({ server }) {
  const { t } = useTranslation("servers");
  const totalSpace = server.diskTotalSpace;
  const freeSpace = server.diskFreeSpace;
  const usage = 100 - (freeSpace / totalSpace) * 100;
  const barClasses = {
    barColorPrimary:
      usage > 90
        ? classes["bar-front-full"]
        : usage > 75
        ? classes["bar-front-medium"]
        : classes["bar-front"],
    colorPrimary:
      usage > 90
        ? classes["bar-background-full"]
        : usage > 75
        ? classes["bar-background-medium"]
        : classes["bar-background"]
  };

  return (
    <div className={classes["disk-usage"]}>
      <div className={classes["disk-usage-header"]}>
        <DataUsageIcon classes={{ root: classes["disk-usage-header-icon"] }} />
        <div className={classes["disk-usage-header-label"]}>
          {t("usage.disk-usage")}
        </div>
      </div>

      <div className={classes["disk-usage-bar"]}>
        {!isNaN(usage) ? (
          <LinearProgress
            variant="determinate"
            value={usage}
            classes={barClasses}
          />
        ) : null}
        {!isNaN(usage) ? (
          <div className={classes["disk-usage-bar-values"]}>
            <span>
              {t("usage.used")}:&nbsp;{usage.toFixed(0)}%
            </span>

            <div>
              <span>
                {t("usage.free")}:&nbsp;{convertBytes(freeSpace)}
              </span>
              <span>
                &nbsp;&nbsp;{t("usage.total")}:&nbsp;{convertBytes(totalSpace)}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

DiskUsage.propTypes = {
  server: object
};

export default DiskUsage;
