import React from "react";
import { string } from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Title = ({ section, subSection }) => {
  const { t } = useTranslation("common", { useSuspense: false });

  return (
    <Helmet>
      <title>
        {subSection
          ? t(`${"side-menu"}.${section}.${subSection}`)
          : section
          ? t(`${"side-menu"}.${section}`)
          : t("welcome-to-idt")}
      </title>
    </Helmet>
  );
};

Title.propTypes = {
  section: string,
  subSection: string
};

export default Title;
