import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { arrayOf, string, bool, func, number } from "prop-types";
import Scrollbars from "react-custom-scrollbars";
import ErrorIcon from "@material-ui/icons/Error";

import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import BackupItem from "./backup-item/BackupItem";
import CreateBackupButton from "../create-backup-btn/CreateBackupButton";

import ServersApi from "services/api/6-servers-api/servers-api";
import useApi from "hooks/useApi";
import { backupsRequestsMessages } from "../../utils/utils";

import classes from "./BackupsList.module.scss";

function BackupsList({
  backups,
  serverId,
  currentBranch,
  isActionInProgress,
  isRebootInProgress,
  isVisible,
  openCreateBackupModal,
  fetchBackups,
}) {
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [backupToBeRestored, setBackupToBeRestored] = useState("");
  const [backupToBeDeleted, setBackupToBeDeleted] = useState("");
  const { t } = useTranslation("servers");
  const {
    RESTORE_BACKUP_SUCCESS,
    RESTORE_BACKUP_ERROR,
    DELETE_BACKUP_SUCCESS,
    DELETE_BACKUP_ERROR,
  } = backupsRequestsMessages(t);

  const openRestoreModal = () => {
    setRestoreModalOpen(true);
  };

  const closeRestoreModal = () => {
    setRestoreModalOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const onRestoreSuccess = () => {
    closeRestoreModal();
    fetchBackups(serverId);
  };

  const onDeleteSuccess = () => {
    closeDeleteModal();
    fetchBackups(serverId);
  };

  const [, restoreBackupLoader, , restoreBackup] = useApi(
    ServersApi.restoreBackup,
    {
      requestOnMount: false,
      successMessage: RESTORE_BACKUP_SUCCESS,
      errorMessage: RESTORE_BACKUP_ERROR,
      onSuccess: onRestoreSuccess,
    }
  );

  const handleRestore = () => {
    const payload = {
      snapshotName: backupToBeRestored,
      serverId,
    };

    restoreBackup(payload);
  };

  const [, deleteBackupLoader, , deleteBackup] = useApi(
    ServersApi.deleteBackup,
    {
      requestOnMount: false,
      successMessage: DELETE_BACKUP_SUCCESS,
      errorMessage: DELETE_BACKUP_ERROR,
      onSuccess: onDeleteSuccess,
    }
  );

  const handleDelete = () => {
    const payload = {
      snapshotName: backupToBeDeleted,
      serverId,
    };

    deleteBackup(payload);
  };

  return (
    <>
      {isVisible ? (
        <div className={classes["backups-list-container"]}>
          {backups.length > 0 ? (
            <Scrollbars autoHeight autoHeightMax="29vh">
              <ul className={classes["backups-list"]}>
                {backups.map((backup, index) => (
                  <BackupItem
                    key={`${backup}-${index}`}
                    backup={backup}
                    currentBranch={currentBranch}
                    openRestoreModal={openRestoreModal}
                    openDeleteModal={openDeleteModal}
                    isActionInProgress={isActionInProgress}
                    isRebootInProgress={isRebootInProgress}
                    setBackupToBeRestored={setBackupToBeRestored}
                    setBackupToBeDeleted={setBackupToBeDeleted}
                  />
                ))}
              </ul>
            </Scrollbars>
          ) : (
            <div className={classes["no-backups"]}>
              {t("backups.warnings.no-backups-yet")}
            </div>
          )}
        </div>
      ) : (
        <div className={classes["backups-load-error"]}>
          <ErrorIcon className={classes["backups-load-error-icon"]} />
          <span className={classes["backups-load-error-text"]}>
            {t("backups.warnings.backups-not-loaded")}
          </span>
        </div>
      )}

      <CreateBackupButton
        onClick={openCreateBackupModal}
        isActionInProgress={isActionInProgress}
        isRebootInProgress={isRebootInProgress}
      />

      <ConfirmationModal
        open={restoreModalOpen}
        onClose={closeRestoreModal}
        onAction={handleRestore}
        actionName="Restore"
        disabled={restoreBackupLoader}
        loading={restoreBackupLoader}
      >
        {t("backups.modals.restore-backup")}
      </ConfirmationModal>

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={handleDelete}
        actionName="Delete"
        disabled={deleteBackupLoader}
        loading={deleteBackupLoader}
      >
        {t("backups.modals.delete-backup")}
      </ConfirmationModal>
    </>
  );
}

BackupsList.propTypes = {
  backups: arrayOf(string),
  serverId: number,
  currentBranch: string,
  isActionInProgress: bool,
  isRebootInProgress: bool,
  isVisible: bool.isRequired,
  openCreateBackupModal: func.isRequired,
  fetchBackups: func,
};

export default BackupsList;
