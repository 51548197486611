import React, { useEffect, useState } from "react";
import { func, arrayOf, object, bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import CustomCheckbox from "components/ui/inputs/custom-checkbox/CustomCheckbox";

import classes from "./AssignTemplateModal.module.scss";

function AssignTemplateModal({
  open,
  onClose,
  onAction,
  handleCheckboxChange,
  selectedGroups,
  loading,
  selectedGroupsInContext,
  allGroups
}) {
  const { t } = useTranslation("control");
  const [checked, setChecked] = useState([]);
  const [groupsForTemplates, setGroupForTemplates] = useState([]);

  const onCheck = ({ target }) => {
    const { id: groupId } = target;

    if (checked.includes(groupId)) {
      const updatedArray = checked.filter(item => item !== groupId);
      setChecked(updatedArray);
    } else {
      setChecked(prevChecked => [...prevChecked, groupId]);
    }

    handleCheckboxChange(groupId);
  };

  useEffect(() => {
    if (open) {
      const groupsSelected = allGroups.filter(group =>
        selectedGroupsInContext.some(item => item === group.id)
      );
      const allSelectedGroups = [...selectedGroups, ...groupsSelected].map(
        ({ id, name }) => ({
          id,
          name
        })
      );
      const selectedGroupsFinal = [
        // @ts-ignore - This removes duplicates from array
        ...new Map(
          allSelectedGroups.map(obj => [JSON.stringify(obj), obj])
        ).values()
      ];

      setGroupForTemplates(selectedGroupsFinal);
      setChecked([]);
    }
  }, [open, selectedGroups]);

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onAction={onAction}
      actionName={t("common:apply")}
      headerContent={
        selectedGroups.length === 1
          ? t("templates.assign-modal.header.singular")
          : t("templates.assign-modal.header.plural")
      }
      loading={loading}
      disabled={loading}
    >
      <div className={classes["assign-table"]}>
        <div className={classes["assign-table-header"]}>
          <div className={classes["assign-table-header-name"]}>
            {t("templates.assign-modal.table.head.group")}
          </div>

          <div className={classes["assign-table-header-assign"]}>
            {t("templates.assign-modal.table.head.assign")}
          </div>

          <div className={classes["assign-table-header-default"]}>
            <div>{t("templates.assign-modal.table.head.default")}</div>
            <div className={classes["info-icon"]}>
              <Tooltip
                title={t("templates.assign-modal.table.head.tooltip")}
                placement="top-start"
                classes={{ tooltip: classes["tooltip-font"] }}
              >
                <HelpIcon />
              </Tooltip>
            </div>
          </div>
        </div>

        {groupsForTemplates.map(group => (
          <div className={classes["assign-table-row"]} key={group.id}>
            <div className={classes["assign-table-row-name"]}>{group.name}</div>
            <div className={classes["assign-table-row-assign"]}>
              <CheckBoxIcon className={classes["check-box-icon"]} />
            </div>
            <div className={classes["assign-table-row-default"]}>
              <CustomCheckbox
                id={group.id}
                rootClass={classes["check-box"]}
                onChange={onCheck}
                checked={checked.includes(group.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </ConfirmationModal>
  );
}

AssignTemplateModal.propTypes = {
  open: bool,
  onClose: func,
  onAction: func,
  handleCheckboxChange: func,
  selectedGroups: arrayOf(object),
  loading: bool,
  selectedGroupsInContext: arrayOf(string),
  allGroups: arrayOf(object)
};

export default AssignTemplateModal;
