import {useContext, useEffect} from "react";
import AuthContext from "../contexts/AuthContext";

function useGetIframeMessage() {
  const {actions} = useContext(AuthContext);
  useEffect(() => {
    const handler = event => {
      if (!!event.data.action) {
        const action = event.data.action
        const payload = event.data.payload

        if (action === 'UNAUTHORIZED') {
          actions.setIsAuthenticated(false, payload.method === 'get')
        }
      }
    }
    window.addEventListener("message", handler)
    // clean up
    return () => window.removeEventListener("message", handler)
  }, []) // empty array => run only once
}

export default useGetIframeMessage;
