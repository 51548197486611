import React, { useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import Flag from "./components/flag/Flag";

import { useTranslation } from "react-i18next";

import classes from "./LanguageRadioSelector.module.scss";

const locales = [
  { code: "en-GB", translation: "English" },
  { code: "pl", translation: "Polski" },
  { code: "de", translation: "Deutsche" },
  { code: "fr", translation: "Français" },
  { code: "es", translation: "Español" }
];

function LanguageRadioSelector() {
  const getDefaultLanguage = () => {
    let langCode =
      localStorage.getItem("i18nextLng") || window.navigator.language;
    let validCodes = locales.map(l => l.code);
    if (!validCodes.includes(langCode)) return "en-GB";
    return langCode;
  };

  const [language, setLanguage] = useState(getDefaultLanguage());

  const { i18n } = useTranslation("common", { useSuspense: false });

  const handleChange = e => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  const getLabel = item => {
    return (
      <div className={classes["selector-item"]}>
        <div className={classes["selector-item-flag"]}>
          <Flag code={item.code} width="50px" height="25px" />
        </div>
        <div className={classes["selector-item-label"]}>{item.translation}</div>
      </div>
    );
  };

  return (
    <FormControl
      component="fieldset"
      classes={{ root: classes["form-control"] }}
    >
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={language}
        onChange={handleChange}
      >
        {locales.map(item => (
          <FormControlLabel
            key={item.code}
            value={item.code}
            control={
              <Radio
                disableRipple
                icon={<RadioButtonUncheckedIcon className={classes["icon"]} />}
                checkedIcon={
                  <RadioButtonCheckedIcon className={classes["checked-icon"]} />
                }
              />
            }
            label={getLabel(item)}
            labelPlacement="start"
            classes={{ root: classes["form-control-label"] }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default LanguageRadioSelector;
