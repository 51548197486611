import React from "react";
import { string } from "prop-types";

import classes from "./ModalTitle.module.scss";

function ModalTitle({ title }) {
  return (
    <div className={classes["videos-modal-title"]}>
      <span>{title}</span>
    </div>
  );
}

ModalTitle.propTypes = {
  title: string
};

export default ModalTitle;
