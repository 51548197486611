import React, { useEffect, useState } from "react";
import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "components/ui/modals/modal/Modal";
import PersonalInfoForm from "./personal-info-form/PersonalInfoForm";

import classes from "./PersonalInfoModal.module.scss";

function PersonalInfoModal({ personalInfoOpen, togglePersonalInfoModal }) {
  const [open, setOpen] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const { t } = useTranslation("edit-profile", { useSuspense: false });

  useEffect(() => {
    setOpen(personalInfoOpen);
  }, [personalInfoOpen]);

  const handleClose = () => {
    setOpen(false);
    togglePersonalInfoModal();
  };

  return (
    <Modal open={open}>
      <Modal.Header loading={apiLoading} onClose={handleClose}>
        {t("personal-information")}
      </Modal.Header>
      <Modal.Content>
        <div className={classes["modal-content"]}>
          <PersonalInfoForm
            togglePersonalInfoModal={togglePersonalInfoModal}
            setApiLoading={setApiLoading}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
}

PersonalInfoModal.propTypes = {
  personalInfoOpen: bool.isRequired,
  togglePersonalInfoModal: func.isRequired
};

export default PersonalInfoModal;
