import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { object } from "prop-types";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PanelHeader from "../panel-header/PanelHeader";
import DiskUsage from "./components/disk-usage/DiskUsage";
import NetworkInfo from "./components/network-info/NetworkInfo";

import classes from "./ServerInfo.module.scss";

function ServerInfo({ server }) {
  return server.isOnline ? (
    <div className={classes["server-info"]}>
      <Accordion classes={{ root: classes["server-info-panel"] }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon className={classes["server-info-panel-icon"]} />
          }
          classes={{ root: classes["server-info-panel-summary"] }}
        >
          <PanelHeader label="Info" />
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes["server-info-panel-details"] }}
        >
          <DiskUsage server={server} />
          <NetworkInfo serverId={+server.id} />
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
}

ServerInfo.propTypes = {
  selectedServer: object,
};

export default ServerInfo;
