import React from "react";
import { Input } from "@material-ui/core";
import clsx from "clsx";

import classes from "./FormInput.module.scss";

const FormInput = React.forwardRef(
  // @ts-ignore
  ({ error, formInputClass = null, ...props }, ref) => {
    const ErrorMessage = () => {
      let errorMessage = null;

      // @ts-ignore
      if (props.playlist || props.video || props.search) {
        errorMessage = (
          <div
            className={clsx(classes["error-mssg"], {
              // @ts-ignore
              [classes["error-mssg-playlist"]]: props.playlist,
              // @ts-ignore
              [classes["error-mssg-video"]]: props.video,
              // @ts-ignore
              [classes["error-mssg-search"]]: props.search,
            })}
          >
            {error}
          </div>
        );
      } else {
        errorMessage = (
          <span
            className={clsx(classes["error-mssg"], {
              // @ts-ignore
              [classes["error-mssg-login"]]: props.login,
            })}
          >
            {error}
          </span>
        );
      }

      return error ? errorMessage : null;
    };

    return (
      <div
        className={clsx(classes["input-container"], {
          [formInputClass]: formInputClass,
        })}
      >
        {props.hasOwnProperty("endAdornment") ? (
          <Input
            inputRef={ref}
            className={clsx(classes["input"], {
              [classes["error-border"]]: error,
            })}
            disableUnderline
            {...props}
          />
        ) : (
          <input
            ref={ref}
            className={clsx(classes["input"], {
              [classes["error-border"]]: error,
              // @ts-ignore
              [props.customclass]: props.customclass,
            })}
            autoComplete="off"
            {...props}
          />
        )}
        <ErrorMessage />
      </div>
    );
  }
);

export default FormInput;
