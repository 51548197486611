import React, { useState, useEffect } from "react";
import { string } from "prop-types";
import { useTranslation } from "react-i18next";

import classes from "./Warning.module.scss";

const warnings = {
  ONE_FILE_ONLY: "one-file-only",
  NOT_IMAGE: "not-image",
  IMAGE_SIZE: "image-size",
  NOT_PNG: "not-png"
};

function Warning({ warning }) {
  const [warningText, setWarningText] = useState("");
  const { t } = useTranslation("channel-list");

  useEffect(() => {
    const defineWarning = warning => {
      const { NOT_IMAGE, IMAGE_SIZE, ONE_FILE_ONLY, NOT_PNG } = warnings;

      switch (warning) {
        case ONE_FILE_ONLY:
          setWarningText(t("warnings.you-can-select-one-file-only"));
          break;
        case NOT_IMAGE:
          setWarningText(t("warnings.this-is-not-an-image-file"));
          break;
        case IMAGE_SIZE:
          setWarningText(t("warnings.required-image-dimensions"));
          break;
        case NOT_PNG:
          setWarningText(t("warnings.image-should-be-in-png-format"));
          break;
        default:
          setWarningText("");
      }
    };

    defineWarning(warning);
  }, [warning]);

  return <span className={classes["warning"]}>{warningText}</span>;
}

Warning.propTypes = {
  warning: string.isRequired
};

export default Warning;
