import React, { useState, useEffect } from "react";
import { bool, arrayOf, object, func } from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import ListDrawer from "components/ui/drawers/ListDrawer";
import Table from "components/ui/table/Table";

import classes from "./EndPointGroupsList.module.scss";

export default function EndPointGroupsList({
  endpointGroups,
  selectedGroup,
  toggleSelectedGroup,
  open
}) {
  const [groups, setGroups] = useState([]);
  const { t } = useTranslation("endpoint-groups");

  useEffect(() => {
    setGroups(endpointGroups);
  }, [endpointGroups]);

  const handleGroupSelection = group => {
    toggleSelectedGroup(group);
  };

  return (
    <ListDrawer open={open}>
      <Table data-cy="endpoints-groups-table" fullWidth>
        <Table.Head>
          <th className={classes["table-head-5"]}>&nbsp;</th>
          <th className={classes["table-head-40"]}>
            {t("end-point-group-name")}
          </th>
          <th
            className={clsx(
              classes["table-head-30"],
              classes["details-column"]
            )}
          >
            {t("details")}
          </th>
          <th className={classes["table-head-10"]}>PIN</th>
        </Table.Head>
        <Table.Body>
          {groups.map((group, index) => {
            const selected = group.id === selectedGroup.id;
            return (
              <Table.Row
                key={group.id}
                selected={selected}
                data-cy={`endpoint-group-${group.id}`}
              >
                <td
                  align="center"
                  id={group.id}
                  onClick={() => handleGroupSelection(group)}
                  className={classes["row-height"]}
                >
                  <span className={classes["table-column"]}>{index + 1}</span>
                </td>
                <td
                  className={classes["p-left"]}
                  onClick={() => handleGroupSelection(group)}
                >
                  <div className={classes["table-column-flex"]}>
                    <div className={classes["p-left"]}>
                      <span
                        className={clsx(
                          classes["table-column"],
                          classes["table-column-name-cell"],
                          {
                            [classes["edit-mode"]]: !open
                          }
                        )}
                      >
                        {group.name}
                      </span>
                    </div>
                  </div>
                </td>
                <td
                  className={clsx(classes["p-left"], classes["details-column"])}
                  onClick={() => handleGroupSelection(group)}
                >
                  <div>
                    <span className={classes["table-column-details-text"]}>
                      {t("endpoints-assigned")}:&nbsp;
                      {group.endpoints.length}
                    </span>
                  </div>
                </td>
                <td
                  align="center"
                  id={group.pin}
                  onClick={() => handleGroupSelection(group)}
                  className={classes["row-height"]}
                >
                  <span className={classes["table-column"]}>{group.pin}</span>
                </td>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </ListDrawer>
  );
}

EndPointGroupsList.propTypes = {
  endpointGroups: arrayOf(object),
  selectedGroup: object,
  toggleSelectedGroup: func,
  open: bool
};
