import React, { useEffect, useState } from "react";
import { arrayOf, object } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import moment from "moment";

import { BAR_COLORS } from "../../../../../../utils/utils";

import CustomTooltip from "../../../custom-tooltip/CustomTooltip";
import XAxisTick from "../../../x-axis-tick/XAxisTick";

import classes from "./Chart.module.scss";

const YAxisTick = () => {
  const { t } = useTranslation("dashboard");

  const getOfflineLabel = () =>
    t("statuses.offline") === "Desconectado"
      ? "Descon."
      : t("statuses.offline");

  return (
    <g transform={`translate(57, 0)`} stroke="ff0000">
      <text
        x={-3}
        y={-7}
        dy={16}
        textAnchor="end"
        fill="#56636b"
        fontSize="1.2rem"
      >
        {t("statuses.online")}
      </text>
      <text
        x={-3}
        y={102}
        dy={16}
        textAnchor="end"
        fill="#56636b"
        fontSize="1.2rem"
      >
        {getOfflineLabel()}
      </text>
    </g>
  );
};

function Chart({ data }) {
  const { ONLINE_FILL, TOOLTIP_CURSOR } = BAR_COLORS;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data.length) {
      const dataArray = data.reverse().map(item => ({
        ...item,
        online: item.isServerOnline ? 1 : 0,
        date: moment(item.createdAt).local().format("DD MMM, hh:mm")
      }));

      setChartData(dataArray);
    }
  }, [data]);

  return (
    <div className={classes["server-stats-chart"]}>
      {chartData.length ? (
        <BarChart width={700} height={200} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" stroke="#000" />
          <XAxis
            dataKey="date"
            tick={false}
            height={60}
            // @ts-ignore
            tick={<XAxisTick />}
          />
          <YAxis
            scale="point"
            // @ts-ignore
            tick={<YAxisTick />}
          />
          <Tooltip
            cursor={{ stroke: TOOLTIP_CURSOR, strokeWidth: 2 }}
            content={<CustomTooltip server />}
          />
          <ReferenceLine y={0} stroke="#000" />
          <Brush dataKey="date" height={20} stroke={ONLINE_FILL} />
          <Bar dataKey="online" fill={ONLINE_FILL} />
        </BarChart>
      ) : null}
    </div>
  );
}

Chart.propTypes = {
  data: arrayOf(object)
};

export default Chart;
