import React from "react";
import { bool } from "prop-types";
import clsx from "clsx";

import classes from "../../ServersTable.module.scss";

function ServerStatus({ status = false }) {
  return (
    <div
      className={clsx(classes["server-status"], classes["red"], {
        [classes["green"]]: status
      })}
    >
      <span>{status ? "ONLINE" : "OFFLINE"}</span>
    </div>
  );
}

ServerStatus.propTypes = {
  status: bool
};

export default ServerStatus;
