import React, { useEffect } from "react";
import { func, string, bool } from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import CustomButton from "components/ui/buttons/custom-button/CustomButton";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ModalButtons.module.scss";

function ModalButtons({
  clearSelected,
  actionName,
  handleAddVideosToPlaylist,
  disabled,
  numberOfSelectedVideos
}) {
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.videos.create-playlist-prompt";

  return (
    <div className={classes["buttons"]}>
      <div className={classes["selected"]}>
        <CustomButton
          onClick={clearSelected}
          classes={{
            root: clsx(classes["selected-clear-btn"], {
              [classes["disabled"]]: disabled
            })
          }}
          disabled={disabled}
        >
          {t("sections.playlists.clear-selected")}
        </CustomButton>
        {numberOfSelectedVideos > 0 && (
          <div className={classes["selected-videos-number"]}>
            {t(`${PREFIX}.number-of-selected-videos`)}&nbsp;
            {numberOfSelectedVideos}
          </div>
        )}
      </div>

      <PrimaryButton onClick={handleAddVideosToPlaylist} disabled={disabled}>
        {actionName}
      </PrimaryButton>
    </div>
  );
}

ModalButtons.propTypes = {
  clearSelected: func.isRequired,
  actionName: string.isRequired,
  handleAddVideosToPlaylist: func.isRequired,
  disabled: bool.isRequired
};

export default ModalButtons;
