import React from "react";
import { object, func, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import { validateRequired, validateIDTName, getInputError } from "utils/utils";
import { MAX_IDTNAME_LEN } from "data/constants";

import Form from "components/ui/form/Form";

import classes from "./PlaylistName.module.scss";

const PlaylistNameContainer = "div";

function PlaylistName({
  editedPlaylist,
  playlist,
  hideEditNameInput,
  register,
  handlePressEnter,
  updating,
  handleRenameClick,
  playlistPlaying,
  playlistAssignedToChannel,
  errors
}) {
  const { t } = useTranslation("video-playlists");

  const handleNameClick = e => {
    e.stopPropagation();
    if (!(playlistPlaying || playlistAssignedToChannel) && !updating) {
      handleRenameClick();
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Tab") {
      e.preventDefault();
    }
  };

  return (
    <PlaylistNameContainer className={classes["playlist-name-container"]}>
      {editedPlaylist.id === playlist.id ? (
        <div
          className={clsx(classes["playlist-name-input-container"], {
            [classes["error"]]: errors.playlistName
          })}
        >
          <Form.Input
            // @ts-ignore
            autoComplete="off"
            className={classes["playlist-name-input"]}
            id="playlist-name"
            name="playlistName"
            ref={register({
              ...validateRequired,
              ...validateIDTName
            })}
            error={getInputError(errors.playlistName, t, MAX_IDTNAME_LEN)}
            onKeyPress={handlePressEnter}
            onKeyDown={handleKeyDown}
            onClick={e => {
              e.stopPropagation();
            }}
            endAdornment={
              <Tooltip
                title={t("common:close")}
                placement="right"
                classes={{ tooltip: classes["tooltip"] }}
              >
                <CloseIcon
                  className={classes["close-icon"]}
                  onClick={hideEditNameInput}
                />
              </Tooltip>
            }
            playlist
          />
        </div>
      ) : (
        <>
          <Tooltip
            title={
              !playlist.assignedToChannel && !updating
                ? t("sections.playlists.tooltips.click-to-rename")
                : updating
                ? t(
                    "sections.playlists.tooltips.cannot-rename-playlist-updating"
                  )
                : t(
                    "sections.playlists.tooltips.cannot-rename-playlist-assigned"
                  )
            }
            placement={updating ? "bottom-start" : "right"}
            classes={{ tooltip: classes["tooltip"] }}
          >
            <div
              className={clsx(classes["playlist-name"], {
                [classes["not-allowed"]]: playlist.assignedToChannel || updating
              })}
              onClick={handleNameClick}
            >
              <span>{playlist.name}</span>
            </div>
          </Tooltip>

          {updating && (
            <span className={classes["playlist-updating"]}>
              {t("sections.playlists.video-placeholder.playlist-updating")}
            </span>
          )}
        </>
      )}
    </PlaylistNameContainer>
  );
}

PlaylistName.propTypes = {
  editedPlaylist: object,
  playlist: object,
  hideEditNameInput: func,
  register: func,
  handlePressEnter: func,
  updating: bool,
  handleRenameClick: func,
  playlistPlaying: bool,
  playlistAssignedToChannel: bool,
  errors: object
};

export default PlaylistName;
