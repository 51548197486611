import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

import ControlContext from "contexts/ControlContext";
import { ENDPOINT_CONTROL } from "data/urls";
import useTimeout from "hooks/useTimeout";

import ForceChannelChange from "../force-channel-change/ForceChannelChange";
import RefreshAndReboot from "./refresh-and-reboot/RefreshAndReboot";
import StbStartupMode from "./stb-startup-mode/StbStartupMode";

import classes from "./Settings.module.scss";

function Settings() {
  const [expanded, setExpanded] = useState("");
  const { groups, endpointIds, setGroups, hidden, setForceChannelChangeMode } =
    useContext(ControlContext);
  const { t } = useTranslation("control");
  const history = useHistory();
  const setTimeOut = useTimeout();

  const [hide, setHide] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  useEffect(() => {
    setForceChannelChangeMode(expanded === "panel1");
  }, [expanded]);

  const handleClose = () => {
    setHide(true);
    setForceChannelChangeMode(false);
    setTimeOut(() => {
      history.push(ENDPOINT_CONTROL.TV_CONTROL.DEFAULT);
    }, [250]);
  };

  return (
    <div
      className={clsx(classes.container, {
        [classes.hide]: hide,
      })}
    >
      <div className={classes.header}>
        <div className={classes["header-title"]}>
          <span>{t("tv-control.advanced-settings")}</span>
        </div>
        <Tooltip
          title="Close settings"
          placement="left"
          classes={{ tooltip: classes["tooltip"] }}
          enterDelay={1000}
        >
          <CloseIcon className={classes["close-icon"]} onClick={handleClose} />
        </Tooltip>
      </div>
      {/* Force channel change */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        classes={{
          root: classes.accordion,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{
            expandIcon: classes["accordion-icon"],
          }}
        >
          <Typography className={classes["accordion-heading"]}>
            {t("force-channel-change.force-channel-change")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ForceChannelChange />
        </AccordionDetails>
      </Accordion>

      {/* Refresh and reboot */}

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        classes={{ root: classes.accordion }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          classes={{
            expandIcon: classes["accordion-icon"],
            root: classes["accordion-summary"],
          }}
        >
          <Typography
            className={clsx(classes["accordion-heading"], {
              [classes.disabled]: hidden,
            })}
          >
            {t("refresh-reboot.refresh-reboot")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RefreshAndReboot
            groups={groups}
            endpoints={endpointIds}
            hidden={hidden}
          />
        </AccordionDetails>
      </Accordion>

      {/* STB startup mode */}

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        classes={{ root: classes.accordion }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          classes={{ expandIcon: classes["accordion-icon"] }}
        >
          <Typography
            className={clsx(classes["accordion-heading"], {
              [classes.disabled]: hidden,
            })}
          >
            {t("stb-startup.stb-startup")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StbStartupMode
            groups={groups}
            endpoints={endpointIds}
            setGroups={setGroups}
            hidden={hidden}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Settings;
