import React from "react";
import clsx from "clsx";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./TopSection.module.scss";

function TopSection({ loading, handleHide, text }) {
  return (
    <div className={classes["messages-top-bar"]}>
      <div
        className={clsx(classes["loader"], {
          [classes["loader-visible"]]: loading
        })}
      >
        <EllipsisLoader />
      </div>
      <PrimaryButton size="large" onClick={handleHide}>
        {text}
      </PrimaryButton>
    </div>
  );
}

export default TopSection;
