import React from "react";
import clsx from "clsx";
import { string, func, bool } from "prop-types";
import { Zoom } from "@material-ui/core";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./PageHeader.module.scss";

function PageHeader({
  title = "",
  open = true,
  onClick = () => {},
  butttonLabel = "",
  hasAccess = true,
  noPadding = false,
  apiLoadSuccessful = false,
  tabs = false
}) {
  return (
    <div
      className={clsx(classes.container, {
        [classes["no-padding"]]: noPadding
      })}
    >
      {title && <h1 className={classes.title}>{title}</h1>}
      {hasAccess && (
        <div
          className={clsx({
            [classes["button-tabs"]]: tabs
          })}
        >
          <Zoom
            in={open && apiLoadSuccessful}
            timeout={{ enter: 300, exit: 300 }}
          >
            <PrimaryButton onClick={onClick} data-cy="add-button">
              <span className={classes["button-label"]}>{butttonLabel}</span>
            </PrimaryButton>
          </Zoom>
        </div>
      )}
    </div>
  );
}

PageHeader.propTypes = {
  title: string,
  open: bool,
  onClick: func,
  butttonLabel: string,
  hasAccess: bool,
  noPadding: bool,
  apiLoadSuccessful: bool,
  tabs: bool
};

export default PageHeader;
