import { useState } from "react";
import useInterval from "./useInterval";
import fallbackImg from "assets/images/tv-screen/tv-test-stripes.png";

export const DIRECTION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical"
};

function useSprite({
  frameWidth,
  frameHeight,
  spriteUrl,
  frames,
  direction = DIRECTION.HORIZONTAL,
  stepTime = 500,
  scale = 1
}) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { setInterVal, clearInterVal } = useInterval();

  const onSpriteMouseEnter = () => {
    setInterVal(() => {
      if (direction === DIRECTION.VERTICAL) {
        setHeight(prevHeight => prevHeight - frameHeight * scale);
      } else {
        setWidth(prevWidth => prevWidth - frameWidth * scale);
      }
    }, stepTime);
  };

  const onSpriteMouseLeave = () => {
    clearInterVal();
    if (direction === DIRECTION.VERTICAL) {
      setHeight(0);
    } else {
      setWidth(0);
    }
  };

  const calculateBackgroundSize = () => {
    const height =
      direction === DIRECTION.VERTICAL
        ? frameHeight * scale * frames
        : frameHeight * scale;
    const width =
      direction === DIRECTION.HORIZONTAL
        ? frameWidth * scale * frames
        : frameWidth * scale;

    return `${width}px ${height}px`;
  };

  const spriteStyles = {
    backgroundImage: `url(${spriteUrl}), url(${fallbackImg})`,
    backgroundPosition: `${width}px ${height}px`,
    backgroundSize: calculateBackgroundSize(),
    width: frameWidth * scale,
    height: frameHeight * scale
  };

  return {
    width,
    height,
    spriteStyles,
    onSpriteMouseEnter,
    onSpriteMouseLeave
  };
}

export default useSprite;
