import React, { createContext, useState } from "react";

const ServerOfflineContext = createContext({
  serverOffline: false,
  setServerOffline: null
});

export function ServerOfflineContextProvider({ children }) {
  const [serverOffline, setServerOffline] = useState(false);

  return (
    <ServerOfflineContext.Provider
      value={{
        serverOffline,
        setServerOffline
      }}
    >
      {children}
    </ServerOfflineContext.Provider>
  );
}

export default ServerOfflineContext;
