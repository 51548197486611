/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import { CHANNEL_TYPES } from "pages/content/channel-list/utils/utils";
import useApi from "./useApi";

function useIntervalScreenshots(
  { intervalTime = 10000, makeRequestOnMount = false },
  channels
) {
  const previewInterval = useRef(null);
  const [screenshots, setScreenshots] = useState([]);
  const { TV, ENCODER, UDP, VLP_CHANNEL } = CHANNEL_TYPES;
  const { t } = useTranslation("channel-list");

  const SCREENSHOT_ERROR = {
    message: t("snacks.screenshot-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const onGetScreenshotsSuccess = data => {
    const { screenshots } = data;
    setScreenshots(screenshots);
  };

  const [, , , fetchScreenshots] = useApi(ChannelListApi.requestScreenshots, {
    requestOnMount: false,
    errorMessage: SCREENSHOT_ERROR,
    onSuccess: onGetScreenshotsSuccess
  });

  useEffect(() => {
    if (makeRequestOnMount) {
      const body = createRequestBody(channels);
      fetchScreenshots(body);
    }
    previewInterval.current = setInterval(() => {
      const body = createRequestBody(channels);
      fetchScreenshots(body);
    }, intervalTime);
    return () => {
      clearScreenshotsInterval();
    };
  }, [channels]);

  const clearScreenshotsInterval = () => {
    clearInterval(previewInterval.current);
  };

  const createRequestBody = channels => {
    return {
      channels: channels
        .filter(channel =>
          [TV, ENCODER, UDP, VLP_CHANNEL].includes(channel.type)
        )
        .map(channel => {
          const { address, id } = channel;
          return {
            ipAddress: address,
            channelId: id
          };
        })
    };
  };

  return {
    screenshots,
    clearScreenshotsInterval
  };
}

export default useIntervalScreenshots;
