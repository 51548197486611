import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";

import DashboardApi from "services/api/1-dashboard-api/dashboard-api";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import { dashboardRequestsMessages } from "../../../../utils/utils";

import Chart from "./components/chart/Chart";
import DaysSelection from "../days-selection/DaysSelection";

import classes from "./EncodersStats.module.scss";

function EncodersStats({ server }) {
  const [lastDaysCurrent, setLastDaysCurrent] = useState("");
  const [lastDaysNew, setLastDaysNew] = useState("");
  const [stateChanged, setStateChanged] = useState(false);
  const lastDaysDebounced = useDebounce(lastDaysNew, 1500);
  const { id: serverId } = server;
  const { t } = useTranslation("dashboard");
  const { GET_ENCODERS_CHART_DATA_ERROR } = dashboardRequestsMessages(t);

  const onFetchEncodersChartDataSuccess = () => {
    if (lastDaysNew) {
      setLastDaysCurrent(lastDaysNew);
    }
    setStateChanged(false);
  };

  const [encodersChartData = [], loading, , fetchEncodersChartData] = useApi(
    DashboardApi.getEncodersStatistics,
    {
      requestOnMount: false,
      onSuccess: onFetchEncodersChartDataSuccess,
      errorMessage: GET_ENCODERS_CHART_DATA_ERROR
    }
  );

  const getDataForEncodersChart = lastDays => {
    const payload = {
      serverId,
      lastDays
    };

    fetchEncodersChartData(payload);
  };

  const handleChange = event => {
    setLastDaysNew(event.target.value);
  };

  useEffect(() => {
    if (lastDaysDebounced) {
      if (lastDaysDebounced !== lastDaysCurrent) {
        setStateChanged(true);
        getDataForEncodersChart(lastDaysDebounced);
      }
    }
  }, [lastDaysDebounced]);

  useEffect(() => {
    if (server.id) {
      getDataForEncodersChart("1");
      setLastDaysCurrent("1");
    }
  }, [server]);

  return (
    <div className={classes["encoders-stats"]}>
      <Chart data={encodersChartData} />
      <DaysSelection
        lastDaysCurrent={lastDaysCurrent}
        lastDaysNew={lastDaysNew}
        handleChange={handleChange}
        loading={loading}
        stateChanged={stateChanged}
        data={encodersChartData}
      />
    </div>
  );
}

EncodersStats.propTypes = {
  server: object
};

export default EncodersStats;
