export function usersRequestsMessages(t) {
  const PREFIX = "users.snacks";

  const GET_USERS_ERROR = {
    message: t(`${PREFIX}.get-users-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CREATE_USER_SUCCESS = {
    message: t(`${PREFIX}.create-user-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CREATE_USER_ERROR = {
    message: t(`${PREFIX}.create-user-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const EDIT_USER_SUCCESS = {
    message: t(`${PREFIX}.edit-user-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const EDIT_USER_ERROR = {
    message: t(`${PREFIX}.edit-user-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const DELETE_USER_SUCCESS = {
    message: t(`${PREFIX}.delete-user-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const DELETE_USER_ERROR = {
    message: t(`${PREFIX}.delete-user-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_USERS_ERROR,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR
  };
}

export const selectedUserInitial = {
  id: null,
  fullName: "",
  Role: {
    name: ""
  },
  Company: {
    id: null
  },
  Dealer: {
    id: null
  },
  Idtservers: []
};

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  CUSTOMER: "CUSTOMER",
  SITE_MANAGER: "SITE_MANAGER",
  CONTENT_EDITOR: "CONTENT_EDITOR"
};
