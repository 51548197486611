import React, { createContext, useState } from "react";

export const VideoContext = createContext({
  selectedVideosArray: [],
  setSelectedVideosArray: null,
  availablePlaylists: [],
  setAvailablePlaylists: null,
  escPressed: false,
  setEscPressed: null
});

export default function VideoContextProvider({ children }) {
  const [selectedVideosArray, setSelectedVideosArray] = useState([]);
  const [availablePlaylists, setAvailablePlaylists] = useState([]);
  const [escPressed, setEscPressed] = useState(false);

  return (
    <VideoContext.Provider
      value={{
        selectedVideosArray,
        setSelectedVideosArray,
        availablePlaylists,
        setAvailablePlaylists,
        escPressed,
        setEscPressed
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}
