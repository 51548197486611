import React, { useState, useEffect } from "react";
import { func, bool, object, number } from "prop-types";
import { Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Modal from "components/ui/modals/modal/Modal";
import ServerStats from "./components/server/ServerStats";
import GatewaysStats from "./components/gateways/GatewaysStats";
import EncodersStats from "./components/encoders/EncodersStats";
import EndpointsStats from "./components/endpoints/EndpointsStats";

import classes from "./LocationModal.module.scss";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes["tab-panel"]}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function LocationModal({ open, onClose, server, tabToBeOpened }) {
  const [value, setValue] = useState(0);
  const { t } = useTranslation("dashboard");

  const handleOnChange = (_, value) => {
    setValue(value);
  };

  useEffect(() => {
    setValue(tabToBeOpened);
  }, [tabToBeOpened]);

  return (
    <Modal open={open}>
      <Modal.Header onClose={onClose}>{server.name}</Modal.Header>
      <Modal.Content>
        <Tabs
          classes={{ indicator: classes["nav-item-indicator"] }}
          value={value}
          onChange={handleOnChange}
        >
          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("modal.tabs.server")}
            value={0}
          />

          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("modal.tabs.gateways")}
            value={1}
          />

          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("modal.tabs.encoders")}
            value={2}
          />

          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("modal.tabs.endpoints")}
            value={3}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ServerStats server={server} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GatewaysStats server={server} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EncodersStats server={server} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EndpointsStats server={server} />
        </TabPanel>
      </Modal.Content>
    </Modal>
  );
}

LocationModal.propTypes = {
  open: bool,
  onClose: func,
  server: object,
  tabToBeOpened: number
};

export default LocationModal;
