import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class AccessManagementAPi {
  getToken = () => cookies.get("token");

  // --- ROLES ---

  getRoles = async () => {
    const method = "get";
    const path = `/api/v1/roles`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  // --- USERS ---

  getUsers = async () => {
    const method = "get";
    const path = `/api/v1/user`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  editUser = async (_, payload) => {
    const { userId } = payload;
    const method = "put";
    const path = `/api/v1/user/${userId}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  deleteUser = async (_, userId) => {
    const method = "delete";
    const path = `/api/v1/user/${userId}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  // --- COMPANIES ---

  getCompanies = async () => {
    const method = "get";
    const path = `/api/v1/companies`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };
}

export default new AccessManagementAPi();
