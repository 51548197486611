export const ROLES = {
  CUSTOMER: "CUSTOMER",
  SITE_MANAGER: "SITE_MANAGER",
  CONTENT_EDITOR: "CONTENT_EDITOR"
};

export const ROLE_NAMES = {
  SUPER_ADMIN: "super-admin",
  CUSTOMER: "customer",
  SITE_MANAGER: "site-manager",
  CONTENT_EDITOR: "content-editor"
};
