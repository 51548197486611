import React from "react";
import PropTypes from "prop-types";

import Modal from "components/ui/modals/modal/Modal";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

function WarningModal({ open, onClose, title = "", info }) {
  return (
    <Modal open={open}>
      <Modal.Header onClose={onClose}>{title}</Modal.Header>
      <Modal.Content>{info}</Modal.Content>
      <Modal.Footer>
        <PrimaryButton onClick={onClose}>OK</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

WarningModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  info: PropTypes.string
};

export default WarningModal;
