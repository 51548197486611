import React, { useEffect, useState } from "react";
import { bool, func, number, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Input } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { NUMBERS_ONLY_REGEX } from "data/constants";

import InputArrows from "./components/input-arrows/InputArrows";
import TimerReset from "./components/timer-reset/TimerReset";

import classes from "./Timer.module.scss";

function Timer({
  isChecked = true,
  setHours,
  setMinutes,
  hours,
  minutes,
  setIsTimerValid,
  maxNumberOfHours = "24",
}) {
  const HOURS = "hours";
  const MINUTES = "minutes";
  const PLUS = "plus";
  const MINUS = "minus";
  const HOURS_PLUS = { unit: HOURS, value: "plus" };
  const HOURS_MINUS = { unit: HOURS, value: "minus" };
  const MINUTES_PLUS = { unit: MINUTES, value: "plus" };
  const MINUTES_MINUS = { unit: MINUTES, value: "minus" };
  const { t } = useTranslation("control");
  const {
    register,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      hours: "0",
      minutes: "0",
    },
    mode: "onChange",
  });
  const isResetEnabled = isChecked && (hours > 0 || minutes > 0);
  const [hasTimerCorrectValues, setHasTimerCorrectValues] = useState(null);

  useEffect(() => {
    setHasTimerCorrectValues(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsTimerValid(hasTimerCorrectValues);
  }, [hasTimerCorrectValues]);

  const handleClickHoursArrow = (value) => {
    const numberOfHours = Number(getValues()[HOURS]);

    if (value === PLUS) {
      if (getValues()[HOURS] === maxNumberOfHours || !getValues()[HOURS]) {
        setValue(HOURS, "0");
        setHours(0);
        setHasTimerCorrectValues(true);
      } else {
        setValue(HOURS, (numberOfHours + 1).toString());
        setHours(numberOfHours + 1);
        setHasTimerCorrectValues(true);
      }
    }
    if (value === MINUS) {
      if (getValues()[HOURS] === "0" || !getValues()[HOURS]) {
        setValue(HOURS, maxNumberOfHours);
        setHours(24);
        setHasTimerCorrectValues(true);
      } else {
        setValue(HOURS, (numberOfHours - 1).toString());
        setHours(numberOfHours - 1);
        setHasTimerCorrectValues(true);
      }
    }
  };

  const handleClickMinutesArrow = (value) => {
    const numberOfMinutes = Number(getValues()[MINUTES]);

    if (value === PLUS) {
      if (getValues()[MINUTES] === "59" || !getValues()[MINUTES]) {
        setValue(MINUTES, "0");
        setMinutes(0);
        setHasTimerCorrectValues(true);
      } else {
        setValue(MINUTES, (numberOfMinutes + 1).toString());
        setMinutes(numberOfMinutes + 1);
        setHasTimerCorrectValues(true);
      }
    }
    if (value === MINUS) {
      if (getValues()[MINUTES] === "0" || !getValues()[MINUTES]) {
        setValue(MINUTES, "59");
        setMinutes(59);
        setHasTimerCorrectValues(true);
      } else {
        setValue(MINUTES, (numberOfMinutes - 1).toString());
        setMinutes(numberOfMinutes - 1);
        setHasTimerCorrectValues(true);
      }
    }
  };

  const handleArrowClick = ({ unit, value }) => {
    if (isChecked) {
      if (unit === HOURS) {
        handleClickHoursArrow(value);
      }
      if (unit === MINUTES) {
        handleClickMinutesArrow(value);
      }
    }
  };

  const sanitizeHoursInput = (e) => {
    const hoursString = e.target.value;
    const isStringForbidden = !NUMBERS_ONLY_REGEX.test(hoursString);

    if (isStringForbidden) {
      const correctedString = hoursString.slice(0, -1);

      setValue(HOURS, correctedString);

      if (correctedString.length) {
        setHasTimerCorrectValues(true);
      } else {
        setHasTimerCorrectValues(false);
      }
    } else if (!hoursString.length) {
      setHasTimerCorrectValues(false);
    } else {
      setHasTimerCorrectValues(true);
    }

    if (hoursString.length === 2 && hoursString.charAt(0) === "0") {
      // @ts-ignore
      setHours(Number(getValues()[HOURS].charAt(1)));
      // @ts-ignore
      setValue(HOURS, getValues()[HOURS].charAt(1));
    } else {
      setHours(Number(getValues()[HOURS]));
    }

    if (Number(hoursString) > Number(maxNumberOfHours)) {
      setValue(HOURS, maxNumberOfHours);
      setMinutes(24);
    }
  };

  const sanitizeMinutesInput = (e) => {
    const minutesString = e.target.value;
    const isStringForbidden = !NUMBERS_ONLY_REGEX.test(minutesString);

    if (isStringForbidden) {
      const correctedString = minutesString.slice(0, -1);

      setValue(MINUTES, correctedString);

      if (correctedString.length) {
        setHasTimerCorrectValues(true);
      } else {
        setHasTimerCorrectValues(false);
      }
    } else if (!minutesString.length) {
      setHasTimerCorrectValues(false);
    } else {
      setHasTimerCorrectValues(true);
    }

    // @ts-ignore
    if (minutesString.length === 2 && minutesString.charAt(0) === "0") {
      // @ts-ignore
      setMinutes(Number(getValues()[MINUTES].charAt(1)));
      // @ts-ignore
      setValue(MINUTES, getValues()[MINUTES].charAt(1));
    } else {
      setMinutes(Number(getValues()[MINUTES]));
    }

    if (Number(minutesString) > 59) {
      setValue(MINUTES, "59");
      setMinutes(59);
    }
  };

  const handleResetTimer = () => {
    setHours(0);
    setMinutes(0);
    setValue(MINUTES, "0");
    setValue(HOURS, "0");
  };

  return (
    <div className={classes["timers-container"]}>
      <div className={classes["timer"]}>
        <Input
          type="text"
          name="hours"
          classes={{ input: classes["timer-input"] }}
          disableUnderline
          disabled={!isChecked}
          autoComplete="off"
          inputRef={register({
            // @ts-ignore
            required: t("common:form.this-field-is-required"),
          })}
          inputProps={{
            maxLength: 2,
          }}
          onInput={sanitizeHoursInput}
          endAdornment={
            <InputArrows
              unit="h"
              isChecked={isChecked}
              handleArrowClick={handleArrowClick}
              plusObject={HOURS_PLUS}
              minusObject={HOURS_MINUS}
            />
          }
        />
      </div>

      <div className={classes["timer"]}>
        <Input
          type="text"
          name="minutes"
          className={classes["timer-root"]}
          classes={{ input: classes["timer-input"] }}
          disableUnderline
          disabled={!isChecked}
          autoComplete="off"
          inputRef={register({
            // @ts-ignore
            required: t("common:form.this-field-is-required"),
          })}
          inputProps={{
            maxLength: 2,
          }}
          onInput={sanitizeMinutesInput}
          endAdornment={
            <InputArrows
              unit="m"
              isChecked={isChecked}
              handleArrowClick={handleArrowClick}
              plusObject={MINUTES_PLUS}
              minusObject={MINUTES_MINUS}
            />
          }
        />
      </div>
      <TimerReset
        handleResetTimer={handleResetTimer}
        isResetEnabled={isResetEnabled}
      />
    </div>
  );
}

Timer.propTypes = {
  isChecked: bool,
  setHours: func,
  setMinutes: func,
  hours: number,
  minutes: number,
  setIsTimerValid: func,
  maxNumberOfHours: string,
};

export default Timer;
