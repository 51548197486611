import React, { useState, useEffect } from "react";
import { func, number, object } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse
} from "@material-ui/core";

import AccessManagementAPi from "services/api/access-management-api/access-management-api";
import useApi from "hooks/useApi";
import { ROLES } from "data/roles";

import LoadError from "components/ui/load-error/LoadError";

import classes from "./CompanySelect.module.scss";

function CompanySelect({
  companyId,
  selectedRole,
  handleCompanySelect,
  ignoreRoles = false
}) {
  const [visible, setVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const { CUSTOMER, SITE_MANAGER, CONTENT_EDITOR } = ROLES;
  const { t } = useTranslation("access-management");

  const [companies = [], , , fetchCompanies] = useApi(
    AccessManagementAPi.getCompanies,
    {
      requestOnMount: false,
      onSuccess: () => setVisible(true),
      onError: () => setIsError(true)
    }
  );

  useEffect(() => {
    if (
      ignoreRoles ||
      [CUSTOMER, SITE_MANAGER, CONTENT_EDITOR].includes(selectedRole.name)
    ) {
      fetchCompanies();
    } else {
      setVisible(false);
    }
  }, [selectedRole]);

  return (
    <>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        <FormControl
          variant="outlined"
          classes={{ root: classes["company-select-form-control"] }}
        >
          <InputLabel
            htmlFor="company"
            classes={{ root: classes["company-select-label"] }}
          >
            {t("users.table.company")}
          </InputLabel>
          <Select
            value={companyId || ""}
            onChange={handleCompanySelect}
            inputProps={{
              name: "company",
              id: "company"
            }}
            variant="outlined"
            className={classes["company-select"]}
          >
            {companies.map(company => (
              <MenuItem
                key={company.id}
                value={company.id}
                classes={{ root: classes["menu-item"] }}
              >
                <span className={classes["menu-item-name"]}>
                  {company.name}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Collapse>
      <LoadError open={isError} module="companies" />
    </>
  );
}

CompanySelect.propTypes = {
  companyId: number,
  selectedRole: object,
  handleCompanySelect: func
};

export default CompanySelect;
