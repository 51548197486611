import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class AlertsApi {
  getToken = () => cookies.get("token");

  getAllAlerts = async serverInUseId => {
    const method = `get`;
    const path = `/api/v1/idtservers/${serverInUseId}/alerts-on-idt`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers
    });
  };

  saveEditedAlert = async (serverInUseId, { alertId, text }) => {
    const method = `patch`;
    const path = `/api/v1/idtservers/${serverInUseId}/alerts`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        text,
        alertId
      }
    });
  };

  saveAlertAsNew = async (serverInUseId, { alertId, text }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/alerts/update-one-as-new`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        text,
        alertId
      }
    });
  };

  showAlert = async (serverInUseId, { alertId, groups, endpoints }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/alerts/show`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groups,
        endpoints,
        alertId
      }
    });
  };

  clearAlerts = async (serverInUseId, { groups, endpoints }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/alerts/clear`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groups,
        endpoints
      }
    });
  };

  deleteAlert = async (serverInUseId, alertId) => {
    const method = `delete`;
    const path = `/api/v1/idtservers/${serverInUseId}/alerts`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        alertId
      }
    });
  };
}

export default new AlertsApi();
