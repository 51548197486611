import React, { useRef } from "react";
import { string, number, func, bool } from "prop-types";
import { Input, InputAdornment, ClickAwayListener } from "@material-ui/core";
import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";
import clsx from "clsx";

import classes from "./NumberInput.module.scss";

function NumberInput({
  name,
  value,
  onChange,
  disabled = false,
  symbol = null,
  step = 1,
  ...restProps
}) {
  const numberInputRef = useRef(null);

  const handleOnChange = e => {
    const newValue = +e.target.value;

    if (!isNaN(newValue)) {
      if (newValue < 0) {
        onChange(-newValue);
      } else {
        onChange(newValue);
      }
    }
  };

  const handleArrowUpClick = () => {
    if (value >= 999) {
      onChange(0);
    } else {
      onChange(value + step);
    }
  };

  const handleArrowDownClick = () => {
    if (value <= 1) {
      onChange(0);
    } else {
      onChange(value - step);
    }
  };

  const handleClickAway = () => {
    if (value === 0) {
      onChange(0);
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (value === 0) {
        onChange(0);
      }
      numberInputRef.current.blur();
    }
  };

  return (
    <div className={classes["number-input-container"]}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Input
          type="text"
          name={name}
          value={value}
          classes={{ input: classes["number-input"] }}
          disableUnderline
          disabled={disabled}
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
          inputProps={{
            maxLength: 3
          }}
          inputRef={numberInputRef}
          endAdornment={
            <InputAdornment position="end">
              {symbol && (
                <div className={classes["number-input-symbol"]}>{symbol}</div>
              )}
              <div
                className={clsx(classes["number-input-icons"], {
                  [classes["no-pointer"]]: disabled
                })}
              >
                <KeyboardArrowUpSharpIcon
                  className={classes["up-icon"]}
                  onClick={handleArrowUpClick}
                />
                <KeyboardArrowDownSharpIcon
                  className={classes["down-icon"]}
                  onClick={handleArrowDownClick}
                />
              </div>
            </InputAdornment>
          }
          {...restProps}
        />
      </ClickAwayListener>
    </div>
  );
}

NumberInput.propTypes = {
  name: string.isRequired,
  value: number.isRequired,
  onChange: func.isRequired,
  disabled: bool,
  symbol: string
};

export default NumberInput;
