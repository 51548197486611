import React from "react";
import { number, object } from "prop-types";

function XAxisTick({ x, y, payload }) {
  return (
    <g transform={`translate(${x},${y})`} stroke="ff0000">
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#56636b"
        transform="rotate(-35)"
      >
        {payload.value.split(" ")[2]}
      </text>
    </g>
  );
}

XAxisTick.propTypes = {
  x: number,
  y: number,
  payload: object
};

export default XAxisTick;
