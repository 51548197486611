import i18next from "i18next";

export const ERRORS = {
  E400: "Request failed with status code 400",
  E401: "Request failed with status code 401",
  E403: "Request failed with status code 403",
  E404: "Request failed with status code 404",
  E500: "Request failed with status code 500",
  E504: "Request failed with status code 504",
  NET_ERR: "Network Error"
};

export const snackInfoInitial = {
  shown: false,
  message: "",
  variant: "",
  autoHideDuration: 0
};

export const getErrorMessage = error => {
  const { E400, E401, E403, E404, E500, E504, NET_ERR } = ERRORS;
  let { message } = error;

  switch (message) {
    case E400:
      return i18next.t("common:errors.request-failed-with-status-code-400");
    case E401:
      return i18next.t("common:errors.request-failed-with-status-code-401");
    case E403:
      return i18next.t("common:errors.request-failed-with-status-code-403");
    case E404:
      return i18next.t("common:errors.request-failed-with-status-code-404");
    case E500:
      return i18next.t("common:errors.request-failed-with-status-code-500");
    case E504:
      return i18next.t("common:errors.request-failed-with-status-code-504");
    case NET_ERR:
      return i18next.t("common:errors.network-error");
    default:
      return message;
  }
};

export const showSnackBar = (info, delay, cb) => {
  setTimeout(
    () => {
      cb({
        shown: true,
        ...info
      });
    },
    delay ? delay : 1800
  );
  resetSnackBar(cb);
};

export const resetSnackBar = cb => {
  setTimeout(() => {
    cb({ ...snackInfoInitial });
  }, 200);
};
