import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import usePermissions from "hooks/usePermissions";
import { permissions } from "data/permissions";
import { ENDPOINT_GROUPS } from "data/urls";

import EndPointGroups from "./endpoint-groups/EndPointGroups";
import AssignEndpoints from "./assign-endpoints/AssignEndpoints";

import classes from "./Endpoints.module.scss";

function Endpoints() {
  const [currRoute, setCurrRoute] = useState(ENDPOINT_GROUPS.LIST);
  const history = useHistory();
  const { pathname } = useLocation();
  const userPermissions = usePermissions();
  const { t } = useTranslation("common");

  const handleCallToRouter = (_, value) => {
    history.push(value);
  };

  useEffect(() => {
    if (pathname === "/endpoints/groups") {
      setCurrRoute(ENDPOINT_GROUPS.LIST);
      history.push(ENDPOINT_GROUPS.LIST);
    } else {
      setCurrRoute(pathname);
    }
  }, [pathname]);

  return (
    <div className={classes.container}>
      <Tabs
        classes={{ indicator: classes["nav-item-indicator"] }}
        value={currRoute}
        onChange={handleCallToRouter}
      >
        {userPermissions.includes(
          permissions.endpointsGroups.ENDPOINTS_GROUPS_GET
        ) && (
          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("side-menu.endpoints.groups")}
            value={ENDPOINT_GROUPS.LIST}
          />
        )}
        {userPermissions.includes(
          permissions.endpointsGroups.ENDPOINTS_GROUPS_GET
        ) && userPermissions.includes(permissions.endpoints.ENDPOINTS_GET) ? (
          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("side-menu.endpoints.assign-endpoints")}
            value={ENDPOINT_GROUPS.ASSIGN}
          />
        ) : null}
      </Tabs>

      <Switch>
        <Route path={ENDPOINT_GROUPS.LIST}>
          <EndPointGroups />
        </Route>

        <Route path={ENDPOINT_GROUPS.ASSIGN}>
          <AssignEndpoints />
        </Route>
      </Switch>
      <Redirect to={ENDPOINT_GROUPS.LIST} />
    </div>
  );
}

export default Endpoints;
