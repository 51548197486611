import React from "react";
import clsx from "clsx";
import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";

import CustomButton from "components/ui/buttons/custom-button/CustomButton";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./GalleryButtons.module.scss";

function GalleryButtons({ openUploadModal, disabled, handleApply, allLoaded }) {
  const { t } = useTranslation(["channel-list"]);

  return (
    <div
      className={clsx(classes["buttons"], {
        [classes["buttons-visible"]]: allLoaded
      })}
    >
      <CustomButton
        onClick={openUploadModal}
        disabled={disabled}
        customButtonClass={classes["btn-background"]}
      >
        {t("upload-logo")}
      </CustomButton>
      <PrimaryButton onClick={handleApply} disabled={disabled}>
        {t("common:apply")}
      </PrimaryButton>
    </div>
  );
}

GalleryButtons.propTypes = {
  openUploadModal: func.isRequired,
  disabled: bool.isRequired,
  handleApply: func.isRequired
};

export default GalleryButtons;
