const PREFIX = "sections.playlist-channels.snacks";

export function playlistChannelsRequestsMessages(t) {
  const CHANNELS_LOAD_ERROR = {
    message: t(`${PREFIX}.channels-could-not-be-loaded`),
    variant: "error",
    autoHideDuration: 3500
  };

  const PLAYLISTS_LOAD_ERROR = {
    message: t(`${PREFIX}.playlists-could-not-be-loaded`),
    variant: "error",
    autoHideDuration: 3500
  };

  const PLAYLIST_SET_AS_ACTIVE_SUCCESS = {
    message: t(`${PREFIX}.playlist-assigned-to-channel-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const PLAYLIST_SET_AS_ACTIVE_ERROR = {
    message: t(`${PREFIX}.playlist-assigned-to-channel-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const PLAYLIST_REMOVE_FROM_CHANNEL_SUCCESS = {
    message: t(`${PREFIX}.playlist-removed-from-channel-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const PLAYLIST_REMOVE_FROM_CHANNEL_ERROR = {
    message: t(`${PREFIX}.playlist-removed-from-channel-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CHANGE_CHANNEL_SETTINGS_SUCCESS = {
    message: t(`${PREFIX}.channel-settings-have-been-changed`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CHANGE_CHANNEL_SETTINGS_ERROR = {
    message: t(`${PREFIX}.channel-settings-could-not-be-changed`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CHANNEL_TRANSMIT_SUCCESS = {
    message: t(`${PREFIX}.channel-transmit-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CHANNEL_STOP_SUCCESS = {
    message: t(`${PREFIX}.channel-stop-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CHANNEL_ENABLE_SUCCESS = {
    message: t(`${PREFIX}.channel-enable-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CHANNEL_DISABLE_SUCCESS = {
    message: t(`${PREFIX}.channel-disable-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CHANNEL_STATE_CHANGE_ERROR = {
    message: t(`${PREFIX}.channel-state-could-not-be-changed`),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    CHANNELS_LOAD_ERROR,
    PLAYLISTS_LOAD_ERROR,
    PLAYLIST_SET_AS_ACTIVE_SUCCESS,
    PLAYLIST_SET_AS_ACTIVE_ERROR,
    PLAYLIST_REMOVE_FROM_CHANNEL_SUCCESS,
    PLAYLIST_REMOVE_FROM_CHANNEL_ERROR,
    CHANGE_CHANNEL_SETTINGS_SUCCESS,
    CHANGE_CHANNEL_SETTINGS_ERROR,
    CHANNEL_STATE_CHANGE_ERROR,
    CHANNEL_TRANSMIT_SUCCESS,
    CHANNEL_STOP_SUCCESS,
    CHANNEL_ENABLE_SUCCESS,
    CHANNEL_DISABLE_SUCCESS
  };
}

export const CHANNEL_STATES = {
  ENABLE: "enable",
  DISABLE: "disable",
  STOP: "stop",
  TRANSMIT: "transmit"
};
