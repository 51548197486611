import React from "react";
import { Dialog } from "@material-ui/core";
import clsx from "clsx";

import ModalHeader from "./modal-header/ModalHeader";
import ModalContent from "./modal-content/ModalContent";
import ModalFooter from "./modal-footer/ModalFooter";

import classes from "./Modal.module.scss";

function Modal({ open, children, modalClass = null }) {
  return (
    <Dialog
      open={open}
      classes={{ paper: clsx(classes["modal"], { [modalClass]: modalClass }) }}
    >
      {children}
    </Dialog>
  );
}

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
