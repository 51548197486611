import React from "react";
import { func } from "prop-types";

function UploadVideoInput({ getInputProps }) {
  return (
    <input
      id="video-file"
      type="file"
      {...getInputProps({
        accept: "video/*,.mkv"
      })}
    />
  );
}

UploadVideoInput.propTypes = {
  getInputProps: func.isRequired
};

export default UploadVideoInput;
