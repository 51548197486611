import React from "react";
import clsx from "clsx";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorSharpIcon from "@material-ui/icons/DragIndicatorSharp";
import { useTranslation } from "react-i18next";
import Img from "react-image";

import { baseUrl } from "data/constants";
import { CHANNEL_TYPES } from "../../../utils/utils";

import Thumb from "components/ui/thumb/Thumb";
import ImgLoader from "components/ui/loaders/img-loader/ImgLoader";
import ImgUnloader from "components/ui/loaders/img-unloader/ImgUnloader";
import ScreenshotUnavailable from "./components/screenshot-unavailable/ScreenshotUnavailable";

import classes from "../ChannelDnDList.module.scss";

function ChannelDnDRow({
  channel,
  index,
  screenshotURL,
  screenshot,
  onSelectChannel,
  rowSelected = false,
  editFormActive = false
}) {
  const { TV, ENCODER, UDP, VLP_CHANNEL } = CHANNEL_TYPES;
  const { t } = useTranslation("channel-list");

  const onChannelsRowSelect = () => {
    const selectedChannelData = {
      channel,
      previewImage: screenshotURL,
      previewInfo: screenshot
    };
    onSelectChannel(selectedChannelData);
  };

  const logoSrc = `${baseUrl}${channel.logoPath}`;

  return (
    <Draggable draggableId={channel.id} index={index}>
      {(provided, snapshot) => {
        const draggingCellClass = {
          [classes["table-cell-dragging"]]: snapshot.isDragging
        };
        return (
          <tr
            onClick={onChannelsRowSelect}
            onDoubleClick={onChannelsRowSelect}
            ref={provided.innerRef}
            style={provided.draggableProps.style}
            className={clsx(classes["table-row"], {
              [classes["table-row-dragging"]]: snapshot.isDragging
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // @ts-ignore
            open={rowSelected}
          >
            <td
              align="center"
              className={clsx(
                classes["draggable"],
                classes["table-col"],
                classes["table-col-width-5"],
                draggingCellClass
              )}
              draggable
              id={channel.id}
            >
              <DragIndicatorSharpIcon color="inherit" />
            </td>

            {/* Channel Number */}
            <td
              align="center"
              className={clsx(
                classes["table-col"],
                classes["table-col-width-5"],
                draggingCellClass
              )}
            >
              <span>{channel.lcn}</span>
            </td>

            {/* Channel Logo */}
            <td
              className={clsx(
                classes["table-col"],
                classes["table-col-width-10"],
                draggingCellClass
              )}
            >
              <div className={classes["table-img-container"]}>
                <Img
                  src={logoSrc}
                  className={classes["table-img"]}
                  alt="logo"
                  loader={<ImgLoader />}
                  unloader={<ImgUnloader />}
                />
              </div>
            </td>

            {/* Channel Screenshot Preview */}
            <td
              align="center"
              className={clsx(classes["table-col"], draggingCellClass)}
            >
              <div className={classes["table-cell-preview"]}>
                {screenshotURL &&
                [TV, ENCODER, UDP, VLP_CHANNEL].includes(channel.type) ? (
                  <Thumb imgSrc={screenshotURL} alt="Logo" placement="right">
                    <section className={classes["thumb-info"]}>
                      <div>{channel.name}</div>
                      <div className={classes["thumb-address"]}>
                        {channel.address}
                      </div>
                    </section>
                  </Thumb>
                ) : (
                  <ScreenshotUnavailable />
                )}
              </div>
            </td>

            {/* Channel type */}

            <td
              className={clsx(
                classes["table-col"],
                classes["table-col-width-10"],
                draggingCellClass
              )}
            >
              <div
                className={clsx(classes["channel-type"], {
                  [classes["not-specified"]]: !channel.type
                })}
              >
                {channel.type ? channel.type : t("channel-type-not-specified")}
              </div>
            </td>

            {/* Channel Details */}
            <td
              className={clsx(
                classes["p-left"],
                classes["table-col"],
                classes["table-col-width-30"],
                draggingCellClass
              )}
            >
              <div
                className={clsx(classes["table-details-name"], {
                  [classes["table-details-name-shrinked"]]: editFormActive
                })}
              >
                {channel.name}
              </div>
              <div className={classes["table-address"]}>
                {channel.address ? channel.address : "udp://224.1.0.1:5500"}
              </div>
            </td>
          </tr>
        );
      }}
    </Draggable>
  );
}

export default ChannelDnDRow;
