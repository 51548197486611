import React from "react";
import { bool, string } from "prop-types";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

function ApplyButton({
  dirty,
  isValid,
  isInputValueInvalid,
  label,
  ...restProps
}) {
  return (
    <PrimaryButton
      {...restProps}
      type="submit"
      disabled={!dirty || !isValid || isInputValueInvalid}
    >
      {label}
    </PrimaryButton>
  );
}

ApplyButton.propTypes = {
  dirty: bool,
  isValid: bool,
  isInputValueInvalid: bool,
  label: string
};

export default ApplyButton;
