import React from "react";
import { func, arrayOf, object, string } from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";

import AutocompleteOption from "../autocomplete-option/AutocompleteOption";
import AutocompleteInput from "../autocomplete-input/AutocompleteInput";

import classes from "./AutocompleteMain.module.scss";

function AutocompleteMain({
  messagesList,
  handleAutoCompleteChange,
  setMessageToBeDeleted,
  newMessage,
  handleTextFieldChange,
  handleKeyPress,
  deleteLabel,
  placeholder,
  setIsMessageTooLong
}) {
  return (
    <Autocomplete
      classes={{
        root: classes.root,
        inputRoot: classes.input,
        noOptions: classes["no-options"],
        popper: clsx({ [classes["no-options"]]: newMessage === undefined })
      }}
      id="size-small-outlined"
      size="small"
      options={messagesList}
      onChange={handleAutoCompleteChange}
      getOptionLabel={option => option.value}
      debug
      closeIcon={
        <div className={classes["icon-container"]}>
          <CloseIcon
            onClick={handleAutoCompleteChange}
            className={classes.icon}
          />
        </div>
      }
      renderOption={option => (
        <AutocompleteOption
          newMessage={newMessage}
          option={option}
          deleteLabel={deleteLabel}
          setMessageToBeDeleted={setMessageToBeDeleted}
        />
      )}
      renderInput={params => (
        <AutocompleteInput
          params={params}
          placeholder={placeholder}
          handleTextFieldChange={handleTextFieldChange}
          handleKeyPress={handleKeyPress}
          setIsMessageTooLong={setIsMessageTooLong}
        />
      )}
    />
  );
}

AutocompleteMain.propTypes = {
  messagesList: arrayOf(object),
  handleAutoCompleteChange: func,
  setMessageToBeDeleted: func,
  newMessage: string,
  handleTextFieldChange: func,
  handleKeyPress: func,
  deleteLabel: string,
  placeholder: string,
  setIsMessageTooLong: func
};

export default AutocompleteMain;
