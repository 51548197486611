import React, { useEffect, useContext } from "react";
import { func } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import EditProfileApi from "services/api/personal-info-api/edit-profile-api";
import useApi from "hooks/useApi";
import { UserContext } from "contexts/UserContext";
import { getSnacks } from "./snacks/snacks";
import {
  getInputError,
  validateRequired,
  validateName,
  validatePhone
} from "utils/utils";

import Form from "components/ui/form/Form";
import ApplyButton from "./components/button/ApplyButton";

import classes from "./PersonalInfoForm.module.scss";
import { MAX_DEFAULT_LEN, MAX_PHONE_LEN } from "data/constants";

function PersonalInfoForm({ togglePersonalInfoModal, setApiLoading }) {
  const { t } = useTranslation("edit-profile", { useSuspense: false });
  const { userInContext, setUserInContext } = useContext(UserContext);
  const {
    handleSubmit,
    register,
    errors,
    formState: { dirty, isValid }
  } = useForm({
    defaultValues: {
      name: userInContext.name,
      surname: userInContext.surname,
      phone: userInContext.phone
    },
    mode: "onChange"
  });

  const disabled = !dirty || !isValid;

  const { EDIT_PROFILE_SUCCESS, EDIT_PROFILE_ERROR } = getSnacks(t);

  const updateUserContext = ({ fields }) => {
    const { name, surname } = fields;
    const fullName = `${name} ${surname}`;

    setUserInContext({ ...userInContext, ...fields, fullName });
    togglePersonalInfoModal();
  };

  const [, loading, , editUserProfile] = useApi(EditProfileApi.editUser, {
    requestOnMount: false,
    onSuccess: updateUserContext,
    successMessage: EDIT_PROFILE_SUCCESS,
    errorMessage: EDIT_PROFILE_ERROR
  });

  const onSubmit = values => {
    editUserProfile(values);
  };

  useEffect(() => {
    setApiLoading(loading);
  }, [loading]);

  return (
    <div className={classes["form"]}>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Form.Field horizontal>
          <Form.Label>
            <div className={classes["form-label"]}>{t("form.email")}</div>
          </Form.Label>
          <Form.Input
            // @ts-ignore
            type="text"
            name="email"
            defaultValue={userInContext.emailAddress}
            readOnly
          />
        </Form.Field>

        <Form.Field horizontal>
          <Form.Label>
            <div className={classes["form-label"]}>{t("form.first-name")}</div>
          </Form.Label>
          <Form.Input
            // @ts-ignore
            type="text"
            name="name"
            ref={register({
              ...validateRequired,
              maxLength: {
                value: MAX_DEFAULT_LEN,
                message: "common:form.max-length"
              },
              ...validateName
            })}
            error={getInputError(errors.name, t, MAX_DEFAULT_LEN)}
          />
        </Form.Field>

        <Form.Field horizontal>
          <Form.Label>
            <div className={classes["form-label"]}>{t("form.last-name")}</div>
          </Form.Label>
          <Form.Input
            // @ts-ignore
            type="text"
            name="surname"
            ref={register({
              ...validateRequired,
              maxLength: {
                value: MAX_DEFAULT_LEN,
                message: "common:form.max-length"
              },
              ...validateName
            })}
            error={getInputError(errors.surname, t, MAX_DEFAULT_LEN)}
          />
        </Form.Field>

        <Form.Field horizontal>
          <Form.Label optional>
            <div className={classes["form-label"]}>
              {t("form.phone-number")}
            </div>
          </Form.Label>
          <Form.Input
            // @ts-ignore
            type="text"
            name="phone"
            ref={register({
              ...validatePhone
            })}
            error={getInputError(errors.phone, t, MAX_PHONE_LEN)}
          />
        </Form.Field>

        <ApplyButton applyTxt={t("common:apply")} disabled={disabled} />
      </Form>
    </div>
  );
}

PersonalInfoForm.propTypes = {
  togglePersonalInfoModal: func.isRequired,
  setApiLoading: func
};

export default PersonalInfoForm;
