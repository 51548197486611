import React from "react";
import { string } from "prop-types";
import clsx from "clsx";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./ChannelStateLoader.module.scss";

function ChannelStateLoader({ stateLoaderClass = null }) {
  return (
    <div
      className={clsx(classes["channel-state-loader"], {
        [stateLoaderClass]: stateLoaderClass
      })}
    >
      <EllipsisLoader color="#ececeb" />
    </div>
  );
}

EllipsisLoader.propTypes = {
  stateLoaderClass: string,
  color: string
};

export default ChannelStateLoader;
