import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import classes from "./FormField.module.scss";

function FormField({ children, horizontal = false, formFieldClass = null }) {
  return (
    <div
      className={clsx(classes["field"], {
        [classes["field-horizontal"]]: horizontal,
        [formFieldClass]: formFieldClass
      })}
    >
      {children}
    </div>
  );
}

FormField.propTypes = {
  children: PropTypes.node
};

export default FormField;
