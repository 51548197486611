import React from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "components/ui/form/Form";

import classes from "./EditUserForm.module.scss";

function EditUserForm({ register }) {
  const { t } = useTranslation("access-management");

  return (
    <Form autoComplete="off" className={classes["edit-selected-user-form"]}>
      <Form.Field horizontal>
        <Form.Label>Email&nbsp;&#x2772;login&#x2773;:</Form.Label>
        <Form.Input
          // @ts-ignore
          name="email"
          readOnly
          ref={register()}
        />
      </Form.Field>

      <Form.Field horizontal>
        <Form.Label>{t("users.form.first-name")}</Form.Label>
        <Form.Input
          // @ts-ignore
          name="name"
          readOnly
          ref={register()}
        />
      </Form.Field>

      <Form.Field horizontal>
        <Form.Label>{t("users.form.last-name")}</Form.Label>
        <Form.Input
          // @ts-ignore
          name="surname"
          readOnly
          ref={register()}
        />
      </Form.Field>

      <Form.Field horizontal>
        <Form.Label htmlFor="phone">{t("users.form.phone")}</Form.Label>
        <Form.Input
          // @ts-ignore
          name="phone"
          readOnly
          ref={register()}
        />
      </Form.Field>

      <Form.Field horizontal>
        <Form.Label htmlFor="phone">Company</Form.Label>
        <Form.Input
          // @ts-ignore
          name="company"
          readOnly
          ref={register()}
        />
      </Form.Field>
    </Form>
  );
}

EditUserForm.propTypes = {
  register: func
};

export default EditUserForm;
