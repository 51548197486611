import React from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";

import classes from "./ActionInProgress.module.scss";

function ActionInProgress({ reboot = false, backup = false }) {
  const { t } = useTranslation("servers");

  return (
    <>
      {reboot && (
        <div className={classes["reboot-in-progress"]}>
          {t("server-rebooting")}...
        </div>
      )}
      {backup && (
        <div className={classes["backup-in-progress"]}>
          <div>{t("backups.warnings.backup-in-progress")}</div>
          <div>{t("backups.warnings.no-action")}</div>
        </div>
      )}
    </>
  );
}

ActionInProgress.propTypes = {
  reboot: bool,
  backup: bool
};

export default ActionInProgress;
