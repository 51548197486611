import {useState, useEffect, useContext, useRef} from "react";

import {ServerContext} from "contexts/ServerContext";
import NotificationContext from "contexts/NotificationContext";
import {getErrorMessage} from "services/helpers/helpers";
import AuthContext from "contexts/AuthContext";
import ErrorContext from "../contexts/ErrorContext";
import * as Sentry from "@sentry/react";
import ServerOfflineContext from "../contexts/ServerOfflineContext";

function useApi(apiMethod, options = {}, ...params) {
  const [data, setData] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const unmounted = useRef(false);

  const {serverInUse} = useContext(ServerContext);
  const {
    actions: {setSnackbar}
  } = useContext(NotificationContext);
  const {setError} = useContext(ErrorContext);
  const {setServerOffline} = useContext(ServerOfflineContext);

  const {actions} = useContext(AuthContext);

  const {
    successMessage,
    errorMessage,
    requestOnMount = true,
    onSuccess,
    onError,
    showMessageOnError = true,
    reAuthenticate = true,
    errorMessageOnStatus
  } = options;

  const callApiMethod = async (...params) => {
    try {
      setLoading(true);
      const {status, data} = await apiMethod(serverInUse.id, ...params);
      if (!unmounted.current) {
        setData(data);
        setStatus(status);
        if (successMessage) {
          const {message, variant, autoHideDuration} = successMessage;
          setSnackbar(message, variant, autoHideDuration);
        }
        if (onSuccess) {
          onSuccess(data);
        }
      }
    } catch (error) {
      Sentry.captureException(error);

      if (!unmounted.current) {
        if (error && error.response) {
          const {status, config} = error.response;
          setStatus(status);

          if (status === 401 && reAuthenticate) {
            actions.setIsAuthenticated(false, config.method === "get");
          } else if (status === 418 && config.url.includes("/idtservers/")) { // 418 status means that IDT server is offline
            setServerOffline(true);
            return;
          } else if (status === 410 && config.url.includes("/idtservers/")) { // 410 status means that some error occurs in IDT server
            setError(true);
            return;
          }
        }

        if (showMessageOnError) {
          if (error && error.response) {
            const {status} = error.response;
            console.log(error.response);
            if (errorMessage) {
              const {message, variant, autoHideDuration} = errorMessage;
              setSnackbar(message, variant, autoHideDuration);
            } else if (
              errorMessageOnStatus &&
              errorMessageOnStatus.hasOwnProperty(+status)
            ) {
              if (errorMessageOnStatus[status]) {
                const {
                  message,
                  variant,
                  autoHideDuration
                } = errorMessageOnStatus[status];

                setSnackbar(message, variant, autoHideDuration);
              }
            } else {
              const message = getErrorMessage(error);
              setSnackbar(message, "error");
            }
          }
        }

        if (onError) {
          onError(error);
        }
      }
    } finally {
      if (!unmounted.current) {
        setLoading(false);
      }
    }
  };

  const retryCallApiMethod = async (...params) => {
    await callApiMethod(...params);
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (requestOnMount) {
      callApiMethod(...params);
    }
    return () => {
      unmounted.current = true;
    };
  }, []);

  return [data, loading, status, retryCallApiMethod];
}

export default useApi;
