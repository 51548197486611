import React from "react";
import clsx from "clsx";
import { number, string, bool, func } from "prop-types";
import { useTranslation } from "react-i18next";

import Circle from "./components/circle/Circle";

import classes from "./Entity.module.scss";

function Entity({
  name,
  total,
  online,
  server = false,
  isServerOnline,
  handleClick
}) {
  const { t } = useTranslation("dashboard");
  const disabled = total === 0;

  return (
    <div className={classes["entity"]}>
      <span className={classes["entity-name"]} onClick={handleClick}>
        {name}
      </span>
      {!server ? (
        <div className={classes["entity-status-container"]}>
          <span
            className={clsx(classes["entity-status"], {
              [classes["grey"]]: disabled
            })}
            onClick={!disabled ? handleClick : e => e.stopPropagation()}
          >
            {online}/{total}
          </span>
          <Circle total={total} online={online} />
        </div>
      ) : (
        <>
          {isServerOnline ? (
            <div className={classes["entity-status-container"]}>
              <span className={classes["entity-status"]}>
                {t("statuses.online")}
              </span>
              <Circle green />
            </div>
          ) : (
            <div className={classes["entity-status-container"]}>
              <span className={classes["entity-status"]}>
                {t("statuses.offline")}
              </span>
              <Circle red />
            </div>
          )}
        </>
      )}
    </div>
  );
}

Entity.propTypes = {
  name: string,
  total: number,
  online: number,
  server: bool,
  isServerOnline: bool,
  handleClick: func
};

export default Entity;
