import React from "react";
import { arrayOf, object, func, bool, string } from "prop-types";
import clsx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";

import CheckSharpIcon from "@material-ui/icons/CheckSharp";

import { baseUrl } from "data/constants";

import classes from "./LogosGallery.module.scss";

function LogosGallery({
  isVisible,
  logos,
  selectedLogo,
  handleLogoClick,
  handleImageLoaded,
  handleImageNotLoaded,
}) {
  const getImageSource = (logo) => `${baseUrl}${logo.logoPath}`;

  return (
    <div
      className={clsx(classes["gallery"], {
        [classes["gallery-visible"]]: isVisible,
      })}
    >
      <Scrollbars autoHeight autoHeightMax="56vh">
        <div className={classes["gallery-container"]}>
          {logos.map((logo, index) => (
            <div
              key={`${logo.logo}-${index}`}
              className={classes["gallery-item-container"]}
            >
              <CheckSharpIcon
                className={clsx(classes["check-icon"], {
                  [classes["check-icon-visible"]]:
                    selectedLogo === logo.logoPath,
                })}
                onClick={handleLogoClick}
              />
              <div
                className={classes["img-container"]}
                onClick={handleLogoClick}
              >
                <div
                  className={clsx(classes["overlay"], {
                    [classes["overlay-visible"]]:
                      selectedLogo === logo.logoPath,
                  })}
                />
                <img
                  src={getImageSource(logo)}
                  id={logo.logoPath}
                  alt="logo"
                  onLoad={handleImageLoaded}
                  onError={handleImageNotLoaded}
                  className={classes["image"]}
                />
              </div>
            </div>
          ))}
        </div>
      </Scrollbars>
    </div>
  );
}

LogosGallery.propTypes = {
  isVisible: bool,
  logos: arrayOf(object),
  selectedLogo: string,
  handleLogoClick: func,
  handleImageLoaded: func,
  handleImageNotLoaded: func,
};

export default LogosGallery;
