import React from "react";
import { Checkbox } from "@material-ui/core";

import classes from "./CustomCheckbox.module.scss";

function CustomCheckbox({ checked = null, rootClass = null, ...restProps }) {
  return (
    <Checkbox
      classes={{
        root: rootClass ? rootClass : classes.root,
        checked: classes["checkbox-checked"],
        colorSecondary: classes["checkbox-color-secondary"],
        disabled: classes.disabled,
      }}
      checked={checked}
      {...restProps}
      disableRipple
    />
  );
}

export default CustomCheckbox;
