import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AssignEndpointsApi from "services/api/2-system-manage-api/assign-endpoints-api";
import EndpointGroupsApi from "services/api/2-system-manage-api/endpoint-groups-api";
import LoadingContext from "contexts/LoadingContext";
import useApi from "hooks/useApi";
import useInterval from "hooks/useInterval";
import { assignEndpointsRequestMessages } from "./utils/utils";

import GroupsEndpointsTable from "./components/table/GroupsEndpointsTable";

function AssignEndpoints() {
  const [endpoints, setEndpoints] = useState([]);
  const [groups, setGroups] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const { t } = useTranslation("endpoint-groups");
  const history = useHistory();
  const { setInterVal, clearInterVal } = useInterval();

  const {
    GET_ENDPOINTS_ERROR,
    GET_GROUPS_ERROR
  } = assignEndpointsRequestMessages(t);

  const [, endpointsLoading, , fetchEndpoints] = useApi(
    AssignEndpointsApi.getAllEndpoints,
    {
      onSuccess: setEndpoints,
      errorMessage: GET_ENDPOINTS_ERROR
    }
  );
  const [, groupsLoading, , fetchGroups] = useApi(
    EndpointGroupsApi.getEndpointGroups,
    {
      onSuccess: setGroups,
      errorMessage: GET_GROUPS_ERROR
    }
  );

  useEffect(() => {
    setLoading(endpointsLoading || groupsLoading);
    history.push("/endpoints/groups/assign");
  }, [endpointsLoading, groupsLoading]);

  const handleRefetchGroupsAndTempates = () => {
    fetchEndpoints();
    fetchGroups();
  };

  useEffect(() => {
    setInterVal(() => {
      fetchEndpoints();
    }, 30000);

    return () => {
      clearInterVal();
    };
  }, []);

  return (
    <GroupsEndpointsTable
      endpoints={endpoints}
      groups={groups}
      refreshTable={handleRefetchGroupsAndTempates}
    />
  );
}

export default AssignEndpoints;
