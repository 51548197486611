import React, { useState, createRef } from "react";
import { func, bool, object } from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "components/ui/modals/modal/Modal";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import Warning from "../Warning/Warning";
import CustomButton from "components/ui/buttons/custom-button/CustomButton";
import SelectedFileName from "./components/selected-file-name/SelectedFileName";
import ImageSizeInfo from "./components/image-size-info/ImageSizeInfo";

import classes from "./UploadLogo.module.scss";

function UploadLogo({
  closeUploadModal,
  uploadLogo,
  open,
  uploading,
  logoFile,
  setLogoFile
}) {
  const [oneFileOnlyWarning, setOneFileOnlyWarning] = useState(false);
  const [notImageWarning, setNotImageWarning] = useState(false);
  const [imageSizeWarning, setImageSizeWarning] = useState(false);
  const [notPngWarning, setNotPngWarning] = useState(false);

  const { t } = useTranslation("channel-list");

  let fileInput = createRef();

  const toggleOneFileOnlyWarning = () => {
    setOneFileOnlyWarning(prev => !prev);
  };

  const toggleNotImageWarning = () => {
    setNotImageWarning(prev => !prev);
  };

  const toggleImageSizeWarning = () => {
    setImageSizeWarning(prev => !prev);
  };

  const toggleNotPngWarning = () => {
    setNotPngWarning(prev => !prev);
  };

  const resetWarnings = () => {
    if (
      oneFileOnlyWarning ||
      notImageWarning ||
      imageSizeWarning ||
      notPngWarning
    ) {
      setLogoFile({ name: "" });
    }
    setOneFileOnlyWarning(false);
    setNotImageWarning(false);
    setImageSizeWarning(false);
    setNotPngWarning(false);
  };

  const handleClose = () => {
    closeUploadModal();
    resetWarnings();
    setLogoFile({ name: "" });
  };

  const handleSelectFile = () => {
    resetWarnings();
    fileInput.current.click();
  };

  const handleUpload = () => {
    uploadLogo(logoFile);
  };

  const handleFileChange = e => {
    if (e.target.files.length === 1) {
      checkFileType(e.target.files[0]);
    } else if (e.target.files.length > 1) {
      setLogoFile({ name: "" });
      toggleOneFileOnlyWarning();
    }
  };

  const checkFileType = file => {
    const fileExtension = file.name.replace(/^.*\./, "");

    if (fileExtension === "png") {
      getImageDimensions(file);
    } else if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "gif"
    ) {
      setLogoFile({ name: "" });
      toggleNotPngWarning();
    } else {
      setLogoFile({ name: "" });
      toggleNotImageWarning();
    }
  };

  const getImageDimensions = file => {
    const reader = new FileReader();

    reader.onload = e => {
      let img = new Image();

      // @ts-ignore
      img.src = e.target.result;

      img.onload = () => {
        const w = img.width;
        const h = img.height;

        checkImageSize({ w, h }, file);
      };
    };

    reader.readAsDataURL(file);
  };

  const checkImageSize = ({ w, h }, file) => {
    if (w === 89 && h === 54) {
      setLogoFile(file);
    } else {
      toggleImageSizeWarning();
    }
  };

  const getWarning = () =>
    oneFileOnlyWarning
      ? "one-file-only"
      : notImageWarning
      ? "not-image"
      : imageSizeWarning
      ? "image-size"
      : notPngWarning
      ? "not-png"
      : "";

  return (
    <Modal open={open}>
      <Modal.Header loading={uploading} onClose={handleClose}>
        {t("logo-upload")}
      </Modal.Header>

      <Modal.Content>
        <div className={classes["modal-content"]}>
          <ImageSizeInfo info={t("warnings.image-size-info")} />
          <input
            id="file"
            type="file"
            onChange={handleFileChange}
            ref={fileInput}
            className={classes["file-input"]}
            accept="image/*"
          />
          <CustomButton
            onClick={handleSelectFile}
            customButtonClass={classes["btn-background"]}
          >
            {t("select-file")}
          </CustomButton>
          <SelectedFileName fileName={logoFile.name} />
          <Warning warning={getWarning()} />
        </div>
      </Modal.Content>

      <Modal.Footer>
        <PrimaryButton
          onClick={handleUpload}
          disabled={!logoFile.name || uploading}
        >
          {t("upload")}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

UploadLogo.propTypes = {
  closeUploadModal: func,
  uploadLogo: func,
  open: bool,
  uploading: bool,
  logoFile: object,
  setLogoFile: func
};

export default UploadLogo;
