import React from "react";
import { object, bool, func, number } from "prop-types";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Img from "react-image";
import { Draggable } from "react-beautiful-dnd";

import { baseUrl } from "data/constants";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./PlaylistVideoItem.module.scss";

function PlaylistVideoItem({
  video,
  playlistPlaying,
  playlistAssignedToChannel,
  handleRemoveVideoFromPlaylist,
  index,
  isDragDisabled,
  videoIndex,
  updating
}) {
  const { t } = useTranslation("video-playlists");
  const src = `${baseUrl}${video.previewUrl}`;

  const handleRemoveClick = () => {
    const { id, duration } = video;

    handleRemoveVideoFromPlaylist({
      id,
      index: videoIndex,
      duration
    });
  };

  return (
    <Draggable
      draggableId={video.uuid}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => {
        const draggingCellClass = {
          [classes["table-cell-dragging"]]: snapshot.isDragging
        };
        return (
          <div
            className={classes["playlist-video-item"]}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className={classes["playlist-video-item-preview"]}>
              {video.sprite ? (
                <VideoSprite spriteUrl={video.previewUrl} />
              ) : (
                <Img
                  src={src}
                  className={classes["image"]}
                  unloader={<PreviewUnloader />}
                />
              )}
            </div>

            <div className={classes["info-background"]}></div>
            <div className={classes["playlist-video-item-info"]}>
              <div className={classes["playlist-video-item-info-container"]}>
                <div className={classes["video-name"]}>{video.name}</div>
                <div className={classes["video-duration"]}>
                  {video.duration}
                </div>
              </div>
              {!playlistPlaying && !playlistAssignedToChannel && !updating && (
                <div
                  className={classes["video-remove"]}
                  onClick={handleRemoveClick}
                >
                  <Tooltip
                    title={t("sections.playlists.remove-from-playlist")}
                    placement="top-start"
                    classes={{ tooltip: classes["tooltip-font"] }}
                  >
                    <div className={classes["video-remove-line"]}>
                      {t("common:remove")}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}

PlaylistVideoItem.propTypes = {
  video: object,
  playlistPlaying: bool,
  playlistAssignedToChannel: bool,
  handleRemoveVideoFromPlaylist: func.isRequired,
  videoIndex: number,
  updating: bool
};

export default PlaylistVideoItem;
