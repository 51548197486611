import React from "react";
import { string, bool } from "prop-types";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ApplyButton.module.scss";

function ApplyButton({ applyTxt, disabled }) {
  return (
    <div className={classes["apply-button"]}>
      <PrimaryButton type="submit" disabled={disabled}>
        <span>{applyTxt}</span>
      </PrimaryButton>
    </div>
  );
}

ApplyButton.propTypes = {
  applyTxt: string.isRequired,
  disabled: bool
};

export default ApplyButton;
