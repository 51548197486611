import React, { useEffect, useContext } from "react";
import { object, func } from "prop-types";
import { ClickAwayListener, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { VideoContext } from "contexts/VideoContext";
import { validateRequired, validateIDTName, getInputError } from "utils/utils";
import { MAX_IDTNAME_LEN } from "data/constants";

import Form from "components/ui/form/Form";

import classes from "./VideoDetails.module.scss";

const VideoDetailsContainer = "div";

function VideoDetails({
  video,
  editedVideo,
  setNewVideoName,
  handleRenameClick,
  setEditedVideo,
}) {
  const { register, setValue, getValues, errors, clearError } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation("video-playlists");
  const { escPressed, setEscPressed } = useContext(VideoContext);

  useEffect(() => {
    if (editedVideo.name) {
      setValue("videoName", editedVideo.name);
      clearError();
      document.getElementById("video-name").focus();
    }
  }, [editedVideo]);

  useEffect(() => {
    if (escPressed) {
      setEscPressed(false);
    }
  }, [escPressed]);

  const getVideoSize = (video) => `${Math.round(video.size / 1000000)} MB`;

  const getVideoLength = ({ videoLength }) => {
    const timeFormat = videoLength < 3600 ? "mm:ss" : "hh:mm:ss";

    return moment
      .utc(moment.duration(videoLength, "seconds").asMilliseconds())
      .format(timeFormat);
  };

  const handlePressEnter = (e) => {
    e.stopPropagation();

    if (e.key === "Enter" && !errors.videoName) {
      const newVideoName = getValues().videoName;
      setNewVideoName(newVideoName);
    }
  };

  const handleClickAway = () => {
    setValue("videoName", editedVideo.name);
    setEditedVideo({ id: undefined, name: "" });
    setEscPressed(true);
  };

  return (
    <VideoDetailsContainer>
      <div className={classes["video-name-container"]}>
        {editedVideo.id === video.id ? (
          <ClickAwayListener
            touchEvent={"onTouchEnd"}
            mouseEvent={"onMouseDown"}
            onClickAway={handleClickAway}
          >
            <Form.Input
              // @ts-ignore
              autoComplete="off"
              className={clsx(classes["video-name-input"], {
                [classes["error"]]: errors.videoName,
              })}
              id="video-name"
              name="videoName"
              ref={register({
                ...validateRequired,
                ...validateIDTName,
              })}
              error={getInputError(errors.videoName, t, MAX_IDTNAME_LEN)}
              onClick={(e) => e.stopPropagation()}
              onKeyPress={handlePressEnter}
              endAdornment={
                <Tooltip
                  title={t("common:close")}
                  placement="right"
                  classes={{ tooltip: classes["tooltip"] }}
                >
                  <CloseIcon
                    className={classes["close-icon"]}
                    onClick={handleClickAway}
                  />
                </Tooltip>
              }
              video
            />
          </ClickAwayListener>
        ) : (
          <Tooltip
            title={t("sections.playlists.tooltips.click-to-rename")}
            placement="top-start"
            classes={{ tooltip: classes["tooltip"] }}
          >
            <div
              className={clsx(classes["video-name"], classes["no-select"])}
              onClick={handleRenameClick(video)}
            >
              {video.name}
            </div>
          </Tooltip>
        )}
      </div>
      <div className={clsx(classes["video-details"], classes["no-select"])}>
        <span>{video.resolution}&nbsp;|&nbsp;</span>
        <span>{getVideoSize(video)}&nbsp;|&nbsp;</span>
        <span>{getVideoLength(video)}&nbsp;&nbsp;</span>
      </div>
    </VideoDetailsContainer>
  );
}

VideoDetails.propTypes = {
  video: object,
  editedVideo: object,
  setNewVideoName: func,
  handleRenameClick: func,
  setEditedVideo: func,
};

export default VideoDetails;
