import React from "react";
import clsx from "clsx";
import { object, func, bool } from "prop-types";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import ModalVideoDetails from "./modal-video-details/ModalVideoDetails";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./ModalVideoItem.module.scss";

function ModalVideoItem({ video, selected, addVideoToSelected }) {
  const spriteUrl = video.preview || "";

  const handleItemClick = () => {
    addVideoToSelected(video);
  };

  return (
    <div
      className={clsx(classes["modal-video-item"], {
        [classes["item-selected"]]: selected
      })}
      onClick={handleItemClick}
    >
      {spriteUrl ? (
        <VideoSprite spriteUrl={spriteUrl} frameWidth={400} frameHeight={225} />
      ) : (
        <PreviewUnloader medium />
      )}
      <ModalVideoDetails video={video} />
    </div>
  );
}

ModalVideoItem.propTypes = {
  video: object,
  selected: bool,
  addVideoToSelected: func.isRequired
};

export default ModalVideoItem;
