import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import { Slide } from "@material-ui/core";
import Img from "react-image";

import { baseUrl } from "data/constants";
import useIntervalScreenshots from "hooks/useIntervalScreenshots";
import useScreenshot from "hooks/useScreenshot";
import UpdatedTime from "../UpdatedTime/UpdatedTime";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./ChannelPreview.module.scss";

function ChannelPreview({ channel, preview }) {
  const [slideStatus, setSlideStatus] = useState(false);
  const { screenshots, clearScreenshotInterval } = useIntervalScreenshots({}, [
    channel
  ]);

  const {
    screenshotSrc,
    screenshotInfo,
    setScreenshotSrc,
    setScreenshotInfo,
    setScreenshotSrcAndInfo
  } = useScreenshot(baseUrl);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setScreenshotSrcAndInfo(screenshots);
  }, [screenshots]);

  useEffect(() => {
    const { id } = channel;
    const { previewImage, previewInfo } = preview;

    if (id) {
      handleSlideIn();
      setScreenshotSrc(previewImage);
      setScreenshotInfo(previewInfo);
    } else {
      handleSlideOut();
    }
  }, [channel, preview]);
  /* eslint-enabled react-hooks/exhaustive-deps */
  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 350);
  };

  const handleSlideOut = () => {
    clearScreenshotInterval();
    setSlideStatus(false);
  };

  return (
    <Slide direction="left" in={slideStatus} mountOnEnter unmountOnExit>
      <div className={classes["channel-preview"]}>
        <div className={classes["channel-preview-screen"]}>
          <Img
            src={screenshotSrc}
            className={classes["image"]}
            unloader={<PreviewUnloader />}
          />
        </div>
        {!!screenshotInfo && (
          <div className={classes["info-container"]}>
            <UpdatedTime
              timestamp={screenshotInfo.timestampScreenshotTaken || 0}
            />
            {screenshotInfo.width && (
              <div>
                {screenshotInfo.width}x{screenshotInfo.height}
              </div>
            )}
          </div>
        )}
      </div>
    </Slide>
  );
}

ChannelPreview.propTypes = {
  channel: object,
  preview: object
};

export default ChannelPreview;
