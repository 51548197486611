import React, { createContext, useState } from "react";

const DrawerContext = createContext({
  open: false,
  setOpen: null,
  duplicateEndpoints: [],
  setDuplicateEndpoints: null,
});

export function DrawerContextProvider({ children }) {
  const [open, setOpen] = useState(true);
  const [duplicateEndpoints, setDuplicateEndpoints] = useState([]);

  return (
    <DrawerContext.Provider
      value={{
        open,
        setOpen,
        duplicateEndpoints,
        setDuplicateEndpoints,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export default DrawerContext;
