import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import clsx from "clsx";

import useApi from "hooks/useApi";
import useQuery from "hooks/useQuery";
import AuthService from "services/auth";
import useLoginHandler from "hooks/useLoginHandler";
import useTimeout from "hooks/useTimeout";

import LinearLoader from "components/layout-components/persistentDrawer/components/loader/LinearLoader";
import Title from "components/layout-components/title/Title";

import classes from "./IDTRedirect.module.scss";

function IDTRedirect() {
  const query = useQuery();
  const setLoginCredentials = useLoginHandler();
  const token = query.get("token");
  const { t } = useTranslation("common");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const setTimeOut = useTimeout();
  const history = useHistory();

  const hideLoader = () => {
    setLoading(false);
  };

  const onLoginRedirectSuccess = data => {
    setLoginCredentials(data);
    hideLoader();
  };

  const onLoginRedirectError = err => {
    const message = err.response.data.message;

    if (message === "Token is not valid") {
      setErrorMessage(t("error-messages.token-is-not-valid"));
    } else {
      setErrorMessage(message);
    }

    hideLoader();
  };

  const [, , , loginRedirect] = useApi(AuthService.loginRedirect, {
    requestOnMount: false,
    onSuccess: onLoginRedirectSuccess,
    onError: onLoginRedirectError,
    errorMessage: {
      message: t("unable-to-redirect"),
      variant: "error",
      autoHideDuration: 5000
    }
  });

  const openIdxHub = () => {
    history.push("/");
  };

  useEffect(() => {
    if (!!token) {
      setTimeOut(() => {
        loginRedirect({ token });
      }, 2000);
    }
  }, []);

  return (
    <div className={classes.container}>
      <Title section="redirecting" />
      <div>
        <div
          className={clsx(classes["idt-redirect-content"], {
            [classes["error-borders"]]: errorMessage
          })}
        >
          <div className={classes["message-container"]}>
            {errorMessage && (
              <ErrorOutlineIcon className={classes["error-icon"]} />
            )}
            <span className={classes["message"]}>
              {errorMessage ? (
                <span className={classes["message-error"]}>{errorMessage}</span>
              ) : (
                <span className={classes["message-redirecting"]}>
                  {t("redirecting-to-idt")}
                </span>
              )}
            </span>
          </div>
          <div className={classes["idt-redirect-loader"]}>
            <LinearLoader progressBar={loading} />
          </div>
        </div>

        <div className={classes["idt-redirect-button-container"]}>
          {errorMessage && (
            <Button
              onClick={openIdxHub}
              classes={{ root: classes["idt-redirect-button"] }}
              variant="contained"
            >
              <ArrowBackIcon classes={{ root: classes["back-icon"] }} />
              <span>{t("back-to-idx-hub")}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default IDTRedirect;
