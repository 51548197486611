import React, { useState, useEffect } from "react";
import { object, func, array, string } from "prop-types";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import clsx from "clsx";

import VideoDetails from "./components/video-details/VideoDetails";
import SideMenu from "./components/side-menu/SideMenu";
import VideoSprite from "./components/video-sprite/VideoSprite";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./VideoItem.module.scss";

function VideoItem({
  video,
  editedVideo,
  handleRenameVideo,
  handleDeleteVideo,
  setNewVideoName,
  handleItemClick,
  selectedVideosArray,
  renameTxt,
  deleteTxt,
  loading,
  setEditedVideo
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    if (!loading) {
      setAllLoaded(true);
    }
  }, [loading]);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClickAway = () => {
    closeMenu();
  };

  const handleMenuClick = videoId => e => {
    e.stopPropagation();
    if (videoId === video.id && !menuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  };

  const handleDeleteClick = video => e => {
    e.stopPropagation();
    closeMenu();
    handleDeleteVideo(video);
  };

  const handleRenameClick = video => e => {
    e.stopPropagation();
    if (menuOpen) {
      closeMenu();
    }
    handleRenameVideo(video);
  };

  const handleSelectVideoClick = () => {
    if (allLoaded) {
      handleItemClick(video);
    }
  };

  const handleEsc = event => {
    if (event.keyCode === 27) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <div
      className={clsx(classes["videos-list-item"], {
        [classes["item-selected"]]: selectedVideosArray.some(
          item => item.id === video.id
        ),
        [classes["show-pointer"]]: allLoaded
      })}
      onClick={handleSelectVideoClick}
      data-cy={`video-${video.id}`}
    >
      <div
        className={clsx(classes["videos-list-item-image"], {
          [classes["loading"]]: loading
        })}
      >
        {!loading && video.preview ? (
          <VideoSprite spriteUrl={video.preview} />
        ) : allLoaded ? (
          <PreviewUnloader />
        ) : null}
      </div>

      <div className={classes["info-background"]} />
      {!loading && (
        <div className={classes["videos-list-item-info"]}>
          <VideoDetails
            video={video}
            editedVideo={editedVideo}
            setNewVideoName={setNewVideoName}
            handleRenameClick={handleRenameClick}
            setEditedVideo={setEditedVideo}
          />
          <div className={classes["more-icon-container"]}>
            <MoreVertSharpIcon
              className={classes["more-icon"]}
              onClick={handleMenuClick(video.id)}
            />
          </div>
        </div>
      )}

      {menuOpen && (
        <SideMenu
          video={video}
          handleClickAway={handleClickAway}
          handleRenameClick={handleRenameClick}
          handleDeleteClick={handleDeleteClick}
          renameTxt={renameTxt}
          deleteTxt={deleteTxt}
        />
      )}
    </div>
  );
}

VideoItem.propTypes = {
  video: object,
  editedVideo: object,
  handleRenameVideo: func,
  handleDeleteVideo: func,
  setNewVideoName: func,
  handleItemClick: func,
  selectedVideosArray: array,
  renameTxt: string,
  deleteTxt: string,
  setEditedVideo: func
};

export default VideoItem;
