import React, { useContext, useState } from "react";

import AlertsApi from "services/api/4-endpoint-control-api/alerts-api";
import ControlContext from "contexts/ControlContext";
import useApi from "hooks/useApi";

import AlertsList from "./alerts-list/AlertsList";
import AlertPreview from "./alerts-preview/AlertPreview";
import NotSelectedOption from "../not-selected-option/NotSelectedOption";
import EllipsisLoader from "components/ui/loaders/EllipsisLoader";
import AlertsTopBar from "./alerts-top-bar/AlertsTopBar";

import { getTextFromHTMLText } from "./utils/utils";

import classes from "./Alerts.module.scss";

export default function Alerts() {
  const [updatedAlerts, setUpdatedAlerts] = useState([]);
  const onFetchAlertsSuccess = data => {
    const alerts = data.map(alert => ({
      ...alert,
      text: getTextFromHTMLText(alert.sampletext)
    }));
    setUpdatedAlerts(alerts);
  };

  const [, alertsLoading] = useApi(AlertsApi.getAllAlerts, {
    onSuccess: onFetchAlertsSuccess
  });
  const [selectedAlert, setSelectedAlert] = useState({ id: undefined });
  const { hidden } = useContext(ControlContext);

  const handleSelectAlert = alert => _ => {
    if (!selectedAlert.id || selectedAlert.id !== alert.id) {
      setSelectedAlert(alert);
    } else if (selectedAlert.id === alert.id) {
      setSelectedAlert({ id: undefined });
    }
  };

  return !hidden ? (
    <div className={classes["alerts"]}>
      {!alertsLoading ? (
        <>
          <AlertsTopBar />
          <div className={classes["container-bottom"]}>
            <AlertsList
              alerts={updatedAlerts}
              selectedAlert={selectedAlert}
              handleSelectAlert={handleSelectAlert}
            />
            <AlertPreview
              selectedAlert={selectedAlert}
              updateAlerts={onFetchAlertsSuccess}
              setSelectedAlert={setSelectedAlert}
            />
          </div>
        </>
      ) : (
        <div className={classes["alerts-loader-container"]}>
          <div className={classes["alerts-loader"]}>
            <EllipsisLoader />
          </div>
        </div>
      )}
    </div>
  ) : (
    <NotSelectedOption />
  );
}
