import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class PlaylistsApi {
  getToken = () => cookies.get("token");

  getAllPlaylists = async serverInUseId => {
    const method = "get";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return await axios({
      method,
      url,
      headers
    });
  };

  createPlaylist = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/create-with-videos`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  renamePlaylist = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/rename`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  deletePlaylist = async (serverInUseId, payload) => {
    const method = "delete";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  addVideosToPlaylist = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/add-videos-to-playlist`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  removeVideoFromPlaylist = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/remove-video`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  copyPlaylistToIDT = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/copy-to-server`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  reorderPlaylistInIDT = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/videos/change-position`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
}

export default new PlaylistsApi();
