import React from "react";
import { string } from "prop-types";
import { useTranslation } from "react-i18next";

import { ENTITIES } from "../../../../utils/utils";

import LegendIcon from "./components/icon/LegendIcon";

import classes from "./ChartLegend.module.scss";

function ChartLegend({ entities }) {
  const { t } = useTranslation("dashboard");
  const OFFLINE = "modal.chart.legend.offline";
  const ONLINE = "modal.chart.legend.online";
  const { ALL, GATEWAYS, TUNERS } = ENTITIES;

  return (
    <div className={classes["chart-legend"]}>
      {entities !== TUNERS && (
        <div className={classes["chart-legend-panel-left"]}>
          <div className={classes["chart-legend-item"]}>
            <LegendIcon offline />
            <div className={classes["chart-legend-item-label"]}>
              {t(`${OFFLINE}.${entities !== ALL ? entities : GATEWAYS}`)}
            </div>
          </div>
          <div className={classes["chart-legend-item"]}>
            <LegendIcon online />
            <div className={classes["chart-legend-item-label"]}>
              {t(`${ONLINE}.${entities !== ALL ? entities : GATEWAYS}`)}
            </div>
          </div>
        </div>
      )}
      {entities === ALL || entities === TUNERS ? (
        <div className={classes["chart-legend-panel-right"]}>
          <div className={classes["chart-legend-item"]}>
            <LegendIcon tunersOffline />
            <div className={classes["chart-legend-item-label"]}>
              {t(`${OFFLINE}.tuners`)}
            </div>
          </div>
          <div className={classes["chart-legend-item"]}>
            <LegendIcon tunersOnline />
            <div className={classes["chart-legend-item-label"]}>
              {t(`${ONLINE}.tuners`)}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

ChartLegend.propTypes = {
  entities: string
};

export default ChartLegend;
