import React from "react";
import { object } from "prop-types";
import clsx from "clsx";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";

import classes from "./GroupDetails.module.scss";

function GroupDetails({ selectedGroup }) {
  const { t } = useTranslation("endpoint-groups");

  const EndpointDetails = ({ name, status }) => (
    <div className={classes["endpoint-details"]}>
      <div className={classes["endpoint-details-name"]}>{name}</div>
      <div className={classes["endpoint-details-status"]}>
        <span
          className={clsx(classes["circle"], {
            [classes["circle-online"]]: status === "online",
          })}
        />
        <span>{t(`common:${status}`)}</span>
      </div>
    </div>
  );

  const getValue = (key) =>
    selectedGroup[key] !== undefined ? selectedGroup[key] : "";

  return (
    <>
      <div className={classes["group-pin"]}>PIN: {getValue("pin")}</div>
      <Accordion
        classes={{
          root: classes["assigned-endpoints-panel"],
          expanded: classes["expanded"],
        }}
      >
        <AccordionSummary
          classes={{ root: classes["summary"] }}
          expandIcon={
            <ExpandMoreIcon classes={{ root: classes["expand-icon"] }} />
          }
        >
          <div className={classes["assigned-endpoints-label"]}>
            {t("endpoints-assigned")}:&nbsp;{getValue("endpoints").length}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Scrollbars autoHeight autoHeightMax="41vh">
            {selectedGroup.id &&
              selectedGroup.endpoints.map((endpoint) => (
                <EndpointDetails
                  key={endpoint.name}
                  name={endpoint.name}
                  status={endpoint.online ? "online" : "offline"}
                />
              ))}
          </Scrollbars>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

GroupDetails.propTypes = {
  selectedGroup: object,
};

export default GroupDetails;
