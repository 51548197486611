import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import NotificationContext from "contexts/NotificationContext";
import useApi from "hooks/useApi";
import useTimeout from "hooks/useTimeout";
import AuthService from "services/auth";
import { getErrorMessage } from "services/helpers/helpers";
import { authRequestsMessages } from "../../utils/utils";
import { MAX_EMAIL_LEN } from "data/constants";
import { getInputError, validateEmail, validateRequired } from "utils/utils";

import LoginHeader from "../login-header/LoginHeader";
import Form from "components/ui/form/Form";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "../../Home.module.scss";

function ForgotPassword() {
  const {
    actions: { setSnackbar }
  } = useContext(NotificationContext);
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState: { dirty, isValid }
  } = useForm({
    mode: "onChange"
  });
  const { push } = useHistory();
  const setTimeOut = useTimeout();

  const { t } = useTranslation("common");

  const { FORGOT_PASSWORD_SUCCESS } = authRequestsMessages(t);

  const redirectToLoginPageDelayed = () => {
    setTimeOut(() => push("/"), 3000);
  };

  const onForgotPasswordFail = error => {
    if (
      (error && error.response && error.response.status === 500) ||
      (error && !error.response)
    ) {
      const message = getErrorMessage(error);
      setSnackbar(message, "error");
    } else {
      const { message, variant, autoHideDuration } = FORGOT_PASSWORD_SUCCESS;
      setSnackbar(message, variant, autoHideDuration);
      redirectToLoginPageDelayed();
    }
  };

  const [, , , handleResetPassword] = useApi(AuthService.forgotPassword, {
    requestOnMount: false,
    successMessage: FORGOT_PASSWORD_SUCCESS,
    onSuccess: redirectToLoginPageDelayed,
    onError: onForgotPasswordFail,
    showMessageOnError: false
  });

  const onSubmit = data => {
    handleResetPassword(data);
  };

  return (
    <div
      className={clsx(
        classes["form-container"],
        classes["form-container-login"]
      )}
    >
      <LoginHeader forgotPassword />
      <Form
        className={classes["form"]}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Field>
          <Form.Input
            // @ts-ignore
            type="text"
            name="email"
            placeholder={t("email-placeholder")}
            className={clsx(classes["form-input"], {
              [classes["form-input-error"]]: errors.email
            })}
            ref={register({
              ...validateRequired,
              ...validateEmail
            })}
            error={getInputError(errors.email, t, MAX_EMAIL_LEN)}
            login
          />
        </Form.Field>

        <div className={classes["bottom-container"]}>
          <div className={classes["helper"]}>
            <Link to="/" className={classes["helper-text"]}>
              {t("back-to-sign-in-form")}
            </Link>
          </div>
          <div className={classes["right-container"]}>
            <PrimaryButton
              className={classes["sign-in-button"]}
              type="submit"
              disabled={!isValid || !dirty}
            >
              {t("forgot-password-form.reset-password-button")}
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </div>
  );
}
export default ForgotPassword;
