import React from "react";
import { node } from "prop-types";

import classes from "./PlaylistDetails.module.scss";

function PlaylistDetails({ children }) {
  return <div className={classes["playlist-details"]}>{children}</div>;
}

PlaylistDetails.propTypes = {
  children: node
};

export default PlaylistDetails;
