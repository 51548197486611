import React, { createContext, useState } from "react";

const ChannelsContext = createContext({
  channels: [],
  setChannels: null
});

export function ChannelsContextProvider({ children }) {
  const [channels, setChannels] = useState([]);

  return (
    <ChannelsContext.Provider
      value={{
        channels: channels,
        setChannels: setChannels
      }}
    >
      {children}
    </ChannelsContext.Provider>
  );
}

export default ChannelsContext;
