import React, { useContext } from "react";
import { bool, func, object } from "prop-types";

import { VideoContext } from "contexts/VideoContext";

import Modal from "components/ui/modals/modal/Modal";
import ModalTitle from "./modal-title/ModalTitle";
import ModalPlaylists from "./modal-playlists/ModalPlaylists";
import ModalButtons from "./modal-buttons/ModalButtons";

function SetActivePlaylistModal({
  open,
  onClose,
  playlistActiveInChannel,
  handleSetPlaylistActiveInChannel,
  assignPlaylistToChannel,
  loading
}) {
  const { availablePlaylists } = useContext(VideoContext);

  const handlePlaylistClick = playlist => {
    handleSetPlaylistActiveInChannel(playlist);
  };

  return (
    <Modal open={open}>
      <Modal.Header loading={loading} onClose={onClose}>
        <ModalTitle />
      </Modal.Header>

      <Modal.Content>
        <ModalPlaylists
          availablePlaylists={availablePlaylists}
          playlistActiveInChannel={playlistActiveInChannel}
          handlePlaylistClick={handlePlaylistClick}
        />
      </Modal.Content>

      <Modal.Footer>
        <ModalButtons
          assignPlaylistToChannel={assignPlaylistToChannel}
          disabled={!playlistActiveInChannel.id}
        />
      </Modal.Footer>
    </Modal>
  );
}

SetActivePlaylistModal.propTypes = {
  open: bool.isRequired,
  onClose: func,
  playlistActiveInChannel: object,
  handleSetPlaylistActiveInChannel: func.isRequired,
  assignPlaylistToChannel: func.isRequired,
  loading: bool
};

export default SetActivePlaylistModal;
