import React from "react";
import { bool } from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";

import classes from "./ExpandIcon.module.scss";

function ExpandIcon({ open, collapsable, disabled }) {
  return (
    <div
      className={clsx(classes["expand-icon-container"], {
        [classes["disabled"]]: disabled
      })}
    >
      {!open ? (
        <ChevronRightIcon
          className={clsx(classes["expand-icon"], {
            [classes["expand-icon-visible"]]: collapsable
          })}
        />
      ) : (
        <ExpandMoreIcon
          className={clsx(classes["expand-icon"], {
            [classes["expand-icon-visible"]]: collapsable
          })}
        />
      )}
    </div>
  );
}

ExpandIcon.propTypes = {
  open: bool,
  collapsable: bool,
  disabled: bool
};

export default ExpandIcon;
