import { useEffect, useRef } from "react";

function useInterval() {
  const intervalId = useRef(null);

  const setInterVal = (callback, delay) => {
    intervalId.current = setInterval(callback, delay);
  };

  const clearInterVal = () => clearInterval(intervalId.current);

  useEffect(() => {
    return () => clearInterVal();
  }, []);

  return { setInterVal, clearInterVal };
}

export default useInterval;
