import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const DETECTION_OPTIONS = {
  order: ["localStorage", "cookie", "navigator"],
  lookupLocalStorage: "i18nextLng",
  lookupCookie: "i18next"
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: "en-GB",
    debug: true,
    react: {
      useSuspense: true,
      wait: true
    }
  });

export default i18n;
