import React from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import classes from "../../Templates.module.scss";

function TemplateTableHeader({ loading }) {
  const { t } = useTranslation("control");

  return !loading ? (
    <thead>
      <tr className={classes["table-head"]}>
        <th
          className={clsx(
            classes["fixed-header"],
            classes["table-col-width-5"]
          )}
        >
          {t("templates.thumb")}
        </th>
        <th
          align="center"
          className={clsx(
            classes["fixed-header"],
            classes["table-col-width-30"]
          )}
        >
          {t("templates.name")}
        </th>
      </tr>
    </thead>
  ) : null;
}

TemplateTableHeader.propTypes = {
  loading: bool
};

export default TemplateTableHeader;
