import React, { useEffect } from "react";
import { object, func, bool } from "prop-types";
import { useForm } from "react-hook-form";

import PlaylistName from "./components/playlist-name/PlaylistName";
import PlaylistDetails from "./components/playlist-details/PlaylistDetails";
import TopContainer from "./components/top-container/TopContainer";
import PlaylistDuration from "./components/playlist-duration/PlaylistDuration";
import PlaylistAssigned from "./components/playlist-assigned/PlaylistAssigned";

import classes from "./PlaylistInfo.module.scss";

const PlaylistInfoContainer = "div";

function PlaylistInfo({
  playlist,
  editedPlaylist,
  setEditedPlaylist,
  renameEditedPlaylist,
  updating = true,
  handleRenameClick,
  playlistPlaying,
  playlistAssignedToChannel
}) {
  const { register, setValue, getValues, errors, clearError } = useForm({
    mode: "onChange"
  });

  useEffect(() => {
    if (editedPlaylist.name !== undefined) {
      setValue("playlistName", editedPlaylist.name);
      clearError();
      document.getElementById("playlist-name").focus();
    }
  }, [editedPlaylist]);

  const handleRenamePlaylist = () => {
    const newPlaylistName = getValues().playlistName;

    renameEditedPlaylist(newPlaylistName);
  };

  const hideEditNameInput = () => {
    setEditedPlaylist({
      name: undefined,
      id: undefined
    });
  };

  const handlePressEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!errors.playlistName) {
        handleRenamePlaylist();
      }
    }
  };

  return (
    <PlaylistInfoContainer className={classes["playlist-info"]}>
      <PlaylistName
        editedPlaylist={editedPlaylist}
        playlist={playlist}
        hideEditNameInput={hideEditNameInput}
        register={register}
        handlePressEnter={handlePressEnter}
        updating={updating}
        handleRenameClick={handleRenameClick}
        playlistPlaying={playlistPlaying}
        playlistAssignedToChannel={playlistAssignedToChannel}
        errors={errors}
      />
      <PlaylistDetails>
        <TopContainer>
          <PlaylistDuration duration={playlist.totalduration} />
          <PlaylistAssigned isAssigned={playlist.assignedToChannel} />
        </TopContainer>
      </PlaylistDetails>
    </PlaylistInfoContainer>
  );
}

PlaylistInfo.propTypes = {
  playlist: object.isRequired,
  editedPlaylist: object,
  setEditedPlaylist: func,
  renameEditedPlaylist: func.isRequired,
  handleRenameClick: func,
  playlistPlaying: bool,
  playlistAssignedToChannel: bool
};

export default PlaylistInfo;
