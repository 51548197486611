import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./i18n";
import { StylesProvider } from "@material-ui/styles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn:
    "https://f29df35be6c94783aca4230b37a34f3d@o300539.ingest.sentry.io/5425449",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0, // Be sure to lower this in production to avoid consuming your quota too quickly
  beforeSend(event) {
    const filteredBreadCrumbs = event.breadcrumbs.filter(
      item =>
        !(item.level && (item.level === "warning" || item.level === "log"))
    );
    event.breadcrumbs = filteredBreadCrumbs;

    return event;
  }
});

// Sentry.captureException("capture exeption...");

ReactDOM.render(
  <StylesProvider injectFirst>
    <App />
  </StylesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
