import React, { useState, useEffect } from "react";
import { arrayOf, object, func } from "prop-types";
import Scrollbars from "react-custom-scrollbars";

import { renderThumbVertical, renderTrackVertical } from "utils/utils";

import ModalVideoItem from "./components/ModalVideoItem/ModalVideoItem";

import classes from "./ModalVideosList.module.scss";

function ModalVideosList({ videos, selectedVideos, addVideoToSelected }) {
  const [allVideos, setAllVideos] = useState([]);

  const checkIfSelected = video =>
    selectedVideos.some(item => item.id === video.id);

  useEffect(() => {
    setAllVideos(videos.reverse());
  }, [videos]);

  return (
    <Scrollbars
      autoHeight
      autoHide
      autoHeightMax="60vh"
      renderTrackVertical={renderTrackVertical}
      renderThumbVertical={renderThumbVertical}
    >
      <div className={classes["modal-videos"]}>
        {allVideos.length > 0 &&
          allVideos.map(video => (
            <ModalVideoItem
              key={video.id}
              video={video}
              selected={checkIfSelected(video)}
              addVideoToSelected={addVideoToSelected}
            />
          ))}
      </div>
    </Scrollbars>
  );
}

ModalVideosList.propTypes = {
  videos: arrayOf(object),
  selectedVideos: arrayOf(object),
  addVideoToSelected: func.isRequired
};

export default ModalVideosList;
