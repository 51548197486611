import React from "react";
import { object } from "prop-types";
import Img from "react-image";

import { baseUrl } from "data/constants";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./ActivePlaylistVideoItem.module.scss";

function ActivePlaylistVideoItem({ video }) {
  const src = `${baseUrl}${video.previewUrl}`;

  return (
    <div className={classes["video-item"]}>
      <div className={classes["video-item-preview"]}>
        {video.sprite ? (
          <VideoSprite spriteUrl={video.previewUrl} />
        ) : (
          <Img
            src={src}
            className={classes["image"]}
            unloader={<PreviewUnloader />}
          />
        )}
      </div>

      <div className={classes["info-background"]}></div>
      <div className={classes["video-item-info"]}>
        <div className={classes["video-item-info-container"]}>
          <div className={classes["video-name"]}>{video.name}</div>
          <div className={classes["video-duration"]}>{video.duration}</div>
        </div>
      </div>
    </div>
  );
}

ActivePlaylistVideoItem.propTypes = {
  video: object.isRequired
};

export default ActivePlaylistVideoItem;
