import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { NotificationContextProvider } from "contexts/NotificationContext";
import ServerContextProvider from "contexts/ServerContext";
import { AuthContextProvider } from "contexts/AuthContext";

import SnackBar from "components/ui/snackbars/SnackBar";
import Routes from "routes/Routes";

import "styles/global-styles.scss";

function App() {
  const theme = createMuiTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: "2.7rem"
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <NotificationContextProvider>
        <AuthContextProvider>
          <ServerContextProvider>
            <Router>
              <Routes />
            </Router>
          </ServerContextProvider>
          <SnackBar />
        </AuthContextProvider>
      </NotificationContextProvider>
    </ThemeProvider>
  );
}

export default App;
