import React from "react";
import { useTranslation } from "react-i18next";

import VideosApi from "services/api/3-content-api/videos-api";
import useApi from "hooks/useApi";
import { videosRequestsMessages } from "./utils/utils";

import VideosList from "./components/videos-list/VideosList";

import classes from "./Videos.module.scss";

const VIDEOS_INITIAL_VALUE = [
  { id: "placeholder_video_1" },
  { id: "placeholder_video_2" },
  { id: "placeholder_video_3" },
  { id: "placeholder_video_4" }
];

function Videos() {
  const { t } = useTranslation("video-playlists");
  const { VIDEOS_LOAD_ERROR } = videosRequestsMessages(t);

  const [videos = [], videosLoading, , getVideos] = useApi(
    VideosApi.getAllVideos,
    {
      errorMessage: VIDEOS_LOAD_ERROR
    }
  );

  const renderVideos =
    videosLoading && !videos.length ? VIDEOS_INITIAL_VALUE : videos;

  return (
    <div className={classes["videos"]}>
      <VideosList
        videos={renderVideos}
        videosLoading={videosLoading}
        fetchVideos={getVideos}
      />
    </div>
  );
}

export default Videos;
