import React, { useState, useEffect, useContext, Suspense } from "react";
import { bool, node } from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import DrawerContext from "../../../contexts/DrawerContext";

import MultiLevelMenu from "../multilevel-menu/MultiLevelMenu";
import LinearLoader from "./components/loader/LinearLoader";
import SelectedServer from "./components/selected-server/SelectedServer";
import User from "./components/user/User";
import LogoIDT from "./components/logoIDT/LogoIDT";

import classes from "./PersistentDrawerLeft.module.scss";

function PersistentDrawerLeft({ progressBar, children }) {
  const { open, setOpen } = useContext(DrawerContext);
  const { ready } = useTranslation("common", { useSuspense: false });
  const [firstTimeRendered, setFirstTimeRendered] = useState(false);

  useEffect(() => {
    if (ready && !firstTimeRendered) {
      setFirstTimeRendered(true);
    }
  }, [ready]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return firstTimeRendered ? (
    <div className={classes["root"]}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes["app-bar"], {
          [classes["app-bar-shift"]]: open,
        })}
      >
        <Toolbar variant="dense" classes={{ dense: classes["tool-bar"] }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes["menu-button"], open && classes["hide"])}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes["tool-bar-components"]}>
            <SelectedServer />
            <Suspense fallback={null}>
              <User />
            </Suspense>
          </div>
        </Toolbar>
        <LinearLoader progressBar={progressBar} />
      </AppBar>

      <Drawer
        className={classes["drawer"]}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes["drawer-paper"],
        }}
      >
        <div className={classes["drawer-header"]}>
          <LogoIDT />
          <IconButton
            onClick={handleDrawerClose}
            classes={{ root: classes["hide-drawer-button"] }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <MultiLevelMenu />
      </Drawer>
      <div
        className={clsx(classes["content"], {
          [classes["content-shift"]]: open,
        })}
      >
        {children}
      </div>
    </div>
  ) : null;
}

PersistentDrawerLeft.propTypes = {
  progressBar: bool.isRequired,
  children: node.isRequired,
};

export default PersistentDrawerLeft;
