import { isNotNullOrUndefinded } from "utils/utils";

export function tvControlSetRequestsMessages(t) {
  const PREFIX = "tv-control.snacks";

  const FULLSCREEN_ON_SUCCESS = {
    message: t(`${PREFIX}.set-fullscreen-on-success`),
    variant: "success",
    autoHideDuration: 3500,
  };
  const FULLSCREEN_OFF_SUCCESS = {
    message: t(`${PREFIX}.set-fullscreen-off-success`),
    variant: "success",
    autoHideDuration: 3500,
  };
  const TURN_ON_SUCCESS = {
    message: t(`${PREFIX}.turn-on-success`),
    variant: "success",
    autoHideDuration: 3500,
  };
  const TURN_OFF_SUCCESS = {
    message: t(`${PREFIX}.turn-off-success`),
    variant: "success",
    autoHideDuration: 3500,
  };
  const SUBTITLES_ON_SUCCESS = {
    message: t(`${PREFIX}.subtitles-on-success`),
    variant: "success",
    autoHideDuration: 3500,
  };
  const SUBTITLES_OFF_SUCCESS = {
    message: t(`${PREFIX}.subtitles-on-off-success`),
    variant: "success",
    autoHideDuration: 3500,
  };

  const GET_CHANNELS_ERROR = {
    message: t(`${PREFIX}.channels-list-load-error`),
    variant: "error",
    autoHideDuration: 3500,
  };

  const SET_CHANNEL_SUCCESS = {
    message: t(`${PREFIX}.change-channel-success`),
    variant: "success",
    autoHideDuration: 3500,
  };

  const SET_VOLUME_SUCCESS = {
    message: t(`${PREFIX}.change-volume-success`),
    variant: "success",
    autoHideDuration: 3500,
  };

  const RETURN_TO_ORIGINAL_CHANNEL = {
    message: t(`${PREFIX}.return-to-original-channel`),
    variant: "success",
    autoHideDuration: 3500,
  };

  return {
    FULLSCREEN_ON_SUCCESS,
    FULLSCREEN_OFF_SUCCESS,
    TURN_ON_SUCCESS,
    TURN_OFF_SUCCESS,
    SUBTITLES_ON_SUCCESS,
    SUBTITLES_OFF_SUCCESS,
    GET_CHANNELS_ERROR,
    SET_CHANNEL_SUCCESS,
    SET_VOLUME_SUCCESS,
    RETURN_TO_ORIGINAL_CHANNEL,
  };
}

export function templatesRequestsMessages(t) {
  const SET_TEMPLATE_SUCCESS = {
    message: t("templates.snacks.change-template-success"),
    variant: "success",
    autoHideDuration: 3500,
  };

  const SET_TEMPLATE_ERROR = {
    message: t("templates.snacks.change-template-error"),
    variant: "error",
    autoHideDuration: 3500,
  };

  const ASSIGN_TEMPLATE_TO_GROUPS_SUCCESS = {
    message: t("templates.snacks.assign-template-to-groups-success"),
    variant: "success",
    autoHideDuration: 3500,
  };

  const ASSIGN_TEMPLATE_TO_GROUPS_ERROR = {
    message: t("templates.snacks.assign-template-to-groups-error"),
    variant: "error",
    autoHideDuration: 3500,
  };

  return {
    SET_TEMPLATE_SUCCESS,
    SET_TEMPLATE_ERROR,
    ASSIGN_TEMPLATE_TO_GROUPS_SUCCESS,
    ASSIGN_TEMPLATE_TO_GROUPS_ERROR,
  };
}

export function refreshRebootRequestsMessages(t) {
  const REFRESH_SUCCESS = {
    message: t("refresh-reboot.snacks.refresh-success"),
    variant: "success",
    autoHideDuration: 3500,
  };

  const REBOOT_SUCCESS = {
    message: t("refresh-reboot.snacks.reboot-success"),
    variant: "success",
    autoHideDuration: 3500,
  };

  return {
    REFRESH_SUCCESS,
    REBOOT_SUCCESS,
  };
}

export function stbStartupRequestsMessages(t) {
  const STARTUP_MODE_CHANGED_SUCCESS = {
    message: t("stb-startup.snacks.change-startup-success"),
    variant: "success",
    autoHideDuration: 3500,
  };
  return {
    STARTUP_MODE_CHANGED_SUCCESS,
  };
}

export function endpointMapper({
  ipAddress,
  name,
  currentChannelID,
  currentChannelName,
  subtitles,
  volume,
  online,
  template,
  bootmode,
  isFullScreen,
}) {
  return {
    id: ipAddress,
    name,
    currentChannelID,
    currentChannel: currentChannelName,
    subtitles,
    volume: "" + volume,
    online,
    template,
    startUp: bootmode,
    checked: false,
    fullscreen: isFullScreen,
  };
}

export function groupMapper({ id, name, endpoints, channelids, templateids }) {
  return {
    id,
    name,
    checked: false,
    endpoints: endpoints.map(endpointMapper),
    channelIds: channelids,
    templateIds: templateids,
  };
}

export function getModifiedGroup(groupToModify) {
  let modifiedGroup = { ...groupToModify };
  const groupChecked = modifiedGroup.checked;
  modifiedGroup.checked = !groupChecked;

  modifiedGroup.endpoints = modifiedGroup.endpoints.map((endpoint) => ({
    ...endpoint,
    checked: !groupChecked,
  }));

  return modifiedGroup;
}

export function getModifiedGroupWithEndpointsChanges(groupToModify, id) {
  let modifiedGroup = { ...groupToModify };
  modifiedGroup.checked = false;

  let modifiedEndpointIndex = modifiedGroup.endpoints.findIndex(
    (endpoint) => endpoint.id === id
  );
  if (isNotNullOrUndefinded(modifiedEndpointIndex)) {
    modifiedGroup.endpoints[modifiedEndpointIndex].checked =
      !modifiedGroup.endpoints[modifiedEndpointIndex].checked;
  }

  return modifiedGroup;
}

export function getSelectedGroupsIds(groups) {
  return groups.filter(({ checked }) => checked).map(({ id }) => id);
}

export function getSelectedEndpointsIds(groups) {
  return groups.reduce((acc, currGroup) => {
    const selectedEndpointsIds = currGroup.endpoints
      .filter(({ checked }) => checked)
      .map(({ id }) => id);
    acc = [...acc, ...selectedEndpointsIds];
    return acc;
  }, []);
}

export function findGroupsForEndpoints(groups, selectedEndpointsIds) {
  const selectedGroups = [
    ...groups
      .filter(({ endpoints }) =>
        endpoints
          .map(({ id }) => id)
          .some((id) => selectedEndpointsIds.includes(id))
      )
      .map(({ id, name }) => ({ id, name })),
  ];

  return selectedGroups;
}

export function getAvailableChannels(
  groups,
  selectedGroupsIds,
  selectedEndpointsIds
) {
  const groupsIds = [
    ...selectedGroupsIds,
    ...groups
      .filter(({ endpoints }) =>
        endpoints
          .map(({ id }) => id)
          .some((id) => selectedEndpointsIds.includes(id))
      )
      .map(({ id }) => id),
  ];

  const availableChannels = groups
    .filter(({ id }) => groupsIds.includes(id))
    .reduce((accessibleChannels, { channelIds }, i) => {
      if (i === 0) {
        return channelIds;
      }

      return accessibleChannels.filter((channelId) =>
        channelIds.includes(channelId)
      );
    }, []);

  return availableChannels;
}

export function getAvailableTemplates(
  groups,
  selectedGroupsIds,
  selectedEndpointsIds
) {
  const groupsIds = [
    ...selectedGroupsIds,
    ...groups
      .filter(({ endpoints }) =>
        endpoints
          .map(({ id }) => id)
          .some((id) => selectedEndpointsIds.includes(id))
      )
      .map(({ id }) => id),
  ];

  const availableTemplates = groups
    .filter(({ id }) => groupsIds.includes(id))
    .reduce((accessibleTemplates, { templateIds }, i) => {
      if (i === 0) {
        return templateIds;
      }
      return accessibleTemplates.filter((templateId) =>
        templateIds.includes(templateId)
      );
    }, []);

  return availableTemplates;
}

// ***************

export const getAllGroupsIds = (groups) => groups.map(({ id }) => id);

export const getAllEndpoints = (groups) =>
  groups
    .map((group) =>
      group.endpoints.map((endpoint) => ({
        id: endpoint.id,
        groupId: group.id,
      }))
    )
    .flat();

export const findCommonElements = (groups, selectedEndpoints, key) => {
  // Create array of group ids based on selected endpoints

  const groupsIds = selectedEndpoints
    .map(({ groupId }) => groupId)
    .reduce(
      (acc, group) => (acc.includes(group) ? acc : [...acc, group]), // Removes duplicates
      []
    );

  // Create array of group objects based on array of group ids

  const groupsObjects = groups.reduce((acc, curr) => {
    if (groupsIds.some((groupId) => groupId === curr.id)) {
      acc = [...acc, curr];
    }
    return acc;
  }, []);

  // Extract channels/templates arrays from groups and create array of channels/templates arrays

  const arraysOfElementsFromGroups = groupsObjects.reduce((acc, curr) => {
    acc = [...acc, curr[key]];

    return acc;
  }, []);

  // Find common channels/templates for all the selected endpoints and groups

  const commonElements = arraysOfElementsFromGroups.length // Find common elements in multiple arrays
    ? arraysOfElementsFromGroups.shift().reduce((acc, curr) => {
        if (
          acc.indexOf(curr) === -1 &&
          arraysOfElementsFromGroups.every(
            (channel) => channel.indexOf(curr) !== -1
          )
        )
          acc.push(curr);
        return acc;
      }, [])
    : [];

  return commonElements;
};
