import React, { useContext } from "react";
import { string, node } from "prop-types";

import LoadingContext from "contexts/LoadingContext";
import UserContextProvider from "contexts/UserContext";
import { DrawerContextProvider } from "contexts/DrawerContext";

import Title from "../layout-components/title/Title";
import PersistentDrawerLeft from "../layout-components/persistentDrawer/PersistentDrawerLeft";

import classes from "./Layout.module.scss";

function Layout({ section, subSection, children }) {
  const { loading } = useContext(LoadingContext);

  return (
    <div className={classes["layout"]}>
      <Title section={section} subSection={subSection} />
      <UserContextProvider>
        <DrawerContextProvider>
          <PersistentDrawerLeft progressBar={loading} children={children} />
        </DrawerContextProvider>
      </UserContextProvider>
    </div>
  );
}

Layout.propTypes = {
  section: string,
  subSection: string,
  children: node.isRequired
};

export default Layout;
