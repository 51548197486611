import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./ScreenshotNotLoaded.module.scss";

function ScreenshotNotLoaded() {
  const { t } = useTranslation("channel-list");

  return (
    <div className={classes["no-screenshot"]}>
      <div className={classes["no-screenshot-text"]}>
        <div>{t("screenshot-not-loaded.top-line")}</div>
        <div>{t("screenshot-not-loaded.bottom-line")}</div>
      </div>
    </div>
  );
}

export default ScreenshotNotLoaded;
