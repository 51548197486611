import React, { useState } from "react";
import { func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "@material-ui/core";

import useApi from "hooks/useApi";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import SetActionLoader from "../loader/SetActionLoader";

import { messageType, requestPayloadType } from "types";

import classes from "../TvControl.module.scss";

function SetActionOn({
  requestPayload,
  offApiMethod,
  onApiMethod,
  label,
  fieldName,
  setGroups = null,
  onReqSuccessMessage,
  offReqSuccessMessage,
}) {
  const [turnOffConifirmationOpen, setTurnOffConifirmationOpen] =
    useState(false);
  const { t } = useTranslation("control");
  const setOnSetActionSuccess = (actionOn) => () => {
    setTurnOffConifirmationOpen((prev) => (prev ? false : prev));
    setGroups(fieldName, actionOn);
  };

  const [, isOffReqLoading, , offApiRequest] = useApi(offApiMethod, {
    requestOnMount: false,
    ...(setGroups ? { onSuccess: setOnSetActionSuccess(false) } : {}),
    successMessage: offReqSuccessMessage,
  });

  const [, isOnReqLoading, , onApiRequest] = useApi(onApiMethod, {
    requestOnMount: false,
    ...(setGroups ? { onSuccess: setOnSetActionSuccess(true) } : {}),
    successMessage: onReqSuccessMessage,
  });

  const disabled = isOffReqLoading || isOnReqLoading;

  const openTurnOffConfirmation = () => {
    setTurnOffConifirmationOpen(true);
  };

  const closeTurnOffConfirmation = () => {
    setTurnOffConifirmationOpen(false);
  };

  const handleOnRequest = () => {
    onApiRequest(requestPayload);
  };

  const handleOffRequest = () => {
    closeTurnOffConfirmation();
    offApiRequest(requestPayload);
  };

  const hadleTurnOffTV = () => {
    openTurnOffConfirmation();
  };

  return (
    <div className={classes["box-content-item"]}>
      <div>
        <label className={classes["box-label"]}>
          <span>{label}</span>
          <SetActionLoader loading={isOffReqLoading || isOnReqLoading} />
        </label>
        <ButtonGroup
          aria-label="outlined primary button group"
          className={classes["btn-group"]}
        >
          <PrimaryButton onClick={handleOnRequest} disabled={disabled}>
            {t("tv-control.on")}
          </PrimaryButton>
          <PrimaryButton
            onClick={fieldName === "online" ? hadleTurnOffTV : handleOffRequest}
            disabled={disabled}
          >
            {t("tv-control.off")}
          </PrimaryButton>
        </ButtonGroup>
      </div>

      {fieldName === "online" && (
        <ConfirmationModal
          open={turnOffConifirmationOpen}
          onClose={closeTurnOffConfirmation}
          onAction={handleOffRequest}
          actionName={t("tv-control.confirmation-modal.button-label")}
          loading={isOffReqLoading}
        >
          <span>{t("tv-control.confirmation-modal.question")}</span>
        </ConfirmationModal>
      )}
    </div>
  );
}

SetActionOn.propTypes = {
  requestPayload: requestPayloadType,
  offApiMethod: func.isRequired,
  onApiMethod: func.isRequired,
  label: string.isRequired,
  fieldName: string.isRequired,
  setGroups: func,
  onReqSuccessMessage: messageType,
  offReqSuccessMessage: messageType,
};

export default SetActionOn;
