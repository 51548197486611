import React from "react";

import classes from "./CircleLoader.module.scss";

export default function CircleLoader() {
  return (
    <div className={classes["lds-ring"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
