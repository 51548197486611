export function dashboardRequestsMessages(t) {
  const GET_COMPANIES_ERROR = {
    message: t("access-management:companies.snacks.get-companies-error"),
    variant: "error",
    autoHideDuration: 3500
  };
  const GET_OVERVIEW_ERROR = {
    message: t("snacks.overview-load-error"),
    variant: "error",
    autoHideDuration: 3500
  };
  const GET_SERVER_CHART_DATA_ERROR = {
    message: t("snacks.get-server-chart-data-error"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_GATEWAYS_CHART_DATA_ERROR = {
    message: t("snacks.get-gateways-chart-data-error"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_ENCODERS_CHART_DATA_ERROR = {
    message: t("snacks.get-encoders-chart-data-error"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_ENDPOINTS_CHART_DATA_ERROR = {
    message: t("snacks.get-endpoints-chart-data-error"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_COMPANIES_ERROR,
    GET_OVERVIEW_ERROR,
    GET_SERVER_CHART_DATA_ERROR,
    GET_GATEWAYS_CHART_DATA_ERROR,
    GET_ENCODERS_CHART_DATA_ERROR,
    GET_ENDPOINTS_CHART_DATA_ERROR
  };
}

export const ENTITIES = {
  ALL: "all",
  GATEWAYS: "gateways",
  TUNERS: "tuners"
};

export const STATUSES = {
  GATEWAYS_ONLINE: "gatewaysOnline",
  TUNERS_ONLINE: "tunersOnline"
};

export const BAR_COLORS = {
  ONLINE_FILL: "#2e8453",
  OFFLINE_FILL: "#b20811",
  ONLINE_FILL_SECONDARY: "#23446a",
  OFFLINE_FILL_SECONDARY: "#607d8b",
  PRIMARY_FILL: "#23446a",
  PRIMARY_BACKGROUND: "#607d8b",
  SECONDARY_FILL: "#267748",
  SECONDARY_BACKGROUND: "#52996d",
  TOOLTIP_CURSOR: "#fdba31"
};
