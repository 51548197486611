import React from "react";
import { func, bool } from "prop-types";
import { useTranslation } from "react-i18next";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ModalButtons.module.scss";

function ModalButtons({ assignPlaylistToChannel, disabled }) {
  const { t } = useTranslation("video-playlists");

  return (
    <div className={classes["playlists-modal-buttons"]}>
      <PrimaryButton onClick={assignPlaylistToChannel} disabled={disabled}>
        {t("common:apply")}
      </PrimaryButton>
    </div>
  );
}

ModalButtons.propTypes = {
  assignPlaylistToChannel: func.isRequired,
  disabled: bool.isRequired
};

export default ModalButtons;
