import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";

import { ServerContext } from "contexts/ServerContext";

import classes from "./SelectedServer.module.scss";

function SelectedServer() {
  const [disabled, setDisabled] = useState(false);
  const { serverInUse } = useContext(ServerContext);
  const { t } = useTranslation("common", { useSuspense: false });
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/template-builder" && !disabled) {
      setDisabled(true);
    } else if (disabled) {
      setDisabled(false);
    }
  }, [history.location]);

  return (
    <div
      className={clsx(classes["selected-server-container"], {
        [classes["disabled"]]: disabled
      })}
    >
      <Tooltip
        disableHoverListener={disabled}
        title={t("change-server")}
        placement="bottom-end"
        classes={{ tooltip: classes["tooltip-font"] }}
      >
        <div
          className={classes["selected-server"]}
          onClick={() => history.push("/servers")}
        >
          <div className={classes["selected-server-text"]}>
            <span
              className={clsx(classes["server-in-use"], {
                [classes["not-selected"]]: !serverInUse.name
              })}
            >
              {!serverInUse.name
                ? t("no-server-selected")
                : t("current-server")}
            </span>{" "}
            <span className={classes["server-in-use-name"]}>
              {serverInUse.name ? serverInUse.name.toUpperCase() : ""}
            </span>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default SelectedServer;
