import React from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";

import classes from "./LoginHeader.module.scss";

function LoginHeader({ forgotPassword = false, resetPassword = false }) {
  const { t } = useTranslation("common");

  return (
    <div className={classes["form-header"]}>
      <h1 className={classes["form-header-welcome"]}>{t("welcome-to-idt")}</h1>
      <div className={classes["form-header-welcome-text"]}>
        {!forgotPassword && !resetPassword ? (
          <>
            <div>{t("enter-your-details")}</div>
            <div>
              {t("cannot-sign-in")}
              <a
                href="https://www.aurafutures.com/contact"
                target="_blank"
                rel="noopener noreferrer"
                className={classes["contact-link"]}
              >
                {t("contact-us")}
              </a>
            </div>
          </>
        ) : resetPassword ? (
          <div>{t("reset-password-form.enter-password")}</div>
        ) : (
          <div>{t("forgot-password-form.enter-login-to-reset-password")}</div>
        )}
      </div>
    </div>
  );
}

LoginHeader.propTypes = {
  forgotPassword: bool,
  resetPassword: bool
};

export default LoginHeader;
