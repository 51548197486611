import React, { useState, useEffect, useContext } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";

import MessagingApi from "services/api/4-endpoint-control-api/messaging-api";
import ControlContext from "contexts/ControlContext";
import useApi from "hooks/useApi";
import { getSnacks } from "../snacks/snacks";

import BottomSection from "./bottom-section/BottomSection";
import TopSection from "./top-section/TopSection";
import AutocompleteMain from "./autocomplete/AutocompleteMain";
import NotSelectedOption from "pages/endpoints/control/control-panel/components/not-selected-option/NotSelectedOption";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./SavedMessages.module.scss";

function SavedMessages({ messages }) {
  const [messagesList, setMessagesList] = useState([]);
  const [messageToBeDeleted, setMessageToBeDeleted] = useState(undefined);
  const [newMessage, setNewMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isMessageTooLong, setIsMessageTooLong] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const { t } = useTranslation("control");
  const { requestPayload, hidden } = useContext(ControlContext);

  const {
    MESSAGE_DELETED,
    MESSAGE_DELETED_ERROR,
    MESSAGE_SHOWN,
    MESSAGE_SHOWN_ERROR,
    MESSAGES_CLEARED,
    MESSAGES_CLEARED_ERROR,
  } = getSnacks();

  // --- LIST UPDATERS ---

  const updateListAfterAddingNew = ({ id }) => {
    const newlyCreatedMssg = { id, value: newMessage };
    const updatedList = [...messagesList, newlyCreatedMssg];

    setMessagesList(updatedList);
    showMessage();
  };

  const updateListAfterDeletion = () => {
    const updatedList = messagesList.filter(
      (item) => item.id !== messageToBeDeleted
    );
    setMessagesList(updatedList);
    toggleDialogOpen();
  };

  const [, showMessageLoading, , callShowMessage] = useApi(
    MessagingApi.showMessage,
    {
      requestOnMount: false,
      successMessage:
        Number(minutes) > 0 || Number(hours) > 0
          ? {
              ...MESSAGE_SHOWN,
              message: t(
                "messages.message-will-be-shown-for-the-time-specified"
              ),
            }
          : MESSAGE_SHOWN,
      errorMessage: MESSAGE_SHOWN_ERROR,
    }
  );
  const [, newMessageLoading, , callAddNewMessage] = useApi(
    MessagingApi.addNewMessage,
    {
      requestOnMount: false,
      onSuccess: updateListAfterAddingNew,
    }
  );

  const [, deleteLoading, , callDeleteMessage] = useApi(
    MessagingApi.deleteMessage,
    {
      requestOnMount: false,
      onSuccess: updateListAfterDeletion,
      successMessage: MESSAGE_DELETED,
      errorMessage: MESSAGE_DELETED_ERROR,
    }
  );

  const [, hideLoading, , callHideMessage] = useApi(MessagingApi.hideMessage, {
    requestOnMount: false,
    successMessage: MESSAGES_CLEARED,
    errorMessage: MESSAGES_CLEARED_ERROR,
  });

  const loading = newMessageLoading || showMessageLoading || hideLoading;

  useEffect(() => {
    const { data } = messages;
    setMessagesList(data);
  }, [messages]);

  useEffect(() => {
    if (messageToBeDeleted !== undefined) {
      toggleDialogOpen();
    }
  }, [messageToBeDeleted]);

  const toggleDialogOpen = () => {
    if (dialogOpen && messageToBeDeleted !== undefined) {
      setMessageToBeDeleted(undefined);
    }
    setDialogOpen((prev) => !prev);
  };

  // --- HANDLERS ---

  const handleAutoCompleteChange = (_, mssg) => {
    const messageTxt = mssg && mssg.value ? mssg.value : "";
    setNewMessage(messageTxt);
  };

  const handleTextFieldChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      showMessage(e.target.value);
    }
  };

  const handleCheckBox = (e) => {
    setIsChecked(e.target.checked);
  };

  // --- MESSAGING API ---

  const addNewMessage = () => {
    callAddNewMessage(newMessage);
    if (isChecked) {
      showMessage(newMessage);
    }
  };

  const deleteMessage = () => {
    callDeleteMessage(messageToBeDeleted);
  };

  const showMessage = (mssg) => {
    const ttl = isChecked ? Number(hours) * 60 + Number(minutes) : null;
    const mssgObj = {
      text: typeof mssg === "string" ? mssg : newMessage,
      ttl,
      ...requestPayload,
    };

    callShowMessage(mssgObj);
  };

  const hideMessage = () => {
    callHideMessage(requestPayload);
  };

  return !hidden ? (
    <>
      <div className={classes["messages-root"]}>
        <Paper className={classes["messages-container"]}>
          <TopSection
            loading={loading}
            handleHide={hideMessage}
            text={t("messages.clear-all-messages")}
          />
          <AutocompleteMain
            messagesList={messagesList}
            handleAutoCompleteChange={handleAutoCompleteChange}
            setMessageToBeDeleted={setMessageToBeDeleted}
            newMessage={newMessage}
            handleTextFieldChange={handleTextFieldChange}
            handleKeyPress={handleKeyPress}
            deleteLabel={t("messages.delete")}
            placeholder={t("messages.enter-a-message")}
            setIsMessageTooLong={setIsMessageTooLong}
          />
          <BottomSection
            loading={loading}
            handleCheckBox={handleCheckBox}
            newMessage={newMessage}
            hours={hours}
            minutes={minutes}
            isChecked={isChecked}
            setHours={setHours}
            setMinutes={setMinutes}
            handleSaveAndShow={addNewMessage}
            handleShow={showMessage}
            isMessageTooLong={isMessageTooLong}
          />
        </Paper>
      </div>

      <ConfirmationModal
        open={dialogOpen}
        onClose={toggleDialogOpen}
        onAction={deleteMessage}
        actionName={t("messages.delete")}
        disabled={deleteLoading}
        loading={deleteLoading}
      >
        {t("messages.delete-confirmation")}
      </ConfirmationModal>
    </>
  ) : (
    <NotSelectedOption />
  );
}

SavedMessages.propTypes = {
  messages: object,
};

export default SavedMessages;
