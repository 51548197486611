import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { func, bool, string } from "prop-types";
import { Checkbox } from "@material-ui/core";

import classes from "./ShowMessageLabel.module.scss";

function ShowMessageLabel({
  handleCheckBox,
  newMessage,
  isChecked,
  isMessageTooLong
}) {
  const { t } = useTranslation("control");

  return (
    <div className={classes["show-message-label"]}>
      <Checkbox
        onChange={handleCheckBox}
        disabled={!newMessage || isMessageTooLong}
        className={classes["checkbox"]}
        classes={{ disabled: classes["disabled"] }}
      />
      <span
        className={clsx(classes["show-message-label-text"], {
          [classes["disabled"]]: !isChecked
        })}
      >
        {t("messages.show-message-for")}
      </span>
    </div>
  );
}

ShowMessageLabel.propTypes = {
  handleCheckBox: func.isRequired,
  newMessage: string,
  isChecked: bool,
  isMessageTooLong: bool
};

export default ShowMessageLabel;
