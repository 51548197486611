import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { string, func, bool } from "prop-types";

import useBackupInfo from "../../../utils/useBackupInfo";

import classes from "./BackupItem.module.scss";

const BackupItem = ({
  backup,
  currentBranch,
  openRestoreModal,
  openDeleteModal,
  isActionInProgress,
  isRebootInProgress,
  setBackupToBeRestored,
  setBackupToBeDeleted
}) => {
  const snapshotItem = useBackupInfo(backup);
  const isCurrent = backup === currentBranch;
  const { t } = useTranslation("servers");

  const handleClickRestore = () => {
    if (!isActionInProgress) {
      openRestoreModal();
      setBackupToBeRestored(backup);
    }
  };

  const handleClickDelete = () => {
    if (!isActionInProgress) {
      openDeleteModal();
      setBackupToBeDeleted(backup);
    }
  };

  return (
    <li className={classes["backup-item"]}>
      <div className={classes["backup-item-info"]}>
        <span className={classes["backup-item-info-name"]}>{snapshotItem}</span>
        {isCurrent && (
          <span className={classes["current"]}>
            &nbsp;&nbsp;&#40;{t("backups.current")}&#41;
          </span>
        )}
      </div>
      <div className={classes["backup-item-buttons"]}>
        <span
          className={clsx(classes["backup-item-buttons-restore"], {
            [classes["disabled"]]: isActionInProgress || isRebootInProgress
          })}
          onClick={handleClickRestore}
        >
          {t("backups.buttons.restore")}
        </span>
        <span
          className={clsx(classes["backup-item-buttons-delete"], {
            [classes["disabled"]]: isActionInProgress || isRebootInProgress,
            [classes["invisible"]]: isCurrent
          })}
          onClick={handleClickDelete}
        >
          {t("common:delete")}
        </span>
      </div>
    </li>
  );
};

BackupItem.propTypes = {
  backup: string,
  currentBranch: string,
  openRestoreModal: func,
  openDeleteModal: func,
  isActionInProgress: bool,
  isRebootInProgress: bool,
  setBackupToBeRestored: func,
  setBackupToBeDeleted: func
};

export default BackupItem;
