import React, { useContext, useEffect } from "react";

import { getTemplateBuilderUrl } from "utils/utils";
import DrawerContext from "contexts/DrawerContext";
import useGetMessage from "../../../hooks/useGetMessage";

import { ServerContext } from "contexts/ServerContext";

import classes from "./TemplateBuilder.module.scss";

function TemplateBuilder() {
  const { setOpen } = useContext(DrawerContext);
  const { serverInUse } = useContext(ServerContext);
  const templateBuilderUrl = getTemplateBuilderUrl(serverInUse.id);
  const templateBuilderIframe = `<iframe src="${templateBuilderUrl}" title="IDT Template Builder" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin allow-downloads" allowfullscreen></iframe>`;

  useGetMessage();

  useEffect(() => {
    setOpen(false);
    return () => {
      setOpen(true);
    };
  }, []);
  return (
    <div
      className={classes["container"]}
      dangerouslySetInnerHTML={{ __html: templateBuilderIframe }}
    />
  );
}

export default TemplateBuilder;
