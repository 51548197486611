import React, { useState, useEffect } from "react";
import { arrayOf, object, func } from "prop-types";
import { useTranslation } from "react-i18next";
import Img from "react-image";

import AssignChannelsApi from "services/api/2-system-manage-api/assign-channels-api";
import useApi from "hooks/useApi";
import { assignChannelsRequestMessages } from "../../utils/utils";
import { baseUrl } from "data/constants";

import PageHeader from "components/ui/page-header/PageHeader";
import Table from "components/ui/table/Table";
import AssignCell from "components/ui/table/assign-cell/AssignCell";
import GroupsEntitiesHeader from "components/ui/table/groups-entities-header/GroupsEntitiesHeader";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import ImgLoader from "components/ui/loaders/img-loader/ImgLoader";
import ImgUnloader from "components/ui/loaders/img-unloader/ImgUnloader";

import classes from "./GroupsChannelsTable.module.scss";

function GroupsChannelsTable({ channels, groups, refreshTable }) {
  const [state, setState] = useState({
    channels: [],
    groups: []
  });
  const [selectedGroup, setSelectedGroup] = useState({
    id: null,
    name: null,
    actionName: null,
    question: null,
    action: null
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation("assign-pages");
  const {
    ALL_CHANNELS_ASSIGNED,
    ALL_CHANNELS_ASSIGNING_ERROR,
    ALL_CHANNELS_REMOVED,
    ALL_CHANNELS_REMOVING_ERROR,
    CHANNEL_ASSIGN_ERROR,
    CHANNEL_REMOVE_ERROR,
    CHANNEL_SET_AS_DEFAULT_ERROR
  } = assignChannelsRequestMessages(t);

  const [, assignInProgress, , assignChannelToGroup] = useApi(
    AssignChannelsApi.assignChannelToGroup,
    {
      requestOnMount: false,
      errorMessage: CHANNEL_ASSIGN_ERROR,
      onSuccess: refreshTable
    }
  );

  const [, removeInProgress, , removeChannelFromGroup] = useApi(
    AssignChannelsApi.removeChannelFromGroup,
    {
      requestOnMount: false,
      errorMessage: CHANNEL_REMOVE_ERROR,
      onSuccess: refreshTable
    }
  );

  const [, , , setChannelAsDefault] = useApi(
    AssignChannelsApi.setChannelAsDefault,
    {
      requestOnMount: false,
      errorMessage: CHANNEL_SET_AS_DEFAULT_ERROR,
      onSuccess: refreshTable
    }
  );

  const [, , , assignAllChannelsToGroup] = useApi(
    AssignChannelsApi.assignAllChannelsToGroup,
    {
      requestOnMount: false,
      successMessage: ALL_CHANNELS_ASSIGNED,
      errorMessage: ALL_CHANNELS_ASSIGNING_ERROR,
      onSuccess: refreshTable
    }
  );
  const [, , , removeAllChannelsFromGroup] = useApi(
    AssignChannelsApi.removeAllChannelsFromGroup,
    {
      requestOnMount: false,
      successMessage: ALL_CHANNELS_REMOVED,
      errorMessage: ALL_CHANNELS_REMOVING_ERROR,
      onSuccess: refreshTable
    }
  );

  useEffect(() => {
    setState({ channels, groups });
  }, [channels, groups]);

  const handleCloseModal = () => {
    setDialogOpen(false);
  };

  const handleOpenModal = () => {
    setDialogOpen(true);
  };

  const handleAssignAllChannelsToGroup = groupId => {
    handleCloseModal();
    assignAllChannelsToGroup({ groupId });
  };

  const handleRemoveAllChannelsFromGroup = groupId => {
    handleCloseModal();
    removeAllChannelsFromGroup({ groupId });
  };

  const toggleAssign = (group, action) => {
    const groupName = group.name;
    const groupId = group.id;
    const shouldAssign = action === "assign";

    setSelectedGroup({
      id: groupId,
      name: groupName,
      actionName: shouldAssign ? t("assign") : t("remove-assignments"),
      question: shouldAssign
        ? t("question-assign-channels")
        : t("question-remove-channels"),
      action: shouldAssign
        ? () => handleAssignAllChannelsToGroup(groupId)
        : () => handleRemoveAllChannelsFromGroup(groupId)
    });
    handleOpenModal();
  };

  return (
    <div className={classes["table"]}>
      <PageHeader title={t("assign-channels")} hasAccess={false} />
      <Table centered autoHeight>
        <Table.Head>
          <th className={classes["table-cell-header"]}>{t("channel")}</th>
          <GroupsEntitiesHeader
            groups={groups}
            toggleAssign={toggleAssign}
            allEnabledChannelsIds={channels.map(({ id }) => id)}
          />
        </Table.Head>
        <Table.Body>
          {state.channels.map((channel, channelIndex) => {
            const imgSrc = `${baseUrl}${channel.logoPath}`;

            return (
              <Table.Row key={`${channel.id}--${channel.name}`}>
                <td className={classes["table-cell-channel"]}>
                  <div className={classes["channel-info-container"]}>
                    <div className={classes["image-container"]}>
                      <Img
                        src={imgSrc}
                        className={classes["image"]}
                        alt="logo"
                        loader={<ImgLoader />}
                        unloader={
                          <ImgUnloader
                            unloaderClass={classes["unloader-size"]}
                          />
                        }
                      />
                    </div>
                    <div className={classes["table-channel-name"]}>
                      <span>{channel.name}</span>
                    </div>
                  </div>
                </td>
                {state.groups.map(group => {
                  const cell = `${group.id}-${channelIndex}`;

                  return (
                    <AssignCell
                      key={cell}
                      groupId={group.id}
                      assignedEntityIds={group.channelids}
                      defaultEntityId={group.defaultchannel}
                      entityName="channelId"
                      entityId={channel.id}
                      handleAssign={assignChannelToGroup}
                      handleRemove={removeChannelFromGroup}
                      handleSetDefault={setChannelAsDefault}
                      cellClasses={[classes["table-cell"]]}
                      assignInProgress={assignInProgress}
                      removeInProgress={removeInProgress}
                    />
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <ConfirmationModal
        open={dialogOpen}
        onClose={handleCloseModal}
        actionName={selectedGroup.actionName}
        onAction={selectedGroup.action}
      >
        <span>
          {selectedGroup.question}&nbsp;
          <span className={classes["modal-group-name"]}>
            {selectedGroup.name}?
          </span>
        </span>
      </ConfirmationModal>
    </div>
  );
}

GroupsChannelsTable.propTypes = {
  channels: arrayOf(object),
  groups: arrayOf(object),
  refreshTable: func.isRequired
};

export default GroupsChannelsTable;
