import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { bool, number } from "prop-types";

import classes from "./Circle.module.scss";

const COLORS = {
  GREEN: "green",
  ORANGE: "orange",
  RED: "red",
  GREY: "grey"
};

function Circle({ green = null, red = null, total = null, online = null }) {
  const [color, setColor] = useState("");
  const { GREEN, ORANGE, RED, GREY } = COLORS;

  useEffect(() => {
    if (green) {
      setColor(GREEN);
    }
    if (red) {
      setColor(RED);
    }
    if (total === 0) {
      setColor(GREY);
    } else if (online === 0) {
      setColor(RED);
    } else if (online > 0 && total > 0) {
      const percentage = (online / total) * 100;

      if (percentage === 0) {
        setColor(RED);
      }
      if (percentage < 100) {
        setColor(ORANGE);
      }
      if (percentage === 100) {
        setColor(GREEN);
      }
    }
  }, [green, red, total, online]);

  return (
    <div
      className={clsx(classes["circle"], [classes[color]])}
      onClick={e => e.stopPropagation()}
    />
  );
}

Circle.propTypes = {
  green: bool,
  red: bool,
  total: number,
  online: number
};

export default Circle;
