import React from "react";
import { object, func, bool } from "prop-types";
import { useTranslation } from "react-i18next";

import { MAX_DEFAULT_LEN, MAX_PRODUCT_KEY_LEN } from "data/constants";
import {
  getInputError,
  validateDefault,
  validateProductKey,
  validateRequired
} from "utils/utils";

import DeleteLine from "components/ui/buttons/delete-line/DeleteLine";
import ApplyButton from "../../components/apply-button/ApplyButton";
import Form from "components/ui/form/Form";

import classes from "./EditServerForm.module.scss";

const FormActions = "div";

function EditServerForm({
  handleSubmit,
  onSubmit,
  register,
  errors,
  openDeleteModal,
  dirty,
  isValid
}) {
  const { t } = useTranslation("servers");

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={classes["edit-server-form"]}
    >
      <Form.Field horizontal>
        <Form.Label>{t("name")}:</Form.Label>
        <Form.Input
          // @ts-ignore
          id="name"
          name="name"
          ref={register({
            ...validateRequired,
            maxLength: {
              value: MAX_DEFAULT_LEN,
              message: "common:form.max-length"
            },
            ...validateDefault
          })}
          error={getInputError(errors.name, t, MAX_DEFAULT_LEN)}
        />
      </Form.Field>

      <Form.Field horizontal>
        <Form.Label>{t("licence-key")}</Form.Label>
        <Form.Input
          // @ts-ignore
          id="licence-key"
          name="productKey"
          ref={register({
            ...validateRequired,
            ...validateProductKey
          })}
          error={getInputError(errors.productKey, t, MAX_PRODUCT_KEY_LEN)}
        />
      </Form.Field>

      <FormActions className={classes["edit-server-form-actions"]}>
        <DeleteLine label={t("common:delete")} onClick={openDeleteModal} />
        <ApplyButton label={t("common:apply")} disabled={!dirty || !isValid} />
      </FormActions>
    </Form>
  );
}

EditServerForm.propTypes = {
  handleSubmit: func,
  onSubmit: func,
  register: func,
  errors: object,
  openDeleteModal: func,
  dirty: bool,
  isValid: bool
};

export default EditServerForm;
