export function assignTemplatesRequestMessages(t) {
  const ALL_TEMPLATES_ASSIGNED = {
    message: t("snacks.templates-assigned-to-group"),
    variant: "success",
    autoHideDuration: 2000
  };

  const ALL_TEMPLATES_ASSIGNING_ERROR = {
    message: t("snacks.templates-could-not-be-assigned-to-group"),
    variant: "error",
    autoHideDuration: 2000
  };

  const ALL_TEMPLATES_REMOVED = {
    message: t("snacks.templates-removed-from-group"),
    variant: "success",
    autoHideDuration: 2000
  };

  const ALL_TEMPLATES_REMOVING_ERROR = {
    message: t("snacks.templates-could-not-be-removed-from-group"),
    variant: "error",
    autoHideDuration: 2000
  };

  const TEMPLATE_ASSIGN_ERROR = {
    message: t("snacks.template-could-not-be-assigned"),
    variant: "error",
    autoHideDuration: 3500
  };

  const TEMPLATE_REMOVE_ERROR = {
    message: t("snacks.template-could-not-be-removed-from-group"),
    variant: "error",
    autoHideDuration: 3500
  };

  const TEMPLATE_SET_AS_DEFAULT_ERROR = {
    message: t("snacks.template-could-not-be-set-as-default"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_TEMPLATES_ERROR = {
    message: t("snacks.templates-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_ENDPOINTS_ERROR = {
    message: t("snacks.endpoint-groups-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    ALL_TEMPLATES_ASSIGNED,
    ALL_TEMPLATES_ASSIGNING_ERROR,
    ALL_TEMPLATES_REMOVED,
    ALL_TEMPLATES_REMOVING_ERROR,
    TEMPLATE_ASSIGN_ERROR,
    TEMPLATE_REMOVE_ERROR,
    TEMPLATE_SET_AS_DEFAULT_ERROR,
    GET_TEMPLATES_ERROR,
    GET_ENDPOINTS_ERROR
  };
}
