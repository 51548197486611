import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";

const cookies = new Cookies();

class AssignEndpoints {
  getToken = () => cookies.get("token");

  getAllEndpoints = async (server) => {
    const method = `get`;
    const path = `/api/v1/idtservers/${server}/endpoints`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  updateEndpoint = async (serverInUseId, { endpoint, groupId }) => {
    const { name, bootmode, ipGateway, ipDHCP, ipSubnet, ipAddress } = endpoint;
    const method = `patch`;

    const path = `/api/v1/idtservers/${serverInUseId}/endpoints/${ipAddress}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        name,
        bootmode,
        groupid: groupId,
        ipGateway,
        ipDHCP,
        ipSubnet,
        ipAddress,
      },
    });
  };
}

export default new AssignEndpoints();
