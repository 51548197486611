import React from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import classes from "./PlaylistVideoPlaceholder.module.scss";

function PlaylistVideoPlaceholder({ video }) {
  const { t } = useTranslation("video-playlists");

  const status = getStatus(t)(video.progress);

  return (
    <div className={classes["playlist-video-placeholder"]}>
      <div className={classes["playlist-video-placeholder-status"]}>
        {video.progress === "100.00%" && (
          <CheckCircleOutlineIcon className={classes["icon"]} />
        )}
        {status}
      </div>
      <div className={classes["info-background"]} />
      <div className={classes["playlist-video-placeholder-info"]}>
        <div className={classes["video-name"]}>{video.name}</div>
        <div className={classes["progress-speed"]}>
          <div className={classes["progress"]}>
            <span>
              {t("sections.playlists.video-placeholder.progress")}:&nbsp;
            </span>
            <span>{video.progress}</span>
          </div>
          <div className={classes["speed"]}>
            <span>
              {t("sections.playlists.video-placeholder.download-speed")}:&nbsp;
            </span>
            <span>{video.downloadSpeed}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const getStatus = t => progress => {
  return progress !== "100.00%"
    ? t("sections.playlists.video-placeholder.downloading")
    : t("sections.playlists.video-placeholder.download-complete");
};

PlaylistVideoPlaceholder.propTypes = {
  video: object
};

export default PlaylistVideoPlaceholder;
