import { useEffect, useState } from "react";

function usePermissions() {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const userStringified = localStorage.getItem("userDetails");
    if (!!userStringified) {
      const user = JSON.parse(userStringified);
      if (user.permissions) {
        setPermissions(user.permissions);
      }
    }
  }, []);

  return permissions;
}

export default usePermissions;
