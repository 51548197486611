import React from "react";
import Img from "react-image";

const ThumbPlaceholder = React.forwardRef(function MyComponent(props, ref) {
  return (
    <div ref={ref}>
      <Img {...props} />
    </div>
  );
});

export default ThumbPlaceholder;
