import React, { Suspense, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getTokenFromCookie } from "utils/utils";

import Title from "components/layout-components/title/Title";
import Header from "../header/Header";

import Login from "./components/login/Login";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";

import classes from "./Home.module.scss";

const HomeFormWrapper = ({ children }) => {
  return (
    <div className={classes["home"]}>
      <Title />
      <Suspense fallback="Loading">
        <Header />
        <section className={classes["forms-container"]}>{children}</section>
      </Suspense>
    </div>
  );
};
export const WrappedLoginForm = () => {
  const history = useHistory();
  useEffect(() => {
    const token = getTokenFromCookie();
    if (!!token) {
      console.warn("Push dashboard");
      history.push("/dashboard/overview");
    }
  }, []);
  return (
    <HomeFormWrapper>
      <Login />
    </HomeFormWrapper>
  );
};

export const WrappedForgotPasswordForm = () => (
  <HomeFormWrapper>
    <ForgotPassword />
  </HomeFormWrapper>
);

export const WrappedResetPasswordForm = () => (
  <HomeFormWrapper>
    <ResetPassword />
  </HomeFormWrapper>
);
