import React from "react";
import { func, string } from "prop-types";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";

import classes from "./UserInfo.module.scss";

function UserInfo({ userName, userRole, toggleEditProfileMenu }) {
  return (
    <div
      className={classes["user-info"]}
      onClick={toggleEditProfileMenu}
      data-cy="user-settings-menu"
    >
      <span className={classes["user-info-name"]}>{userName}</span>
      <span className={classes["user-info-role"]}>
        &#40;
        {userRole}
        &#41;
      </span>
      <PersonSharpIcon />
    </div>
  );
}

UserInfo.propTypes = {
  userName: string,
  userRole: string,
  toggleEditProfileMenu: func
};

export default UserInfo;
