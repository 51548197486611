import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import AuthService from "services/auth";
import useApi from "hooks/useApi";
import useQuery from "hooks/useQuery";
import useLoginHandler from "hooks/useLoginHandler";
import { authRequestsMessages } from "../../utils/utils";
import { getInputError, validatePassword, validateRequired } from "utils/utils";
import { MAX_PASSWORD_LEN, MIN_PASSWORD_LEN } from "data/constants";

import LoginHeader from "../login-header/LoginHeader";
import Form from "components/ui/form/Form";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "../../Home.module.scss";

function ResetPassword() {
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirty, isValid }
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation("common");
  const setLoginCredentials = useLoginHandler();

  const query = useQuery();

  const { CHANGE_PASSWORD_SUCCESS } = authRequestsMessages(t);

  const onLoginSuccess = data => {
    setLoginCredentials({ ...data, delay: 3000 });
  };

  const [, , , handleChangePassword] = useApi(AuthService.changePassword, {
    requestOnMount: false,
    successMessage: CHANGE_PASSWORD_SUCCESS,
    onSuccess: onLoginSuccess
  });

  const onSubmit = ({ password }) => {
    const token = query.get("token");
    handleChangePassword({ password, token });
  };

  return (
    <div
      className={clsx(
        classes["form-container"],
        classes["form-container-login"]
      )}
    >
      <LoginHeader resetPassword />
      <Form
        className={classes["form"]}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Input
          // @ts-ignore
          type="password"
          name="password"
          placeholder={t("password-placeholder")}
          className={clsx(classes["form-input"], {
            [classes["form-input-error"]]: errors.password
          })}
          ref={register({
            ...validateRequired,
            ...validatePassword
          })}
          data-cy="reset-password-input"
          error={getInputError(
            errors.password,
            t,
            MAX_PASSWORD_LEN,
            MIN_PASSWORD_LEN
          )}
          login
        />

        <div className={classes["bottom-container"]}>
          <div className={classes["helper"]}>
            <span className={classes["helper-icon"]}>&gt;&nbsp;</span>
            <Link to="/" className={classes["helper-text"]}>
              {t("back-to-sign-in-form")}
            </Link>
          </div>
          <div className={classes["right-container"]}>
            <PrimaryButton
              className={classes["sign-in-button"]}
              type="submit"
              data-cy="reset-password-button"
              disabled={!isValid || !dirty}
            >
              {t("change-password")}
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ResetPassword;
