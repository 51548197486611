import React, { useState, useEffect } from "react";
import { arrayOf, func, string, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";

import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CircleLoader from "components/ui/loaders/CircleLoader";

import classes from "./AssignCell.module.scss";

function AssignCell({
  assignedEntityIds,
  defaultEntityId = null,
  groupId,
  entityName,
  entityId,
  handleAssign,
  handleRemove,
  handleSetDefault = null,
  cellClasses = null,
  handleDefault = true,
  assignInProgress,
  removeInProgress
}) {
  const [assignMenuVisible, setAssignMenuVisible] = useState(false);
  const [cellLoading, setCellLoading] = useState(false);
  const { t } = useTranslation("assign-pages");
  const showAssignMenu = e => {
    e.stopPropagation();
    setAssignMenuVisible(true);
  };
  const hideAssignMenu = e => {
    e.stopPropagation();
    setAssignMenuVisible(false);
  };

  const onAssign = async e => {
    e.stopPropagation();
    setAssignMenuVisible(false);
    setCellLoading(true);

    handleAssign({ [entityName]: entityId, groupId });
  };
  const onRemove = async e => {
    e.stopPropagation();
    setAssignMenuVisible(false);
    setCellLoading(true);

    handleRemove({ [entityName]: entityId, groupId });
  };
  const onSetDefault = async e => {
    e.stopPropagation();
    setAssignMenuVisible(false);
    setCellLoading(true);

    handleSetDefault({ [entityName]: entityId, groupId });
  };

  const entityAssignedToGroup = assignedEntityIds.includes(entityId);
  const cellQuickAction = entityAssignedToGroup ? onRemove : onAssign;
  const requestInProgress = assignInProgress || removeInProgress;
  const isDefaultEntity = defaultEntityId === entityId;

  useEffect(() => {
    setCellLoading(false);
  }, [entityAssignedToGroup, isDefaultEntity]);

  return (
    <td
      onClick={!requestInProgress ? cellQuickAction : null}
      className={clsx(
        ...(cellClasses ? cellClasses : []),
        classes["table-cell"]
      )}
    >
      <div className={clsx(classes["more-vert-icon"])} onClick={showAssignMenu}>
        <MoreVertIcon className={classes["more-icon"]} />
      </div>

      {cellLoading && (
        <div
          className={clsx(classes["loader"], {
            [classes["loader-show"]]: cellLoading || requestInProgress
          })}
        >
          <CircleLoader />
        </div>
      )}

      {entityAssignedToGroup && !cellLoading ? (
        handleDefault && isDefaultEntity ? (
          <div className={classes["circle"]}>
            <CheckSharpIcon className={classes["check-icon-circle"]} />
          </div>
        ) : (
            <CheckSharpIcon className={classes["check-icon"]} />
          )
      ) : null}

      {assignMenuVisible && (
        <ClickAwayListener onClickAway={hideAssignMenu}>
          <div className={classes["selected-cell"]}>
            <div className={classes["fields-container"]}>
              <div className={classes["close-icon"]} onClick={hideAssignMenu}>
                <CloseSharpIcon className={classes["icon-close"]} />
              </div>
              {!entityAssignedToGroup ? (
                <div
                  className={clsx(classes["select-field"])}
                  onClick={onAssign}
                >
                  {t("assign-to-group")}
                </div>
              ) : (
                  <div
                    className={clsx(classes["select-field"])}
                    onClick={onRemove}
                  >
                    {t("remove-from-group")}
                  </div>
                )}
              {handleDefault && (
                <div
                  className={clsx(
                    classes["default-field"],
                    defaultEntityId === entityId && classes["hide-default"]
                  )}
                  onClick={onSetDefault}
                >
                  <div style={{ whiteSpace: `pre-line` }}>
                    {t("set-as-default")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </td>
  );
}

AssignCell.propTypes = {
  assignedEntityIds: arrayOf(string),
  defaultEntityId: string,
  groupId: string,
  entityName: string,
  entityId: string,
  handleAssign: func,
  handleRemove: func,
  handleSetDefault: func,
  cellClasses: arrayOf(string),
  handleDefault: bool,
  assignInProgress: bool,
  removeInProgress: bool
};

export default AssignCell;
