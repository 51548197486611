import { useEffect, useState } from "react";

import usePermissions from "./usePermissions";

function useHasPermission(perm) {
  const [hasPermission, setHasPermission] = useState(false);
  const userPermissions = usePermissions();

  useEffect(() => {
    setHasPermission(userPermissions.includes(perm));
  }, [perm, userPermissions]);

  return hasPermission;
}

export default useHasPermission;
