import React, { useContext, useEffect, useState } from "react";
import { object, func } from "prop-types";
import Img from "react-image";
import clsx from "clsx";

import { VideoContext } from "contexts/VideoContext";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import NoActivePlaylistUnloader from "./unloaders/no-active-playlist-unloader/NoActivePlaylistUnloader";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./ChannelPlaylistPreview.module.scss";

function ChannelPlaylistPreview({ channel, getCurrentPlaylistInfo }) {
  const [channelPreview, setChannelPreview] = useState({
    url: undefined,
    isSprite: undefined
  });
  const { availablePlaylists } = useContext(VideoContext);

  useEffect(() => {
    const getActivePlaylistPreview = () => {
      const activePlaylist = availablePlaylists.filter(
        item => item.id === channel.currentPlaylistID
      )[0];

      const firstVideo =
        activePlaylist !== undefined ? activePlaylist.videos[0] : "";

      const playlistPreview = {
        url: firstVideo !== undefined ? firstVideo.previewUrl : "",
        isSprite: firstVideo !== undefined ? firstVideo.sprite : false
      };

      setChannelPreview(playlistPreview);
    };

    if (availablePlaylists.length > 0) {
      getActivePlaylistPreview();
    }
  }, [availablePlaylists, channel]);

  const getUnloader = () =>
    getCurrentPlaylistInfo().assigned ? (
      <PreviewUnloader small />
    ) : (
      <NoActivePlaylistUnloader />
    );

  return (
    <div
      className={clsx(classes["channel-preview"], {
        [classes["not-assigned"]]: !channel.enabled
      })}
    >
      {channelPreview.isSprite ? (
        <VideoSprite
          spriteUrl={channelPreview.url}
          frameWidth={135}
          frameHeight={76}
        />
      ) : (
        <Img
          src={channelPreview.url}
          className={classes["image"]}
          unloader={getUnloader()}
        />
      )}
    </div>
  );
}

ChannelPlaylistPreview.propTypes = {
  channel: object.isRequired,
  getCurrentPlaylistInfo: func.isRequired
};

export default ChannelPlaylistPreview;
