import React from "react";
import { node } from "prop-types";

import classes from "./TopContainer.module.scss";

function TopContainer({ children }) {
  return <div className={classes["top-container"]}>{children}</div>;
}

TopContainer.propTypes = {
  children: node
};

export default TopContainer;
