import React from "react";
import { func, bool } from "prop-types";
import { ClickAwayListener } from "@material-ui/core";

import MenuItem from "./menu-item/MenuItem";

import classes from "./SideMenu.module.scss";

function SideMenu({
  handleClickAway,
  handleRenameClick,
  handleDeleteClick,
  menuOpen,
  playlistPlaying,
  playlistAssignedToChannel,
  handleCopyClick
}) {
  return menuOpen ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ul className={classes["options-list"]}>
        <MenuItem
          label="rename"
          onClick={handleRenameClick}
          playlistPlaying={playlistPlaying}
          playlistAssignedToChannel={playlistAssignedToChannel}
        />
        <MenuItem
          label="delete"
          onClick={handleDeleteClick}
          playlistPlaying={playlistPlaying}
          playlistAssignedToChannel={playlistAssignedToChannel}
        />
        <MenuItem
          label="push"
          onClick={handleCopyClick}
          playlistPlaying={playlistPlaying}
          playlistAssignedToChannel={playlistAssignedToChannel}
        />
      </ul>
    </ClickAwayListener>
  ) : null;
}

SideMenu.propTypes = {
  handleClickAway: func.isRequired,
  handleRenameClick: func.isRequired,
  handleDeleteClick: func.isRequired,
  menuOpen: bool.isRequired,
  playlistPlaying: bool.isRequired,
  playlistAssignedToChannel: bool.isRequired,
  handleCopyClick: func.isRequired
};

export default SideMenu;
