import React, { useState, useContext } from "react";
import { string, arrayOf, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";

import ChannelsContext from "contexts/ChannelsContext";
import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import TvControlApi from "services/api/tv-control-api/tv-control-api";
import useApi from "hooks/useApi";
import { tvControlSetRequestsMessages } from "pages/endpoints/control/control-panel/utils/utils";
import { requestPayloadType } from "types";

import ChannelsSelect from "../../ui/channels-select/ChannelsSelect";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import SetActionLoader from "../loader/SetActionLoader";

import classes from "../TvControl.module.scss";

function ChangeChannel({ availableChannels, setGroups, requestPayload }) {
  const { t } = useTranslation("control");
  const [selectedChannelId, setSelectedChannelId] = useState("none");
  const { channels, setChannels } = useContext(ChannelsContext);

  const { GET_CHANNELS_ERROR, SET_CHANNEL_SUCCESS } =
    tvControlSetRequestsMessages(t);

  useApi(ChannelListApi.getEnabledChannels, {
    errorMessage: GET_CHANNELS_ERROR,
    onSuccess: setChannels,
    requestOnMount: channels.length === 0,
  });

  const setGroupsOnSuccess = () => {
    const channel = channels.find(
      (channel) => channel.id === selectedChannelId
    );

    if (channel) {
      setGroups("currentChannel", channel.name);
    }
  };

  const [, channelChangeLoading, , changeChannel] = useApi(
    TvControlApi.setChannel,
    {
      requestOnMount: false,
      onSuccess: setGroupsOnSuccess,
      successMessage: SET_CHANNEL_SUCCESS,
    }
  );

  const handleSelectChannel = (e) => {
    const channelId = e.target.value || "";

    setSelectedChannelId(channelId);
  };

  const handleApply = () => {
    const payload = {
      ...requestPayload,
      channelId: selectedChannelId,
    };

    changeChannel(payload);
  };

  return (
    <Paper className={classes["box"]}>
      <div className={classes["box-title"]}>
        <span>{t("tv-control.change-channel")}</span>
        <SetActionLoader loading={channelChangeLoading} />
      </div>

      <div className={classes["box-content"]}>
        <ChannelsSelect
          selectedChId={selectedChannelId}
          onSelectChannel={handleSelectChannel}
          channels={channels}
          availableChannels={availableChannels}
        />
        <div className={classes["box-action"]}>
          <PrimaryButton
            onClick={handleApply}
            disabled={selectedChannelId === "none" || channelChangeLoading}
          >
            {t("common:apply")}
          </PrimaryButton>
        </div>
      </div>
    </Paper>
  );
}

ChangeChannel.propTypes = {
  availableChannels: arrayOf(string),
  setGroups: func,
  requestPayload: requestPayloadType,
};

export default ChangeChannel;
