import React, { useEffect, useState } from "react";
import { number } from "prop-types";
import { useTranslation } from "react-i18next";
import { FaNetworkWired } from "react-icons/fa";
import ErrorIcon from "@material-ui/icons/Error";

import ServersApi from "services/api/6-servers-api/servers-api";
import useApi from "hooks/useApi";

import classes from "./NetworkInfo.module.scss";

function NetworkInfo({ serverId }) {
  const [ipsData, setIpsData] = useState([]);
  const [interfaceNames, setInterfaceNames] = useState([]);
  const [error, setError] = useState(false);
  const { t } = useTranslation("servers");

  const onFetchNetworkInfoSucess = ({ data }) => {
    setIpsData(data);
    setInterfaceNames(Object.keys(data));
  };

  const onFetchNetworkInfoError = err => {
    setError(true);
  };

  const [, , , fetchNetworkinfo] = useApi(ServersApi.getNetworkInfo, {
    requestOnMount: false,
    showMessageOnError: false,
    onSuccess: onFetchNetworkInfoSucess,
    onError: onFetchNetworkInfoError
  });

  useEffect(() => {
    if (serverId) {
      fetchNetworkinfo(serverId);
    }
  }, [serverId]);

  return (
    <div className={classes["network-info"]}>
      <div className={classes["network-info-header"]}>
        <FaNetworkWired className={classes["network-info-header-icon"]} />
        <div className={classes["network-info-header-label"]}>
          {t("network-interfaces.label")}
        </div>
      </div>

      {interfaceNames.length > 0 ? (
        <ul className={classes["network-info-interfaces-list"]}>
          {interfaceNames.map(
            name =>
              ipsData[name] &&
              ipsData[name].map(ip => (
                <li
                  key={`${name}-${ip}`}
                  className={classes["network-info-interfaces-list-item"]}
                >
                  <div
                    className={
                      classes["network-info-interfaces-list-item-values"]
                    }
                  >
                    <span>{name}</span>
                    <span>{ip}</span>
                  </div>
                </li>
              ))
          )}
        </ul>
      ) : error ? (
        <div className={classes["load-error"]}>
          <ErrorIcon className={classes["load-error-icon"]} />
          <span>{t("network-interfaces.load-error")}</span>
        </div>
      ) : null}
    </div>
  );
}

NetworkInfo.propTypes = {
  serverId: number
};

export default NetworkInfo;
