import {useEffect, useState} from "react";
import usePermissions from "./usePermissions";

function usePageFeatures(featureAccessMapper) {
    const [featuresAccess, setFeaturesAccess] = useState(null);
    const permissions = usePermissions()

    useEffect(() => {
        if (featureAccessMapper) {
            const newFeaturesAccess = Object.entries(featureAccessMapper).reduce((acc, [featureName, perm]) => {
                acc[featureName] = permissions.includes(perm)
                return acc;
            }, {})
            setFeaturesAccess(newFeaturesAccess)
        }

    }, [permissions])

    return featuresAccess;
}

export default usePageFeatures;
