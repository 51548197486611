import React, { useEffect, useState } from "react";
import { bool, string } from "prop-types";
import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import classes from "./LoadError.module.scss";

function LoadError({ open, module, loadErrorClass = null }) {
  const [message, setMessage] = useState("");
  const { t } = useTranslation("access-management");
  const PREFIX = "load-error.snacks";

  useEffect(() => {
    switch (module) {
      case "roles":
        setMessage(t(`${PREFIX}.roles-load-error`));
        break;
      case "companies":
        setMessage(t(`${PREFIX}.companies-load-error`));
        break;
      case "servers":
        setMessage(t(`${PREFIX}.servers-load-error`));
        break;
      default:
        break;
    }
  }, [module]);

  return (
    <Collapse in={open}>
      <div
        className={clsx(classes["load-error-message"], {
          [loadErrorClass]: loadErrorClass
        })}
      >
        {message}
      </div>
    </Collapse>
  );
}

LoadError.propTypes = {
  open: bool,
  module: string,
  loadErrorClass: string
};

export default LoadError;
