import React from "react";
import { array, bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";

import classes from "./EndpointDuplicatesInfo.module.scss";

function EndpointDuplicatesInfo({
  hideDuplicatesInfo,
  toggleDuplicatesList,
  duplicatesListVisible,
  duplicateEndpoints,
}) {
  const { t } = useTranslation("control");

  return (
    <div className={classes.duplicates}>
      <div className={classes["duplicates-info"]}>
        <CloseIcon
          className={classes["duplicates-info-close-icon"]}
          onClick={() => {
            hideDuplicatesInfo();
          }}
        />
        <div className={classes["duplicates-info-top"]}>
          <WarningIcon className={classes["duplicates-info-icon"]} />
          <span>{t("duplicates-info.top")}</span>
        </div>
        <div className={classes["duplicates-info-bottom"]}>
          {t("duplicates-info.bottom")}
        </div>
      </div>

      <div
        className={classes["toggle-duplicates"]}
        onClick={toggleDuplicatesList}
      >
        <ChevronRightIcon
          className={clsx(classes["toggle-duplicates-icon"], {
            [classes["rotate-icon"]]: duplicatesListVisible,
          })}
        />
        <span>
          {!duplicatesListVisible
            ? t("duplicates-info.show-duplicates")
            : t("duplicates-info.hide-duplicates")}
        </span>
      </div>

      <ul
        className={clsx(classes["duplicates-list"], {
          [classes["show-list"]]: duplicatesListVisible,
        })}
      >
        <li className={classes["duplicates-list-header"]}>
          <span className={classes["duplicates-cell-wide"]}>Endpoint 1</span>
          <span className={classes["duplicates-cell-wide"]}>Endpoint 2</span>
          <span className={classes["duplicates-cell-narrow"]}>Id</span>
        </li>
        {duplicateEndpoints.map((item) => (
          <li key={item[0].name} className={classes["duplicates-list-item"]}>
            <span className={classes["duplicates-cell-wide"]}>
              {item[0].name}
            </span>
            <span className={classes["duplicates-cell-wide"]}>
              {item[1].name}
            </span>
            <span className={classes["duplicates-cell-narrow"]}>
              {item[1].id}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

EndpointDuplicatesInfo.propTypes = {
  hideDuplicatesInfo: func,
  toggleDuplicatesList: func,
  duplicatesListVisible: bool,
  duplicateEndpoints: array,
};

export default EndpointDuplicatesInfo;
