import React, { useState } from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";

import useApi from "hooks/useApi";
import DashboardApi from "services/api/1-dashboard-api/dashboard-api";
import { dashboardRequestsMessages } from "../../utils/utils";

import LocationsPanel from "../locations-panel/LocationsPanel";

import classes from "./CompanyUserDashboard.module.scss";

function CompanyUserDashboard({ setProgressBarLoading, handleLocationClick }) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t } = useTranslation("dashboard");
  const onFetchOverviewSuccess = () => {
    setProgressBarLoading(false);
    setDataLoaded(true);
  };
  const { GET_OVERVIEW_ERROR } = dashboardRequestsMessages(t);
  const [locations = []] = useApi(DashboardApi.getDashboardOverview, {
    errorMessage: GET_OVERVIEW_ERROR,
    onSuccess: onFetchOverviewSuccess,
    onError: () => setProgressBarLoading(false)
  });

  return dataLoaded ? (
    <div className={classes.container}>
      <Scrollbars
        autoHeight
        autoHeightMax="calc(100vh - 12rem)"
        autoHide
        autoHideTimeout={2000}
        autoHideDuration={1000}
      >
        <LocationsPanel
          locations={locations}
          handleLocationClick={handleLocationClick}
        />
      </Scrollbars>
    </div>
  ) : null;
}

CompanyUserDashboard.propTypes = {
  setProgressBarLoading: func.isRequired,
  handleLocationClick: func.isRequired
};

export default CompanyUserDashboard;
