import React, { useEffect, useState } from "react";
import { func, object, node, array } from "prop-types";
import { useTranslation } from "react-i18next";
import { Slide } from "@material-ui/core";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import useOptimalSize from "hooks/useOptimalSize";

import SidePanelHeader from "components/ui/headers/side-panel-header/SidePanelHeader";
import LogoSelectGallery from "../LogoSelectGallery/LogoSelectGallery";
import ChannelInfoForm from "./components/form/ChannelInfoForm";

import classes from "./ChannelInformation.module.scss";

const ChannelInformationContainer = "div";

function ChannelInformation({
  channel,
  resetChannelToEdit,
  refreshChannelsList,
  channelNumbers,
  children
}) {
  const [channelLogo, setChannelLogo] = useState({ logo: "", logoPath: "" });
  const [slideStatus, setSlideStatus] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(true);
  const isWindowLarge = useOptimalSize();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["channel-list"]);
  const {
    handleSubmit,
    register,
    reset,
    formState: { dirty, isValid },
    setValue,
    errors
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      logo: "",
      lcn: ""
    },
    mode: "onChange"
  });

  const resetForm = ({ id, name = "", logo, lcn }) => {
    reset({
      id,
      name,
      logo,
      lcn
    });
  };

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty]);

  useEffect(() => {
    const { id, logo, logoPath } = channel;
    const logoObject = {
      logo,
      logoPath
    };

    setChannelLogo(logoObject);
    resetForm(channel);
    setIsFormDirty(false);
    if (id) {
      handleSlideIn();
    } else {
      handleSlideOut();
    }
  }, [channel]);

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 250);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const openGalleryModal = () => {
    setGalleryOpen(true);
  };

  const closeGalleryModal = () => {
    setGalleryOpen(false);
  };

  const handleCancel = () => {
    handleSlideOut();
    resetChannelToEdit();
    setIsFormDirty(false);
  };

  const setNewLogo = logo => {
    setValue("logo", logo.logo, true);
    setChannelLogo(logo);
    setIsFormDirty(true);
  };

  return (
    <ChannelInformationContainer
      className={clsx(classes["channel-information-container"], {
        [classes["open"]]: slideStatus,
        [classes["close"]]: !slideStatus
      })}
    >
      <Slide direction="left" in={slideStatus} mountOnEnter unmountOnExit>
        <div
          className={clsx(classes["channel-information"], {
            [classes["large-window-height"]]: isWindowLarge
          })}
        >
          <SidePanelHeader
            title={t("channel-information")}
            loading={loading}
            onClose={handleCancel}
          />
          <ChannelInfoForm
            handleSubmit={handleSubmit}
            channel={channel}
            register={register}
            channelLogo={channelLogo}
            openGalleryModal={openGalleryModal}
            dirty={isFormDirty}
            isValid={isValid}
            refreshChannelsList={refreshChannelsList}
            resetForm={resetForm}
            setIsFormDirty={setIsFormDirty}
            setLoading={setLoading}
            errors={errors}
            channelNumbers={channelNumbers}
          />
        </div>
      </Slide>
      <LogoSelectGallery
        galleryOpen={galleryOpen}
        closeGalleryModal={closeGalleryModal}
        setNewLogo={setNewLogo}
        channelLogo={channelLogo}
      />
      {children}
    </ChannelInformationContainer>
  );
}

ChannelInformation.propTypes = {
  channel: object,
  resetChannelToEdit: func,
  refreshChannelsList: func,
  channelNumbers: array,
  children: node
};

export default ChannelInformation;
