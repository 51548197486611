import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import EndpointGroupsApi from "services/api/2-system-manage-api/endpoint-groups-api";
import LoadingContext from "contexts/LoadingContext";
import useApi from "hooks/useApi";
import { assignChannelsRequestMessages } from "./utils/utils";

import GroupsChannelsTable from "./components/table/GroupsChannelsTable";

function AssignChannels() {
  const { setLoading } = useContext(LoadingContext);
  const { t } = useTranslation("assign-pages");

  const { GET_CHANNELS_ERROR, GET_ENDPOINTS_ERROR } =
    assignChannelsRequestMessages(t);

  const [groups = [], groupsLoading, , fetchGroups] = useApi(
    EndpointGroupsApi.getEndpointGroups,
    {
      requestOnMount: false,
      errorMessage: GET_ENDPOINTS_ERROR
    }
  );

  const [channels = [], channelsLoading, , fetchChannels] = useApi(
    ChannelListApi.getEnabledChannels,
    {
      errorMessage: GET_CHANNELS_ERROR,
      onSuccess: fetchGroups
    }
  );

  useEffect(() => {
    setLoading(channelsLoading || groupsLoading);
  }, [channelsLoading, groupsLoading, setLoading]);

  return (
    !!channels.length &&
    !!groups.length && (
      <GroupsChannelsTable
        channels={channels}
        groups={groups}
        refreshTable={fetchChannels}
      />
    )
  );
}

export default AssignChannels;
