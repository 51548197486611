import React, { useEffect } from "react";
import clsx from "clsx";
import { object, func } from "prop-types";

import { imageNotLoaded } from "../../utils/utils";
import { baseUrl } from "data/constants";
import useStyles from "hooks/useStyles";

import classes from "./AlertsListItem.module.scss";

export default function AlertsListItem({
  alert,
  selectedAlert,
  handleSelectAlert
}) {
  const { styles, getStylesFromHTMLText, getStylesFromAlertObject } =
    useStyles();

  useEffect(() => {
    if (alert.id) {
      getStylesFromHTMLText(alert.sampletext);
      getStylesFromAlertObject(alert);
    }
  }, [alert]);

  return (
    <div
      className={clsx(classes["alert-item"], {
        [classes["selected"]]: alert.id === selectedAlert.id
      })}
      onClick={handleSelectAlert(alert)}
    >
      <div className={classes["alert-item-overlay"]} />
      <div className={classes["alert-item-image-container"]}>
        <img
          className={clsx(classes["alert-item-image"], {
            [classes["img-selected"]]: alert.id === selectedAlert.id
          })}
          src={`${baseUrl}${alert.image}`}
          alt={`${alert.name}`}
          onError={imageNotLoaded}
        />
        <div
          className={classes["alert-text"]}
          style={{
            fontSize: `${styles["font-size"] * 0.145}px`,
            lineHeight: 1.15,
            color: alert.textcolor,
            // @ts-ignore
            textAlign: styles["text-align"],
            fontStyle: styles["font-style"],
            wordBreak: styles["word-break"],
            top: `${styles.top * 0.145}px`,
            left: `${styles.left * 0.145}px`,
            height: `${styles.height * 0.145}px`,
            width: `${styles.width * 0.145}px`
          }}
        >
          {alert.text}
        </div>
      </div>
      <footer className={classes["alert-item-footer"]}>
        <div className={classes["alert-message"]}>{alert.name}</div>
      </footer>
    </div>
  );
}

AlertsListItem.propTypes = {
  alert: object.isRequired,
  selectedAlert: object.isRequired,
  handleSelectAlert: func.isRequired
};
