import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";

const cookies = new Cookies();

class AssignTemplates {
  getToken = () => cookies.get("token");

  getAllTemplates = async (server) => {
    const method = `get`;
    const path = `/api/v1/idtservers/${server}/templates`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  assignTemplateToGroup = async (serverInUseId, { templateId, groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/templates/${templateId}/assign-to-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groupId,
      },
    });
  };

  assignTemplateToGroups = async (
    serverInUseId,
    { templateId, groups, defaults }
  ) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/templates/${templateId}/assign-to-groups`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groups,
        defaults,
      },
    });
  };

  removeTemplateFromGroup = async (serverInUseId, { templateId, groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/templates/${templateId}/remove-from-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groupId,
      },
    });
  };

  setTemplateAsDefault = async (serverInUseId, { templateId, groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointgroups/${groupId}/set-default-template`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        templateId,
      },
    });
  };

  assignAllTemplatesToGroup = async (serverInUseId, groupId) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/templates/${groupId}/assign-all-to-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  removeAllTemplatesFromGroup = async (serverInUseId, groupId) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/templates/${groupId}/remove-all-from-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  setTemplateForGroupsAndEnpoints = async (serverInUse, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUse}/endpointcontrol/template`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: payload,
    });
  };
}

export default new AssignTemplates();
