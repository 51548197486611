import React from "react";
import { arrayOf, string } from "prop-types";
import { useTranslation } from "react-i18next";

import { refreshRebootRequestsMessages } from "pages/endpoints/control/control-panel/utils/utils";
import SettingsApi from "services/api/settings-api/settings-api";
import useApi from "hooks/useApi";

import CustomPaper from "../../ui/custom-paper/CustomPaper";
import PaperContent from "../../ui/custom-paper/paper-content/PaperContent";
import PaperLabel from "../../ui/custom-paper/paper-label/PaperLabel";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "../Settings.module.scss";

function RefreshAndReboot({ groups, endpoints, hidden }) {
  const { t } = useTranslation("control");
  const { REFRESH_SUCCESS, REBOOT_SUCCESS } = refreshRebootRequestsMessages(t);

  const [, refreshLoading, , callRefresh] = useApi(SettingsApi.refresh, {
    requestOnMount: false,
    successMessage: REFRESH_SUCCESS,
  });

  const [, rebootLoading, , callReboot] = useApi(SettingsApi.reboot, {
    requestOnMount: false,
    successMessage: REBOOT_SUCCESS,
  });

  const handleRefreshApply = () => {
    const payload = {
      endpoints,
      groups,
    };

    callRefresh(payload);
  };

  const handleRebootApply = () => {
    const payload = {
      endpoints,
    };

    callReboot(payload);
  };

  return (
    <CustomPaper dark>
      <PaperContent>
        <PaperLabel
          label={t("refresh-reboot.force-refresh-label")}
          loading={refreshLoading}
          centered
          disabled={hidden}
        />
        <PrimaryButton
          onClick={handleRefreshApply}
          disabled={hidden || refreshLoading}
        >
          {t("common:apply")}
        </PrimaryButton>
      </PaperContent>
      <PaperContent>
        <PaperLabel
          label={t("refresh-reboot.force-reboot-label")}
          loading={rebootLoading}
          disabled={hidden}
        />
        <div className={classes["box-actions"]}>
          {/*Button hidden for a now*/}
          {/*<PrimaryButton disabled>{t("common:schedule-event")}</PrimaryButton>*/}
          <PrimaryButton
            onClick={handleRebootApply}
            disabled={hidden || rebootLoading}
          >
            {t("common:apply")}
          </PrimaryButton>
        </div>
      </PaperContent>
    </CustomPaper>
  );
}

RefreshAndReboot.propTypes = {
  endpoints: arrayOf(string).isRequired,
  groups: arrayOf(string).isRequired,
};

export default RefreshAndReboot;
