import React from "react";
import clsx from "clsx";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "../CustomPaper.module.scss";

function PaperLabel({
  label,
  loading = false,
  centered = false,
  disabled = false,
}) {
  return (
    <div className={classes["paper-label-container"]}>
      <label
        className={clsx(classes["paper-label"], {
          [classes["paper-label-center"]]: centered,
          [classes["paper-label-disabled"]]: disabled,
        })}
      >
        <div>{label}</div>
        {loading && (
          <div
            className={clsx(
              classes["paper-label-loader"],
              classes["paper-loader"]
            )}
          >
            <EllipsisLoader />
          </div>
        )}
      </label>
    </div>
  );
}

export default PaperLabel;
