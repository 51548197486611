import React from "react";
import { arrayOf, object, bool } from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import ListDrawer from "components/ui/drawers/ListDrawer";
import Table from "components/ui/table/Table";

import classes from "./CompaniesTable.module.scss";

function CompaniesTable({
  companies,
  selectedCompany,
  toggleSelectedCompany,
  open,
  apiLoadSuccessful
}) {
  const { t } = useTranslation("access-management");
  const handleCompanySelection = company => {
    toggleSelectedCompany(company);
  };

  return (
    <ListDrawer open={open}>
      {apiLoadSuccessful && companies.length ? (
        <Table fullWidth>
          <Table.Head>
            <th className={classes["table-col-5"]}>&nbsp;</th>
            <th className={classes["table-col-25"]}>{t("form.name")}</th>
            <th className={classes["table-col-20"]}>Email</th>
            <th className={classes["table-col-20"]}>{t("form.country")}</th>
          </Table.Head>
          <Table.Body>
            {companies.map((company, index) => {
              const selected = company.id === selectedCompany.id;
              return (
                <Table.Row key={company.id} selected={selected}>
                  <td className={classes["table-cell"]} align="center">
                    {index + 1}
                  </td>
                  <td
                    className={clsx(
                      classes["table-cell"],
                      classes["table-col-name"]
                    )}
                    onClick={() => handleCompanySelection(company)}
                  >
                    <span className={classes["company-name"]}>
                      {company.name}
                    </span>
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={() => handleCompanySelection(company)}
                  >
                    {company.email}
                  </td>
                  <td
                    className={clsx(
                      classes["table-cell"],
                      classes["table-col-country"]
                    )}
                    onClick={() => handleCompanySelection(company)}
                  >
                    <span className={classes["country-name"]}>
                      {company.country}
                    </span>
                  </td>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : apiLoadSuccessful ? (
        <div className={classes["no-companies"]}>
          {t("companies.no-companies-available")}
        </div>
      ) : null}
    </ListDrawer>
  );
}

CompaniesTable.propTypes = {
  companies: arrayOf(object),
  selectedCompany: object,
  open: bool,
  apiLoadSuccessful: bool
};

export default CompaniesTable;
