import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./ModalTitle.module.scss";

function ModalTitle() {
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.playlist-channels";

  return (
    <div className={classes["playlists-modal-title"]}>
      <span>
        {t(`${PREFIX}.select-playlist-and-set-it-as-active-in-channel`)}
      </span>
    </div>
  );
}

export default ModalTitle;
