import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import usePermissions from "hooks/usePermissions";
import { permissions } from "data/permissions";
import { ACCESS_MANAGEMENT } from "data/urls";

import Users from "./users/Users";
import Companies from "./companies/Companies";

import classes from "./Access.module.scss";

function Access() {
  const [currRoute, setCurrRoute] = useState("");
  const history = useHistory();
  const { pathname } = useLocation();
  const userPermissions = usePermissions();
  const { t } = useTranslation("access-management");
  const hasAccessToUsers = userPermissions.includes(
    permissions.users.USERS_GET
  );
  const hasAccessToCompanies = userPermissions.includes(
    permissions.companies.COMPANIES_GET
  );

  const handleCallToRouter = (_, value) => {
    history.push(value);
  };

  useEffect(() => {
    if (pathname === "/access") {
      setCurrRoute(ACCESS_MANAGEMENT.USERS);
      history.push(ACCESS_MANAGEMENT.USERS);
    } else {
      setCurrRoute(pathname);
    }
  }, [pathname]);

  return (
    <div className={classes["access"]}>
      <Tabs
        classes={{ indicator: classes["nav-item-indicator"] }}
        value={currRoute}
        onChange={handleCallToRouter}
      >
        {hasAccessToUsers && (
          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("users.header.users")}
            value={ACCESS_MANAGEMENT.USERS}
          />
        )}
        {hasAccessToCompanies && (
          <Tab
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("companies.header.companies")}
            value={ACCESS_MANAGEMENT.COMPANIES}
          />
        )}
      </Tabs>

      <Switch>
        <Route path={ACCESS_MANAGEMENT.USERS}>
          <Users />
        </Route>

        {hasAccessToCompanies && (
          <Route path={ACCESS_MANAGEMENT.COMPANIES}>
            <Companies />
          </Route>
        )}
      </Switch>
      <Redirect to={ACCESS_MANAGEMENT.USERS} />
    </div>
  );
}

export default Access;
