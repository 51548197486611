export function authRequestsMessages(t) {
  const FORGOT_PASSWORD_SUCCESS = {
    message: t("success-messages.forgot-password"),
    variant: "success",
    autoHideDuration: 5000
  };
  const CHANGE_PASSWORD_SUCCESS = {
    message: t("success-messages.change-password"),
    variant: "success",
    autoHideDuration: 5000
  };

  return {
    FORGOT_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_SUCCESS
  };
}
