import React, { useState } from "react";
import { arrayOf, object, bool, func } from "prop-types";
import Scrollbars from "react-custom-scrollbars";

import { playlistChannelType } from "types";

import ChannelItem from "./components/channel-item/ChannelItem";
import BroadcastSettingsModal from "../modals/broadcast-settings-modal/BroadcastSettingsModal";
import ChannelItemPlaceholder from "./components/channel-item-placeholder/ChannelItemPlaceholder";
import ListDrawer from "components/ui/drawers/ListDrawer";

const placeholders = [1, 2, 3, 4];

function ChannelsTable({
  channels,
  setChannels,
  selectedChannel,
  toggleSelectedChannel,
  handleSetActiveChannel,
  drawerOpen,
  fetchChannels,
  channelsLoading,
  onRemoveClick
}) {
  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [channelToBeModified, setChannelToBeModified] = useState({
    id: undefined
  });

  const toggleBroadcastModalOpen = () => {
    setBroadcastModalOpen(prev => !prev);
  };

  return (
    <>
      <ListDrawer open={drawerOpen}>
        <Scrollbars autoHeight autoHeightMax="90vh">
          {!channelsLoading
            ? channels.map(channel => (
                <ChannelItem
                  key={channel.id}
                  channel={channel}
                  setChannels={setChannels}
                  selectedChannel={selectedChannel}
                  toggleSelectedChannel={toggleSelectedChannel}
                  handleSetActiveChannel={handleSetActiveChannel}
                  toggleBroadcastModalOpen={toggleBroadcastModalOpen}
                  setChannelToBeModified={setChannelToBeModified}
                  onRemoveClick={onRemoveClick}
                />
              ))
            : placeholders.map(item => <ChannelItemPlaceholder key={item} />)}
        </Scrollbars>
      </ListDrawer>

      <BroadcastSettingsModal
        open={broadcastModalOpen}
        onClose={toggleBroadcastModalOpen}
        channelToBeModified={channelToBeModified}
        fetchChannels={fetchChannels}
      />
    </>
  );
}

ChannelsTable.propTypes = {
  channels: arrayOf(playlistChannelType),
  setChannels: func,
  selectedChannel: object.isRequired,
  toggleSelectedChannel: func.isRequired,
  handleSetActiveChannel: func.isRequired,
  drawerOpen: bool.isRequired,
  fetchChannels: func.isRequired,
  channelsLoading: bool,
  onRemoveClick: func
};

export default ChannelsTable;
