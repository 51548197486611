import React from "react";
import { arrayOf, object } from "prop-types";
import { useTranslation } from "react-i18next";
import WarningIcon from "@material-ui/icons/Warning";
import Scrollbars from "react-custom-scrollbars";
import Img from "react-image";

import { baseUrl } from "data/constants";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import fallbackImg from "assets/images/tv-screen/tv-test-stripes.png";

import classes from "./MissingVideosWarning.module.scss";

const PreviewUnloader = () => (
  <div className={classes["preview-unloader"]}>
    <img
      src={fallbackImg}
      className={classes["preview-unloader-image"]}
      alt="unloader"
    />
  </div>
);

function MissingVideosWarning({ missingVideos }) {
  const { t } = useTranslation("video-playlists");

  return (
    <div className={classes["missing-videos"]}>
      <div className={classes["missing-videos-info"]}>
        <WarningIcon className={classes["missing-videos-info-icon"]} />
        <div>
          <div>{t("sections.playlists.copy-modal.warning.line-top")}</div>
          <div>{t("sections.playlists.copy-modal.warning.line-bottom")}</div>
        </div>
      </div>
      <Scrollbars autoHeight autoHeightMax="30vh">
        <ul className={classes["missing-videos-list"]}>
          {missingVideos.map(video => (
            <li key={video.id} className={classes["missing-videos-list-item"]}>
              {video.sprite ? (
                <VideoSprite
                  spriteUrl={video.previewUrl}
                  frameWidth={76}
                  frameHeight={42}
                />
              ) : (
                <div
                  className={
                    classes["missing-videos-list-item-image-container"]
                  }
                >
                  <Img
                    src={`${baseUrl}${video.previewUrl}`}
                    className={classes["missing-videos-list-item-image"]}
                    unloader={<PreviewUnloader />}
                  />
                </div>
              )}
              <div className={classes["missing-videos-list-item-name"]}>
                {video.name}
              </div>
            </li>
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
}

MissingVideosWarning.propTypes = {
  missingVideos: arrayOf(object)
};

export default MissingVideosWarning;
