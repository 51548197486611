import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";

const cookies = new Cookies();

class ChannelListApi {
  getToken = () => cookies.get("token");

  getEnabledChannels = async (server) => {
    const method = `get`;
    const path = `/api/v1/idtservers/${server}/channels/enabled`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };

  renumberChannel = async (server, start) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${server}/channels/renumber`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        start,
      },
    });
  };

  updateChannelInfo = async (serverInUseId, { id, name, logo, lcn }) => {
    const method = `patch`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/${id}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const data = {
      id,
      name,
      logo,
      lcn,
    };

    return await axios({
      method,
      url,
      headers,
      data,
    });
  };

  updateListOrderAfterDnD = async (serverInUseId, data) => {
    const { current, neighbour, aboveLast } = data;
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/position`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        current: current,
        neighbour: neighbour,
        aboveLast,
      },
    });
  };

  getLogos = async (server) => {
    const method = `get`;
    const path = `/api/v1/idtservers/${server}/channels/logos`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };

  requestScreenshots = async (serverInUseId, data) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/screenshot`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data,
    });
  };

  uploadLogo = async (serverInUseId, logo) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/channels/upload-logo`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const formData = new FormData();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    formData.append("image", logo);

    return await axios({
      method,
      url,
      headers,
      data: formData,
    });
  };
}

export default new ChannelListApi();
