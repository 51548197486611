import React from "react";

import idtLogo from "assets/images/idtWhite.svg";

import classes from "./LogoIDT.module.scss";

function LogoIDT() {
  return (
    <div className={classes["logo-container"]}>
      <img src={idtLogo} className={classes["img"]} alt="IDT logo" />
    </div>
  );
}

export default LogoIDT;
