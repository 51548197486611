import React from "react";
import clsx from "clsx";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./MaintenanceHeader.module.scss";

function MaintenanceHeader({ loading }) {
  const { t } = useTranslation("servers");

  return (
    <>
      <div className={classes["server-mgmt-header"]}>
        {t("server-maintenance")}
      </div>
      <div
        className={clsx(classes["loader"], {
          [classes["show"]]: loading
        })}
      >
        <EllipsisLoader />
      </div>
    </>
  );
}

MaintenanceHeader.propTypes = {
  loading: bool
};

export default MaintenanceHeader;
