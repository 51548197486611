import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class DashboardApi {
  getToken = () => cookies.get("token");

  getDashboardOverview = async () => {
    const method = "get";
    const path = `/api/v1/dashboard/overview`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  getServerOnlineStatistics = async (_, payload) => {
    const { serverId, lastDays } = payload;
    const method = "get";
    const path = `/api/v1/dashboard/${serverId}/graph/server?lastDays=${lastDays}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  getGatewaysStatistics = async (_, payload) => {
    const { serverId, lastDays } = payload;
    const method = "get";
    const path = `/api/v1/dashboard/${serverId}/graph/gateways?lastDays=${lastDays}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  getEncodersStatistics = async (_, payload) => {
    const { serverId, lastDays } = payload;
    const method = "get";
    const path = `/api/v1/dashboard/${serverId}/graph/encoders?lastDays=${lastDays}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  getEndpointsStatistics = async (_, payload) => {
    const { serverId, lastDays } = payload;
    const method = "get";
    const path = `/api/v1/dashboard/${serverId}/graph/endpoints?lastDays=${lastDays}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };
}
export default new DashboardApi();
