import React from "react";
import { func } from "prop-types";
import { InputAdornment } from "@material-ui/core";
import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";

import classes from "./InputArrows.module.scss";

function InputArrows({ handleArrowClick }) {
  const PLUS = "plus";
  const MINUS = "minus";

  return (
    <InputAdornment position="end">
      <div className={classes["icons-container"]}>
        <KeyboardArrowUpSharpIcon
          className={classes["up-icon"]}
          onClick={() => handleArrowClick(PLUS)}
        />
        <KeyboardArrowDownSharpIcon
          className={classes["down-icon"]}
          onClick={() => handleArrowClick(MINUS)}
        />
      </div>
    </InputAdornment>
  );
}

InputArrows.propTypes = {
  handleArrowClick: func.isRequired
};

export default InputArrows;
