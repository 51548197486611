import React from "react";
import { array, func } from "prop-types";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { useTranslation } from "react-i18next";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./CreatePlaylistPrompt.module.scss";

function CreatePlaylistPrompt({
  selectedVideosArray,
  toggleDialogOpen,
  openAddPlaylistModal,
  closeCreatePlaylistPrompt
}) {
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.videos.create-playlist-prompt";
  const numberOfVideos = selectedVideosArray.length;

  return selectedVideosArray.length > 0 ? (
    <div className={classes["create-playlist"]}>
      <div className={classes["create-playlist-prompt"]}>
        <div className={classes["number-of-selected"]}>
          {t(`${PREFIX}.number-of-selected-videos`)} {numberOfVideos}
        </div>
        <div className={classes["buttons"]}>
          <div className={classes["buttons-delete"]} onClick={toggleDialogOpen}>
            {t(`${PREFIX}.delete-selected`)}
          </div>
          <div className={classes["buttons-create"]}>
            <PrimaryButton
              onClick={openAddPlaylistModal}
              data-cy="create-playlist-button"
            >
              {t(`${PREFIX}.create-playlist`)}
            </PrimaryButton>
          </div>
        </div>
      </div>

      <CloseSharpIcon
        className={classes["close-icon"]}
        onClick={closeCreatePlaylistPrompt}
      />
    </div>
  ) : null;
}

CreatePlaylistPrompt.propTypes = {
  selectedVideosArray: array,
  toggleDialogOpen: func,
  openAddPlaylistModal: func,
  closeCreatePlaylistPrompt: func
};

export default CreatePlaylistPrompt;
