import React from "react";
import { func, bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import moment from "moment";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./Reboot.module.scss";

function Reboot({ openRebootModal, isActionInProgress, lastReboot }) {
  const { t } = useTranslation("servers");

  return (
    <div className={classes["reboot"]}>
      <div>
        <div className={classes["reboot-label"]}>
          <RefreshIcon className={classes["reboot-label-icon"]} />
          <div className={classes["reboot-label-text"]}>
            {t("reboot-btn-text")}
          </div>
        </div>

        <div className={classes["last-reboot"]}>
          {t("last-reboot")}:&nbsp;
          {lastReboot ? (
            moment(lastReboot).local().format("YYYY-MM-DD HH:mm")
          ) : (
            <span>&ndash;</span>
          )}
        </div>
      </div>
      <PrimaryButton
        onClick={openRebootModal}
        disabled={isActionInProgress}
        primaryButtonClass={classes["run-button"]}
      >
        {t("speed-test.modal.run-button")}
      </PrimaryButton>
    </div>
  );
}

Reboot.propTypes = {
  openRebootModal: func,
  isActionInProgress: bool,
  lastReboot: string
};

export default Reboot;
