import React, { useState, useEffect } from "react";
import { object, func } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Slide } from "@material-ui/core";

import SidePanelHeader from "components/ui/headers/side-panel-header/SidePanelHeader";
import Form from "components/ui/form/Form";

import classes from "./CompanyDetails.module.scss";

function CompanyDetails({ selectedCompany, resetSelectedCompany }) {
  const [slideStatus, setSlideStatus] = useState(false);
  const { t } = useTranslation("access-management");

  const { register, reset } = useForm();

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 250);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const handleCancel = () => {
    handleSlideOut();
    resetSelectedCompany();
  };

  useEffect(() => {
    const { name, email, phone, country, address } = selectedCompany;

    if (name) {
      handleSlideIn();
    } else {
      handleSlideOut();
    }

    reset({
      name,
      email,
      phone: phone ? phone : "\u2014",
      country,
      address
    });
  }, [reset, selectedCompany]);

  return (
    <Slide direction="left" in={slideStatus}>
      <div className={classes.container}>
        <SidePanelHeader
          title={t("companies.company-details.title")}
          onClose={handleCancel}
        />
        <Form autoComplete="off" className={classes.form}>
          <Form.Field horizontal>
            <Form.Label>{t("form.name")}</Form.Label>
            <Form.Input
              // @ts-ignore
              name="name"
              readOnly
              ref={register()}
            />
          </Form.Field>
          <Form.Field horizontal>
            <Form.Label>{t("form.email")}</Form.Label>
            <Form.Input
              // @ts-ignore
              name="email"
              readOnly
              ref={register()}
            />
          </Form.Field>
          <Form.Field horizontal>
            <Form.Label>{t("form.phone")}</Form.Label>
            <Form.Input
              // @ts-ignore
              name="phone"
              readOnly
              ref={register()}
            />
          </Form.Field>
          <Form.Field horizontal>
            <Form.Label>{t("form.country")}</Form.Label>
            <Form.Input
              // @ts-ignore
              name="country"
              readOnly
              ref={register()}
            />
          </Form.Field>
          <Form.Field horizontal>
            <Form.Label>{t("form.address")}</Form.Label>
            <Form.Textarea
              // @ts-ignore
              name="address"
              readOnly
              ref={register()}
            />
          </Form.Field>
        </Form>
      </div>
    </Slide>
  );
}

CompanyDetails.propTypes = {
  selectedCompany: object,
  resetSelectedCompany: func
};

export default CompanyDetails;
