import React, { createContext, useEffect, useState } from "react";

export const ServerContext = createContext({
  serverInUse: {},
  setServerInUse: null
});

export default function ServerContextProvider({ children }) {
  const getServerInUseFromLocalStorage = () => {
    const localData = localStorage.getItem("serverInUse");
    return localData ? JSON.parse(localData) : {};
  };
  const [serverInUse, setServerInUse] = useState(
    getServerInUseFromLocalStorage()
  );

  useEffect(() => {
    localStorage.setItem("serverInUse", JSON.stringify(serverInUse));
  }, [serverInUse]);

  return (
    <ServerContext.Provider value={{ serverInUse, setServerInUse }}>
      {children}
    </ServerContext.Provider>
  );
}
