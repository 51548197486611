import React from "react";
import { CssBaseline } from "@material-ui/core";

import LanguageSelect from "../../../components/layout-components/persistentDrawer/components/language-select/LanguageSelect";
import idtLogo from "assets/images/idt-logo.png";
import AuraLogoCircle from "../../../assets/images/AuraWhite_circle.png";
import AuraLogoTitle from "../../../assets/images/AuraWhite_title.png";
import Image from "../image/Image";

import classes from "./Header.module.scss";

function Header() {
  return (
    <>
      <CssBaseline />
      <div className={classes["header"]}>
        <div className={classes["header-assets"]}>
          <div className={classes["idt-logo"]}>
            <Image image={idtLogo} />
          </div>
          <div className={classes["lng-select"]}>
            <LanguageSelect />
          </div>
          <div className={classes["aura-logo"]}>
            <div className={classes["aura-logo-circle"]}>
              <img
                src={AuraLogoCircle}
                alt="Aura Logo"
                className={classes["aura-logo-circle-image"]}
              />
            </div>
            <div className={classes["aura-logo-title"]}>
              <img
                src={AuraLogoTitle}
                alt="Aura Logo"
                className={classes["aura-logo-title-image"]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
