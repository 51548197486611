export const getSnacks = t => {
  const GET_SERVERS_ERROR = {
    message: t("snacks.servers-list-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };
  const SERVER_ADDED = {
    message: t("snacks.server-added"),
    variant: "success",
    autoHideDuration: 2000
  };
  const SERVER_ADDING_ERROR = {
    message: t("snacks.server-could-not-be-added"),
    variant: "error",
    autoHideDuration: 3500
  };

  const SERVER_ALREADY_EXISTS = {
    message: t("snacks.server-with-this-productkey-already-exists"),
    variant: "error",
    autoHideDuration: 3500
  };

  const SERVER_DELETED = {
    message: t("snacks.server-deleted"),
    variant: "success",
    autoHideDuration: 2000
  };
  const SERVER_DELETING_ERROR = {
    message: t("snacks.server-could-not-be-deleted"),
    variant: "error",
    autoHideDuration: 3500
  };
  const EDIT_SERVER_SUCCESS = {
    message: t("snacks.server-updated"),
    variant: "success",
    autoHideDuration: 2000
  };
  const EDIT_SERVER_ERROR = {
    message: t("snacks.server-could-not-be-updated"),
    variant: "error",
    autoHideDuration: 3500
  };
  const SERVER_SYNCHRONISATION_SUCCESS = {
    message: t("snacks.server-synchronisation-success"),
    variant: "success",
    autoHideDuration: 2500
  };
  const SERVER_SYNCHRONISATION_ERROR = {
    message: t("snacks.server-synchronisation-error"),
    variant: "error",
    autoHideDuration: 3500
  };
  const SERVER_REBOOT_SUCCESS = {
    message: t("snacks.server-reboot-success"),
    variant: "success",
    autoHideDuration: 3500
  };
  const SERVER_REBOOT_ERROR = {
    message: t("snacks.server-reboot-error"),
    variant: "error",
    autoHideDuration: 3500
  };

  const REQUEST_SPEED_TEST_SUCCESS = {
    message: t("snacks.request-speed-test-success"),
    variant: "success",
    autoHideDuration: 3500
  };

  const REQUEST_SPEED_TEST_ERROR = {
    message: t("snacks.request-speed-test-error"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_SERVERS_ERROR,
    SERVER_ADDED,
    SERVER_ADDING_ERROR,
    SERVER_ALREADY_EXISTS,
    EDIT_SERVER_SUCCESS,
    EDIT_SERVER_ERROR,
    SERVER_SYNCHRONISATION_SUCCESS,
    SERVER_SYNCHRONISATION_ERROR,
    SERVER_REBOOT_SUCCESS,
    SERVER_REBOOT_ERROR,
    REQUEST_SPEED_TEST_SUCCESS,
    REQUEST_SPEED_TEST_ERROR,
    SERVER_DELETED,
    SERVER_DELETING_ERROR
  };
};
