import React, { useEffect, useState, useContext } from "react";
import { array, bool } from "prop-types";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

import VideosApi from "services/api/3-content-api/videos-api";
import { VideoContext } from "contexts/VideoContext";
import { videosRequestsMessages } from "../../utils/utils";
import useApi from "hooks/useApi";
import { renderThumbVertical, renderTrackVertical } from "utils/utils";

import VideoItem from "../video-item/VideoItem";
import UploadComponent from "../upload-component/UploadComponent";
import CreatePlaylistPrompt from "./components/create-playlist-prompt/CreatePlaylistPrompt";
import CreatePlaylistModal from "./components/create-playlist-modal/CreatePlaylistModal";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./VideosList.module.scss";

function VideosList({ videos, videosLoading, fetchVideos }) {
  const [allVideos, setAllVideos] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editedVideo, setEditedVideo] = useState({ id: undefined, name: "" });
  const [newVideoName, setNewVideoName] = useState("");
  const [videoToBeDeleted, setVideoToBeDeleted] = useState({ id: undefined });
  const { selectedVideosArray, setSelectedVideosArray, setEscPressed } =
    useContext(VideoContext);
  const { t } = useTranslation("video-playlists");
  const PREFIX_1 = "sections.videos.videos-list";
  const PREFIX_2 = "sections.videos.side-menu";
  const {
    VIDEO_RENAME_SUCCESS,
    VIDEO_RENAME_ERROR,
    VIDEO_DELETE_SUCCESS,
    VIDEOS_DELETE_SUCCESS,
    VIDEO_DELETE_ERROR,
    VIDEOS_DELETE_ERROR
  } = videosRequestsMessages(t);

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        setEditedVideo({ id: undefined, name: "" });
        setEscPressed(true);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    setAllVideos(videos.reverse());
    if (selectedVideosArray.length > 0) {
      setSelectedVideosArray([]);
    }
  }, [videos]);

  const checkIfSelected = video =>
    selectedVideosArray.some(item => item.id === video.id);

  const handleItemClick = video => {
    if (
      selectedVideosArray.length === 1 &&
      selectedVideosArray[0].id === video.id
    ) {
      setSelectedVideosArray([]);
    } else if (selectedVideosArray.length >= 1) {
      if (!checkIfSelected(video)) {
        setSelectedVideosArray([...selectedVideosArray, video]);
      } else {
        setSelectedVideosArray(
          [...selectedVideosArray].filter(item => item.id !== video.id)
        );
      }
    } else {
      setSelectedVideosArray([video]);
    }
  };

  const toggleDialogOpen = () => {
    setDialogOpen(prev => !prev);
  };

  const openAddPlaylistModal = () => {
    setShowModal(true);
  };

  const closeAddPlaylistModal = () => {
    setShowModal(false);
  };

  const closeCreatePlaylistPrompt = () => {
    setSelectedVideosArray([]);
  };

  const handleRenameVideo = video => {
    setEditedVideo(video);
  };

  const handleDeleteVideo = video => {
    toggleDialogOpen();
    setVideoToBeDeleted(video);
  };

  // --- UPDATERS ---

  const updateArrayAfterRenaming = () => {
    const newArray = allVideos.map(item =>
      item.id === editedVideo.id ? { ...item, name: newVideoName } : item
    );

    setAllVideos(newArray);
    setEditedVideo({ id: undefined, name: "" });
    setNewVideoName("");
  };

  const updateArrayAfterDeletion = () => {
    if (videoToBeDeleted.id !== undefined) {
      setAllVideos(
        [...allVideos].filter(item => item.id !== videoToBeDeleted.id)
      );
      setVideoToBeDeleted({ id: undefined });
    } else {
      setAllVideos(
        [...allVideos].filter(item => !selectedVideosArray.includes(item))
      );
    }

    if (selectedVideosArray.length > 0) {
      setSelectedVideosArray([]);
    }

    toggleDialogOpen();
  };

  // --- HOOK API ---

  const [, , , renameSelectedVideo] = useApi(VideosApi.renameVideo, {
    requestOnMount: false,
    onSuccess: updateArrayAfterRenaming,
    successMessage: VIDEO_RENAME_SUCCESS,
    errorMessage: VIDEO_RENAME_ERROR
  });

  const handleRenameSelectedVideo = () => {
    if (newVideoName !== editedVideo.name) {
      const playload = {
        videoId: editedVideo.id,
        name: newVideoName
      };
      renameSelectedVideo(playload);
    } else {
      updateArrayAfterRenaming();
    }
  };

  useEffect(() => {
    if (newVideoName !== "" && editedVideo.id !== undefined) {
      handleRenameSelectedVideo();
    }
  }, [newVideoName]);

  const getSuccessMessage = () =>
    selectedVideosArray.length === 1
      ? VIDEO_DELETE_SUCCESS
      : VIDEOS_DELETE_SUCCESS;

  const getErrorMessage = () =>
    selectedVideosArray.length === 1 ? VIDEO_DELETE_ERROR : VIDEOS_DELETE_ERROR;

  const [, deleteVideoLoader, , deleteVideosFromCloud] = useApi(
    VideosApi.deleteVideos,
    {
      requestOnMount: false,
      onSuccess: updateArrayAfterDeletion,
      successMessage: getSuccessMessage(),
      errorMessage: getErrorMessage()
    }
  );

  const deleteVideos = () => {
    const payload = {
      files:
        videoToBeDeleted.id !== undefined
          ? [videoToBeDeleted.id]
          : selectedVideosArray.map(video => video.id)
    };

    deleteVideosFromCloud(payload);
  };

  return (
    <div className={classes["videos-list-container"]}>
      <Scrollbars
        autoHide
        autoHideTimeout={3000}
        autoHideDuration={1000}
        autoHeight
        autoHeightMax="86vh"
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumbVertical}
      >
        <div className={classes["videos-list"]}>
          <UploadComponent refetchVideos={fetchVideos} allVideos={allVideos} />
          {allVideos.length > 0 &&
            allVideos.map(video => (
              <VideoItem
                key={video.id}
                video={video}
                editedVideo={editedVideo}
                handleRenameVideo={handleRenameVideo}
                handleDeleteVideo={handleDeleteVideo}
                setNewVideoName={setNewVideoName}
                handleItemClick={handleItemClick}
                selectedVideosArray={selectedVideosArray}
                renameTxt={t(`${PREFIX_2}.rename`)}
                deleteTxt={t("common:delete")}
                loading={videosLoading}
                setEditedVideo={setEditedVideo}
              />
            ))}
        </div>
      </Scrollbars>

      <CreatePlaylistPrompt
        selectedVideosArray={selectedVideosArray}
        toggleDialogOpen={toggleDialogOpen}
        openAddPlaylistModal={openAddPlaylistModal}
        closeCreatePlaylistPrompt={closeCreatePlaylistPrompt}
      />

      <CreatePlaylistModal
        open={showModal}
        onClose={closeAddPlaylistModal}
        setSelectedVideosArray={setSelectedVideosArray}
      />

      <ConfirmationModal
        open={dialogOpen}
        onClose={toggleDialogOpen}
        onAction={deleteVideos}
        actionName={t("common:delete")}
        disabled={deleteVideoLoader}
        loading={deleteVideoLoader}
      >
        <span>
          {selectedVideosArray.length <= 1
            ? t(`${PREFIX_1}.are-you-sure-you-want-to-delete-this-video`)
            : t(`${PREFIX_1}.are-you-sure-you-want-to-delete-these-videos`)}
        </span>
      </ConfirmationModal>
    </div>
  );
}

VideosList.propTypes = {
  videos: array,
  videosLoading: bool
};

export default VideosList;
