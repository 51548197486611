import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { InputLabel } from "@material-ui/core";

import classes from "./FormLabel.module.scss";

function FormLabel({ children, formLabelClass = null, ...restProps }) {
  const { optional } = restProps;

  return (
    <InputLabel
      className={clsx(classes["label"], {
        [formLabelClass]: formLabelClass
      })}
      {...restProps}
    >
      <div>
        {children}
        {optional && <div className={classes["optional"]}>optional</div>}
      </div>
    </InputLabel>
  );
}

FormLabel.propTypes = {
  children: PropTypes.node
};

export default FormLabel;
