export function companiesRequestsMessages(t) {
  const PREFIX = "companies.snacks";

  const GET_COMPANIES_ERROR = {
    message: t(`${PREFIX}.get-companies-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const EDIT_COMPANY_SUCCESS = {
    message: t(`${PREFIX}.edit-company-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const EDIT_COMPANY_ERROR = {
    message: t(`${PREFIX}.edit-company-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CREATE_COMPANY_SUCCESS = {
    message: t(`${PREFIX}.create-company-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CREATE_COMPANY_ERROR = {
    message: t(`${PREFIX}.create-company-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const DELETE_COMPANY_SUCCESS = {
    message: t(`${PREFIX}.delete-company-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const DELETE_COMPANY_ERROR = {
    message: t(`${PREFIX}.delete-company-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_COMPANIES_ERROR,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_ERROR,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_ERROR,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_ERROR
  };
}
