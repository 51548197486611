import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";

import AssignTemplatesApi from "services/api/2-system-manage-api/assign-templates-api";
import ControlContext from "contexts/ControlContext";
import useApi from "hooks/useApi";
import useWindowDimensions from "hooks/useWindowDimensions";
import { baseUrl } from "data/constants";
import {
  templatesRequestsMessages,
  findGroupsForEndpoints,
} from "../../utils/utils";
import { renderThumbVertical, renderTrackVertical } from "utils/utils";

import TemplateTableHeader from "./components/templates-table-header/TemplateTableHeader";
import TemplatesTableRow from "./components/templates-table-row/TemplatesTableRow";
import NotSelectedOption from "../not-selected-option/NotSelectedOption";
import AssignTemplateModal from "./components/assign-template-modal/AssignTemplateModal";
import TemplatesLoader from "./components/templates-loader/TemplatesLoader";

import classes from "./Templates.module.scss";

function Templates() {
  const {
    groups,
    endpointIds,
    requestPayload,
    availableTemplates,
    setAvailableTemplates,
    allGroups,
    setAllGroups,
    hidden,
  } = useContext(ControlContext);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [templates = [], fetchTemplateLoading] = useApi(
    AssignTemplatesApi.getAllTemplates,
    {}
  );
  const { t } = useTranslation("control");
  const selectedTemplateRef = useRef(null);
  const selectedGroupsRef = useRef([]);
  const groupsWithDefaultTemplates = useRef([]);
  const { height } = useWindowDimensions();
  const autoHeightMax = `${height - 140}px`;

  const {
    SET_TEMPLATE_SUCCESS,
    SET_TEMPLATE_ERROR,
    ASSIGN_TEMPLATE_TO_GROUPS_SUCCESS,
    ASSIGN_TEMPLATE_TO_GROUPS_ERROR,
  } = templatesRequestsMessages(t);

  const [, setTemplateLoading, , setTemplateForGroupsAndEnpoints] = useApi(
    AssignTemplatesApi.setTemplateForGroupsAndEnpoints,
    {
      requestOnMount: false,
      successMessage: SET_TEMPLATE_SUCCESS,
      errorMessage: SET_TEMPLATE_ERROR,
    }
  );

  const onAssignTemplateSuccess = () => {
    const updatedGroups = allGroups.reduce((acc, currGroup) => {
      acc = [
        ...acc,
        {
          ...currGroup,
          templateIds: selectedGroupsRef.current.some(
            (selectedGroup) => selectedGroup.id === currGroup.id
          )
            ? Array.from(
                new Set([...currGroup.templateIds, selectedTemplateRef.current])
              )
            : currGroup.templateIds,
        },
      ];

      return acc;
    }, []);

    setAllGroups(updatedGroups);

    setAvailableTemplates((prevAvailableTemplates) => [
      ...prevAvailableTemplates,
      selectedTemplateRef.current,
    ]);

    closeAssignModal();
  };

  const [, assignTemplateLoading, , assignTemplateToGroups] = useApi(
    AssignTemplatesApi.assignTemplateToGroups,
    {
      requestOnMount: false,
      successMessage: ASSIGN_TEMPLATE_TO_GROUPS_SUCCESS,
      errorMessage: ASSIGN_TEMPLATE_TO_GROUPS_ERROR,
      onSuccess: onAssignTemplateSuccess,
    }
  );

  const handleShowTemplate = (templateId) => {
    setSelectedTemplate(templateId);

    const payload = {
      ...requestPayload,
      templateId,
    };

    setTemplateForGroupsAndEnpoints(payload);
  };

  const handleAssignTemplateToGroups = () => {
    const { current: selectedGroups } = selectedGroupsRef;
    const { current: templateId } = selectedTemplateRef;
    const { current: defaults } = groupsWithDefaultTemplates;

    const groups = selectedGroups
      .map(({ id }) => id)
      .filter((group) =>
        defaults.some((def) => def === group) ? null : group
      );

    const payload = {
      templateId,
      groups,
      defaults,
    };

    assignTemplateToGroups(payload);
  };

  const handleClickAssign = (templateId) => () => {
    const selectedGroups = findGroupsForEndpoints(allGroups, endpointIds);
    const selectedGroupsInContext = allGroups.filter((group) =>
      groups.some((item) => item === group.id)
    );

    const allSelectedGroups = [
      ...selectedGroups,
      ...selectedGroupsInContext,
    ].map(({ id, name }) => ({
      id,
      name,
    }));

    const selectedGroupsFinal = [
      // @ts-ignore - This removes duplicates from array
      ...new Map(
        allSelectedGroups.map((obj) => [JSON.stringify(obj), obj])
      ).values(),
    ];

    selectedGroupsRef.current = selectedGroupsFinal;
    selectedTemplateRef.current = templateId;

    openAssignModal();
  };

  const openAssignModal = () => {
    setAssignModalOpen(true);
  };

  const closeAssignModal = () => {
    setAssignModalOpen(false);
    groupsWithDefaultTemplates.current = [];
  };

  const handleCheckboxChange = (groupId) => {
    if (groupsWithDefaultTemplates.current.includes(groupId)) {
      const updatedArray = groupsWithDefaultTemplates.current.filter(
        (item) => item !== groupId
      );
      groupsWithDefaultTemplates.current = updatedArray;
    } else {
      groupsWithDefaultTemplates.current = [
        ...groupsWithDefaultTemplates.current,
        groupId,
      ];
    }
  };

  useEffect(() => {
    if (templates.length) {
      const templatesReversed = templates.reverse();
      setAllTemplates(templatesReversed);
    }
  }, [templates]);

  return (
    <>
      {!hidden ? (
        <Scrollbars
          autoHide
          autoHideTimeout={3000}
          autoHideDuration={1000}
          autoHeight
          autoHeightMax={autoHeightMax}
          renderTrackVertical={renderTrackVertical}
          renderThumbVertical={renderThumbVertical}
        >
          <table className={classes["table"]}>
            <TemplateTableHeader loading={fetchTemplateLoading} />
            <tbody>
              {!fetchTemplateLoading ? (
                allTemplates.map(
                  ({ id, name, logoPath, requires, orientation }) => {
                    const logoSrc = `${baseUrl}${logoPath}`;
                    const disabled =
                      (availableTemplates.length &&
                        !availableTemplates.includes(id)) ||
                      !availableTemplates.length;

                    return (
                      <TemplatesTableRow
                        key={id}
                        id={id}
                        name={name}
                        imgSrc={logoSrc}
                        requires={requires}
                        orientation={orientation}
                        disabled={disabled}
                        handleClickAssign={handleClickAssign}
                        handleShowTemplate={handleShowTemplate}
                        selectedTemplate={selectedTemplate}
                        loading={setTemplateLoading}
                      />
                    );
                  }
                )
              ) : (
                <TemplatesLoader />
              )}
            </tbody>
          </table>
        </Scrollbars>
      ) : (
        <NotSelectedOption />
      )}

      <AssignTemplateModal
        open={assignModalOpen}
        onClose={closeAssignModal}
        onAction={handleAssignTemplateToGroups}
        handleCheckboxChange={handleCheckboxChange}
        selectedGroups={selectedGroupsRef.current}
        loading={assignTemplateLoading}
        selectedGroupsInContext={groups}
        allGroups={allGroups}
      />
    </>
  );
}

export default Templates;
