export const getSnacks = t => {
  return {
    ALERT_SAVED: {
      message: t("control:alerts.snacks.alert-has-been-saved"),
      variant: "success",
      autoHideDuration: 2500
    },
    ALERT_SAVED_ERROR: {
      message: t("control:alerts.snacks.alert-could-not-be-saved"),
      variant: "error",
      autoHideDuration: 3500
    },
    ALERT_SHOWN: {
      message: t("control:alerts.snacks.alert-has-been-shown"),
      variant: "success",
      autoHideDuration: 2500
    },
    ALERT_SHOWN_ERROR: {
      message: t("control:alerts.snacks.alert-could-not-be-shown"),
      variant: "error",
      autoHideDuration: 3500
    },
    ALERTS_CLEARED: {
      message: t("control:alerts.snacks.alerts-have-been-cleared"),
      variant: "success",
      autoHideDuration: 2500
    },
    ALERTS_CLEARED_ERROR: {
      message: t("control:alerts.snacks.alerts-could-not-be-cleared"),
      variant: "error",
      autoHideDuration: 2500
    },
    ALERT_DELETED: {
      message: t("control:alerts.snacks.alert-has-been-deleted"),
      variant: "success",
      autoHideDuration: 2500
    },
    ALERT_DELETED_ERROR: {
      message: t("control:alerts.snacks.alert-could-not-be-deleted"),
      variant: "error",
      autoHideDuration: 2500
    }
  };
};
