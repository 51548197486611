import React from "react";
import Scrollbars from "react-custom-scrollbars";
import clsx from "clsx";

import useWindowDimensions from "hooks/useWindowDimensions";
import {
  renderTrackHorizontal,
  renderThumbHorizontal,
  renderTrackVertical,
  renderThumbVertical
} from "utils/utils";

import TableBody from "./table-body/TableBody";
import TableHeader from "./table-header/TableHeader";
import TableRow from "./table-row/TableRow";

import classes from "./Table.module.scss";

function Table({
  children,
  centered = false,
  isInModal = false,
  customMaxHeight = null,
  tableContainerClass = null,
  autoHeight = false,
  fullWidth = false,
  ...restProps
}) {
  const { height } = useWindowDimensions();
  const autoHeightMax = autoHeight ? `${height - 97}px` : "";

  return (
    <div
      className={clsx(classes["table-container"], {
        [tableContainerClass]: tableContainerClass
      })}
    >
      <Scrollbars
        autoHide
        autoHideTimeout={2000}
        autoHideDuration={1000}
        autoHeight={autoHeight}
        autoHeightMax={autoHeightMax}
        renderTrackHorizontal={renderTrackHorizontal}
        renderThumbHorizontal={renderThumbHorizontal}
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumbVertical}
      >
        <table
          className={clsx(classes["table"], {
            [classes["table-centered"]]: centered,
            [classes["full-width"]]: fullWidth
          })}
          {...restProps}
        >
          {children}
        </table>
      </Scrollbars>
    </div>
  );
}

Table.Head = TableHeader;
Table.Body = TableBody;
Table.Row = TableRow;

export default Table;
