import React, { useState, useEffect } from "react";
import { object, func, bool, array } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Slide } from "@material-ui/core";

import { ROLES } from "../../utils/utils";

import SidePanelHeader from "components/ui/headers/side-panel-header/SidePanelHeader";

import RoleSelect from "./components/role-select/RoleSelect";
import ServersList from "./components/servers-list/ServersList";
import EditUserForm from "./components/edit-user-form/EditUserForm";
import EditUserButtons from "./components/edit-user-buttons/EditUserButtons";

import classes from "./EditSelectedUser.module.scss";

function EditSelectedUser({
  selectedUser,
  setSelectedUser,
  roles,
  resetSelectedUser,
  loading,
  onEditUserSubmit,
  edited,
  setEdited
}) {
  const [slideStatus, setSlideStatus] = useState(false);
  const { SITE_MANAGER, CONTENT_EDITOR } = ROLES;
  const [currentUser, setCurrentUser] = useState({ id: null });
  const { t } = useTranslation("access-management");
  const {
    handleSubmit,
    register,
    formState: { dirty, isValid },
    reset
  } = useForm({
    mode: "onChange"
  });
  const disabled = (!dirty && !edited) || !isValid;

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 250);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const handleCancel = () => {
    handleSlideOut();
    resetSelectedUser();
  };

  const onSubmit = data => {
    onEditUserSubmit(data);
  };

  useEffect(() => {
    const {
      name,
      surname,
      fullName,
      phone,
      emailAddress: email,
      Company
    } = selectedUser;

    if (fullName) {
      handleSlideIn();
    } else {
      handleSlideOut();
    }
    reset({
      name,
      surname,
      phone: phone ? phone : "\u2014",
      email,
      company: Company ? Company.name : "\u2014"
    });
  }, [reset, selectedUser]);

  useEffect(() => {
    setCurrentUser(selectedUser);

    if (currentUser.id !== selectedUser.id) {
      setEdited(false);
    }
  }, [selectedUser, currentUser.id]);

  const handleRoleChange = selectedRole => {
    const { id, name } = selectedRole;
    let userServers = selectedUser.Idtservers;

    if (
      !(
        (selectedUser.Role.name === SITE_MANAGER ||
          selectedUser.Role.name === CONTENT_EDITOR) &&
        (name === SITE_MANAGER || name === CONTENT_EDITOR)
      )
    ) {
      userServers = [];
    }

    setSelectedUser(prevUser => ({
      ...prevUser,
      Idtservers: userServers,
      Role: {
        id,
        name
      }
    }));
    setEdited(true);
  };

  const updateSelectedUser = Idtservers => {
    setSelectedUser({ ...selectedUser, Idtservers });
    setEdited(true);
  };

  const handleSelectServers = selectedServer => {
    const hasSelectedServer = selectedUser.Idtservers.some(
      idtServer => idtServer.id === selectedServer.id
    );

    if (!hasSelectedServer) {
      const Idtservers = [...selectedUser.Idtservers, selectedServer];

      updateSelectedUser(Idtservers);
    } else {
      const Idtservers = selectedUser.Idtservers.filter(
        idtServer => idtServer.id !== selectedServer.id
      );

      updateSelectedUser(Idtservers);
    }
  };

  return (
    <Slide direction="left" in={slideStatus}>
      <div className={classes["edit-selected-user"]}>
        <SidePanelHeader
          title={t("users.edit-selected-user.title")}
          loading={loading}
          onClose={handleCancel}
        />
        <EditUserForm register={register} />
        <RoleSelect
          selectedUser={selectedUser}
          roles={roles}
          handleRoleChange={handleRoleChange}
        />
        <ServersList
          selectedUser={selectedUser}
          handleSelectServers={handleSelectServers}
        />
        <EditUserButtons
          disabled={disabled}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </div>
    </Slide>
  );
}

EditSelectedUser.propTypes = {
  selectedUser: object,
  setSelectedUser: func,
  roles: array,
  resetSelectedUser: func,
  loading: bool,
  onEditUserSubmit: func,
  edited: bool,
  setEdited: func
};

export default EditSelectedUser;
