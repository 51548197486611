import React from "react";
import { object } from "prop-types";
import clsx from "clsx";
import moment from "moment";

import classes from "./ModalVideoDetails.module.scss";

function ModalVideoDetails({ video }) {
  const getVideoSize = video => `${Math.round(video.size / 1000000) / 10} MB`;
  const getVideoLength = ({ videoLength }) =>
    moment
      .utc(moment.duration(videoLength, "seconds").asMilliseconds())
      .format("mm:ss");

  return (
    <>
      <div className={classes["video-item"]}>
        <div className={classes["video-item-name"]}>{video.name}</div>
        <div
          className={clsx(classes["video-item-details"], classes["no-select"])}
        >
          <span>{video.resolution}</span>&nbsp;|&nbsp;
          <span>{getVideoSize(video)}</span>&nbsp;|&nbsp;
          <span>{getVideoLength(video)}</span>
        </div>
      </div>
    </>
  );
}

ModalVideoDetails.propTypes = {
  video: object.isRequired
};

export default ModalVideoDetails;
