import React from "react";
import clsx from "clsx";

import classes from "./FormTextarea.module.scss";

const FormTextarea = React.forwardRef(
  // @ts-ignore
  ({ error, formTextareaClass = null, ...props }, ref) => {
    return (
      <div
        className={clsx(classes["textarea-container"], {
          [formTextareaClass]: formTextareaClass
        })}
      >
        <textarea
          ref={ref}
          className={clsx(classes["textarea"], {
            [classes["error-border"]]: error
          })}
          autoComplete="none"
          {...props}
        ></textarea>
        {error && <span className={classes["error-mssg"]}>{error}</span>}
      </div>
    );
  }
);

export default FormTextarea;
