import React, { useEffect, useState, useContext } from "react";
import { func, bool } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";
import ServersApi from "services/api/6-servers-api/servers-api";
import { getSnacks } from "../snacks/snacks";

import NotificationContext from "contexts/NotificationContext";
import { handleError } from "utils/utils";
import { permissions } from "data/permissions";
import usePageFeatures from "hooks/usePageFeatures";

import Modal from "components/ui/modals/modal/Modal";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import AddServerForm from "./components/add-server-form/AddServerForm";

const isInvalidCharacterInitial = { productKey: false, name: false };

function AddNewServerModal({ open, onSuccess, onClose }) {
  const { t } = useTranslation("servers");
  const {
    SERVER_ADDED,
    SERVER_ADDING_ERROR,
    SERVER_ALREADY_EXISTS
  } = getSnacks(t);
  const [companyId, setCompanyId] = useState(null);
  const [isInvalidCharacter, setIsInvalidCharacter] = useState(
    isInvalidCharacterInitial
  );
  const { productKey, name } = isInvalidCharacter;
  const isInputValueInvalid = productKey || name;
  const {
    actions: { setSnackbar }
  } = useContext(NotificationContext);

  const featuresAccess = usePageFeatures({
    companies: permissions.companies.COMPANIES_GET
  });

  const {
    handleSubmit,
    register,
    errors,
    formState: { dirty, isValid }
  } = useForm({ mode: "onChange" });

  const disabled =
    (featuresAccess && featuresAccess.companies
      ? !dirty || !companyId
      : !dirty) ||
    isInputValueInvalid ||
    !isValid;

  const handleAddServerError = err => {
    const serverExists = err.response.data.includes(
      "Server with this productkey already exists"
    );

    const defaultError = serverExists
      ? SERVER_ALREADY_EXISTS
      : SERVER_ADDING_ERROR;

    const error = serverExists ? "" : err;

    handleError({ error, defaultError, setSnackbar, t });
  };

  const [, addServerLoading, , addServer] = useApi(ServersApi.addNewServer, {
    requestOnMount: false,
    onSuccess: onSuccess,
    onError: handleAddServerError,
    successMessage: SERVER_ADDED
  });

  const handleCompanySelect = e => {
    setCompanyId(e.target.value);
  };

  const handleAddServer = data => {
    addServer({ ...data, companyId });
  };

  useEffect(() => {
    if (!open) {
      setCompanyId(null);
    }

    setIsInvalidCharacter(isInvalidCharacterInitial);
  }, [open]);

  return (
    <Modal open={open}>
      <Modal.Header loading={addServerLoading} onClose={onClose}>
        {t("add-a-new-server")}
      </Modal.Header>
      <Modal.Content>
        <AddServerForm
          register={register}
          errors={errors}
          companyId={companyId}
          handleCompanySelect={handleCompanySelect}
        />
      </Modal.Content>
      <Modal.Footer>
        <PrimaryButton
          disabled={disabled}
          onClick={handleSubmit(handleAddServer)}
        >
          {t("common:apply")}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

AddNewServerModal.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
  open: bool.isRequired
};

export default AddNewServerModal;
