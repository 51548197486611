import React, { Suspense, useState, useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import { LoadingContextProvider } from "contexts/LoadingContext";
import { SocketIOContextProvider } from "contexts/SocketIOContext";
import { ErrorContextProvider } from "../contexts/ErrorContext";
import { ServerOfflineContextProvider } from "../contexts/ServerOfflineContext";
import { permissions } from "data/permissions";

import {
  WrappedForgotPasswordForm,
  WrappedResetPasswordForm
} from "pages/_landingPage/home/Home";
import Dashboard from "pages/dashboard/Dashboard";
import ChannelLineup from "pages/content/channel-list/ChannelList";
import AssignChannels from "pages/endpoints/assign-channels/AssignChannels";
import AssignTemplates from "pages/endpoints/assign-templates/AssignTemplates";
import AssignEndpoints from "pages/endpoints/assign-endpoints/AssignEndpoints";
import Video from "pages/content/video-playlist-channels/VideoPlaylistChannels";
import ControlPanel from "pages/endpoints/control/control-panel/ControlPanel";
import TemplateBuilder from "pages/content/template-builder/TemplateBuilder";
import Servers from "pages/servers/Servers";
import ProtectedRoute from "components/routes/ProtectedRoute";
import Layout from "components/layout/Layout";
import ReAuthModal from "components/ui/modals/re-auth-modal/ReAuthModal";
import IDTErrorModal from "../components/idt-error-modal/IDTErrorModal";
import IDTOfflineModal from "../components/idt-offline-modal/IDTOfflineModal";
import Endpoints from "pages/endpoints/Endpoints";
import Access from "pages/access/Access";
import IDTRedirect from "pages/_landingPage/idt-redirect/IDTRedirect";
import LogoutPage from "pages/logout-page/LogoutPage";

function Routes() {
  const [section, setSection] = useState("");
  const [subSection, setSubSection] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    const [, page, division] = pathname.split("/");

    setSection(page);
    setSubSection(division);
  }, [pathname]);

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/" exact component={LogoutPage} />
        <Route path="/forgot-password" component={WrappedForgotPasswordForm} />
        <Route path="/reset-password" component={WrappedResetPasswordForm} />
        <Route path="/user/login-idx" component={IDTRedirect} />
        <ErrorContextProvider>
          <ServerOfflineContextProvider>
            <SocketIOContextProvider>
              <LoadingContextProvider>
                <Layout section={section} subSection={subSection}>
                  <Suspense fallback={null}>
                    <Switch>
                      <ProtectedRoute path="/servers" component={Servers} />
                      <ProtectedRoute path="/dashboard" component={Dashboard} />
                      <ProtectedRoute
                        path="/endpoints/groups"
                        accessPermission={
                          permissions.endpointsGroups.ENDPOINTS_GROUPS_GET
                        }
                        component={Endpoints}
                      />
                      <ProtectedRoute
                        path="/endpoints/channels"
                        accessPermission={
                          permissions.channels.CHANNELS_ASSIGN_TO_GROUP
                        }
                        component={AssignChannels}
                      />
                      <ProtectedRoute
                        path="/endpoints/templates"
                        accessPermission={permissions.templates.TEMPLATES_GET}
                        component={AssignTemplates}
                      />
                      <ProtectedRoute
                        path="/endpoints/assign-endpoints"
                        accessPermission={permissions.endpoints.ENDPOINTS_GET}
                        component={AssignEndpoints}
                      />
                      <ProtectedRoute
                        path="/content/video-playlist-channels"
                        accessPermission={[
                          permissions.playlists.PLAYLISTS_PAGE_VIDEOS,
                          permissions.playlists.PLAYLISTS_PAGE_PLAYLISTS,
                          permissions.playlists.PLAYLISTS_PAGE_CHANNELS
                        ]}
                        component={Video}
                      />
                      <ProtectedRoute
                        path="/content/channel-list"
                        accessPermission={permissions.channels.CHANNELS_GET}
                        component={ChannelLineup}
                      />
                      <ProtectedRoute
                        path="/template-builder"
                        accessPermission={
                          permissions.templateBuilder.TEMPLATE_BUILDER_GET
                        }
                        component={TemplateBuilder}
                      />
                      <ProtectedRoute
                        path="/endpoints/control"
                        accessPermission={
                          permissions.endpointsControl.ENDPOINTS_CONTROL_VOLUME
                        }
                        component={ControlPanel}
                      />
                      <ProtectedRoute
                        path="/access"
                        accessPermission={permissions.users.USERS_GET}
                        component={Access}
                      />

                      <Redirect to="/servers" />
                    </Switch>
                    <ReAuthModal />
                    <IDTErrorModal />
                    <IDTOfflineModal />
                  </Suspense>
                </Layout>
              </LoadingContextProvider>
            </SocketIOContextProvider>
          </ServerOfflineContextProvider>
        </ErrorContextProvider>
      </Switch>
    </Suspense>
  );
}

export default Routes;
