import React from "react";
import { arrayOf, object } from "prop-types";
import Scrollbars from "react-custom-scrollbars";

import ActivePlaylistVideoItem from "./active-playlist-video-item/ActivePlaylistVideoItem";

function ActivePlaylistVideos({ videos }) {
  return (
    <Scrollbars autoHeight autoHeightMax="77vh">
      {videos.map((video, index) => (
        <ActivePlaylistVideoItem key={`${video.id}-${index}`} video={video} />
      ))}
    </Scrollbars>
  );
}

ActivePlaylistVideos.propTypes = {
  videos: arrayOf(object)
};

export default ActivePlaylistVideos;
