import React from "react";
import { string, func } from "prop-types";
import { Tooltip } from "@material-ui/core";
import Img from "react-image";

import ImgUnloader from "components/ui/loaders/img-unloader/ImgUnloader";

import { baseUrl } from "data/constants";

import classes from "./ChannelLogoPreview.module.scss";

function ChannelLogoPreview({ logoPath, openGalleryModal, title }) {
  return (
    <div className={classes["logo-image-container"]} onClick={openGalleryModal}>
      {logoPath !== undefined && (
        <Tooltip
          title={title}
          placement="right-start"
          classes={{ tooltip: classes["tooltip-text"] }}
        >
          <div>
            <Img
              src={`${baseUrl}${logoPath}`}
              className={classes["logo-image"]}
              unloader={
                <ImgUnloader
                  unloaderClass={classes["logo-image"]}
                  defaultLogo
                />
              }
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

ChannelLogoPreview.propTypes = {
  logoPath: string,
  openGalleryModal: func.isRequired,
  title: string
};

export default ChannelLogoPreview;
