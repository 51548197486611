import React from "react";
import { bool, func } from "prop-types";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import { useTranslation } from "react-i18next";

function LogoutConfirmation({ open, onClose, onAction }) {
  const { t } = useTranslation("edit-profile", { useSuspense: false });

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onAction={onAction}
      actionName={t("menu.log-out")}
    >
      <span>{t("logout-confirmation")}</span>
    </ConfirmationModal>
  );
}

LogoutConfirmation.propTypes = {
  open: bool,
  onClose: func,
  onAction: func
};

export default LogoutConfirmation;
