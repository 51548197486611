import React, { useContext, useState, useEffect } from "react";
import { func, bool } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MAX_IDTNAME_LEN } from "data/constants";
import PlaylistsApi from "services/api/3-content-api/playlists-api";
import useApi from "hooks/useApi";
import { playlistsRequestsMessages } from "pages/content/video-playlist-channels/components/playlists/utils/utils";
import { VideoContext } from "contexts/VideoContext";
import { getInputError, validateIDTName, validateRequired } from "utils/utils";

import Modal from "components/ui/modals/modal/Modal";
import Form from "components/ui/form/Form";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import CreatePlaylistLoader from "./components/create-playlist-loader/CreatePlaylistLoader";

function CreatePlaylistModal({ open, onClose, setSelectedVideosArray }) {
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { dirty, isValid }
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.videos.create-playlist-modal";
  const {
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_ERROR
  } = playlistsRequestsMessages(t);
  const { selectedVideosArray, setAvailablePlaylists } = useContext(
    VideoContext
  );
  const [isInvalidCharacter, setIsInvalidCharacter] = useState({
    playlistName: false
  });

  const disabled = !dirty || isInvalidCharacter.playlistName || !isValid;

  const [, , , fetchPlaylists] = useApi(PlaylistsApi.getAllPlaylists, {
    requestOnMount: false,
    showMessageOnError: false,
    onSuccess: setAvailablePlaylists
  });

  const onCreateNewPlaylistSuccess = () => {
    setSelectedVideosArray([]);
    fetchPlaylists();
    onClose();
  };

  const [, createPlaylistLoader, , createNewPlaylist] = useApi(
    PlaylistsApi.createPlaylist,
    {
      requestOnMount: false,
      onSuccess: onCreateNewPlaylistSuccess,
      successMessage: CREATE_PLAYLIST_SUCCESS,
      errorMessage: CREATE_PLAYLIST_ERROR
    }
  );

  const createPlaylist = () => {
    const payload = {
      name: getValues().playlistName,
      files: selectedVideosArray.map(({ id }) => id)
    };

    if (isInvalidCharacter.playlistName) {
      setIsInvalidCharacter({ playlistName: false });
    }
    createNewPlaylist(payload);
  };

  useEffect(() => {
    if (isInvalidCharacter.playlistName) {
      setIsInvalidCharacter({ playlistName: false });
    }
  }, [open]);

  return (
    <Modal open={open}>
      <Modal.Header loading={null} onClose={onClose}>
        {t(`${PREFIX}.create-playlist`)}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit(createPlaylist)}>
          <Form.Field>
            <Form.Label htmlFor="playlist-name">
              {t(`${PREFIX}.enter-playlist-name`)}
            </Form.Label>
            <Form.Input
              // @ts-ignore
              type="text"
              id="playlist-name"
              name="playlistName"
              ref={register({
                ...validateRequired,
                ...validateIDTName
              })}
              error={getInputError(errors.playlistName, t, MAX_IDTNAME_LEN)}
              data-cy="playlist-name-input"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <CreatePlaylistLoader loading={createPlaylistLoader} />
        <PrimaryButton
          type="submit"
          onClick={handleSubmit(createPlaylist)}
          disabled={disabled}
        >
          {t("common:apply")}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

CreatePlaylistModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  setSelectedVideosArray: func
};

export default CreatePlaylistModal;
