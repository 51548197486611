import React, { useState, useEffect, useRef } from "react";
import { arrayOf, object, bool, func, string } from "prop-types";
import Scrollbars from "react-custom-scrollbars";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import PlaylistVideoItem from "./playlist-video-item/PlaylistVideoItem";
import PlaylistVideoPlaceholder from "./playlist-video-placeholder/PlaylistVideoPlaceholder";
import { reorder } from "utils/utils";
import useSocketIO from "hooks/useSocketIO";
import { SOCKET_EVENTS } from "../../../../../../../../../data/socket-events";

function SelectedPlaylistVideos({
  videos = [],
  playlistPlaying,
  playlistAssignedToChannel,
  handleRemoveVideoFromPlaylist,
  playlistId,
  getPlaylists,
  updatingPlaylists,
  setUpdatingPlaylists,
  handleReorderPlaylistVideos,
  reorderPlaylistVideos,
  updating
}) {
  const playlistIdRef = useRef(undefined);
  const downloadingVideosToIDTRef = useRef([]);
  const [downloadingVideosToIDT, setDownloadingVideosToIDT] = useState([]);
  const [displayingVideos, setDisplayingVideos] = useState([]);

  const handleVideoUploaded = data => {
    const downloadingCurrentPlaylistVideos = downloadingVideosToIDTRef.current.reduce(
      (playlistDownloadingVideos, currentDownloadedVideo) => {
        if (data.playlistId === currentDownloadedVideo.playlistId) {
          playlistDownloadingVideos.push(currentDownloadedVideo);
        }
        return playlistDownloadingVideos;
      },
      []
    );
    const shouldRefetchPlaylists =
      !downloadingVideosToIDTRef.current.length ||
      (downloadingCurrentPlaylistVideos.length > 0 &&
        downloadingCurrentPlaylistVideos.every(
          ({ progress }) => progress === "100.00%"
        ));
    if (shouldRefetchPlaylists) {
      const newUpdatingPlaylists = updatingPlaylists.filter(
        id => id === data.playlistId
      );
      setUpdatingPlaylists(newUpdatingPlaylists);
      getPlaylists();

      const downloadingVideosWithoutCurrentPlaylist = downloadingVideosToIDTRef.current.filter(
        video => video.playlistId !== playlistIdRef.current
      );

      downloadingVideosToIDTRef.current = downloadingVideosWithoutCurrentPlaylist;
      setDownloadingVideosToIDT(downloadingVideosWithoutCurrentPlaylist);
    }
  };

  const handleVideoUpload = data => {
    const updatingPlaylistIndex = updatingPlaylists.findIndex(
      id => id === data.playlistId
    );
    if (updatingPlaylistIndex === -1) {
      setUpdatingPlaylists(prevUpdatingPlaylists => [
        ...prevUpdatingPlaylists,
        data.playlistId
      ]);
    }

    const [filename] = data.fileName.split(".");
    const downloadingVideosIds = downloadingVideosToIDTRef.current.map(
      ({ id }) => id
    );

    const downloadingVideoIndex = downloadingVideosIds.findIndex(
      id => id === data.tmpFileId
    );

    if (downloadingVideoIndex === -1) {
      const updatedDownlodingVideos = [
        ...downloadingVideosToIDTRef.current,
        {
          id: data.tmpFileId,
          name: filename,
          downloadSpeed: data.downloadSpeed,
          progress: data.progress,
          playlistId: data.playlistId
        }
      ];
      downloadingVideosToIDTRef.current = updatedDownlodingVideos;
      setDownloadingVideosToIDT(updatedDownlodingVideos);
    } else {
      const downloadingVideo = {
        ...downloadingVideosToIDTRef.current[downloadingVideoIndex],
        downloadSpeed: data.downloadSpeed,
        progress: data.progress
      };

      const updatedDownlodingVideos = [...downloadingVideosToIDTRef.current];
      updatedDownlodingVideos[downloadingVideoIndex] = downloadingVideo;

      downloadingVideosToIDTRef.current = updatedDownlodingVideos;
      setDownloadingVideosToIDT(updatedDownlodingVideos);
    }
  };

  useSocketIO(
    SOCKET_EVENTS.DOWNLOAD_VIDEO_TO_IDT_BEFORE_ADD_TO_PLAYLIST,
    handleVideoUpload
  );

  useSocketIO(
    SOCKET_EVENTS.DOWNLOAD_VIDEO_TO_IDT_AND_ADDED_TO_PLAYLIST,
    handleVideoUploaded
  );

  useEffect(() => {
    playlistIdRef.current = playlistId;
  }, [playlistId]);

  useEffect(() => {
    setDisplayingVideos(videos);
  }, [videos]);

  const onDragEnd = result => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    const videoId = videos ? videos[source.index].id : null;
    const reorderedVideos = reorder(videos, source.index, destination.index);

    if (videoId) {
      const payload = {
        videoId,
        playlistId,
        newPosition: destination.index
      };

      setDisplayingVideos(reorderedVideos);
      handleReorderPlaylistVideos(playlistId, reorderedVideos);
      reorderPlaylistVideos(payload);
    }
  };

  const isDragDisabled =
    playlistPlaying || playlistAssignedToChannel || displayingVideos.length < 2;

  return (
    <Scrollbars autoHeight autoHeightMin="65vh" autoHeightMax="79vh">
      {downloadingVideosToIDT.map(video => {
        return playlistId === video.playlistId ? (
          <PlaylistVideoPlaceholder
            key={`${playlistId}-${video.id}`}
            video={video}
          />
        ) : null;
      })}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" isDropDisabled={isDragDisabled}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {displayingVideos.map((video, index) => (
                <PlaylistVideoItem
                  key={video.uuid}
                  index={index}
                  video={video}
                  isDragDisabled={isDragDisabled}
                  playlistPlaying={playlistPlaying}
                  playlistAssignedToChannel={playlistAssignedToChannel}
                  handleRemoveVideoFromPlaylist={handleRemoveVideoFromPlaylist}
                  videoIndex={index}
                  updating={updating}
                />
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Scrollbars>
  );
}

SelectedPlaylistVideos.propTypes = {
  videos: arrayOf(object),
  playlistPlaying: bool,
  playlistAssignedToChannel: bool,
  handleRemoveVideoFromPlaylist: func.isRequired,
  playlistId: string,
  getPlaylists: func.isRequired,
  updatingPlaylists: arrayOf(string).isRequired,
  setUpdatingPlaylists: func.isRequired,
  updating: bool
};

export default SelectedPlaylistVideos;
