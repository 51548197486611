import React from "react";
import { node, bool, func } from "prop-types";
import { DialogTitle } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import ModalLoader from "./components/modal-loader/ModalLoader";

import classes from "../Modal.module.scss";

function ModalHeader({
  loading = false,
  children,
  description = null,
  onClose = null
}) {
  return (
    <DialogTitle disableTypography className={classes["modal-header"]}>
      <div className={classes["modal-header-top-bar"]}>
        <div className={classes["children-loader-container"]}>
          <div>{children}</div>
          <ModalLoader loading={loading} />
        </div>
        {onClose && (
          <CloseIcon className={classes["close-icon"]} onClick={onClose} />
        )}
      </div>

      {description && (
        <div className={classes["modal-header-description"]}>{description}</div>
      )}
    </DialogTitle>
  );
}

ModalHeader.propTypes = {
  children: node,
  description: node,
  loading: bool,
  onClose: func
};

export default ModalHeader;
