import React from "react";
import { func, string, bool, array } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from "@material-ui/core";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./DaysSelection.module.scss";

function DaysSelection({
  lastDaysCurrent,
  lastDaysNew,
  handleChange,
  loading,
  stateChanged,
  data
}) {
  const { t } = useTranslation("dashboard");
  return data.length ? (
    <div className={classes["days-selection"]}>
      <div className={classes["days-selection-loader"]}>
        {loading && stateChanged ? (
          <div className={classes["days-selection-loader-container"]}>
            <EllipsisLoader />
          </div>
        ) : null}
      </div>
      <div className={classes["days-selection-label"]}>
        {t("modal.chart.last-days")}:
      </div>

      <FormControl
        component="fieldset"
        className={classes["days-selection-form"]}
      >
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={lastDaysNew ? lastDaysNew : lastDaysCurrent}
        >
          <FormControlLabel
            value="1"
            className={classes["days-selection-form-label"]}
            control={
              <Radio
                color="primary"
                onChange={handleChange}
                classes={{
                  root: classes["radio-btn"],
                  checked: classes["radio-btn-checked"]
                }}
              />
            }
            label="1"
            labelPlacement="end"
          />
          <FormControlLabel
            value="7"
            className={classes["days-selection-form-label"]}
            control={
              <Radio
                color="primary"
                onChange={handleChange}
                classes={{
                  root: classes["radio-btn"],
                  checked: classes["radio-btn-checked"]
                }}
              />
            }
            label="7"
            labelPlacement="end"
          />
          <FormControlLabel
            value="14"
            className={classes["days-selection-form-label"]}
            control={
              <Radio
                color="primary"
                onChange={handleChange}
                classes={{
                  root: classes["radio-btn"],
                  checked: classes["radio-btn-checked"]
                }}
              />
            }
            label="14"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </div>
  ) : null;
}

DaysSelection.propTypes = {
  lastDaysCurrent: string,
  lastDaysNew: string,
  handleChange: func,
  loading: bool,
  stateChanged: bool,
  data: array
};

export default DaysSelection;
