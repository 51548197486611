import React, { createContext, useReducer, useEffect } from "react";
import { getTokenFromCookie } from "utils/utils";

export const initialState = {
  isAuthenticated: false,
  email: null,
  refreshPageAfterAuth: false
};

const types = {
  SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",
  SET_EMAIL: "SET_EMAIL"
};

const setIsAuthenticated = (isAuthenticated, refreshPageAfterAuth = false) => ({
  type: types.SET_IS_AUTHENTICATED,
  isAuthenticated,
  refreshPageAfterAuth
});
const setEmail = email => ({ type: types.SET_EMAIL, email });

export const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        refreshPageAfterAuth: action.refreshPageAfterAuth
      };
    case types.SET_EMAIL:
      return { ...state, email: action.email };
    default:
      return state;
  }
};

const AuthContext = createContext({
  state: initialState,
  actions: { setIsAuthenticated: null, setEmail: null }
});

export function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {
    setIsAuthenticated: (isAuthenticated, refreshPageAfterAuth) =>
      dispatch(setIsAuthenticated(isAuthenticated, refreshPageAfterAuth)),
    setEmail: email => dispatch(setEmail(email))
  };

  useEffect(() => {
    const token = getTokenFromCookie();

    if (token) {
      actions.setIsAuthenticated(true);
    }
    const userDetailsStringified = localStorage.getItem("userDetails");
    if (userDetailsStringified) {
      const { emailAddress } = JSON.parse(userDetailsStringified);
      actions.setEmail(emailAddress);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, actions }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
