import React from "react";
import { useTranslation } from "react-i18next";
import { bool } from "prop-types";
import { Tooltip } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import classes from "../../ServersTable.module.scss";

function CurrentlyUsedServer({ isCurrentlyUsed = false, isOnline = false }) {
  const { t } = useTranslation("servers");

  return (
    <div className={classes["server-currently-used-icon"]}>
      {isCurrentlyUsed ? (
        <RadioButtonCheckedIcon data-cy="check-icon" />
      ) : isOnline ? (
        <Tooltip
          title={t("tooltips.click-to-select")}
          placement="right"
          classes={{ tooltip: classes["tooltip-font"] }}
        >
          <RadioButtonUncheckedIcon
            className={classes["server-not-currently-used-icon"]}
          />
        </Tooltip>
      ) : (
        <RadioButtonUncheckedIcon
          className={classes["server-not-currently-used-icon"]}
        />
      )}
    </div>
  );
}

CurrentlyUsedServer.propTypes = {
  isCurrentlyUsed: bool,
  isOnline: bool
};

export default CurrentlyUsedServer;
