import React from "react";
import { string, bool } from "prop-types";
import { Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { CHANNEL_STATES } from "../../../../../../../../utils/utils";

import ChannelStateLoader from "../channel-state/loader/ChannelStateLoader";

import classes from "./ChannelName.module.scss";

function ChannelName({
  channelName,
  isAssignedToGroup,
  isEnabled,
  loading,
  newState
}) {
  const { t } = useTranslation("video-playlists");
  const history = useHistory();
  const { ENABLE, DISABLE } = CHANNEL_STATES;
  const showLoader = loading && (newState === ENABLE || newState === DISABLE);

  const handleClick = e => {
    if (isEnabled) {
      history.push("/endpoints/channels");
    } else {
      e.stopPropagation();
    }
  };

  const NotAssignedLabel = () => (
    <span
      onClick={handleClick}
      className={clsx(classes["channel-not-assigned-label"], {
        [classes["enabled"]]: isEnabled
      })}
    >
      {t("sections.playlist-channels.warnings.channel-not-assigned-to-group")}
    </span>
  );

  return (
    <div className={classes["container"]}>
      <div className={classes["channel-name"]}>
        <div className={classes["channel-name-label"]}>{channelName}</div>
        {showLoader && (
          <ChannelStateLoader
            stateLoaderClass={classes["channel-name-loader"]}
          />
        )}
      </div>
      {!isAssignedToGroup && (
        <div className={classes["channel-not-assigned"]}>
          <WarningIcon classes={{ root: classes["warning-icon"] }} />
          {isEnabled ? (
            <Tooltip
              title={t("sections.playlist-channels.tooltips.click-to-assign")}
              placement="top-start"
              classes={{ tooltip: classes["tooltip"] }}
            >
              <div>
                <NotAssignedLabel />
              </div>
            </Tooltip>
          ) : (
            <NotAssignedLabel />
          )}
        </div>
      )}
    </div>
  );
}

ChannelName.propTypes = {
  channelName: string,
  isAssignedToGroup: bool,
  isEnabled: bool,
  newState: string,
  loading: bool
};

export default ChannelName;
