import React from "react";
import PropTypes from "prop-types";
import { DialogContent } from "@material-ui/core";

import classes from "../Modal.module.scss";

function ModalContent({ children }) {
  return (
    <DialogContent className={classes["modal-content"]}>
      {children}
    </DialogContent>
  );
}

ModalContent.propTypes = {
  children: PropTypes.node
};

export default ModalContent;
