import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { func, bool, string } from "prop-types";
import { Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import classes from "./MenuItem.module.scss";

function MenuItem({
  label,
  onClick,
  playlistPlaying,
  playlistAssignedToChannel
}) {
  const { t } = useTranslation("video-playlists");
  const isEditable = !playlistPlaying && !playlistAssignedToChannel;
  const PREFIX = "sections.playlists.tooltips";

  return (
    <Tooltip
      title={
        label !== "push" && !isEditable ? (
          <div className={classes["tooltip-title"]}>
            <WarningIcon className={classes["warning-icon"]} />
            {playlistAssignedToChannel
              ? t(`${PREFIX}.playlist-is-assigned-to-channel`)
              : t(`${PREFIX}.playlist-is-playing`)}
          </div>
        ) : (
          ""
        )
      }
      placement="left-start"
      classes={{ tooltip: classes.tooltip }}
    >
      <li
        className={clsx(classes.option, {
          [classes["not-active"]]: label !== "push" && !isEditable
        })}
        onClick={label === "push" || isEditable ? onClick : () => {}}
      >
        {label === "push"
          ? t("sections.playlists.side-menu.push-to-another-idt")
          : t(`common:${label}`)}
      </li>
    </Tooltip>
  );
}

MenuItem.propTypes = {
  label: string.isRequired,
  onClick: func,
  playlistPlaying: bool.isRequired,
  playlistAssignedToChannel: bool.isRequired
};

export default MenuItem;
