import React, { useContext, useEffect, useState } from "react";
import { object, func, bool } from "prop-types";
import {
  Slide,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from "@material-ui/icons/Warning";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Scrollbars from "react-custom-scrollbars";

import ServersApi from "services/api/6-servers-api/servers-api";
import NotificationContext from "contexts/NotificationContext";
import useApi from "hooks/useApi";
import { getSnacks } from "../snacks/snacks";
import { handleError } from "utils/utils";

import SidePanelHeader from "components/ui/headers/side-panel-header/SidePanelHeader";
import Backups from "./components/backups/Backups";
import PanelHeader from "./components/panel-header/PanelHeader";
import ServerMaintenance from "./components/server-maintenance/ServerMaintenance";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import ServerInfo from "./components/server-info/ServerInfo";
import EditServerForm from "./components/edit-server-form/EditServerForm";

import classes from "./EditSelectedServer.module.scss";

function EditSelectedServer({
  selectedServer,
  setSelectedServer,
  resetSelectedServer,
  refreshServersList,
  openSyncModal,
  syncLoading,
  openDeleteModal,
  fetchServers,
}) {
  const [slideStatus, setSlideStatus] = useState(false);
  const [newData, setNewData] = useState({ name: "", productKey: "" });
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation("servers");
  const {
    handleSubmit,
    register,
    formState: { dirty, isValid },
    reset,
    errors,
    watch,
  } = useForm({
    defaultValues: {
      name: selectedServer.name,
      productKey: selectedServer.productKey,
    },
    mode: "onChange",
  });
  const {
    actions: { setSnackbar },
  } = useContext(NotificationContext);

  const { EDIT_SERVER_SUCCESS, EDIT_SERVER_ERROR, SERVER_ALREADY_EXISTS } =
    getSnacks(t);

  // @ts-ignore
  const watchLicenceKey = watch("productKey");

  useEffect(() => {
    if (selectedServer.name) {
      handleSlideIn();
    } else {
      handleSlideOut();
    }
    reset({ name: selectedServer.name, productKey: selectedServer.productKey });
  }, [selectedServer]);

  // --- HANDLERS ---

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 250);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const handleCancel = () => {
    handleSlideOut();
    resetSelectedServer();
  };

  const handleUpdateSuccess = () => {
    closeModal();
    setNewData({ name: "", productKey: "" });
    refreshServersList();
  };

  const handleServerDetailsUpdate = () => {
    setSelectedServer({ ...selectedServer, ...newData });
    updateServer({ ...selectedServer, ...newData });
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleUpdateServerError = (err) => {
    const serverExists = err.response.data.message.includes(
      "Server with this license key already exists"
    );
    const defaultError = serverExists
      ? SERVER_ALREADY_EXISTS
      : EDIT_SERVER_ERROR;
    const error = serverExists ? "" : err;

    handleError({ error, defaultError, setSnackbar, t });
    closeModal();
  };

  // --- HOOK API ---

  const [, updateLoading, , updateServer] = useApi(ServersApi.updateServer, {
    requestOnMount: false,
    successMessage: EDIT_SERVER_SUCCESS,
    onSuccess: handleUpdateSuccess,
    onError: handleUpdateServerError,
  });

  const onSubmit = (data) => {
    setNewData(data);
  };

  useEffect(() => {
    if (newData.name && newData.productKey) {
      if (watchLicenceKey !== selectedServer.productKey) {
        openModal();
      } else {
        handleServerDetailsUpdate();
      }
    }
  }, [newData]);

  return (
    <>
      <Slide direction="left" in={slideStatus}>
        <div className={classes["edit-server"]} data-cy="edit-server-slider">
          <Scrollbars
            autoHeight
            autoHeightMax="89vh"
            autoHide
            autoHideTimeout={3000}
            autoHideDuration={1000}
          >
            <div className={classes["edit-server-top"]}>
              <SidePanelHeader
                title={t("server-management")}
                onClose={handleCancel}
                sidePanelHeaderClass={classes["header-padding"]}
              />

              <ServerInfo server={selectedServer} />

              <Accordion classes={{ root: classes["edit-server-top-panel"] }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      className={classes["edit-server-top-panel-icon"]}
                    />
                  }
                  classes={{ root: classes["edit-server-top-panel-summary"] }}
                >
                  <PanelHeader
                    loading={updateLoading}
                    label={t("edit-selected-server")}
                  />
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: classes["edit-server-top-panel-details"] }}
                >
                  <EditServerForm
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                    openDeleteModal={openDeleteModal}
                    dirty={dirty}
                    isValid={isValid}
                  />
                </AccordionDetails>
              </Accordion>
            </div>

            <ServerMaintenance
              openSyncModal={openSyncModal}
              loading={syncLoading}
              server={selectedServer}
              setSelectedServer={setSelectedServer}
              refreshServersList={refreshServersList}
            />

            {selectedServer.isOnline && (
              <Backups
                server={selectedServer}
                setSelectedServer={setSelectedServer}
                fetchServers={fetchServers}
              />
            )}
          </Scrollbars>
        </div>
      </Slide>

      <ConfirmationModal
        open={modalOpen}
        onClose={closeModal}
        onAction={handleServerDetailsUpdate}
        actionName={t("licence-key-edit-modal.continue")}
      >
        <div>
          <div className={classes["modal-warning"]}>
            <WarningIcon className={classes["modal-warning-icon"]} />
            <div>{t("licence-key-edit-modal.warning")}</div>
          </div>
          <div className={classes["modal-question"]}>
            {t("licence-key-edit-modal.question")}
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
}

EditSelectedServer.propTypes = {
  selectedServer: object.isRequired,
  setSelectedServer: func.isRequired,
  resetSelectedServer: func.isRequired,
  refreshServersList: func.isRequired,
  openSyncModal: func.isRequired,
  openDeleteModal: func.isRequired,
  syncLoading: bool.isRequired,
  fetchServers: func,
};

export default EditSelectedServer;
