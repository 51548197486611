import React from "react";
import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";

import idtLogo from "assets/images/idtWhite.png";
import Modal from "components/ui/modals/modal/Modal";

import classes from "./AboutModal.module.scss";

function AboutModal({ open, onClose }) {
  const { t } = useTranslation("common", { useSuspense: false });

  return (
    <Modal open={open}>
      <Modal.Header onClose={onClose}></Modal.Header>
      <Modal.Content>
        <div className={classes["about"]}>
          <div className={classes["about-logo-container"]}>
            <img
              src={idtLogo}
              className={classes["about-logo"]}
              alt="IDT logo"
            />
          </div>

          <div className={classes["about-info"]}>
            <div>
              Intelligent Digital&nbsp;
              <div className={classes["television"]}>Television</div>
            </div>
            <div className={classes["version"]}>{t("version")}&nbsp;1.0.0</div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

AboutModal.propTypes = {
  open: bool,
  onClose: func
};

export default AboutModal;
