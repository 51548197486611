import React from "react";
import { arrayOf, object, func } from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LocationsPanel from "../locations-panel/LocationsPanel";

import classes from "./CompaniesPanel.module.scss";

function CompaniesPanel({ companies, overview, handleLocationClick }) {
  return companies
    ? companies.map((company, index) => (
        <Accordion
          key={`${company.name}-${company.id}`}
          classes={{ root: classes["company"] }}
          defaultExpanded={index === 0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={company.id}
            id={company.id}
            classes={{ expandIcon: classes["expand-icon"] }}
          >
            <div className={classes["company-summary"]}>{company.name}</div>
          </AccordionSummary>
          <AccordionDetails className={classes["panel-details"]}>
            <LocationsPanel
              locations={overview[company.name] ? overview[company.name] : null}
              handleLocationClick={handleLocationClick}
            />
          </AccordionDetails>
        </Accordion>
      ))
    : null;
}

CompaniesPanel.propTypes = {
  companies: arrayOf(object),
  overview: object,
  handleLocationClick: func,
};

export default CompaniesPanel;
