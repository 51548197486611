import React from "react";
import { string } from "prop-types";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import classes from "./ImageSizeInfo.module.scss";

function ImageSizeInfo({ info }) {
  return (
    <div className={classes["img-size-info"]}>
      <InfoOutlinedIcon className={classes["img-size-info-icon"]} />
      <span className={classes["img-size-info-text"]}>
        {info}:&nbsp;89x54&nbsp;&#40;px&#41;
      </span>
    </div>
  );
}

ImageSizeInfo.propTypes = {
  info: string
};

export default ImageSizeInfo;
