import React, { useState } from "react";
import { arrayOf, func, number, object, string } from "prop-types";
import { Tooltip } from "@material-ui/core";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import SideMenu from "./components/side-menu/SideMenu";

import classes from "./GroupsEntitiesHeader.module.scss";

function GroupsEntitiesHeader({
  groups,
  toggleAssign,
  allEnabledChannelsIds,
  numberOfTemplates,
}) {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [clickedGroup, setClickedGroup] = useState(null);
  const { t } = useTranslation("assign-pages");
  const { pathname } = useLocation();

  const openSideMenu = () => {
    setSideMenuOpen(true);
  };

  const closeSideMenu = () => {
    setSideMenuOpen(false);
  };

  const handleMoreIconClick = (group) => (_) => {
    setClickedGroup(group.id);
    openSideMenu();
  };

  const handleClickAway = () => {
    setClickedGroup(null);
    closeSideMenu();
  };

  const generateNoAssignInfo = (group) => {
    let key = "";
    let title = "";

    if (pathname === "/endpoints/channels") {
      key = "channelids";
      title = t("tooltip.no-channels-assigned");
    }

    if (pathname === "/endpoints/templates") {
      key = "templateids";
      title = t("tooltip.no-templates-assigned");
    }

    return !group[key].length ? (
      <Tooltip
        title={title}
        placement="top-start"
        classes={{ tooltip: classes["tooltip-font"] }}
      >
        <div className={classes["no-entities"]}>
          <div className={classes["no-entities-triangle"]} />
          <div className={classes["no-entities-icon"]}>!</div>
        </div>
      </Tooltip>
    ) : null;
  };

  return groups.map((group) => (
    <th key={group.id} className={classes["table-header-container"]}>
      <div className={classes["table-header"]}>
        <span className={classes["table-header-title"]}>{group.name}</span>
        <div className={classes["more-icon-container"]}>
          <MoreVertSharpIcon
            className={classes["more-icon"]}
            onClick={handleMoreIconClick(group)}
          />
        </div>
        <SideMenu
          open={sideMenuOpen && clickedGroup === group.id}
          group={group}
          handleClickAway={handleClickAway}
          toggleAssign={toggleAssign}
          closeSideMenu={closeSideMenu}
          pathname={pathname}
          allEnabledChannelsIds={allEnabledChannelsIds}
          numberOfTemplates={numberOfTemplates}
        />
      </div>
      {generateNoAssignInfo(group)}
    </th>
  ));
}

GroupsEntitiesHeader.propTypes = {
  groups: arrayOf(object),
  toggleAssign: func,
  allEnabledChannelsIds: arrayOf(string),
  numberOfTemplates: number,
};

export default GroupsEntitiesHeader;
