import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class SettingsApi {
  getToken = () => cookies.get("token");

  refresh = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/refresh`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  reboot = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/reboot`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  forceChannelChange = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/force-channel-change`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  returnOrginalChannels = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/return-original-channel`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  startupMode = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/startup-mode`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
}

export default new SettingsApi();
