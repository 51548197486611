import React, { useState } from "react";
import { arrayOf, object, func } from "prop-types";
import { useTranslation } from "react-i18next";

import AssignTemplatesApi from "services/api/2-system-manage-api/assign-templates-api";
import useApi from "hooks/useApi";
import { baseUrl } from "data/constants";
import { assignTemplatesRequestMessages } from "../../utils/utils";

import PageHeader from "components/ui/page-header/PageHeader";
import Table from "components/ui/table/Table";
import Thumb from "components/ui/thumb/Thumb";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import GroupsTemplatesHeader from "components/ui/table/groups-entities-header/GroupsEntitiesHeader";
import AssignCell from "components/ui/table/assign-cell/AssignCell";

import classes from "./GroupsTemplatesTable.module.scss";

function GroupsTemplatesTable({ templates, groups, refreshTable }) {
  const [selectedGroup, setSelectedGroup] = useState({
    id: null,
    name: null,
    actionName: null,
    question: null,
    action: null
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation("assign-pages");
  const {
    ALL_TEMPLATES_ASSIGNED,
    ALL_TEMPLATES_ASSIGNING_ERROR,
    ALL_TEMPLATES_REMOVED,
    ALL_TEMPLATES_REMOVING_ERROR,
    TEMPLATE_ASSIGN_ERROR,
    TEMPLATE_REMOVE_ERROR,
    TEMPLATE_SET_AS_DEFAULT_ERROR
  } = assignTemplatesRequestMessages(t);

  const [, , , assignTemplateToGroup] = useApi(
    AssignTemplatesApi.assignTemplateToGroup,
    {
      requestOnMount: false,
      errorMessage: TEMPLATE_ASSIGN_ERROR,
      onSuccess: refreshTable
    }
  );

  const [, , , removeTemplateFromGroup] = useApi(
    AssignTemplatesApi.removeTemplateFromGroup,
    {
      requestOnMount: false,
      errorMessage: TEMPLATE_REMOVE_ERROR,
      onSuccess: refreshTable
    }
  );

  const [, , , setTemplateAsDefault] = useApi(
    AssignTemplatesApi.setTemplateAsDefault,
    {
      requestOnMount: false,
      errorMessage: TEMPLATE_SET_AS_DEFAULT_ERROR,
      onSuccess: refreshTable
    }
  );
  const [, , , assignAllTemplatesToGroup] = useApi(
    AssignTemplatesApi.assignAllTemplatesToGroup,
    {
      requestOnMount: false,
      successMessage: ALL_TEMPLATES_ASSIGNED,
      errorMessage: ALL_TEMPLATES_ASSIGNING_ERROR,
      onSuccess: refreshTable
    }
  );
  const [, , , removeAllTemplatesFromGroup] = useApi(
    AssignTemplatesApi.removeAllTemplatesFromGroup,
    {
      requestOnMount: false,
      successMessage: ALL_TEMPLATES_REMOVED,
      errorMessage: ALL_TEMPLATES_REMOVING_ERROR,
      onSuccess: refreshTable
    }
  );

  const handleCloseModal = () => {
    setDialogOpen(false);
  };

  const handleOpenModal = () => {
    setDialogOpen(true);
  };

  const handleAssignAllTemplatesToGroup = groupId => {
    handleCloseModal();
    assignAllTemplatesToGroup(groupId);
  };

  const handleRemoveAllTemplatesFromGroup = groupId => {
    handleCloseModal();
    removeAllTemplatesFromGroup(groupId);
  };

  const toggleAssign = (group, action) => {
    const groupName = group.name;
    const groupId = group.id;
    const shouldAssign = action === "assign";

    setSelectedGroup({
      id: groupId,
      name: groupName,
      actionName: shouldAssign ? t("assign") : t("remove-assignments"),
      question: shouldAssign
        ? t("question-assign-templates")
        : t("question-remove-templates"),
      action: shouldAssign
        ? () => handleAssignAllTemplatesToGroup(groupId)
        : () => handleRemoveAllTemplatesFromGroup(groupId)
    });
    handleOpenModal();
  };

  return (
    <div className={classes["table"]}>
      <PageHeader title={t("assign-templates")} hasAccess={false} />
      <Table centered autoHeight>
        <Table.Head>
          <th className={classes["table-cell-header"]}>{t("template")}</th>
          <GroupsTemplatesHeader
            groups={groups}
            toggleAssign={toggleAssign}
            numberOfTemplates={templates.length}
          />
        </Table.Head>
        <Table.Body>
          {templates.map((template, templateIndex) => (
            <Table.Row key={`${template.id}-${templateIndex}`}>
              <td className={classes["table-cell-long"]}>
                <div className={classes["image-container"]}>
                  <Thumb
                    imgSrc={`${baseUrl}${template.logoPath}`}
                    tooltipClass={classes["tooltip"]}
                    tooltipImgClass={classes["tooltip-img"]}
                    placeholderClass={classes["image"]}
                    loaderClass={classes["img-loader"]}
                    unloaderClass={classes["img-loader"]}
                    isVertical={template.orientation === "PORTRAIT"}
                    large
                  >
                    <div className={classes["name-container"]}>
                      {template.name}
                    </div>
                  </Thumb>
                </div>
                <div className={classes["template"]}>
                  <div className={classes["template-name"]}>
                    <span>{template.name}</span>
                  </div>
                  <div className={classes["template-requires"]}>
                    {template.requires}
                  </div>
                </div>
              </td>
              {groups.map(group => {
                const cell = `${group.id}-${templateIndex}`;
                return (
                  <AssignCell
                    key={cell}
                    groupId={group.id}
                    assignedEntityIds={group.templateids}
                    defaultEntityId={group.defaulttemplate}
                    entityName="templateId"
                    entityId={template.id}
                    handleAssign={assignTemplateToGroup}
                    handleRemove={removeTemplateFromGroup}
                    handleSetDefault={setTemplateAsDefault}
                  />
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <ConfirmationModal
        open={dialogOpen}
        onClose={handleCloseModal}
        actionName={selectedGroup.actionName}
        onAction={selectedGroup.action}
      >
        <span>
          {selectedGroup.question}&nbsp;
          <span className={classes["modal-group-name"]}>
            {selectedGroup.name}?
          </span>
        </span>
      </ConfirmationModal>
    </div>
  );
}

GroupsTemplatesTable.propTypes = {
  templates: arrayOf(object),
  groups: arrayOf(object),
  refreshTable: func.isRequired
};

export default GroupsTemplatesTable;
