import React, { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { removeDuplicates } from "utils/utils";
import DrawerContext from "contexts/DrawerContext";

const ControlContext = createContext({
  groups: [],
  endpoints: [],
  endpointIds: [],
  availableChannels: [],
  setAvailableChannels: null,
  availableTemplates: [],
  setAvailableTemplates: null,
  hidden: true,
  disabled: false,
  allGroups: [],
  setGroups: () => {},
  setAllGroups: (groups) => {},
  setHidden: null,
  forceChannelChangeMode: false,
  setForceChannelChangeMode: null,
  setSelectedGroups: null,
  requestPayload: {},
});

const DISABLED_TREE_ROUTE =
  "/endpoints/control/tv-control/force-channel-change";

export function ControlContextProvider({
  children,
  groups,
  selectedGroups,
  selectedEndpoints,
  setGroups,
  setSelectedGroups,
}) {
  const [availableChannels, setAvailableChannels] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [forceChannelChangeMode, setForceChannelChangeMode] = useState(false);
  const history = useHistory();
  const endpointIds = selectedEndpoints.map(({ id }) => id);

  const [payloadGroups, setPayloadGroups] = useState([]);
  const [payloadEndpoints, setPayloadEndpoints] = useState([]);
  const requestPayload = {
    groups: payloadGroups,
    endpoints: payloadEndpoints,
  };

  const { setDuplicateEndpoints } = useContext(DrawerContext);

  useEffect(() => {
    const allEndpoints = groups.reduce((acc, curr) => {
      acc = [...acc, ...curr.endpoints];

      return acc;
    }, []);

    const duplicateIds = allEndpoints
      .map(({ id }) => id)
      .filter((item, index, array) => array.indexOf(item) !== index);

    const duplicateObjects = allEndpoints.filter((obj) =>
      duplicateIds.includes(obj.id)
    );
    const duplicatesSorted = duplicateObjects.sort((a, b) =>
      a.id > b.id ? 1 : -1
    );
    const duplicatesPairs = duplicatesSorted.reduce((acc, _, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    setDuplicateEndpoints(duplicatesPairs);
  }, [groups]);

  useEffect(() => {
    if (history.location.pathname === DISABLED_TREE_ROUTE) {
      setDisabled(true);
    } else if (disabled) {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    setPayloadGroups(selectedGroups);
  }, [selectedGroups]);

  useEffect(() => {
    const filterArray = (arr1, arr2) =>
      arr1.filter((el) => arr2.indexOf(el.groupId) === -1);

    // Filter out endpoints whose groups are in payload
    const filteredEndpoints = filterArray(selectedEndpoints, payloadGroups).map(
      ({ id }) => id
    );

    setPayloadEndpoints(filteredEndpoints);
  }, [payloadGroups, selectedEndpoints]);

  useEffect(() => {
    if (selectedEndpoints.length) {
      const lastSelectedEndpoint = selectedEndpoints.slice(-1)[0];
      const lastSelectedEndpointGroup = groups.find(
        ({ id }) => id === lastSelectedEndpoint.groupId
      );
      const allSelectedEndpointsForThisGroup = selectedEndpoints.filter(
        ({ groupId }) => groupId === lastSelectedEndpointGroup.id
      );

      const shouldGroupBeInPayload =
        lastSelectedEndpointGroup.endpoints.length ===
        allSelectedEndpointsForThisGroup.length;

      if (shouldGroupBeInPayload) {
        setSelectedGroups((prev) =>
          removeDuplicates([...prev, lastSelectedEndpointGroup.id])
        );
      } else {
        const updatedGroups = selectedGroups.filter(
          (groupId) => groupId !== lastSelectedEndpointGroup.id
        );

        setSelectedGroups(updatedGroups);
      }
    }
  }, [selectedEndpoints]);

  /* eslint-enable react-hooks/exhaustive-deps */
  const setModifiedGroups = (propName, propVal) => {
    const modifiedGroups = groups.map((group) => {
      const isGroupModified = selectedGroups.includes(group.id) || disabled;

      if (isGroupModified) {
        const endpoints = group.endpoints.map((endpoint) => ({
          ...endpoint,
          [propName]: propVal,
        }));
        group.endpoints = endpoints;
      } else {
        const endpoints = group.endpoints.map((endpoint) => {
          const isEndpointModified = selectedEndpoints.includes(endpoint.id);

          if (isEndpointModified) {
            endpoint[propName] = propVal;
          }
          return endpoint;
        });
        group.endpoints = endpoints;
      }
      return group;
    });

    setGroups(modifiedGroups);
  };
  return (
    <ControlContext.Provider
      value={{
        groups: selectedGroups,
        endpoints: selectedEndpoints,
        endpointIds,
        availableChannels: availableChannels,
        setAvailableChannels: setAvailableChannels,
        availableTemplates: availableTemplates,
        setAvailableTemplates: setAvailableTemplates,
        setGroups: setModifiedGroups,
        hidden,
        disabled,
        allGroups: groups,
        setAllGroups: setGroups,
        setHidden,
        forceChannelChangeMode,
        setForceChannelChangeMode,
        setSelectedGroups,
        requestPayload,
      }}
    >
      {children}
    </ControlContext.Provider>
  );
}

export default ControlContext;
