// @ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import { object, func } from "prop-types";
import { Paper, TextareaAutosize } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

import ControlContext from "contexts/ControlContext";
import AlertsApi from "services/api/4-endpoint-control-api/alerts-api";
import useApi from "hooks/useApi";
import useStyles from "hooks/useStyles";

import { getSnacks } from "../utils/snacks";
import { imageNotLoaded, getTextFromHTMLText } from "../utils/utils";
import { baseUrl } from "data/constants";

import NoAlertSelectedPrompt from "./no-alert-selected-prompt/NoAlertSelectedPrompt";
import EllipsisLoader from "components/ui/loaders/EllipsisLoader";
import AlertSettings from "./alert-settings/AlertSettings";
import SplitButton from "components/ui/buttons/split-button/SplitButton";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./AlertPreview.module.scss";

export default function AlertPreview({
  selectedAlert,
  updateAlerts,
  setSelectedAlert,
}) {
  const { name, image } = selectedAlert;
  const [alertText, setAlertText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t } = useTranslation("control");
  const { requestPayload } = useContext(ControlContext);

  const {
    ALERT_SAVED,
    ALERT_SAVED_ERROR,
    ALERT_SHOWN,
    ALERT_SHOWN_ERROR,
    ALERT_DELETED,
    ALERT_DELETED_ERROR,
  } = getSnacks(t);

  const {
    styles,
    setFunctions,
    getStylesFromHTMLText,
    getStylesFromAlertObject,
    convertStylesToString,
  } = useStyles();

  const { setFontSize } = setFunctions;

  useEffect(() => {
    if (selectedAlert.id) {
      getStylesFromHTMLText(selectedAlert.sampletext);
      getStylesFromAlertObject(selectedAlert);
      setAlertText(getTextFromHTMLText(selectedAlert.sampletext));
      // setFontSize(+selectedAlert.fontsize);
    }
  }, [selectedAlert]);

  const [, saveLoading, , saveAlert] = useApi(AlertsApi.saveEditedAlert, {
    requestOnMount: false,
    onSuccess: updateAlerts,
    successMessage: ALERT_SAVED,
    errorMessage: ALERT_SAVED_ERROR,
  });

  const [, saveAsNewLoading, , saveAlertAsNew] = useApi(
    AlertsApi.saveAlertAsNew,
    {
      requestOnMount: false,
      onSuccess: updateAlerts,
      successMessage: ALERT_SAVED,
    }
  );

  const [, showAlertLoader, , showAlert] = useApi(AlertsApi.showAlert, {
    requestOnMount: false,
    successMessage: ALERT_SHOWN,
    errorMessage: ALERT_SHOWN_ERROR,
  });

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteAlertSuccess = (alerts) => {
    setSelectedAlert({ id: undefined });
    closeDeleteModal();
    return updateAlerts(alerts);
  };

  const [, deleteAlertLoader, , deleteAlert] = useApi(AlertsApi.deleteAlert, {
    requestOnMount: false,
    onSuccess: handleDeleteAlertSuccess,
    successMessage: ALERT_DELETED,
    errorMessage: ALERT_DELETED_ERROR,
  });

  const handleSave = async () => {
    const styleString = convertStylesToString();
    const htmlString = `<div style="position:relative;${styleString}">${alertText}</div>`;
    const payload = {
      alertId: selectedAlert.id,
      text: htmlString,
    };

    await saveAlert(payload);
  };
  const handleShow = async () => {
    await showAlert({ alertId: selectedAlert.id, ...requestPayload });
  };

  const handleSaveAsNew = async () => {
    const styleString = convertStylesToString();
    const htmlString = `<div style="position:relative;${styleString}padding:0 2rem;line-height:1.15;">${alertText}</div>`;
    const payload = {
      alertId: selectedAlert.id,
      text: htmlString,
    };

    await saveAlertAsNew(payload);
  };

  // --- CLICK HANDLERS ---

  const handleSaveAndShow = async () => {
    await handleSave();
    await handleShow();
  };

  const handleDeleteAlert = async () => {
    await deleteAlert(selectedAlert.id);
  };

  const handleDeleteClick = () => {
    openDeleteModal();
  };

  const actions = [
    {
      _key: "alert-save-and-show",
      label: t("alerts.show"),
      onClickHandler: handleSaveAndShow,
    },
    // TODO: N2H Alert features
    // {
    //   _key: "alert-update",
    //   label: t("alerts.update"),
    //   onClickHandler: handleSave
    // },
    // {
    //   _key: "alert-save-as-new",
    //   label: t("alerts.save-as-new"),
    //   onClickHandler: handleSaveAsNew
    // },
    {
      _key: "alert-delete",
      label: t("alerts.delete"),
      onClickHandler: handleDeleteClick,
      type: "delete",
    },
  ];

  const loading =
    saveLoading || saveAsNewLoading || showAlertLoader || deleteAlertLoader;

  return (
    <div className={classes["alert-preview-container"]}>
      <Scrollbars autoHeight autoHeightMin="78vh">
        <Paper className={classes["alert-preview"]}>
          {name ? (
            <div className={classes["alert-preview-editor"]}>
              <div className={classes["alert-preview-editor-image"]}>
                <img
                  className={classes["image"]}
                  src={`${baseUrl}${image}`}
                  alt={""}
                  onError={imageNotLoaded}
                />

                <TextareaAutosize
                  className={classes["alert-preview-editor-textarea"]}
                  value={alertText}
                  onChange={(e) => setAlertText(e.target.value)}
                  style={{
                    fontSize: `${styles["font-size"] / 4}px`,
                    lineHeight: 1.15,
                    color: styles.color || "#fff",
                    textAlign: styles["text-align"],
                    top: `${styles.top / 4}px`,
                    fontFamily: styles["font-family"],
                    fontStyle: styles["font-style"],
                    fontWeight: styles["font-weight"],
                    height: `${styles.height / 4}px`,
                    width: `${styles.width / 4}px`,
                    left: `${styles.left / 4}px`,
                  }}
                />
              </div>
              <div className={classes["alert-preview-editor-buttons"]}>
                {alertText.length > 80 && (
                  <div className={classes["alert-error"]}>
                    {t("alerts.text-too-long")}
                  </div>
                )}
                <div className={classes["loader-save"]}>
                  {loading && <EllipsisLoader />}
                </div>
                <SplitButton
                  disabled={alertText.length > 80}
                  options={actions}
                />
              </div>
            </div>
          ) : (
            <NoAlertSelectedPrompt />
          )}
        </Paper>
        {
          // TODO: N2H Alert features
          /*name && <AlertSettings styles={styles} {...setFunctions} />*/
        }
      </Scrollbars>

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        actionName={t("common:delete")}
        onAction={handleDeleteAlert}
      >
        <span>{t("alerts.delete-confirmation")}</span>
      </ConfirmationModal>
    </div>
  );
}

AlertPreview.propTypes = {
  selectedAlert: object.isRequired,
  setSelectedAlert: func.isRequired,
  updateAlerts: func.isRequired,
};
