import { useState, useEffect } from "react";

import useWindowDimensions from "./useWindowDimensions";

function useOptimalSize() {
  const { height } = useWindowDimensions();
  const [isWindowLarge, setIsWindowLarge] = useState(false);

  useEffect(() => {
    if (height > 722) {
      setIsWindowLarge(true);
    } else {
      setIsWindowLarge(false);
    }
  }, [height]);

  return isWindowLarge;
}

export default useOptimalSize;
