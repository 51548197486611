import React, { useEffect, useState } from "react";
import { func, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";

import ServersApi from "services/api/6-servers-api/servers-api";
import useApi from "hooks/useApi";
import { ROLES } from "../../../../utils/utils";

import CustomCheckbox from "components/ui/inputs/custom-checkbox/CustomCheckbox";
import LoadError from "components/ui/load-error/LoadError";

import classes from "./ServersList.module.scss";

function ServersList({ selectedUser, handleSelectServers }) {
  const [serversList, setServersList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const { SITE_MANAGER, CONTENT_EDITOR } = ROLES;
  const { t } = useTranslation("access-management");

  const onFetchServersSuccess = (data) => {
    if (!!selectedUser.Company) {
      const companyServers = data.filter(
        ({ company }) => company === selectedUser.Company.id
      );
      setServersList(companyServers);
    }
    setVisible(true);
  };

  const [fetchedServers = [], , , fetchServers] = useApi(
    ServersApi.getServers,
    {
      requestOnMount: false,
      onSuccess: onFetchServersSuccess,
      onError: () => setIsError(true),
    }
  );

  const setUpSelect = (role) => {
    if ([SITE_MANAGER, CONTENT_EDITOR].includes(role)) {
      if (!serversList.length) {
        fetchServers();
      }
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    setUpSelect(selectedUser.Role.name);
    if (selectedUser.Company) {
      const companyServers = fetchedServers.filter(
        ({ company }) => company === selectedUser.Company.id
      );
      setServersList(companyServers);
    }
  }, [selectedUser]);

  const handleOnChange = (server) => () => {
    handleSelectServers(server);
  };

  return (
    <>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        <div className={classes["servers-list-container"]}>
          <div className={classes["servers-list-header"]}>
            {t("servers.select-server")}
          </div>
          {serversList.length ? (
            <Scrollbars autoHeight autoHeightMax="11vh">
              <ul className={classes["servers-list"]}>
                {serversList.map((server) => (
                  <li
                    key={server.productKey}
                    className={classes["servers-list-item"]}
                  >
                    <CustomCheckbox
                      inputProps={{ id: server.id }}
                      onChange={handleOnChange(server)}
                      size="small"
                      rootClass={classes["check-box"]}
                      checked={selectedUser.Idtservers.some(
                        (idtServer) => idtServer.id === server.id
                      )}
                    />
                    <span className={classes["server-name"]}>
                      {server.name}
                    </span>
                  </li>
                ))}
              </ul>
            </Scrollbars>
          ) : (
            <div className={classes["no-server-available"]}>
              {t("servers.no-servers-available")}
            </div>
          )}
        </div>
      </Collapse>
      <LoadError open={isError} module="servers" />
    </>
  );
}

ServersList.propTypes = {
  selectedUser: object,
  handleSelectServers: func.isRequired,
};

export default ServersList;
