import React, { useState } from "react";
import { func, bool, object } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ServersApi from "services/api/6-servers-api/servers-api";
import useApi from "hooks/useApi";
import { getSnacks } from "../../../snacks/snacks";
import { SYNC_STATUSES } from "../../../../utils/utils";

import MaintenanceHeader from "./components/header/MaintenanceHeader";
import ServerMGMTButtons from "./components/buttons/ServerMGMTButtons";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";
import SpeedTestTable from "./components/speed-test-table/SpeedTestTable";

import classes from "./ServerMaintenance.module.scss";

function ServerMaintenance({
  openSyncModal,
  loading,
  server,
  setSelectedServer,
  refreshServersList,
}) {
  const [rebootModalOpen, setRebootModalOpen] = useState(false);
  const { id: serverId, isOnline, assetsSynchronizationStatus } = server;
  const { t } = useTranslation("servers");
  const { SERVER_REBOOT_SUCCESS, SERVER_REBOOT_ERROR } = getSnacks(t);
  const { IN_PROGRESS } = SYNC_STATUSES;

  const openRebootModal = () => {
    setRebootModalOpen(true);
  };

  const closeRebootModal = () => {
    setRebootModalOpen(false);
  };

  const onRebootSuccess = () => {
    closeRebootModal();
    setSelectedServer({ ...server, isRebootInProgress: true });
    refreshServersList();
  };

  const [, rebootLoader, , rebootServer] = useApi(ServersApi.rebootServer, {
    requestOnMount: false,
    successMessage: SERVER_REBOOT_SUCCESS,
    errorMessage: SERVER_REBOOT_ERROR,
    onSuccess: onRebootSuccess,
  });

  const handleRebootClick = () => {
    rebootServer(serverId);
  };

  return isOnline && assetsSynchronizationStatus !== IN_PROGRESS ? (
    <div className={classes["server-mgmt"]}>
      <Accordion
        classes={{
          root: classes["server-mgmt-panel"],
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon className={classes["server-mgmt-panel-icon"]} />
          }
          classes={{ root: classes["server-mgmt-panel-summary"] }}
        >
          <MaintenanceHeader loading={loading} />
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes["server-mgmt-panel-details"] }}
        >
          <SpeedTestTable server={server} />
          <ServerMGMTButtons
            server={server}
            openSyncModal={openSyncModal}
            openRebootModal={openRebootModal}
          />
        </AccordionDetails>
      </Accordion>

      <ConfirmationModal
        open={rebootModalOpen}
        onClose={closeRebootModal}
        onAction={handleRebootClick}
        actionName={t("reboot-btn-text")}
        disabled={rebootLoader}
        loading={rebootLoader}
      >
        {t("reboot-server")}
      </ConfirmationModal>
    </div>
  ) : null;
}

ServerMaintenance.propTypes = {
  openSyncModal: func.isRequired,
  loading: bool.isRequired,
  server: object,
  setSelectedServer: func,
  refreshServersList: func,
};

export default ServerMaintenance;
