import React, { useEffect, useState } from "react";
import { object, bool, func } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  MAX_IDTNAME_LEN,
  MAX_IP_LEN,
  MAX_TTL_LEN,
  MAX_PORT_LEN
} from "data/constants";
import ManageChannelsApi from "services/api/3-content-api/playlist-channels-api";
import useApi from "hooks/useApi";
import { playlistChannelsRequestsMessages } from "../../../utils/utils";
import {
  validateRequired,
  validateIDTName,
  getInputError,
  validateIP,
  validateIPPort,
  validateTTL
} from "utils/utils";

import Modal from "components/ui/modals/modal/Modal";
import Form from "components/ui/form/Form";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./BroadcastSettingsModal.module.scss";

const initalSettings = {
  playlistChannelId: undefined,
  channelname: undefined,
  multicastaddress: undefined,
  multicastport: undefined,
  ttl: undefined
};

function BroadcastSettingsModal({
  open,
  onClose,
  channelToBeModified,
  fetchChannels
}) {
  const [settings, setSettings] = useState(initalSettings);
  const {
    handleSubmit,
    register,
    errors,
    formState: { dirty, isValid },
    reset
  } = useForm({
    defaultValues: {
      channelname: channelToBeModified.channelname,
      multicastaddress: channelToBeModified.multicastaddress,
      multicastport: channelToBeModified.multicastport,
      ttl: channelToBeModified.multicastttl
    },
    mode: "onChange"
  });
  const { t } = useTranslation("video-playlists");

  const PREFIX = "sections.playlist-channels";
  const {
    CHANGE_CHANNEL_SETTINGS_SUCCESS,
    CHANGE_CHANNEL_SETTINGS_ERROR
  } = playlistChannelsRequestsMessages(t);

  useEffect(() => {
    const setBroadcastSetting = () => {
      const {
        id: playlistChannelId,
        channelname,
        multicastaddress,
        multicastport,
        multicastttl: ttl
      } = channelToBeModified;

      setSettings({
        playlistChannelId,
        channelname,
        multicastaddress,
        multicastport,
        ttl
      });
    };

    reset({
      channelname: channelToBeModified.channelname,
      multicastaddress: channelToBeModified.multicastaddress,
      multicastport: channelToBeModified.multicastport,
      ttl: channelToBeModified.multicastttl
    });

    setBroadcastSetting();
  }, [channelToBeModified]);

  const onChangeChannelSettingsSuccess = () => {
    fetchChannels();
    onClose();
  };

  const [, settingsLoading, , changeChannelBroadcastSettings] = useApi(
    ManageChannelsApi.changeChannelSettings,
    {
      requestOnMount: false,
      successMessage: CHANGE_CHANNEL_SETTINGS_SUCCESS,
      errorMessage: CHANGE_CHANNEL_SETTINGS_ERROR,
      onSuccess: onChangeChannelSettingsSuccess
    }
  );

  const changeChannelSettings = data => {
    const { playlistChannelId } = settings;
    const payload = {
      playlistChannelId,
      ...data
    };

    changeChannelBroadcastSettings(payload);
  };

  return (
    <Modal open={open} modalClass={classes["broadcast-settings-modal"]}>
      <Modal.Header loading={settingsLoading} onClose={onClose}>
        {t(`${PREFIX}.broadcast-settings`)}
      </Modal.Header>
      <Form onSubmit={handleSubmit(changeChannelSettings)}>
        <Modal.Content>
          <Form.Field horizontal>
            <Form.Label htmlFor="channelname" className={classes["form-label"]}>
              {t(`${PREFIX}.channel-name`)}
            </Form.Label>
            <Form.Input
              // @ts-ignore
              id="channelname"
              name="channelname"
              ref={register({
                ...validateRequired,
                ...validateIDTName
              })}
              error={getInputError(errors.channelname, t, MAX_IDTNAME_LEN)}
            />
          </Form.Field>

          <Form.Field horizontal>
            <Form.Label
              htmlFor="multicastaddress"
              className={classes["form-label"]}
            >
              {t(`${PREFIX}.multicast-address`)}
            </Form.Label>
            <Form.Input
              // @ts-ignore
              id="multicastaddress"
              name="multicastaddress"
              ref={register({
                ...validateRequired,
                ...validateIP
              })}
              error={getInputError(errors.multicastaddress, t, MAX_IP_LEN)}
            />
          </Form.Field>

          <Form.Field horizontal>
            <Form.Label
              htmlFor="multicastport"
              className={classes["form-label"]}
            >
              {t(`${PREFIX}.multicast-port`)}
            </Form.Label>
            <Form.Input
              // @ts-ignore
              id="multicastport"
              name="multicastport"
              ref={register({
                ...validateRequired,
                ...validateIPPort
              })}
              error={getInputError(errors.multicastport, t, MAX_PORT_LEN)}
            />
          </Form.Field>

          <Form.Field horizontal>
            <Form.Label htmlFor="ttl" className={classes["form-label"]}>
              TTL:
            </Form.Label>
            <Form.Input
              // @ts-ignore
              id="ttl"
              name="ttl"
              ref={register({
                ...validateRequired,
                ...validateTTL
              })}
              error={getInputError(errors.ttl, t, MAX_TTL_LEN)}
            />
          </Form.Field>
        </Modal.Content>

        <Modal.Footer>
          <PrimaryButton type="submit" disabled={!dirty || !isValid}>
            {t("common:apply")}
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

BroadcastSettingsModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  channelToBeModified: object,
  fetchChannels: func.isRequired
};

export default BroadcastSettingsModal;
