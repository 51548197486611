import React from "react";
import { bool } from "prop-types";
import SpeedIcon from "@material-ui/icons/Speed";
import { useTranslation } from "react-i18next";

import classes from "./SpeedTestHeader.module.scss";

function SpeedTestHeader({ isTestInProgress }) {
  const { t } = useTranslation("servers");

  return (
    <div className={classes["speed-test-header"]}>
      <SpeedIcon classes={{ root: classes["speed-test-header-icon"] }} />
      <div className={classes["speed-test-header-label"]}>
        {t("speed-test.title")}
      </div>
      {isTestInProgress && (
        <span className={classes["in-progress"]}>
          {t("speed-test.in-progress")}
        </span>
      )}
    </div>
  );
}

SpeedTestHeader.propTypes = {
  isTestInProgress: bool
};

export default SpeedTestHeader;
