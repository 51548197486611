import React from "react";
import clsx from "clsx";
import { bool, string } from "prop-types";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./PanelHeader.module.scss";

function PanelHeader({ label, loading = false }) {
  return (
    <div className={classes["server-mgmt-header"]}>
      <div className={classes["server-mgmt-header-label"]}>{label}</div>
      <div
        className={clsx(classes["server-mgmt-header-loader"], {
          [classes["show-loader"]]: loading
        })}
      >
        <EllipsisLoader />
      </div>
    </div>
  );
}

PanelHeader.propTypes = {
  label: string,
  loading: bool
};

export default PanelHeader;
