import React from "react";
import clsx from "clsx";
import { bool } from "prop-types";

import classes from "./LegendIcon.module.scss";

function LegendIcon({
  offline = false,
  online = false,
  tunersOffline = false,
  tunersOnline = false
}) {
  return (
    <div
      className={clsx(classes["legend-icon"], {
        [classes["legend-icon-offline"]]: offline,
        [classes["legend-icon-online"]]: online,
        [classes["legend-icon-tuners-offline"]]: tunersOffline,
        [classes["legend-icon-tuners-online"]]: tunersOnline
      })}
    />
  );
}

LegendIcon.propTypes = {
  total: bool,
  online: bool,
  tunersOffline: bool,
  tunersOnline: bool
};

export default LegendIcon;
