import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./NoAlertSelectedPrompt.module.scss";

export default function NoAlertSelectedPrompt() {
  const { t } = useTranslation("control");
  return (
    <div className={classes["not-selected"]}>
      <div className={classes["not-selected-text"]}>
        <div className={classes["not-selected-text-header"]}>
          {t("alerts.preview")}
        </div>
        <div className={classes["not-selected-text-info"]}>
          {t("alerts.no-alert-selected-1")}
          <br />
          {t("alerts.no-alert-selected-2")}
        </div>
      </div>
    </div>
  );
}
