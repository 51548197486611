import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { func, object, bool, array } from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { MAX_CHANNEL_NUMBER, MAX_CHANNEL_NUMBER_LEN } from "data/constants";
import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import useApi from "hooks/useApi";
import useOptimalSize from "hooks/useOptimalSize";
import { channelLineupRequestsMessages } from "../../../../utils/utils";
import {
  getInputError,
  validateIDTName,
  validateNumber,
  validateRequired
} from "utils/utils";

import Form from "components/ui/form/Form";
import ChannelLogoPreview from "./channel-logo-preview/ChannelLogoPreview";
import ApplyButton from "./apply-button/ApplyButton";

import classes from "./ChannelInfoForm.module.scss";

function ChannelInfoForm({
  handleSubmit,
  channel,
  register,
  channelLogo,
  openGalleryModal,
  dirty,
  isValid,
  refreshChannelsList,
  resetForm,
  setIsFormDirty,
  setLoading,
  errors,
  channelNumbers
}) {
  const isWindowLarge = useOptimalSize();
  const { t } = useTranslation(["channel-list"]);
  const { CHANNEL_EDIT_SUCCESS, CHANNEL_EDIT_ERROR } =
    channelLineupRequestsMessages(t);
  const [numberAlreadyAssigned, setNumberAlreadyAssigned] = useState(false);
  const history = useHistory();
  const isVPChannel =
    channel.hasOwnProperty("type") && channel["type"] === "VPL Channel";

  const onEditChannelSuccess = response => {
    refreshChannelsList();
    resetForm(response);
    setIsFormDirty(false);
  };

  const [, updateChannelLoading, , updateChannel] = useApi(
    ChannelListApi.updateChannelInfo,
    {
      requestOnMount: false,
      successMessage: CHANNEL_EDIT_SUCCESS,
      errorMessage: CHANNEL_EDIT_ERROR,
      onSuccess: onEditChannelSuccess
    }
  );

  useEffect(() => {
    setLoading(updateChannelLoading);
  }, [updateChannelLoading]);

  useEffect(() => {
    if (numberAlreadyAssigned) {
      setNumberAlreadyAssigned(false);
    }
  }, [channel]);

  const onSubmit = ({ name, lcn }) => {
    const updatedChannel = {
      name,
      lcn: Number(lcn),
      id: channel.id,
      logo: channelLogo.logo
    };
    updateChannel(updatedChannel);
  };

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(classes["form"], {
        [classes["large-window-margin"]]: isWindowLarge
      })}
    >
      <Form.Field horizontal formFieldClass={classes["channel-source-field"]}>
        <Form.Label>{t("channel-source")}</Form.Label>
        <div className={classes["channel-source"]}>{channel.source}</div>
      </Form.Field>

      <Form.Field horizontal formFieldClass={classes["less-margin"]}>
        <Form.Label>{t("channel-name")}</Form.Label>
        <Tooltip
          title={
            isVPChannel ? (
              <span className={classes["tooltip-title"]}>
                {t("tooltips.to-edit-name-go-to-section")}&nbsp;
                <span
                  className={classes["tooltip-title-link"]}
                  onClick={() =>
                    history.push("/content/video-playlist-channels/channels")
                  }
                >
                  {t("common:side-menu.content.video-playlist-channels")}
                </span>
              </span>
            ) : (
              ""
            )
          }
          placement="top-start"
          classes={{ tooltip: classes["tooltip"] }}
          arrow
          interactive
        >
          <div className={classes["input-container"]}>
            <Form.Input
              // @ts-ignore
              type="text"
              name="name"
              ref={register({
                ...validateRequired,
                ...validateIDTName
              })}
              error={getInputError(errors.name, t)}
              readOnly={isVPChannel}
            />
          </div>
        </Tooltip>
      </Form.Field>

      <Form.Field horizontal formFieldClass={classes["less-margin"]}>
        <Form.Label>{t("channel-number")}</Form.Label>

        <Form.Input
          // @ts-ignore
          type="text"
          name="lcn"
          ref={register({
            ...validateRequired,
            maxLength: {
              value: MAX_CHANNEL_NUMBER_LEN,
              message: "common:form.max-length"
            },
            validate: {
              ...validateNumber.validate,
              validatePositive: value =>
                parseInt(value) >= 0 || "warnings.lower-than-zero",
              validateAssigned: value =>
                !channelNumbers.includes(value) ||
                "warnings.number-already-assigned",
              // TODO: 999 or lower warning
              validateMax: value =>
                parseInt(value) <= MAX_CHANNEL_NUMBER ||
                "warnings.lower-than-zero",
              validateNumberAlreadyExists: value =>
                !(
                  channelNumbers.includes(parseInt(value)) &&
                  parseInt(value) !== channel.lcn
                ) || "warnings.number-already-assigned"
            }
          })}
          error={getInputError(errors.lcn, t, MAX_CHANNEL_NUMBER_LEN)}
        />
      </Form.Field>

      <Form.Field horizontal formFieldClass={classes["channel-logo-field"]}>
        <Form.Label>{t("channel-logo")}</Form.Label>
        <ChannelLogoPreview
          logoPath={channelLogo.logoPath}
          openGalleryModal={openGalleryModal}
          title={t("select-logo")}
        />
      </Form.Field>

      <ApplyButton label={t("common:apply")} disabled={!isValid || !dirty} />
    </Form>
  );
}

ChannelInfoForm.propTypes = {
  handleSubmit: func.isRequired,
  channel: object,
  register: func,
  channelLogo: object,
  openGalleryModal: func,
  dirty: bool,
  isValid: bool,
  refreshChannelsList: func,
  resetForm: func.isRequired,
  setIsFormDirty: func.isRequired,
  setLoading: func,
  errors: object,
  channelNumbers: array
};

export default ChannelInfoForm;
