import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Img from "react-image";

import { baseUrl } from "data/constants";
import useTimeout from "hooks/useTimeout";

import PrimaryInput from "components/ui/inputs/primary-input/PrimaryInput";
import ImgUnloader from "components/ui/loaders/img-unloader/ImgUnloader";
import LinearLoader from "components/layout-components/persistentDrawer/components/loader/LinearLoader";

import classes from "./ChannelsSelect.module.scss";

function ChannelsSelect({
  selectedChId,
  onSelectChannel,
  channels = [],
  availableChannels = null,
}) {
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation("control");
  const setTimeOut = useTimeout();

  useEffect(() => {
    setShowLoader(true);
  }, [availableChannels]);

  useEffect(() => {
    if (showLoader) {
      setTimeOut(() => {
        setShowLoader(false);
      }, 1500);
    }
  }, [showLoader]);

  return !!availableChannels && availableChannels.length ? (
    <div className={classes.container}>
      <Select
        value={selectedChId}
        onChange={onSelectChannel}
        classes={{
          root: classes["channels-select-root"],
          select: classes["channels-select"],
          icon: classes["channels-select-icon"],
        }}
        input={<PrimaryInput />}
        disabled={showLoader}
      >
        <MenuItem
          value="none"
          className={classes["channels-select-item"]}
          disabled
        >
          <div className={classes["channels-select-item-title"]}>
            {showLoader ? (
              <span className={classes["circular-loader-container"]}>
                <CircularProgress
                  size={12}
                  classes={{ circle: classes["circular-loader"] }}
                />
                <span className={classes["circular-loader-label"]}>
                  {t("tv-control.loading-channels")}
                </span>
              </span>
            ) : (
              t("tv-control.select-channel")
            )}
          </div>
        </MenuItem>
        {channels
          .filter(
            ({ id }) =>
              !(!!availableChannels && !availableChannels.includes(id))
          )
          .map(({ id, name, logoPath }) => {
            const imgUrl = `${baseUrl}${logoPath}`;

            return (
              <MenuItem
                key={id}
                className={classes["channels-select-item"]}
                value={id}
              >
                <Img
                  src={imgUrl}
                  className={classes["channels-select-item-img"]}
                  unloader={<ImgUnloader />}
                />
                <span className={classes["channels-select-item-title"]}>
                  {name}
                </span>
              </MenuItem>
            );
          })}
      </Select>
      <div className={classes["linear-loader"]}>
        <LinearLoader progressBar={showLoader} />
      </div>
    </div>
  ) : (
    <div className={classes["no-channels"]}>
      <span>{t("tv-control.no-channels-available")}</span>
    </div>
  );
}

export default ChannelsSelect;
