import React from "react";

import MessagingApi from "services/api/4-endpoint-control-api/messaging-api";
import useApi from "hooks/useApi";
import { getSnacks } from "./components/snacks/snacks";

import SavedMessages from "./components/saved-messages/SavedMessages";

export default function Messaging() {
  const { MESSAGES_LOAD_ERROR } = getSnacks();

  const [messages = {}] = useApi(MessagingApi.getAllMessages, {
    errorMessage: MESSAGES_LOAD_ERROR
  });

  return <SavedMessages messages={messages} />;
}
