import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { object, func } from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Entity from "./components/entity/Entity";

import classes from "./Location.module.scss";

function Location({ location, handleLocationClick }) {
  const { t } = useTranslation("dashboard");
  const {
    isServerOnline,
    totalGateways,
    gatewaysOnline,
    totalEndpoints,
    endpointsOnline,
    totalEncoders,
    encodersOnline,
    Idtserver,
    updatedAt
  } = location;

  const handleClick = tab => e => {
    e.stopPropagation();
    handleLocationClick(Idtserver, tab);
  };

  return (
    <Card className={classes["location"]} onClick={handleClick(0)}>
      <CardHeader
        title={Idtserver.name}
        subheader={`${t("table.location.last-update")}: ${moment(updatedAt)
          .local()
          .format("YYYY-MM-DD HH:mm")}`}
        classes={{
          subheader: classes["location-subheader"],
          title: classes["location-title"]
        }}
      />
      <CardContent>
        <Entity
          name={t("modal.tabs.server")}
          server
          isServerOnline={isServerOnline}
          handleClick={handleClick(0)}
        />
        <Entity
          name={t("modal.tabs.gateways")}
          total={totalGateways}
          online={gatewaysOnline}
          handleClick={handleClick(1)}
        />
        <Entity
          name={t("modal.tabs.encoders")}
          total={totalEncoders}
          online={encodersOnline}
          handleClick={handleClick(2)}
        />
        <Entity
          name={t("modal.tabs.endpoints")}
          total={totalEndpoints}
          online={endpointsOnline}
          handleClick={handleClick(3)}
        />
      </CardContent>
    </Card>
  );
}

Location.propTypes = {
  location: object,
  handleLocationClick: func
};

export default Location;
