import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LoadingContext from "contexts/LoadingContext";
import usePageFeatures from "hooks/usePageFeatures";
import { permissions } from "data/permissions";

import PageHeader from "components/ui/page-header/PageHeader";
import LocationModal from "./components/location-modal/LocationModal";
import SuperAdminDashboard from "./components/super-admin-dashboard/SuperAdminDashboard";
import CompanyUserDashboard from "./components/company-user-dashboard/CompanyUserDashboard";

function Dashboard() {
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [selectedLocationServer, setSelectedLocationServer] = useState({
    id: null
  });
  const [tabToBeOpened, setTabToBeOpened] = useState(0);
  const { setLoading: setProgressBarLoading } = useContext(LoadingContext);
  const { t } = useTranslation(["dashboard", "access-management"]);
  const pageFeatures = usePageFeatures({
    accessToSuperAdminDashboard: permissions.companies.COMPANIES_GET
  });

  const openLocationModal = () => {
    setLocationModalOpen(true);
  };

  const closeLocationModal = () => {
    setLocationModalOpen(false);
    setSelectedLocationServer({ id: null });
  };

  const handleLocationClick = (server, tab) => {
    setSelectedLocationServer(server);
    setTabToBeOpened(tab);
  };

  useEffect(() => {
    if (selectedLocationServer.id) {
      openLocationModal();
    }
  }, [selectedLocationServer, tabToBeOpened]);

  useEffect(() => {
    setProgressBarLoading(true);
  }, []);

  const renderDashboard = () => {
    if (pageFeatures) {
      const { accessToSuperAdminDashboard } = pageFeatures;

      if (accessToSuperAdminDashboard) {
        return (
          <SuperAdminDashboard
            setProgressBarLoading={setProgressBarLoading}
            handleLocationClick={handleLocationClick}
          />
        );
      } else {
        return (
          <CompanyUserDashboard
            setProgressBarLoading={setProgressBarLoading}
            handleLocationClick={handleLocationClick}
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      <PageHeader title={t("dashboard")} />
      {renderDashboard()}

      <LocationModal
        open={locationModalOpen}
        onClose={closeLocationModal}
        server={selectedLocationServer}
        tabToBeOpened={tabToBeOpened}
      />
    </>
  );
}

export default Dashboard;
