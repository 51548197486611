import React, { useState, useEffect } from "react";
import { bool, string } from "prop-types";

import useDebounce from "hooks/useDebounce";

import classes from "./NoUsersInfo.module.scss";

function NoUsersInfo({ apiLoadSuccessful, info }) {
  const [apiLoaded, setApiLoaded] = useState(false);
  const apiLoadedDebounced = useDebounce(apiLoaded, 2000);

  useEffect(() => {
    setApiLoaded(apiLoadSuccessful);
  }, [apiLoadSuccessful]);

  return apiLoadedDebounced ? (
    <div className={classes["no-users"]}>{info}</div>
  ) : null;
}

NoUsersInfo.propTypes = { apiLoadSuccessful: bool, info: string };

export default NoUsersInfo;
