import React from "react";
import { bool, string, func } from "prop-types";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./RunTestButton.module.scss";

function RunTestButton({ isVisible, label, onClick }) {
  return (
    <div className={classes["run-test-button-container"]}>
      {isVisible && (
        <PrimaryButton
          onClick={onClick}
          primaryButtonClass={classes["run-button"]}
        >
          {label}
        </PrimaryButton>
      )}
    </div>
  );
}

RunTestButton.propTypes = {
  isVisible: bool,
  label: string,
  onClick: func
};

export default RunTestButton;
