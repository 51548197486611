import React from "react";
import { bool, string, func } from "prop-types";
import clsx from "clsx";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import NotInterestedSharpIcon from "@material-ui/icons/NotInterestedSharp";

import classes from "../../UploadComponent.module.scss";

function UploadVideoIcon({ isDragActive, showWarning, prefix, t }) {
  const getIconText = () =>
    !isDragActive
      ? showWarning
        ? t(`${prefix}.not-a-video`)
        : t(`${prefix}.upload-video`)
      : t(`${prefix}.drop-file-here`);

  return (
    <div
      className={clsx(classes["upload-video-component-icon"], {
        [classes["upload-video-component-icon-active"]]: isDragActive
      })}
    >
      {!showWarning ? (
        <CloudUploadIcon className={classes["icon-image"]} />
      ) : (
        <NotInterestedSharpIcon
          className={clsx(classes["icon-image"], classes["warning"])}
        />
      )}
      <div
        className={clsx(classes["icon-text"], {
          [classes["icon-text-active"]]: isDragActive,
          [classes["warning"]]: showWarning
        })}
      >
        {getIconText()}
      </div>
    </div>
  );
}

UploadVideoIcon.propTypes = {
  isDragActive: bool.isRequired,
  showWarning: bool.isRequired,
  prefix: string.isRequired,
  t: func.isRequired
};

export default UploadVideoIcon;
