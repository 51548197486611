import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import SettingsApi from "services/api/settings-api/settings-api";
import ChannelsContext from "contexts/ChannelsContext";
import useApi from "hooks/useApi";
import { tvControlSetRequestsMessages } from "../../utils/utils";

import SubRouteContainer from "../ui/sub-route-container/SubRouteContainer";
import CustomPaper from "../ui/custom-paper/CustomPaper";
import PaperContent from "../ui/custom-paper/paper-content/PaperContent";
import PaperLabel from "../ui/custom-paper/paper-label/PaperLabel";
import PrimarySwitch from "components/ui/switch/primary-switch/PrimarySwitch";
import ActionButtons from "../ui/custom-paper/action-buttons/ActionButtons";
import ChannelsSelect from "../ui/channels-select/ChannelsSelect";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import Timer from "components/ui/inputs/timer/Timer";

import classes from "../../ControlPanel.module.scss";

function ForceChannelChange() {
  const [selectedChannelId, setSelectedChannelId] = useState("none");
  const [forceFullscreen, setForceFullscreen] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isTimerValid, setIsTimerValid] = useState(false);

  const { channels, setChannels } = useContext(ChannelsContext);
  const availableChannels = channels.map(({ id }) => id);

  const { t } = useTranslation("control");
  const {
    GET_CHANNELS_ERROR,
    SET_CHANNEL_SUCCESS,
    RETURN_TO_ORIGINAL_CHANNEL,
  } = tvControlSetRequestsMessages(t);

  useApi(ChannelListApi.getEnabledChannels, {
    errorMessage: GET_CHANNELS_ERROR,
    onSuccess: setChannels,
    requestOnMount: channels.length === 0,
  });

  const [, channelChangeLoading, , changeChannel] = useApi(
    SettingsApi.forceChannelChange,
    {
      requestOnMount: false,
      successMessage: SET_CHANNEL_SUCCESS,
    }
  );

  const [, returnToOriginalChannelLoading, , retunrToOriginalChannel] = useApi(
    SettingsApi.returnOrginalChannels,
    {
      requestOnMount: false,
      successMessage: RETURN_TO_ORIGINAL_CHANNEL,
    }
  );

  const handleSelectChannel = (e) => {
    const channelId = e.target.value || "";

    setSelectedChannelId(channelId);
  };
  const handleForceChannelChangeApply = () => {
    const changeChannelTime = hours * 60 + minutes;

    const channel = channels.find(
      (channel) => channel.id === selectedChannelId
    );

    const payload = {
      channelIp: channel ? channel.address : null,
      minutes: changeChannelTime,
      fullscreen: forceFullscreen,
    };

    changeChannel(payload);
  };
  const handleReturnToOriginalChannelApply = () => {
    retunrToOriginalChannel();
  };
  return (
    <SubRouteContainer>
      <CustomPaper loading={channelChangeLoading} dark>
        <PaperContent>
          <ChannelsSelect
            selectedChId={selectedChannelId}
            onSelectChannel={handleSelectChannel}
            channels={channels}
            availableChannels={availableChannels}
          />
          <div>
            <PrimarySwitch
              label={t("force-channel-change.force-fullscreen")}
              checked={forceFullscreen}
              onChange={(_, checked) => setForceFullscreen(checked)}
            />
          </div>
        </PaperContent>
        <PaperContent>
          <div className={classes["force-change-channel-for"]}>
            <PaperLabel label={t("force-channel-change.change-channel-for")} />
            <div className={classes["force-change-channel-timers"]}>
              <Timer
                hours={hours}
                minutes={minutes}
                setHours={setHours}
                setMinutes={setMinutes}
                setIsTimerValid={setIsTimerValid}
                maxNumberOfHours="99"
              />
            </div>
          </div>
          <ActionButtons>
            <PrimaryButton
              onClick={handleForceChannelChangeApply}
              disabled={
                channelChangeLoading ||
                selectedChannelId === "none" ||
                !isTimerValid
              }
            >
              {t("common:apply")}
            </PrimaryButton>
          </ActionButtons>
        </PaperContent>
        <PaperContent>
          <PaperLabel
            label={t("force-channel-change.return-to-original-channel")}
            loading={returnToOriginalChannelLoading}
            centered
          />
          <PrimaryButton
            onClick={handleReturnToOriginalChannelApply}
            disabled={returnToOriginalChannelLoading}
          >
            {t("common:apply")}
          </PrimaryButton>
        </PaperContent>
      </CustomPaper>
    </SubRouteContainer>
  );
}

export default ForceChannelChange;
