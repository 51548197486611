import React from "react";
import { bool } from "prop-types";
import Loader from "react-loader-spinner";

import classes from "./GalleryLoader.module.scss";

function GalleryLoader({ isVisible }) {
  return (
    <>
      {isVisible && (
        <div className={classes["loader-container"]}>
          <div className={classes["loader"]}>
            <Loader type="ThreeDots" color="#00BFFF" height={200} width={200} />
          </div>
        </div>
      )}
    </>
  );
}

GalleryLoader.propTypes = {
  isVisible: bool.isRequired
};

export default GalleryLoader;
