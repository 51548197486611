import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import AccessManagementApi from "services/api/access-management-api/access-management-api";
import useApi from "hooks/useApi";
import {
  usersRequestsMessages,
  selectedUserInitial,
  ROLES
} from "./utils/utils";
import LoadingContext from "contexts/LoadingContext";

import UsersTable from "./components/users-table/UsersTable";
import EditSelectedUser from "./components/edit-selected-user/EditSelectedUser";
import WarningModal from "./components/warning-modal/WarningModal";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./Users.module.scss";

function Users() {
  const [roles, setRoles] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(selectedUserInitial);
  const [open, setOpen] = useState(true);
  const [edited, setEdited] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [warningInfo, setWarningInfo] = useState("");
  const { t } = useTranslation("access-management");
  const {
    GET_USERS_ERROR,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR
  } = usersRequestsMessages(t);
  const { setLoading: setProgressBarLoading } = useContext(LoadingContext);
  const [apiLoadSuccessful, setApiLoadSuccessful] = useState(false);
  const { CUSTOMER, SITE_MANAGER, CONTENT_EDITOR } = ROLES;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openWarningModal = () => {
    setWarningModalOpen(true);
  };

  const closeWarningModal = () => {
    setWarningModalOpen(false);
    setWarningInfo("");
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const resetSelectedUser = () => {
    setSelectedUser(selectedUserInitial);
  };

  const toggleSelectedUser = user => {
    if (!selectedUser.fullName) {
      setSelectedUser(user);
    } else if (selectedUser.id === user.id) {
      setSelectedUser(selectedUserInitial);
    } else {
      setSelectedUser(user);
    }
  };

  const hideProgressBar = () => {
    setProgressBarLoading(false);
  };

  const onFetchRolesSuccess = data => {
    setRoles(data.filter(role => role.name !== "DEALER"));
    setApiLoadSuccessful(true);
    hideProgressBar();
  };

  const onEditUserSuccess = () => {
    setEdited(false);
    fetchUsers();
  };

  const onDeleteUserSuccess = () => {
    setSelectedUser(selectedUserInitial);
    closeConfirmationModal();
    fetchUsers();
  };

  const onFetchUsersSuccess = users => {
    setAllUsers(users);

    if (!!selectedUser.id) {
      const fetchedSelectedUser = users.find(
        ({ id }) => id === selectedUser.id
      );
      if (!!fetchedSelectedUser) {
        setSelectedUser(fetchedSelectedUser);
      }
    }
  };

  const [, , , fetchRoles] = useApi(AccessManagementApi.getRoles, {
    requestOnMount: false,
    onSuccess: onFetchRolesSuccess,
    onError: hideProgressBar
  });

  const [, , , fetchUsers] = useApi(AccessManagementApi.getUsers, {
    requestOnMount: false,
    errorMessage: GET_USERS_ERROR,
    onSuccess: onFetchUsersSuccess,
    onError: hideProgressBar
  });

  const [, editUserLoader, , editUser] = useApi(AccessManagementApi.editUser, {
    requestOnMount: false,
    successMessage: EDIT_USER_SUCCESS,
    errorMessage: EDIT_USER_ERROR,
    onSuccess: onEditUserSuccess
  });

  const [, deleteUserLoader, , deleteUser] = useApi(
    AccessManagementApi.deleteUser,
    {
      requestOnMount: false,
      successMessage: DELETE_USER_SUCCESS,
      errorMessage: DELETE_USER_ERROR,
      onSuccess: onDeleteUserSuccess
    }
  );

  const sendEditUserRequest = data => {
    const payload = {
      userId: selectedUser.id,
      companyId: selectedUser.Company ? selectedUser.Company.id : null,
      dealerId: null,
      roleId: selectedUser.Role.id,
      servers: selectedUser.Idtservers.map(server => server.id),
      ...data
    };

    editUser(payload);
  };

  const onEditUserSubmit = data => {
    const noCompanySelected = t("users.modal.warning-info-companies");

    if (selectedUser.Role.name === CUSTOMER) {
      if (!selectedUser.Company) {
        setWarningInfo(noCompanySelected);
      } else {
        sendEditUserRequest(data);
      }
    }

    if ([SITE_MANAGER, CONTENT_EDITOR].includes(selectedUser.Role.name)) {
      if (!selectedUser.Company) {
        setWarningInfo(noCompanySelected);
      } else {
        sendEditUserRequest(data);
      }
    }
  };

  const handleDeleteUser = () => {
    deleteUser(selectedUser.id);
  };

  useEffect(() => {
    setProgressBarLoading(true);
    if (!allUsers.length) {
      fetchUsers();
      fetchRoles();
    }
  }, []);

  useEffect(() => {
    if (selectedUser.fullName) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (warningInfo) {
      openWarningModal();
    } else {
      closeWarningModal();
    }
  }, [warningInfo]);

  return (
    <>
      <div className={classes.container}>
        <UsersTable
          users={allUsers}
          selectedUser={selectedUser}
          toggleSelectedUser={toggleSelectedUser}
          open={open}
          apiLoadSuccessful={apiLoadSuccessful}
        />
        <EditSelectedUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          roles={roles}
          resetSelectedUser={resetSelectedUser}
          loading={editUserLoader}
          onEditUserSubmit={onEditUserSubmit}
          edited={edited}
          setEdited={setEdited}
        />
      </div>

      <WarningModal
        open={warningModalOpen}
        onClose={closeWarningModal}
        info={warningInfo}
      />

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onAction={handleDeleteUser}
        actionName={t("common:delete")}
        disabled={deleteUserLoader}
        loading={deleteUserLoader}
      >
        {t("users.modal.delete-user")}
      </ConfirmationModal>
    </>
  );
}

export default Users;
