import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import useApi from "hooks/useApi";
import LoadingContext from "contexts/LoadingContext";
import { channelLineupRequestsMessages } from "./utils/utils";
import { CHANNEL_TYPES } from "./utils/utils";

import PageHeader from "components/ui/page-header/PageHeader";
import ChannelInformation from "./components/ChannelInformation/ChannelInformation";
import ChannelPreview from "./components/ChannelPreview/ChannelPreview";
import ChannelRenumberModal from "./components/ChannelRenumberModal/ChannelRenumberModal";
import ChannelDnDList from "./components/ChannelDnDList/ChannelDnDList";

import classes from "./ChannelList.module.scss";

const channelToEditIntial = { id: null, name: null, type: "" };

function ChannelList() {
  const [channels, setChannels] = useState([]);
  const [channelToEdit, setChannelToEdit] = useState(channelToEditIntial);
  const [listRefreshed, refreshList] = useState(false);
  const [open, setOpen] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [preview, setPreview] = useState({
    previewImage: null,
    previewInfo: null
  });
  const { setLoading: setProgressBarLoading } = useContext(LoadingContext);
  const { t } = useTranslation("channel-list");
  const {
    GET_CHANNELS_ERROR,
    CHANNELS_RENUMBER_SUCCESS,
    CHANNELS_RENUMBER_ERROR
  } = channelLineupRequestsMessages(t);
  const [apiLoadSuccessful, setApiLoadSuccessful] = useState(false);
  const channelNumbers = channels.map(({ lcn }) => lcn);
  const { TV, ENCODER, UDP, VLP_CHANNEL } = CHANNEL_TYPES;

  const hideLoadingBar = () => {
    setProgressBarLoading(false);
  };

  const onChannelsLoadSuccess = data => {
    setChannels(data);
    setApiLoadSuccessful(true);
    hideLoadingBar();
  };

  const [, , , fetchChannels] = useApi(ChannelListApi.getEnabledChannels, {
    errorMessage: GET_CHANNELS_ERROR,
    onSuccess: onChannelsLoadSuccess,
    onError: hideLoadingBar
  });

  useEffect(() => {
    setProgressBarLoading(true);
  }, []);

  const togglePopUp = () => {
    setShowPopUp(prev => !prev);
  };

  const onRenumberChannelsSuccess = () => {
    togglePopUp();
    fetchChannels();
  };

  const [, renumberLoading, , renumberChannels] = useApi(
    ChannelListApi.renumberChannel,
    {
      requestOnMount: false,
      successMessage: CHANNELS_RENUMBER_SUCCESS,
      errorMessage: CHANNELS_RENUMBER_ERROR,
      onSuccess: onRenumberChannelsSuccess,
      onError: togglePopUp
    }
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchChannels();
  }, [listRefreshed]);
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (channelToEdit.id) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [channelToEdit]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const refreshChannelsList = () => {
    refreshList(!listRefreshed);
  };

  const resetChannelToEdit = () => {
    setChannelToEdit(channelToEditIntial);
  };

  const handleChannelToEdit = selectedChannelData => {
    const { channel, previewImage, previewInfo } = selectedChannelData;
    const previewData = {
      previewImage,
      previewInfo
    };

    if (!channelToEdit.id) {
      setChannelToEdit(channel);
      setPreview(previewData);
    } else if (channelToEdit.id && channelToEdit.id === channel.id) {
      setChannelToEdit(channelToEditIntial);
    } else {
      setChannelToEdit(channel);
      setPreview(previewData);
    }
  };

  return (
    <>
      <PageHeader
        title={t("channel-list")}
        open={open}
        onClick={togglePopUp}
        butttonLabel={t("renumber-channels")}
        apiLoadSuccessful={apiLoadSuccessful}
      />

      {channels.length && (
        <div className={classes.container}>
          <ChannelDnDList
            channels={channels}
            channelToEdit={channelToEdit}
            onSelectChannelToEdit={handleChannelToEdit}
            open={open}
          />
          <ChannelInformation
            channel={channelToEdit}
            refreshChannelsList={refreshChannelsList}
            resetChannelToEdit={resetChannelToEdit}
            channelNumbers={channelNumbers}
          >
            {channelToEdit.id &&
            [TV, ENCODER, UDP, VLP_CHANNEL].includes(channelToEdit.type) ? (
              <ChannelPreview channel={channelToEdit} preview={preview} />
            ) : null}
          </ChannelInformation>
        </div>
      )}

      <ChannelRenumberModal
        showPopUp={showPopUp}
        togglePopUp={togglePopUp}
        renumberChannels={renumberChannels}
        loading={renumberLoading}
        numberOfChannels={channels.length}
      />
    </>
  );
}

export default ChannelList;
