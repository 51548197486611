import React from "react";
import { useTranslation } from "react-i18next";
import { arrayOf, object, func, bool } from "prop-types";
import clsx from "clsx";

import ListDrawer from "components/ui/drawers/ListDrawer";
import Table from "components/ui/table/Table";
import NoUsersInfo from "./components/no-users-info/NoUsersInfo";

import classes from "./UsersTable.module.scss";

function UsersTable({
  users,
  selectedUser,
  toggleSelectedUser,
  open,
  apiLoadSuccessful
}) {
  const { t } = useTranslation("access-management");

  const handleUserSelection = user => {
    toggleSelectedUser(user);
  };

  return (
    <ListDrawer open={open}>
      {apiLoadSuccessful && users.length ? (
        <Table fullWidth>
          <Table.Head>
            <th className={classes["table-head-5"]}>&nbsp;</th>
            <th className={classes["table-head-20"]}>
              {t("users.table.full-name")}
            </th>
            <th className={classes["table-head-35"]}>Email</th>
            <th className={classes["table-head-20"]}>
              {t("users.table.role")}
            </th>
            <th className={classes["table-head-20"]}>
              {t("users.table.company")}
            </th>
          </Table.Head>
          <Table.Body>
            {users.map((user, index) => {
              const selected = user.id === selectedUser.id;
              return (
                <Table.Row key={user.id} selected={selected}>
                  <td className={classes["table-cell"]} align="center">
                    {index + 1}
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={() => handleUserSelection(user)}
                  >
                    <div
                      className={clsx(classes["user-name"], {
                        [classes["user-name-edit-mode"]]: !open
                      })}
                    >
                      {user.fullName}
                    </div>
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={() => handleUserSelection(user)}
                  >
                    <div
                      className={clsx(classes["user-email"], {
                        [classes["user-email-edit-mode"]]: !open
                      })}
                    >
                      {user.emailAddress}
                    </div>
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={() => handleUserSelection(user)}
                  >
                    {user.Role.name !== "DEALER" ? (
                      t(
                        `users.roles.${user.Role.name
                          .replace("_", "-")
                          .toLowerCase()}`
                      )
                    ) : (
                      <span style={{ color: "crimson" }}>
                        Dealer{" "}
                        <span style={{ fontSize: 10, fontStyle: "italic" }}>
                          (to be removed)
                        </span>
                      </span>
                    )}
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={() => handleUserSelection(user)}
                  >
                    <span className={classes.company}>
                      {user.Company ? user.Company.name : ""}
                    </span>
                  </td>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : apiLoadSuccessful ? (
        <NoUsersInfo
          apiLoadSuccessful={apiLoadSuccessful}
          info={t("users.table.no-users-available")}
        />
      ) : null}
    </ListDrawer>
  );
}

UsersTable.propTypes = {
  users: arrayOf(object),
  selectedUser: object,
  toggleSelectedUser: func,
  open: bool,
  apiLoadSuccessful: bool
};

export default UsersTable;
