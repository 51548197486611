import React from "react";
import clsx from "clsx";

import Loader from "react-loader-spinner";

import classes from "./ImgLoader.module.scss";

function ImgLoader({ loaderClass = null }) {
  return (
    <div
      className={clsx(classes["loader"], {
        [loaderClass]: loaderClass
      })}
    >
      <Loader type="ThreeDots" color="#56636b" height={30} width={30} />
    </div>
  );
}

export default ImgLoader;
