import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import GroupsTemplatesTable from "./components/table/GroupsTemplatesTable";
import EndpointGroupsApi from "../../../services/api/2-system-manage-api/endpoint-groups-api";
import AssignTemplatesApi from "../../../services/api/2-system-manage-api/assign-templates-api";

import LoadingContext from "contexts/LoadingContext";
import { assignTemplatesRequestMessages } from "./utils/utils";
import useApi from "hooks/useApi";

function AssignTemplates() {
  const [allTemplates, setAllTemplates] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const { t } = useTranslation("assign-pages");

  const { GET_TEMPLATES_ERROR, GET_ENDPOINTS_ERROR } =
    assignTemplatesRequestMessages(t);

  const [groups = [], groupsLoading, , fetchGroups] = useApi(
    EndpointGroupsApi.getEndpointGroups,
    {
      requestOnMount: false,
      errorMessage: GET_ENDPOINTS_ERROR
    }
  );

  const [templates = [], templatesLoading, , fetchTemplates] = useApi(
    AssignTemplatesApi.getAllTemplates,
    {
      errorMessage: GET_TEMPLATES_ERROR,
      onSuccess: fetchGroups
    }
  );

  useEffect(() => {
    if (templates.length) {
      const templatesReversed = templates.reverse();
      setAllTemplates(templatesReversed);
    }
  }, [templates]);

  useEffect(() => {
    setLoading(templatesLoading || groupsLoading);
  }, [templatesLoading, groupsLoading, setLoading]);

  return (
    !!allTemplates.length &&
    !!groups.length && (
      <GroupsTemplatesTable
        templates={allTemplates}
        groups={groups}
        refreshTable={fetchTemplates}
      />
    )
  );
}

export default AssignTemplates;
