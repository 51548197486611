import React from "react";
import { bool, func } from "prop-types";
import { Tooltip } from "@material-ui/core";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import classes from "./MoreOptionsIcon.module.scss";

function MoreOptionsIcon({ handleMoreIconClick, updating }) {
  const { t } = useTranslation("video-playlists");

  return (
    <Tooltip
      title={
        updating
          ? t("sections.playlists.tooltips.playlist-updating-cannot-be-edited")
          : ""
      }
      placement="left"
      classes={{ tooltip: classes["tooltip"] }}
    >
      <div
        className={clsx(classes["more-icon-container"], {
          [classes["disabled"]]: updating
        })}
      >
        <MoreVertSharpIcon
          className={classes["more-icon"]}
          onClick={handleMoreIconClick}
        />
      </div>
    </Tooltip>
  );
}

MoreOptionsIcon.propTypes = {
  playlistPlaying: bool,
  playlistAssignedToChannel: bool,
  handleMoreIconClick: func.isRequired,
  updating: bool
};

export default MoreOptionsIcon;
