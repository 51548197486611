import React, { useState, useEffect } from "react";
import { bool, func, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { MAX_CHANNEL_NUMBER } from "data/constants";

import Modal from "components/ui/modals/modal/Modal";
import Form from "components/ui/form/Form";
import InputArrows from "./components/input-arrows/InputArrows";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import ErrorLine from "./components/error-line/ErrorLine";

import classes from "./ChannelRenumberModal.module.scss";

function ChannelRenumberModal({
  showPopUp,
  togglePopUp,
  renumberChannels,
  loading,
  numberOfChannels
}) {
  const [open, setOpen] = useState(false);
  const [maxRenumberValue, setMaxRenumberValue] = useState(null);
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation("channel-list");
  const { handleSubmit, register, getValues, setValue } = useForm({
    defaultValues: {
      renumber: 0
    }
  });
  const PLUS = "plus";
  const MINUS = "minus";
  const MAX_ACCEPTABLE_CHANNEL_NUMBER =
    MAX_CHANNEL_NUMBER - numberOfChannels + 1;

  useEffect(() => {
    setOpen(showPopUp);
    setMaxRenumberValue(MAX_ACCEPTABLE_CHANNEL_NUMBER);
    setShowError(false);
  }, [showPopUp, MAX_ACCEPTABLE_CHANNEL_NUMBER]);

  const handleClose = () => {
    togglePopUp();
  };

  const handleRenumberChannels = firstChannelNumber => {
    renumberChannels(+firstChannelNumber);
  };

  const handlePlusClick = value => {
    if (value === MAX_ACCEPTABLE_CHANNEL_NUMBER) {
      setValue("renumber", MAX_ACCEPTABLE_CHANNEL_NUMBER);
    } else {
      setValue("renumber", value + 1);
    }
  };

  const handleMinusClick = value => {
    if (value === 0) {
      setValue("renumber", 0);
    } else {
      setValue("renumber", value - 1);
    }
  };

  const handleArrowClick = arrow => {
    const currentValue = Number(getValues().renumber);

    if (arrow === PLUS) {
      handlePlusClick(currentValue);
    }
    if (arrow === MINUS) {
      handleMinusClick(currentValue);
    }
  };

  const handlePressEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentValue = getValues().renumber;
      handleRenumberChannels(currentValue);
    }
  };

  const sanitizeInput = e => {
    let val = e.target.value.slice(0, 3); // maximum 3 digits

    if (e.target.value.length > 3) {
      setValue("renumber", parseInt(val));
      return;
    }

    let num = parseInt(val) || 0; // 0 if value is not valid int
    if (num < 0) num = 0; // minimum 0
    if (num > maxRenumberValue) {
      num = maxRenumberValue;
      setShowError(true);
    } // maximum: 999 - number of channels
    setValue("renumber", num); // update with sanitized value
    if (showError) setShowError(false);
  };

  const onSubmit = ({ renumber }) => {
    handleRenumberChannels(renumber);
  };

  return (
    <Modal open={open}>
      <Modal.Header loading={loading} onClose={handleClose}>
        {t("renumber-channels")}
      </Modal.Header>

      <Modal.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field
            horizontal
            formFieldClass={classes["renumber-form-field"]}
          >
            <Form.Label formLabelClass={classes["renumber-label"]}>
              {t("renumber-channels-starting-at")}
            </Form.Label>
            <Form.Input
              // @ts-ignore
              id="renumber"
              name="renumber"
              ref={register({
                required: "Required"
              })}
              endAdornment={<InputArrows handleArrowClick={handleArrowClick} />}
              formInputClass={classes["renumber-input"]}
              onKeyPress={handlePressEnter}
              onInput={sanitizeInput}
              inputProps={{ type: "number" }}
            />
          </Form.Field>

          <ErrorLine
            showError={showError}
            maxRenumberValue={maxRenumberValue}
          />
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <PrimaryButton type="submit" onClick={handleSubmit(onSubmit)}>
          {t("common:apply")}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

ChannelRenumberModal.propTypes = {
  showPopUp: bool.isRequired,
  togglePopUp: func.isRequired,
  renumberChannels: func.isRequired,
  loading: bool,
  numberOfChannels: number
};

export default ChannelRenumberModal;
