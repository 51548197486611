import React from "react";
import { func, bool, string } from "prop-types";
import { useTranslation } from "react-i18next";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ShowMessageButtons.module.scss";

function ShowMessageButtons({
  handleSaveAndShow,
  handleShow,
  loading,
  newMessage,
  isTimerValid,
  bothZero,
  isChecked,
  isMessageTooLong
}) {
  const { t } = useTranslation("control");
  const disabled = isChecked
    ? loading || !newMessage || !isTimerValid || bothZero || isMessageTooLong
    : !newMessage || isMessageTooLong;

  return (
    <div className={classes["show-message-buttons"]}>
      <PrimaryButton
        type="submit"
        onClick={handleSaveAndShow}
        disabled={disabled}
      >
        {t("messages.save-as-new-and-show")}
      </PrimaryButton>
      <PrimaryButton
        type="submit"
        onClick={handleShow}
        disabled={disabled}
        primaryButtonClass={classes["space"]}
      >
        {t("messages.show")}
      </PrimaryButton>
    </div>
  );
}

ShowMessageButtons.propTypes = {
  handleSaveAndShow: func.isRequired,
  handleShow: func.isRequired,
  loading: bool,
  newMessage: string,
  isTimerValid: bool,
  bothZero: bool,
  isChecked: bool,
  isMessageTooLong: bool
};

export default ShowMessageButtons;
