import axios from "axios";
import { Cookies } from "react-cookie";
import { getTokenFromCookie } from "utils/utils";
import { baseUrl } from "../data/constants";
const cookies = new Cookies();
const path = "/api/v1/user/login";

class Auth {
  constructor() {
    this.loginStatus = false;
  }

  setTokenInCookie = token => {
    cookies.set("token", token);
  };

  removeToken = () => {
    cookies.remove("token");
  };

  setUserInStorage = user => {
    localStorage.setItem("userDetails", JSON.stringify(user));
  };

  clearLocalStorage = () => {
    localStorage.removeItem("serverInUse");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("i18nextLng");
  };

  sendRequest = (email, password) =>
    axios({
      method: `post`,
      url: `${baseUrl}${path}`,
      data: {
        email,
        password
      }
    });

  handleLogIn = (email, password) => {
    return new Promise((resolve, reject) => {
      this.sendRequest(email, password)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject("User not found");
        });
    });
  };

  handleLogOut = () => {
    this.removeToken();
    this.clearLocalStorage();
  };

  login = (_, payload) => {
    return axios({
      method: `post`,
      url: `${baseUrl}${path}`,
      data: payload
    });
  };

  forgotPassword = (_, payload) => {
    return axios({
      method: `post`,
      url: `${baseUrl}/api/v1/user/forgot-password`,
      data: payload
    });
  };

  changePassword = (_, payload) => {
    return axios({
      method: `post`,
      url: `${baseUrl}/api/v1/user/change-password`,
      data: payload
    });
  };

  logout = (_, payload) => {
    const token = getTokenFromCookie();
    const headers = { Authorization: `Bearer ${token}` };
    return axios({
      method: `get`,
      url: `${baseUrl}/api/v1/user/logout`,
      headers
    });
  };

  loginRedirect = (_, payload) => {
    const { token } = payload;

    return axios({
      method: `get`,
      url: `${baseUrl}/api/v1/user/login-idx?token=${token}`
    });
  };
}

export default new Auth();
