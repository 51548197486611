import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import LoadingContext from "contexts/LoadingContext";
import EndpointGroupsApi from "services/api/2-system-manage-api/endpoint-groups-api";
import useApi from "hooks/useApi";
import { assignEndpointGroupsRequestMessages } from "./utils/utils";

import PageHeader from "components/ui/page-header/PageHeader";
import EndPointGroupsList from "./components/endpoint-groups-list/EndPointGroupsList";
import EditSelectedGroup from "./components/edit-selected-group/EditSelectedGroup";
import AddGroupModal from "./components/add-group-modal/AddGroupModal";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./EndPointGroups.module.scss";

function EndPointGroups() {
  const [selectedGroup, setSelectedGroup] = useState({ id: null, name: null });
  const [groupToBeDeleted, setGroupToBeDeleted] = useState({ name: null });
  const [open, setOpen] = useState(true);
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { t } = useTranslation("endpoint-groups");
  const { setLoading: setProgressBarLoading } = useContext(LoadingContext);
  const [apiLoadSuccessful, setApiLoadSuccessful] = useState(false);
  const history = useHistory();

  const { GET_ENDPOINTS_ERROR, DELETED, DELETING_ERROR } =
    assignEndpointGroupsRequestMessages(t);

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (selectedGroup.name) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [selectedGroup]);
  /* eslint-enabled react-hooks/exhaustive-deps */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetSelectedGroup = () => {
    setSelectedGroup({ id: null, name: null });
  };

  const toggleSelectedGroup = group => {
    if (selectedGroup.name === null) {
      setSelectedGroup(group);
    } else if (selectedGroup.name !== null && selectedGroup.id === group.id) {
      setSelectedGroup({ id: null, name: null });
    } else {
      setSelectedGroup(group);
    }
  };

  const openAddGroupModal = () => {
    setAddGroupModalOpen(true);
  };

  const closeAddGroupModal = () => {
    setAddGroupModalOpen(false);
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const hideLoadingBar = () => {
    setProgressBarLoading(false);
  };

  const onLoadGroupsSuccess = () => {
    setApiLoadSuccessful(true);
    hideLoadingBar();
  };

  const [endpointGroups = [], , , fetchAllEndpointGroups] = useApi(
    EndpointGroupsApi.getEndpointGroups,
    {
      errorMessage: GET_ENDPOINTS_ERROR,
      onSuccess: onLoadGroupsSuccess,
      onError: hideLoadingBar
    }
  );

  const onDeleteGroupSuccess = () => {
    closeConfirmationModal();
    resetSelectedGroup();
    fetchAllEndpointGroups();
  };

  const [, deleteGroupLoading, , deleteGroup] = useApi(
    EndpointGroupsApi.deleteGroup,
    {
      requestOnMount: false,
      successMessage: DELETED,
      errorMessage: DELETING_ERROR,
      onSuccess: onDeleteGroupSuccess
    }
  );

  useEffect(() => {
    setProgressBarLoading(true);
    history.push("/endpoints/groups/list");
  }, []);

  const deleteSelectedGroup = () => {
    deleteGroup(groupToBeDeleted);
  };

  return (
    <>
      <PageHeader
        open={open}
        onClick={openAddGroupModal}
        butttonLabel={t("add-a-new-group")}
        apiLoadSuccessful={apiLoadSuccessful}
        noPadding
        tabs
      />

      {!!endpointGroups.length && (
        <div className={classes.container}>
          <EndPointGroupsList
            endpointGroups={endpointGroups}
            toggleSelectedGroup={toggleSelectedGroup}
            selectedGroup={selectedGroup}
            open={open}
          />
          <EditSelectedGroup
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            fetchAllEndpointGroups={fetchAllEndpointGroups}
            resetSelectedGroup={resetSelectedGroup}
            openConfirmationModal={openConfirmationModal}
            setGroupToBeDeleted={setGroupToBeDeleted}
          />
        </div>
      )}

      <AddGroupModal
        open={addGroupModalOpen}
        onSuccess={fetchAllEndpointGroups}
        onClose={closeAddGroupModal}
      />

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={closeConfirmationModal}
        actionName={t("action")}
        onAction={deleteSelectedGroup}
        disabled={deleteGroupLoading}
        loading={deleteGroupLoading}
      >
        <span>
          {t("delete-group")}&nbsp;
          <span className={classes["modal-group-name"]}>
            {groupToBeDeleted.name}?
          </span>
        </span>
      </ConfirmationModal>
    </>
  );
}

export default EndPointGroups;
