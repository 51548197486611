import React from "react";
import { func, string, bool } from "prop-types";
import { useTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";

import classes from "./TopBar.module.scss";

function TopBar({
  playlistName,
  openAddVideoModal,
  playlistPlaying,
  playlistAssignedToChannel,
  onClose,
  updating
}) {
  const { t } = useTranslation("video-playlists");

  return (
    <>
      <div className={classes["close-icon-container"]}>
        <CloseIcon className={classes["close-icon"]} onClick={onClose} />
      </div>
      <div className={classes["edit-playlist-top-bar"]}>
        <div className={classes["selected-playlist-name"]}>{playlistName}</div>
        {!playlistPlaying && !playlistAssignedToChannel && !updating && (
          <div className={classes["add-video"]} onClick={openAddVideoModal}>
            + {t("sections.playlists.add-video")}
          </div>
        )}
      </div>
    </>
  );
}

TopBar.propTypes = {
  playlistName: string,
  openAddVideoModal: func.isRequired,
  playlistPlaying: bool,
  playlistAssignedToChannel: bool,
  onClose: func.isRequired,
  updating: bool
};

export default TopBar;
