import React from "react";
import clsx from "clsx";
import { bool, func, string } from "prop-types";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import CustomCheckbox from "components/ui/inputs/custom-checkbox/CustomCheckbox";

import classes from "../GroupsTree.module.scss";

function GroupsTreeLabel({
  name,
  disabled,
  checked,
  handleClick,
  hasDuplicates,
  handleWarningIconClick,
}) {
  const { t } = useTranslation("assign-pages");

  const title = disabled ? t("tooltip.no-endpoints-assigned") : "";

  return (
    <Tooltip
      title={title}
      placement="top-start"
      classes={{
        tooltipPlacementTop: classes["tooltip-top"],
      }}
    >
      <div
        className={clsx(classes["label"], {
          [classes["label-disabled"]]: disabled,
        })}
        onClick={handleClick}
      >
        <div className={classes["label-checkbox-and-name"]}>
          <CustomCheckbox checked={checked} disabled={disabled} />
          <Typography className={classes["tree-view-typography"]}>
            {name}
          </Typography>
        </div>

        {hasDuplicates && (
          <Tooltip
            title={
              <div>
                <div>{t("control:tree.tooltip.group.top")}</div>
                <div>{t("control:tree.tooltip.group.bottom")}</div>
              </div>
            }
            placement="left"
            classes={{ tooltip: classes["tooltip"] }}
          >
            <WarningIcon
              className={classes["label-warning-icon"]}
              onClick={(e) => {
                e.stopPropagation();
                handleWarningIconClick();
              }}
            />
          </Tooltip>
        )}
      </div>
    </Tooltip>
  );
}

GroupsTreeLabel.propTypes = {
  name: string.isRequired,
  disabled: bool,
  checked: bool.isRequired,
  handleClick: func.isRequired,
  hasDuplicates: bool,
  handleWarningIconClick: func,
};

export default GroupsTreeLabel;
