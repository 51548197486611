import React, { useEffect, useState } from "react";
import { arrayOf, object } from "prop-types";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { ENTITIES, STATUSES, BAR_COLORS } from "../../../../../../utils/utils";

import ChartLegend from "../../../chart-legend/ChartLegend";
import EntitySelector from "../entity-selector/EntitySelector";
import CustomTooltip from "../../../custom-tooltip/CustomTooltip";
import XAxisTick from "../../../x-axis-tick/XAxisTick";

import classes from "./Chart.module.scss";

function Chart({ data }) {
  const { ALL, GATEWAYS, TUNERS } = ENTITIES;
  const { GATEWAYS_ONLINE, TUNERS_ONLINE } = STATUSES;
  const {
    ONLINE_FILL,
    OFFLINE_FILL,
    ONLINE_FILL_SECONDARY,
    OFFLINE_FILL_SECONDARY,
    TOOLTIP_CURSOR
  } = BAR_COLORS;
  const [chartData, setChartData] = useState([]);
  const [dataKey, setDataKey] = useState(GATEWAYS_ONLINE);
  const { t } = useTranslation("dashboard");

  const showAll = () => {
    setDataKey(ALL);
  };

  const showGateways = () => {
    setDataKey(GATEWAYS_ONLINE);
  };

  const showTuners = () => {
    setDataKey(TUNERS_ONLINE);
  };

  const actions = [
    {
      _key: "show-all",
      label: t("modal.chart.split-button.all"),
      onClickHandler: showAll
    },
    {
      _key: "show-gateways",
      label: t("modal.chart.split-button.gateways"),
      onClickHandler: showGateways
    },
    {
      _key: "show-tuners",
      label: t("modal.chart.split-button.tuners"),
      onClickHandler: showTuners
    }
  ];

  useEffect(() => {
    if (data.length) {
      const dataArray = data.reverse().map(item => {
        return {
          ...item,
          date: moment(item.createdAt).local().format("DD MMM, hh:mm"),
          gatewaysOffline: item.totalGateways - item.gatewaysOnline,
          tunersOffline: item.totalTuners - item.tunersOnline
        };
      });

      setChartData(dataArray);
    }
  }, [data]);

  return (
    <div className={classes["gateways-stats-chart"]}>
      <div className={classes["gateways-stats-chart-top-panel"]}>
        <ChartLegend
          entities={
            dataKey === GATEWAYS_ONLINE
              ? GATEWAYS
              : dataKey === TUNERS_ONLINE
              ? TUNERS
              : ALL
          }
        />
        <EntitySelector options={actions} />
      </div>
      <BarChart width={700} height={200} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" stroke="#000" />
        <XAxis dataKey="date" height={60} tick={<XAxisTick />} />
        <YAxis type="number" domain={[0, 5]} />
        <Tooltip
          cursor={{ stroke: TOOLTIP_CURSOR, strokeWidth: 2 }}
          itemStyle={{ backgroundColor: "#ff0000" }}
          content={
            <CustomTooltip
              online={dataKey}
              offline={
                dataKey === GATEWAYS_ONLINE
                  ? "gatewaysOffline"
                  : dataKey === TUNERS_ONLINE
                  ? "tunersOffline"
                  : ""
              }
              totalDouble={
                dataKey === ALL ? ["gatewaysOffline", "tunersOffline"] : []
              }
              onlineDouble={
                dataKey === ALL ? [GATEWAYS_ONLINE, TUNERS_ONLINE] : []
              }
            />
          }
        />
        <ReferenceLine y={0} stroke="#000" />
        <Brush
          dataKey="date"
          height={20}
          stroke={
            dataKey === TUNERS_ONLINE ? ONLINE_FILL_SECONDARY : ONLINE_FILL
          }
        />

        <Bar
          dataKey={dataKey !== ALL ? dataKey : GATEWAYS_ONLINE}
          stackId="a"
          fill={dataKey === TUNERS_ONLINE ? ONLINE_FILL_SECONDARY : ONLINE_FILL}
        />
        <Bar
          dataKey={
            dataKey === TUNERS_ONLINE ? "tunersOffline" : "gatewaysOffline"
          }
          stackId="a"
          fill={
            dataKey === TUNERS_ONLINE ? OFFLINE_FILL_SECONDARY : OFFLINE_FILL
          }
        />

        {dataKey === ALL && (
          <Bar
            dataKey={TUNERS_ONLINE}
            stackId="b"
            fill={ONLINE_FILL_SECONDARY}
          />
        )}
        {dataKey === ALL && (
          <Bar
            dataKey="tunersOffline"
            stackId="b"
            fill={OFFLINE_FILL_SECONDARY}
          />
        )}
      </BarChart>
    </div>
  );
}

Chart.propTypes = {
  data: arrayOf(object)
};

export default Chart;
