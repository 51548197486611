import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";

import useApi from "hooks/useApi";
import DashboardApi from "services/api/1-dashboard-api/dashboard-api";
import AccessManagementApi from "services/api/access-management-api/access-management-api";
import { DASHBOARD } from "data/urls";
import { dashboardRequestsMessages } from "../../utils/utils";

import CompaniesPanel from "../companies-panel/CompaniesPanel";

import classes from "./SuperAdminDashboard.module.scss";

function SuperAdminDashboard({ setProgressBarLoading, handleLocationClick }) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currRoute, setCurrRoute] = useState("/dashboard/locations");
  const { t } = useTranslation("dashboard");
  const history = useHistory();
  const onFetchOverviewSuccess = () => {
    setProgressBarLoading(false);
    setDataLoaded(true);
  };
  const { GET_COMPANIES_ERROR, GET_OVERVIEW_ERROR } =
    dashboardRequestsMessages(t);
  const [overview = {}, , , fetchDashboardOverview] = useApi(
    DashboardApi.getDashboardOverview,
    {
      requestOnMount: false,
      errorMessage: GET_OVERVIEW_ERROR,
      onSuccess: onFetchOverviewSuccess,
      onError: () => setProgressBarLoading(false)
    }
  );

  const [companies = []] = useApi(AccessManagementApi.getCompanies, {
    errorMessage: GET_COMPANIES_ERROR,
    onSuccess: fetchDashboardOverview,
    onError: () => setProgressBarLoading(false)
  });

  useEffect(() => {
    setCurrRoute(history.location.pathname);
  }, []);

  const handleCallToRouter = (_, value) => {
    history.push(value);
    setCurrRoute(value);
  };

  return (
    <div className={classes.container}>
      <Tabs
        classes={{ indicator: classes["nav-item-indicator"] }}
        value={currRoute}
        onChange={handleCallToRouter}
      >
        <Tab
          className={classes["nav-item"]}
          classes={{
            selected: classes["nav-item-selected"]
          }}
          label={t("tabs.idts")}
          value={DASHBOARD.IDTS}
        />
        <Tab
          className={classes["nav-item"]}
          classes={{
            selected: classes["nav-item-selected"]
          }}
          label={t("tabs.health-check")}
          value={DASHBOARD.HEALTH_CHECK}
        />
      </Tabs>
      <Scrollbars
        autoHeight
        autoHeightMax="calc(100vh - 14rem)"
        autoHide
        autoHideTimeout={2000}
        autoHideDuration={1000}
      >
        <Switch>
          <Route path={DASHBOARD.IDTS}>
            {dataLoaded && (
              <CompaniesPanel
                companies={companies}
                overview={overview}
                handleLocationClick={handleLocationClick}
              />
            )}
          </Route>
          <Route path={DASHBOARD.HEALTH_CHECK}>
            <iframe
              className={classes["health-check"]}
              src={process.env.REACT_APP_NETDATA_URL}
              title="IDX Health Check"
              sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin allow-downloads"
              allowFullScreen
            />
          </Route>
          <Redirect to={DASHBOARD.IDTS} />
        </Switch>
      </Scrollbars>
    </div>
  );
}

SuperAdminDashboard.propTypes = {
  setProgressBarLoading: func.isRequired,
  handleLocationClick: func.isRequired
};

export default SuperAdminDashboard;
