import React from "react";
import { Paper } from "@material-ui/core";
import clsx from "clsx";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./CustomPaper.module.scss";

function CustomPaper({ title = "", loading = false, dark = false, children }) {
  return (
    <Paper
      className={clsx(classes["paper"], {
        [classes.dark]: dark,
      })}
    >
      <div className={classes["paper-title"]}>
        {title}
        {loading && (
          <div className={classes["paper-loader"]}>
            <EllipsisLoader />
          </div>
        )}
      </div>
      {children}
    </Paper>
  );
}

export default CustomPaper;
