import React from "react";
import classes from "./Image.module.scss";

function Image({ image }) {
  return (
    <div className={classes["image-container"]}>
      <img src={image} className={classes["image"]} alt="logo" />
    </div>
  );
}

export default Image;
