import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";

import AuthContext from "contexts/AuthContext";
import { ServerContext } from "contexts/ServerContext";
import SocketIOContext from "contexts/SocketIOContext";
import auth from "services/auth";

import Modal from "../modal/Modal";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ReAuthModal.module.scss";

function ReAuthModal() {
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const { setServerInUse } = useContext(ServerContext);
  const { closeWebsocket } = useContext(SocketIOContext);
  const {
    actions: { setIsAuthenticated }
  } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const logOutAndRedirectToIDX = () => {
    auth.handleLogOut();
    setServerInUse({});
    closeWebsocket();
    setIsAuthenticated(false);
    history.push("/");
  };

  return (
    <Modal open={!state.isAuthenticated}>
      <Modal.Header>
        <div className={classes["header"]}>
          <AccessTimeRoundedIcon className={classes["header-icon"]} />
          <span className={classes["header-title"]}>
            {t("re-authenticate-modal.header")}
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className={classes["content"]}>
          {t("re-authenticate-modal.content")}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <PrimaryButton type="submit" onClick={logOutAndRedirectToIDX}>
          OK
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ReAuthModal;
