import React, { useState, useEffect } from "react";
import clsx from "clsx";

import useDebounce from "hooks/useDebounce";

import Modal from "../modal/Modal";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./ConfirmationModal.module.scss";

function ConfirmationModal({
  open,
  onClose,
  onAction,
  actionName = "Apply",
  children,
  headerContent = null,
  disabled = false,
  loading = false,
  contentClass = null
}) {
  const [apiLoading, setApiLoading] = useState(false);
  const debouncedLoading = useDebounce(apiLoading, 1000);

  useEffect(() => {
    setApiLoading(loading);
  }, [loading]);

  return (
    <Modal open={open}>
      <Modal.Header onClose={onClose}>
        {headerContent && <div>{headerContent}</div>}
      </Modal.Header>
      <Modal.Content>
        <div
          className={clsx(classes["confirmation-modal-content"], {
            [contentClass]: contentClass
          })}
        >
          {children}
        </div>
      </Modal.Content>
      <Modal.Footer>
        {debouncedLoading && (
          <div className={classes["loader-container"]}>
            <EllipsisLoader />
          </div>
        )}
        <PrimaryButton
          disabled={disabled}
          onClick={onAction}
          data-cy="confirmation-modal-action-button"
        >
          {actionName}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
