import React, { useState, useEffect, useContext } from "react";
import { Collapse } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from "react-i18next";

import ServersApi from "services/api/6-servers-api/servers-api";
import LoadingContext from "contexts/LoadingContext";
import useApi from "hooks/useApi";
import usePageFeatures from "hooks/usePageFeatures";
import useInterval from "hooks/useInterval";
import useTimeout from "hooks/useTimeout";
import { getSnacks } from "./components/snacks/snacks";
import { permissions } from "data/permissions";
import { ServerContext } from "contexts/ServerContext";

import AddNewServerModal from "./components/add-new-server-modal/AddNewServerModal";
import Form from "../../components/ui/form/Form";
import PageHeader from "components/ui/page-header/PageHeader";
import ServersTable from "./components/Table/ServersTable";
import EditSelectedServer from "./components/edit-selected-server/EditSelectedServer";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./Servers.module.scss";

export default function Servers() {
  const selectedServerInitial = { id: "", name: "" };
  const [addServerModalOpen, setAddServerModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [selectedServer, setSelectedServer] = useState(selectedServerInitial);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [serverNameToDeleteConfirmation, setServerNameToDeleteConfirmation] =
    useState("");
  const { t } = useTranslation("servers");
  const { setLoading: setProgressBarLoading } = useContext(LoadingContext);
  const {
    GET_SERVERS_ERROR,
    SERVER_SYNCHRONISATION_SUCCESS,
    SERVER_SYNCHRONISATION_ERROR,
    SERVER_DELETED,
    SERVER_DELETING_ERROR
  } = getSnacks(t);
  const [apiLoadSuccessful, setApiLoadSuccessful] = useState(false);
  const { setInterVal, clearInterVal } = useInterval();
  const setTimeOut = useTimeout();
  const { serverInUse, setServerInUse } = useContext(ServerContext);
  const isServerSelected = serverInUse.hasOwnProperty("id");

  const hideLoadingBar = () => {
    setProgressBarLoading(false);
  };

  const onLoadSeversSuccess = () => {
    setApiLoadSuccessful(true);
    hideLoadingBar();
  };

  const [servers, , , fetchServers] = useApi(ServersApi.getServers, {
    errorMessage: GET_SERVERS_ERROR,
    onSuccess: onLoadSeversSuccess,
    onError: hideLoadingBar,
    showMessageOnError: false
  });

  const featuresAccess = usePageFeatures({
    create: permissions.servers.SERVERS_CREATE,
    edit: permissions.servers.SERVERS_EDIT,
    companies: permissions.companies.COMPANIES_GET
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setInterVal(() => {
      fetchServers();
    }, 180000);

    setProgressBarLoading(true);

    return () => {
      clearInterVal();
    };
  }, []);

  useEffect(() => {
    if (selectedServer.name) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [selectedServer]);

  /* eslint-enable react-hooks/exhaustive-deps */

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openAddServerModal = () => {
    setAddServerModalOpen(true);
  };

  const closeAddServerModal = () => {
    setAddServerModalOpen(false);
  };

  const openSyncModal = () => {
    setSyncModalOpen(true);
  };

  const closeSyncModal = () => {
    setSyncModalOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setServerNameToDeleteConfirmation("");
    setDeleteModalOpen(false);
  };

  const onDeleteServerSuccess = () => {
    closeDeleteModal();
    setSelectedServer(selectedServerInitial);
    fetchServers();
    setServerInUse({});
  };

  const resetSelectedServer = () => {
    setSelectedServer(selectedServerInitial);
  };

  const toggleSelectedServer = server => {
    if (featuresAccess && featuresAccess.edit) {
      if (selectedServer === selectedServerInitial) {
        setSelectedServer(server);
      } else if (
        selectedServer !== selectedServerInitial &&
        selectedServer.id === server.id
      ) {
        setSelectedServer(selectedServerInitial);
      } else {
        setSelectedServer(server);
      }
    }
  };

  const onSynchronizeServerSuccess = () => {
    setTimeOut(() => {
      fetchServers();
    }, 2000);
  };

  const [, syncLoading, , synchronizeServer] = useApi(
    ServersApi.synchronizeServer,
    {
      requestOnMount: false,
      onSuccess: onSynchronizeServerSuccess,
      successMessage: SERVER_SYNCHRONISATION_SUCCESS,
      errorMessage: SERVER_SYNCHRONISATION_ERROR
    }
  );

  const [, deleteServerLoader, , deleteServer] = useApi(
    ServersApi.deleteServer,
    {
      requestOnMount: false,
      onSuccess: onDeleteServerSuccess,
      successMessage: SERVER_DELETED,
      errorMessage: SERVER_DELETING_ERROR
    }
  );

  const handleDelete = () => {
    closeSyncModal();
    deleteServer(selectedServer.id);
  };

  const handleSynchronize = () => {
    closeSyncModal();
    synchronizeServer(selectedServer.id);
  };

  const handleAddServerSuccess = () => {
    closeAddServerModal();
    fetchServers();
  };

  return (
    <>
      <PageHeader
        title={t("servers")}
        open={open}
        onClick={openAddServerModal}
        butttonLabel={t("add-new")}
        hasAccess={featuresAccess && featuresAccess.create}
        apiLoadSuccessful={apiLoadSuccessful}
      />

      {servers && (
        <Collapse in={!isServerSelected}>
          <div className={classes["select-server"]}>
            <div className={classes["select-server-header"]}>
              <InfoOutlinedIcon
                className={classes["select-server-header-icon"]}
              />
              <span>{t("select-server.header")}</span>
            </div>
            <div className={classes["select-server-content"]}>
              <div>{t("select-server.line-top")}</div>
              <div>{t("select-server.line-bottom")}</div>
            </div>
          </div>
        </Collapse>
      )}

      {servers && (
        <div className={classes.container}>
          <ServersTable
            servers={servers}
            toggleSelectedServer={toggleSelectedServer}
            selectedServer={selectedServer}
            open={open}
            displayCompanyName={featuresAccess && featuresAccess.companies}
            isServerSelected={isServerSelected}
          />
          {featuresAccess && featuresAccess.edit && (
            <EditSelectedServer
              selectedServer={selectedServer}
              setSelectedServer={setSelectedServer}
              refreshServersList={fetchServers}
              resetSelectedServer={resetSelectedServer}
              openSyncModal={openSyncModal}
              openDeleteModal={openDeleteModal}
              syncLoading={syncLoading}
              fetchServers={fetchServers}
            />
          )}
        </div>
      )}

      {featuresAccess && featuresAccess.create && (
        <AddNewServerModal
          open={addServerModalOpen}
          onSuccess={handleAddServerSuccess}
          onClose={closeAddServerModal}
        />
      )}

      <ConfirmationModal
        open={syncModalOpen}
        onClose={closeSyncModal}
        onAction={handleSynchronize}
        actionName={t("synchronize-btn-text")}
      >
        <div>{t("synchronize-server-with-cloud")}</div>
      </ConfirmationModal>
      <ConfirmationModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={handleDelete}
        actionName={t("common:delete")}
        disabled={serverNameToDeleteConfirmation !== selectedServer.name}
        loading={deleteServerLoader}
      >
        <div className={classes["delete-server-info"]}>
          {t("delete-question")}
        </div>
        <div className={classes["delete-server-proceed"]}>{t("proceed")}</div>
        <Form.Input
          // @ts-ignore
          type="text"
          id="name"
          name="name"
          value={serverNameToDeleteConfirmation}
          onChange={e => setServerNameToDeleteConfirmation(e.target.value)}
        />
      </ConfirmationModal>
    </>
  );
}
