import React from "react";
import { bool } from "prop-types";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./CreatePlaylistLoader.module.scss";

function CreatePlaylistLoader({ loading }) {
  return (
    <div className={classes["create-playlist-loader"]}>
      {loading && <EllipsisLoader />}
    </div>
  );
}

CreatePlaylistLoader.propTypes = {
  loading: bool
};

export default CreatePlaylistLoader;
