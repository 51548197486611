import moment from "moment";
import React from "react";
import { Cookies } from "react-cookie";
import {
  ALL_LANGUAGES_LETTERS_AND_NUMBERS_REGEX,
  ALL_LANGUAGES_LETTERS_REGEX,
  MULTICAST_ADDRESS_REGEX,
  MULTICAST_PORT_REGEX,
  NUMBERS_ONLY_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
  IDT_PRODUCT_KEY_REGEX,
  IDT_NAME_REGEX,
  MAX_IDTNAME_LEN,
  MAX_PHONE_LEN,
  MAX_PRODUCT_KEY_LEN,
  MAX_IP_LEN,
  MAX_EMAIL_LEN,
  MAX_PASSWORD_LEN,
  MIN_PASSWORD_LEN,
  MAX_PORT_LEN,
  MAX_TTL_LEN,
} from "data/constants";

export function timeAgo(time) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: (number) => number + "s ago",
      ss: "%ds ago",
      m: "1m ago",
      mm: "%dm ago",
      h: "1h ago",
      hh: "%dh ago",
      d: "1d ago",
      dd: "%dd ago",
      M: "a month ago",
      MM: "%d months ago",
      y: "a year ago",
      yy: "%d years ago",
    },
  });

  let secondsElapsed = moment().diff(time, "seconds");
  let dayStart = moment("2019-01-01").startOf("day").seconds(secondsElapsed);

  if (secondsElapsed > 300) {
    return moment(time).fromNow(true);
  } else if (secondsElapsed < 60) {
    return dayStart.format("s") + "s ago";
  } else {
    return dayStart.format("m:ss") + "m ago";
  }
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function isNotNullOrUndefinded(value) {
  return value !== null && value !== undefined;
}

const TOKEN = "token";
const cookies = new Cookies();
export const setTokenInCookie = (token) => {
  cookies.set(TOKEN, token);
};

export const getTokenFromCookie = () => {
  return cookies.get(TOKEN);
};

export const handleError = ({
  error,
  defaultError,
  section = null,
  setSnackbar,
  t,
}) => {
  const errorMessage = !!error ? error.response.data : "";
  const { message, variant, autoHideDuration } = defaultError;
  let emailAlreadyExistsError;
  let snackMessage = message;

  if (errorMessage) {
    const translationKey = errorMessage.split(" ").join("-").toLowerCase();
    emailAlreadyExistsError = section
      ? t(`${section}.snacks.${translationKey}`)
      : t(`snacks.${translationKey}`);
    snackMessage = emailAlreadyExistsError;
  }

  setSnackbar(snackMessage, variant, autoHideDuration);
};

export const convertBytes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return i === 0
    ? bytes + " " + sizes[i]
    : (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

export const convertStringToSeconds = (arg) =>
  arg ? arg.split(":").reduce((acc, time) => 60 * acc + +time) : null;

export const convertSecondsToString = (seconds) =>
  new Date(+seconds * 1000).toISOString().substr(11, 8);

// --- Input validation ---

export const validate = {
  noLeadingSpace: (value) => value[0] !== "\u0020",
  noTrailingSpace: (value) => value[value.length - 1] !== "\u0020",
};

export const validateRequired = {
  required: "common:form.this-field-is-required",
};

export const validateBase = {
  noLeadingSpace: (value) =>
    value[0] !== "\u0020" || "common:form.no-leading-space",
  noTrailingSpace: (value) =>
    value[value.length - 1] !== "\u0020" || "common:form.no-trailing-space",
};

export const validateDefault = {
  validate: {
    ...validateBase,
    validDefault: (value) =>
      ALL_LANGUAGES_LETTERS_AND_NUMBERS_REGEX.test(value) ||
      "common:form.invalid-character",
  },
};

export const validateIDTName = {
  validate: {
    ...validateBase,
    validIDTName: (value) =>
      IDT_NAME_REGEX.test(value) || "common:form.invalid-character",
  },
  maxLength: {
    value: MAX_IDTNAME_LEN,
    message: "common:form.max-length",
  },
};

export const validatePhone = {
  validate: {
    ...validateBase,
    validPhone: (value) =>
      PHONE_REGEX.test(value) || "common:form.invalid-phone-number",
  },
  maxLength: {
    value: MAX_PHONE_LEN,
    message: "common:form.max-length",
  },
};

export const validateProductKey = {
  validate: {
    ...validateBase,
    validKey: (value) =>
      IDT_PRODUCT_KEY_REGEX.test(value) ||
      "common:form.invalid-product-key-format",
  },
  maxLength: {
    value: MAX_PRODUCT_KEY_LEN,
    message: "common:form.max-length",
  },
};

export const validateIP = {
  validate: {
    ...validateBase,
    validIP: (value) =>
      MULTICAST_ADDRESS_REGEX.test(value) || "common:form.invalid-ip-format",
  },
  maxLength: {
    value: MAX_IP_LEN,
    message: "common:form.max-length",
  },
};

export const validateIPPort = {
  validate: {
    ...validateBase,
    noLeadingZero: (value) =>
      value[0] !== "\u0030" || "common:form.no-leading-zero",
    validNumber: (value) =>
      NUMBERS_ONLY_REGEX.test(value) || "common:form.numbers-only",
    validIPPort: (value) =>
      MULTICAST_PORT_REGEX.test(value) || "common:form.invalid-port-number",
  },
  maxLength: {
    value: MAX_PORT_LEN,
    message: "common:form.max-length",
  },
};

export const validateTTL = {
  validate: {
    ...validateBase,
    noLeadingZero: (value) =>
      value[0] !== "\u0030" || "common:form.no-leading-zero",
    validNumber: (value) =>
      NUMBERS_ONLY_REGEX.test(value) || "common:form.numbers-only",
    validTTLMin: (value) =>
      parseInt(value, 10) > 0 || "common:form.value-greater-than-zero",
    validTTLMax: (value) =>
      parseInt(value, 10) < 256 || "common:form.ttl-max-value",
  },
  maxLength: {
    value: MAX_TTL_LEN,
    message: "common:form.max-length",
  },
};

export const validateNumber = {
  validate: {
    ...validateBase,
    validNumber: (value) =>
      NUMBERS_ONLY_REGEX.test(value) || "common:form.numbers-only",
  },
};

export const validateEmail = {
  validate: {
    ...validateBase,
    validEmail: (value) =>
      EMAIL_REGEX.test(value) || "common:error-messages.invalid-email",
  },
  maxLength: {
    value: MAX_EMAIL_LEN,
    message: "common:form.max-length",
  },
};

export const validateName = {
  validate: {
    ...validateBase,
    validCountry: (value) =>
      ALL_LANGUAGES_LETTERS_REGEX.test(value) ||
      "common:form.invalid-character",
  },
};

export const validatePassword = {
  maxLength: {
    value: MAX_PASSWORD_LEN,
    message: "common:form.max-length",
  },
  minLength: {
    value: MIN_PASSWORD_LEN,
    message: "common:form.min-length",
  },
};

export const getInputError = (
  inputError,
  t,
  maxLength = 255,
  minLength = 8
) => {
  if (!inputError) {
    return undefined;
  } else {
    if (inputError.type === "maxLength") {
      return `${t(inputError.message)} ${maxLength}`;
    } else if (inputError.type === "minLength") {
      return `${t(inputError.message)} ${minLength}`;
    } else {
      return `${t(inputError.message)}`;
    }
  }
};

export const getTemplateBuilderUrl = (serverId) =>
  `https://${window.location.hostname}/api/v1/template-builder/authenticate/${serverId}`;

export const renderTrackHorizontal = ({ style }) => (
  <div
    style={{
      ...style,
      right: 2,
      bottom: 2,
      left: 2,
      height: 10,
      zIndex: 100,
    }}
  />
);

export const renderThumbHorizontal = ({ style }) => (
  <div
    style={{
      ...style,
      backgroundColor: "#607d8b",
      borderRadius: 5,
      opacity: 0.7,
    }}
  />
);

export const renderTrackVertical = ({ style }) => (
  <div
    style={{
      ...style,
      right: 2,
      bottom: 2,
      top: 2,
      width: 10,
      zIndex: 100,
    }}
  />
);

export const renderThumbVertical = ({ style }) => (
  <div
    style={{
      ...style,
      backgroundColor: "#607d8b",
      borderRadius: 5,
      opacity: 0.7,
    }}
  />
);

export const focusElement = (element) => {
  if (element.current) {
    element.current.focus();
  }
};

export const is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
export const is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

export const is_Mac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
export const is_iPhone = navigator.platform == "iPhone";
export const is_iPod = navigator.platform == "iPod";
export const is_iPad = navigator.platform == "iPad";

export const removeDuplicates = (array) =>
  array.reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []);
