import React, { useState, useEffect, memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { bool, func } from "prop-types";
import { Tabs, Tab } from "@material-ui/core";

import { ChannelsContextProvider } from "contexts/ChannelsContext";
import DrawerContext from "contexts/DrawerContext";
import { ENDPOINT_CONTROL } from "data/urls";
import useTimeout from "hooks/useTimeout";

import TvControl from "../tv-control/TvControl";
import Templates from "../templates/Templates";
import Settings from "../settings/Settings";
import Messaging from "../../Messaging/Messaging";
import Alerts from "../alerts/Alerts";
import EndpointDuplicatesInfo from "./components/endpoint-duplicates-info/EndpointDuplicatesInfo";

import classes from "../../ControlPanel.module.scss";

// const RouteTabsMemoised = memo(function RouteTabs() {
function RouteTabs({
  duplicatesInfoVisible,
  duplicatesListVisible,
  showDuplicatesInfo,
  hideDuplicatesInfo,
  toggleDuplicatesList,
}) {
  const [currRoute, setCurrRoute] = useState(
    ENDPOINT_CONTROL.TV_CONTROL.DEFAULT
  );
  const history = useHistory();
  const { t } = useTranslation("control");
  const { pathname } = useLocation();
  const disabled = currRoute === ENDPOINT_CONTROL.SETTINGS;
  const { duplicateEndpoints } = useContext(DrawerContext);
  const setTimeOut = useTimeout();

  const [tvControlTabValue, setTvControlTabValue] = useState(
    ENDPOINT_CONTROL.TV_CONTROL.DEFAULT
  );

  const handleCallToRouter = (_, value) => {
    history.push(value);
  };

  useEffect(() => {
    setTimeOut(() => {
      if (pathname.includes("control") && !!duplicateEndpoints.length) {
        showDuplicatesInfo();
      }
    }, 1000);
  }, [duplicateEndpoints]);

  useEffect(() => {
    setCurrRoute(pathname);

    if (pathname === ENDPOINT_CONTROL.SETTINGS) {
      setTvControlTabValue(ENDPOINT_CONTROL.SETTINGS);
    }

    if (pathname === ENDPOINT_CONTROL.TV_CONTROL.DEFAULT) {
      setTvControlTabValue(ENDPOINT_CONTROL.TV_CONTROL.DEFAULT);
    }
  }, [pathname]);

  return (
    <div className={classes["router-box"]}>
      <Tabs
        classes={{ indicator: classes["nav-item-indicator"] }}
        value={currRoute}
        onChange={handleCallToRouter}
      >
        <Tab
          className={classes["nav-item"]}
          label={t("tabs.tv-control")}
          value={tvControlTabValue}
          disabled={disabled}
        />
        <Tab
          className={classes["nav-item"]}
          label={t("tabs.messages")}
          value={ENDPOINT_CONTROL.MESSAGES}
          disabled={disabled}
        />
        <Tab
          className={classes["nav-item"]}
          label={t("tabs.alerts")}
          value={ENDPOINT_CONTROL.ALERTS}
          disabled={disabled}
        />
        <Tab
          className={classes["nav-item"]}
          label={t("tabs.templates")}
          value={ENDPOINT_CONTROL.TEMPLATES}
          disabled={disabled}
        />
      </Tabs>
      <div className={classes["router-box-content"]}>
        <ChannelsContextProvider>
          <Switch>
            <Route path="/endpoints/control/tv-control">
              <TvControl />
            </Route>
            <Route path={ENDPOINT_CONTROL.MESSAGES}>
              <Messaging />
            </Route>
            <Route path={ENDPOINT_CONTROL.TEMPLATES}>
              <Templates />
            </Route>
            <Route path={ENDPOINT_CONTROL.SETTINGS}>
              <Settings />
            </Route>
            <Route path={ENDPOINT_CONTROL.ALERTS}>
              <Alerts />
            </Route>
            <Redirect to={ENDPOINT_CONTROL.TV_CONTROL.DEFAULT} />
          </Switch>
        </ChannelsContextProvider>

        {duplicatesInfoVisible && (
          <EndpointDuplicatesInfo
            hideDuplicatesInfo={hideDuplicatesInfo}
            toggleDuplicatesList={toggleDuplicatesList}
            duplicatesListVisible={duplicatesListVisible}
            duplicateEndpoints={duplicateEndpoints}
          />
        )}
      </div>
    </div>
  );
}

RouteTabs.propTypes = {
  duplicatesInfoVisible: bool,
  duplicatesListVisible: bool,
  showDuplicatesInfo: func,
  hideDuplicatesInfo: func,
  toggleDuplicatesList: func,
};

export default memo(RouteTabs);
