import React, { createContext, useReducer } from "react";

export const initialState = {
  open: false,
  autoHideDuration: 0,
  variant: "success",
  message: ""
};

const types = {
  SET_MESSAGE: "SET_MESSAGE",
  SET_VARIANT: "SET_VARIANT",
  SET_NOTIFICATION_VISIBILITY: "SET_NOTIFICATION_VISIBILITY",
  SET_AUTO_HIDE_DURATION: "SET_AUTO_HIDE_DURATION",
  SET_SNACKBAR: "SET_SNACKBAR"
};

const setMessage = message => ({ type: types.SET_MESSAGE, message });
const setVariant = variant => ({ type: types.SET_VARIANT, variant });
const setAutoHideDuration = autoHideDuration => ({
  type: types.SET_AUTO_HIDE_DURATION,
  autoHideDuration
});
const setNotificationVisibility = open => ({
  type: types.SET_NOTIFICATION_VISIBILITY,
  open
});
const setSnackbar = (message, variant, autoHideDuration = 1800) => ({
  type: types.SET_SNACKBAR,
  message,
  variant,
  autoHideDuration
});

export const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_MESSAGE:
      return { ...state, message: action.message };
    case types.SET_VARIANT:
      return { ...state, variant: action.variant };
    case types.SET_AUTO_HIDE_DURATION:
      return { ...state, autoHideDuration: action.autoHideDuration };
    case types.SET_NOTIFICATION_VISIBILITY:
      return { ...state, open: action.open };
    case types.SET_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.message,
        variant: action.variant,
        autoHideDuration: action.autoHideDuration
      };
    default:
      return state;
  }
};

const NotificationContext = createContext({ state: initialState, actions: {} });

export function NotificationContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {
    setMessage: message => dispatch(setMessage(message)),
    setVariant: variant => dispatch(setVariant(variant)),
    setAutoHideDuration: autoHideDuration =>
      dispatch(setAutoHideDuration(autoHideDuration)),
    setNotificationVisibility: open =>
      dispatch(setNotificationVisibility(open)),
    setSnackbar: (message, variant, autoHideDuration) =>
      dispatch(setSnackbar(message, variant, autoHideDuration))
  };

  return (
    <NotificationContext.Provider value={{ state, actions }}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
