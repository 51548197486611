import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import manageChannelsApi from "services/api/3-content-api/playlist-channels-api";
import useApi from "hooks/useApi";
import {
  playlistChannelsRequestsMessages,
  CHANNEL_STATES
} from "../../../../../../utils/utils";
import { playlistChannelType } from "types";

import ChannelName from "./components/channel-name/ChannelName";
import ChannelState from "./components/channel-state/ChannelState";
import CurrentPlaylist from "./components/current-playlist/CurrentPlaylist";
import ToolTipTitle from "./components/tooltip-title/ToolTipTitle";

import IDTSwitch from "components/ui/switch/IDTSwitch";

import classes from "./ChannelPlaylistInfo.module.scss";

function ChannelPlaylistInfo({
  channel,
  setChannels,
  handleSetActiveChannel,
  getCurrentPlaylistInfo,
  onRemoveClick
}) {
  const [currentState, setCurrentState] = useState("");
  const [newState, setNewState] = useState(null);
  const [channelEnabled, setChannelEnabled] = useState(false);
  const { t } = useTranslation("video-playlists");
  const {
    CHANNEL_STATE_CHANGE_ERROR,
    CHANNEL_TRANSMIT_SUCCESS,
    CHANNEL_STOP_SUCCESS,
    CHANNEL_ENABLE_SUCCESS,
    CHANNEL_DISABLE_SUCCESS
  } = playlistChannelsRequestsMessages(t);
  const { ENABLE, DISABLE, STOP, TRANSMIT } = CHANNEL_STATES;
  const { assignedtogroup, enabled } = channel;
  const disabled =
    (channel.assignedtogroup && channelEnabled) || currentState === TRANSMIT;

  const successMessage =
    newState === TRANSMIT
      ? CHANNEL_TRANSMIT_SUCCESS
      : newState === STOP
      ? CHANNEL_STOP_SUCCESS
      : newState === ENABLE
      ? CHANNEL_ENABLE_SUCCESS
      : newState === DISABLE
      ? CHANNEL_DISABLE_SUCCESS
      : null;

  const onSetChannelStateSuccess = () => {
    if (newState === TRANSMIT || newState === STOP) {
      const updateChannelsArray = channelsArray =>
        channelsArray.reduce((acc, current) => {
          acc = [
            ...acc,
            current.id === channel.id
              ? { ...current, broadcasting: newState === TRANSMIT }
              : current
          ];

          return acc;
        }, []);

      setChannels(prev => updateChannelsArray(prev));
    } else {
      const updateChannelsArray = channelsArray =>
        channelsArray.reduce((acc, current) => {
          acc = [
            ...acc,
            current.id === channel.id
              ? { ...current, enabled: newState === ENABLE }
              : current
          ];

          return acc;
        }, []);

      setChannels(prev => updateChannelsArray(prev));
    }
  };

  const onSetChannelStateError = () => {
    if (newState === ENABLE || newState === DISABLE) {
      setChannelEnabled(prev => !prev);
    }
    setNewState(null);
  };

  const [, changeChannelStateLoading, , changeChannelState] = useApi(
    manageChannelsApi.changeChannelState,
    {
      requestOnMount: false,
      successMessage,
      errorMessage: CHANNEL_STATE_CHANGE_ERROR,
      onSuccess: onSetChannelStateSuccess,
      onError: onSetChannelStateError
    }
  );

  const handlePlaylistNameClick = e => {
    e.stopPropagation();

    if (currentState !== TRANSMIT) {
      handleSetActiveChannel(channel, "assign");
    }
  };

  const handleSwitchChange = e => {
    const value = e.target.value !== "true";

    setChannelEnabled(prev => !prev);
    setNewState(value ? ENABLE : DISABLE);
  };

  useEffect(() => {
    if (newState) {
      const payload = {
        id: channel.id,
        state: newState
      };
      changeChannelState(payload);
    }
  }, [newState]);

  useEffect(() => {
    const { broadcasting, enabled } = channel;
    const channelState = broadcasting ? TRANSMIT : STOP;

    setCurrentState(channelState);
    setChannelEnabled(enabled);
  }, [channel]);

  return (
    <div
      className={clsx(classes["channel-info"], {
        [classes["disabled-background"]]: false
      })}
    >
      <div className={classes["channel-info-top"]}>
        <Tooltip
          title={
            <ToolTipTitle
              assignedtogroup={assignedtogroup}
              currentState={currentState}
              enabled={enabled}
            />
          }
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <div>
            <IDTSwitch
              checked={channelEnabled}
              onChange={handleSwitchChange}
              value={channelEnabled}
              disabled={disabled}
              color="primary"
              onClick={e => e.stopPropagation()}
            />
          </div>
        </Tooltip>
        <ChannelName
          channelName={channel.channelname}
          isAssignedToGroup={channel.assignedtogroup}
          isEnabled={channel.enabled}
          newState={newState}
          loading={changeChannelStateLoading}
        />
      </div>
      <div className={classes["state-and-active-palylist"]}>
        <ChannelState
          channel={channel}
          newState={newState}
          currentState={currentState}
          setNewState={setNewState}
          loading={changeChannelStateLoading}
        />
        <CurrentPlaylist
          handlePlaylistNameClick={handlePlaylistNameClick}
          getCurrentPlaylistInfo={getCurrentPlaylistInfo}
          currentState={currentState}
          channel={channel}
          onRemoveClick={onRemoveClick}
        />
      </div>
    </div>
  );
}

ChannelPlaylistInfo.propTypes = {
  channel: playlistChannelType,
  setChannels: func,
  handleSetActiveChannel: func.isRequired,
  getCurrentPlaylistInfo: func.isRequired,
  onRemoveClick: func
};

export default ChannelPlaylistInfo;
