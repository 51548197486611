import React from "react";
import { object, func } from "prop-types";

import Reboot from "./reboot/Reboot";
import Synchronise from "./synchronise/Synchronise";
import ActionInProgress from "./components/action-in-progress/ActionInProgress";

import classes from "./ServerMGMTButtons.module.scss";

function ServerMGMTButtons({ server, openSyncModal, openRebootModal }) {
  const {
    isSnapshotActionInProgress,
    isRebootInProgress,
    lastAssetsSynchronization,
    lastRebootDate
  } = server;

  return (
    <div className={classes["server-mgmt-buttons-container"]}>
      {isRebootInProgress ? (
        <ActionInProgress reboot />
      ) : isSnapshotActionInProgress ? (
        <ActionInProgress backup />
      ) : (
        <>
          <Synchronise
            openSyncModal={openSyncModal}
            isActionInProgress={isSnapshotActionInProgress}
            lastSynchronization={lastAssetsSynchronization}
          />
          <Reboot
            openRebootModal={openRebootModal}
            isActionInProgress={isSnapshotActionInProgress}
            lastReboot={lastRebootDate}
          />
        </>
      )}
    </div>
  );
}

ServerMGMTButtons.propTypes = {
  server: object,
  openSyncModal: func,
  openRebootModal: func
};

export default ServerMGMTButtons;
