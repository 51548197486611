import React from "react";
import { arrayOf, string, func } from "prop-types";
import clsx from "clsx";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";
import SettingsApi from "services/api/settings-api/settings-api";
import { stbStartupRequestsMessages } from "pages/endpoints/control/control-panel/utils/utils";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "../Settings.module.scss";

function StbStartupMode({ groups, endpoints, setGroups, hidden }) {
  const { t } = useTranslation("control");
  const { STARTUP_MODE_CHANGED_SUCCESS } = stbStartupRequestsMessages(t);

  const setGroupsOnSuccess = (startupMode) => {
    setGroups("startUp", "" + startupMode);
  };
  const [, changeStartupModeLoading, , callChangeStartupMode] = useApi(
    SettingsApi.startupMode,
    {
      requestOnMount: false,
      successMessage: STARTUP_MODE_CHANGED_SUCCESS,
      onSuccess: setGroupsOnSuccess,
    }
  );
  const handleDefaultApply = () => {
    const payload = {
      endpoints,
      groups,
      startup: "DEFAULT",
    };

    callChangeStartupMode(payload);
  };
  const handleLastUsedApply = () => {
    const payload = {
      endpoints,
      groups,
      startup: "LAST-USED",
    };

    callChangeStartupMode(payload);
  };

  const disabled = hidden || changeStartupModeLoading;

  return (
    <Paper className={classes["box"]}>
      <div className={classes["box-content"]}>
        <div className={classes["box-label-container"]}>
          <label
            className={clsx(classes["box-label"], {
              [classes.disabled]: hidden,
            })}
          >
            {t("stb-startup.change-selected-label")}
          </label>
        </div>
      </div>
      <div className={clsx(classes["box-content"], classes["align-end"])}>
        <div className={classes["box-actions"]}>
          <PrimaryButton
            disabled={disabled}
            onClick={handleDefaultApply}
            primaryButtonClass={classes["small-font"]}
          >
            {t("stb-startup.default-ch-&-template")}
          </PrimaryButton>
          <PrimaryButton
            disabled={disabled}
            onClick={handleLastUsedApply}
            primaryButtonClass={classes["small-font"]}
          >
            {t("stb-startup.last-ch-&-template")}
          </PrimaryButton>
        </div>
      </div>
    </Paper>
  );
}

StbStartupMode.propTypes = {
  endpoints: arrayOf(string).isRequired,
  groups: arrayOf(string).isRequired,
  setGroups: func.isRequired,
};

export default StbStartupMode;
