// @ts-nocheck
import React from "react";
import { string, node, bool } from "prop-types";
import Img from "react-image";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { NO_PREVIEW_URL } from "data/constants";

import ThumbPlaceholder from "./thumb-placeholder/ThumbPlaceholder";
import ImgLoader from "../loaders/img-loader/ImgLoader";
import ImgUnloader from "../loaders/img-unloader/ImgUnloader";
import ScreenshotNotLoaded from "./screenshot-not-loaded/ScreenshotNotLoaded";

import { thumbPlacementType } from "types";

import classes from "./Thumb.module.scss";

function Thumb({
  imgSrc,
  disabled = false,
  placement = "right",
  children = null,
  tooltipClass = null,
  tooltipImgClass = null,
  placeholderClass = null,
  loaderClass = null,
  unloaderClass = null,
  isVertical = false,
  large = false,
  ...props
}) {
  const getUnloader = (unloaderClass, imageClass) => (
    <ImgUnloader unloaderClass={unloaderClass} imageClass={imageClass} />
  );

  const TemplatePreview = () =>
    imgSrc ? (
      <ThumbPlaceholder
        src={imgSrc}
        className={clsx({
          [classes["thumb-img"]]: !disabled,
          [classes["thumb-img-disabled"]]: disabled,
          [placeholderClass]: placeholderClass,
          [classes["img-landscape"]]: !isVertical,
          [classes["img-landscape-large"]]: !isVertical && large
        })}
        loader={<ImgLoader loaderClass={loaderClass} />}
        unloader={<ScreenshotNotLoaded />}
        {...props}
      />
    ) : null;

  return !disabled && imgSrc !== NO_PREVIEW_URL ? (
    <Tooltip
      placement={placement}
      title={
        <div className={classes["thumb-tooltip-img-container"]}>
          <Img
            src={imgSrc}
            className={clsx(classes["thumb-tooltip-img"], {
              [tooltipImgClass]: tooltipImgClass,
              [classes["img-portrait"]]: isVertical,
              [classes["img-portrait-small"]]: isVertical && !large
            })}
            loader={<ImgLoader />}
            unloader={getUnloader(
              classes["unloader-container"],
              classes["unloader-image"]
            )}
            {...props}
          />
          {children}
        </div>
      }
      classes={{
        tooltip: clsx(classes["thumb-tooltip"], {
          [tooltipClass]: tooltipClass
        })
      }}
    >
      <div>
        <TemplatePreview />
      </div>
    </Tooltip>
  ) : (
    <TemplatePreview />
  );
}

Thumb.propTypes = {
  imgSrc: string.isRequired,
  disabled: bool,
  placement: thumbPlacementType,
  children: node,
  tooltipClass: string,
  tooltipImgClass: string,
  placeholderClass: string,
  loaderClass: string,
  unloaderClass: string,
  isVertical: bool,
  large: bool
};

export default Thumb;
