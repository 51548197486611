import React from "react";
import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";

import { START_UP_TRANSLATOR_MAPPER } from "pages/endpoints/control/control-panel/utils/constants";

import { startUpType } from "types";

import classes from "../../GroupsTree.module.scss";

function EndpointDetails({
  template,
  currentChannel,
  subtitles,
  fullscreen,
  volume,
  startUp
}) {
  const { t } = useTranslation("control");
  return (
    <>
      <div className={classes["details-row"]}>
        <span className={classes["details-column-name"]}>
          {t("tree.details.template")}:
        </span>
        <span>{template}</span>
      </div>
      <div className={classes["details-row"]}>
        <span className={classes["details-column-name"]}>
          {t("tree.details.playing")}:
        </span>
        <span>{currentChannel}</span>
      </div>
      <div className={classes["details-row"]}>
        <span className={classes["details-column-name"]}>
          {t("tree.details.subtitles")}:
        </span>
        <span>{subtitles ? "On" : "Off"}</span>
      </div>
      <div className={classes["details-row"]}>
        <span className={classes["details-column-name"]}>
          {t("tree.details.fullscreen")}:
        </span>
        <span>{fullscreen ? "On" : "Off"}</span>
      </div>
      <div className={classes["details-row"]}>
        <span className={classes["details-column-name"]}>
          {t("tree.details.volume")}:
        </span>
        <span>{volume}</span>
      </div>
      <div className={classes["details-row"]}>
        <span className={classes["details-column-name"]}>
          {t("tree.details.start-up")}:
        </span>
        <span>{t(START_UP_TRANSLATOR_MAPPER[startUp])}</span>
      </div>
    </>
  );
}

EndpointDetails.propTypes = {
  template: string.isRequired,
  currentChannel: string.isRequired,
  subtitles: bool.isRequired,
  fullscreen: bool.isRequired,
  volume: string.isRequired,
  startUp: startUpType
};

export default EndpointDetails;
