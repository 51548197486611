import React, { useEffect, useState } from "react";
import { bool, array, string } from "prop-types";
import { useTranslation } from "react-i18next";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

import classes from "./CustomTooltip.module.scss";

function CustomTooltip({
  active,
  payload,
  label,
  offline,
  online,
  totalDouble = [],
  onlineDouble = [],
  server = false
}) {
  const [totalFirst, setTotalFrist] = useState("");
  const [onlineFirst, setOnlineFrist] = useState("");
  const [totalSecond, setTotalSecond] = useState("");
  const [onlineSecond, setOnlineSecond] = useState("");
  const [serverOnline, setServerOnline] = useState(null);
  const { t } = useTranslation("dashboard");
  const PREFIX = "modal.chart.tooltip";

  const resetState = () => {
    setTotalFrist("");
    setOnlineFrist("");
    setTotalSecond("");
    setOnlineSecond("");
    setServerOnline(null);
  };

  useEffect(() => {
    resetState();

    if (payload && payload.length) {
      if (server) {
        const isOnline = payload[0].payload.isServerOnline;

        setServerOnline(isOnline);
      } else if (!totalDouble.length) {
        setTotalFrist(
          t(`${PREFIX}.offline`) + ": " + payload[0].payload[offline]
        );
        setOnlineFrist(
          t(`${PREFIX}.online`) + ": " + payload[0].payload[online]
        );
      } else if (totalDouble.length) {
        const totalOne = totalDouble[0];
        const totalTwo = totalDouble[1];
        const onlineOne = onlineDouble[0];
        const onlineTwo = onlineDouble[1];

        setTotalFrist(
          t(`${PREFIX}.offline`) + ": " + payload[0].payload[totalOne]
        );
        setTotalSecond(
          t(`${PREFIX}.offline`) + ": " + payload[0].payload[totalTwo]
        );

        setOnlineFrist(
          t(`${PREFIX}.online`) + ": " + payload[0].payload[onlineOne]
        );
        setOnlineSecond(
          t(`${PREFIX}.online`) + ": " + payload[0].payload[onlineTwo]
        );
      }
    }
  }, [payload, totalDouble, onlineDouble]);

  const getDate = () => {
    if (label) {
      const value = label.split(",")[0];
      const day = value.split(" ")[0];
      const month = value.split(" ")[1].toLowerCase();
      const date = day + " " + t(`${PREFIX}.months.${month}`).substr(0, 3);

      return date;
    }
  };

  return active ? (
    <div className={classes["custom-tooltip"]}>
      <div className={classes["custom-tooltip-label"]}>
        <div className={classes["custom-tooltip-label-start"]}>
          {getDate()},&nbsp;
        </div>
        <WatchLaterIcon className={classes["custom-tooltip-label-icon"]} />
        <div className={classes["custom-tooltip-label-end"]}>
          {label ? label.split(",")[1] : ""}
        </div>
      </div>
      {totalSecond && (
        <div className={classes["custom-tooltip-gateways"]}>
          {t("modal.chart.split-button.gateways")}
        </div>
      )}
      <div>{totalFirst}</div>
      <div>{onlineFirst}</div>
      {totalSecond && (
        <div className={classes["custom-tooltip-tuners"]}>
          {t("modal.chart.split-button.tuners")}
        </div>
      )}
      {totalSecond && <div>{totalSecond}</div>}
      {onlineSecond && <div>{onlineSecond}</div>}
      {server && (
        <div>{serverOnline ? t("statuses.online") : t("statuses.offline")}</div>
      )}
    </div>
  ) : null;
}

CustomTooltip.propTypes = {
  active: bool,
  payload: array,
  label: string,
  offline: string,
  online: string,
  totalDouble: array,
  onlineDouble: array
};

export default CustomTooltip;
