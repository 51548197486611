import React from "react";
import { object } from "prop-types";
import Img from "react-image";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./PlaylistPreview.module.scss";

function PlaylistPreview({ playlist }) {
  const firstVideo = playlist.videos[0];
  const preview = firstVideo !== undefined ? firstVideo.previewUrl : "";
  const isSprite = firstVideo !== undefined ? firstVideo.sprite : false;

  return (
    <div className={classes["playlist-preview"]}>
      {isSprite ? (
        <VideoSprite spriteUrl={preview} frameWidth={135} frameHeight={76} />
      ) : (
        <Img
          src={preview}
          className={classes["image"]}
          unloader={<PreviewUnloader small />}
        />
      )}
    </div>
  );
}

PlaylistPreview.propTypes = {
  playlist: object.isRequired
};

export default PlaylistPreview;
