import React from "react";
import { Switch, FormControlLabel } from "@material-ui/core";

import classes from "./PrimarySwitch.module.scss";

function PrimarySwitch({ label, checked, onChange, ...restProps }) {
  return (
    <FormControlLabel
      className={classes["switch"]}
      classes={{ label: classes["switch-label"] }}
      control={
        <Switch
          {...restProps}
          checked={checked}
          onChange={onChange}
          classes={{
            checked: classes["switch-checked"],
            colorSecondary: classes["color-secondary"],
            track: classes["switch-track"]
          }}
        />
      }
      label={label}
    />
  );
}

export default PrimarySwitch;
