import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";

import DashboardApi from "services/api/1-dashboard-api/dashboard-api";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import { dashboardRequestsMessages } from "../../../../utils/utils";

import Chart from "./components/chart/Chart";
import DaysSelection from "../days-selection/DaysSelection";

import classes from "./GatewaysStats.module.scss";

function GatewaysStats({ server }) {
  const [lastDaysCurrent, setLastDaysCurrent] = useState("");
  const [lastDaysNew, setLastDaysNew] = useState("");
  const [stateChanged, setStateChanged] = useState(false);
  const lastDaysDebounced = useDebounce(lastDaysNew, 1500);
  const { id: serverId } = server;
  const { t } = useTranslation("dashboard");
  const { GET_GATEWAYS_CHART_DATA_ERROR } = dashboardRequestsMessages(t);

  const onFetchGatewaysChartDataSuccess = () => {
    if (lastDaysNew) {
      setLastDaysCurrent(lastDaysNew);
    }
    setStateChanged(false);
  };

  const [gatewaysChartData = [], loading, , fetchGatewaysChartData] = useApi(
    DashboardApi.getGatewaysStatistics,
    {
      requestOnMount: false,
      onSuccess: onFetchGatewaysChartDataSuccess,
      errorMessage: GET_GATEWAYS_CHART_DATA_ERROR
    }
  );

  const getDataForGatewaysChart = lastDays => {
    const payload = {
      serverId,
      lastDays
    };

    fetchGatewaysChartData(payload);
  };

  const handleChange = event => {
    setLastDaysNew(event.target.value);
  };

  useEffect(() => {
    if (lastDaysDebounced) {
      if (lastDaysDebounced !== lastDaysCurrent) {
        setStateChanged(true);
        getDataForGatewaysChart(lastDaysDebounced);
      }
    }
  }, [lastDaysDebounced]);

  useEffect(() => {
    if (server.id) {
      getDataForGatewaysChart("1");
      setLastDaysCurrent("1");
    }
  }, [server]);

  return (
    <div className={classes["gateways-stats"]}>
      <Chart data={gatewaysChartData} />
      <DaysSelection
        lastDaysCurrent={lastDaysCurrent}
        lastDaysNew={lastDaysNew}
        handleChange={handleChange}
        loading={loading}
        stateChanged={stateChanged}
        data={gatewaysChartData}
      />
    </div>
  );
}

GatewaysStats.propTypes = {
  server: object
};

export default GatewaysStats;
