import React from "react";
import { bool } from "prop-types";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./ModalLoader.module.scss";

function ModalLoader({ loading }) {
  return (
    <div className={classes["loader-container"]}>
      <div className={classes["loader"]}>{loading && <EllipsisLoader />}</div>
    </div>
  );
}

ModalLoader.propTypes = {
  loading: bool
};

export default ModalLoader;
