import React from "react";
import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./TemplatesLoader.module.scss";

function TemplatesLoader() {
  return (
    <tr>
      <td
        // @ts-ignore
        colSpan="2"
      >
        <div className={classes["templates-loader"]}>
          <EllipsisLoader />
        </div>
      </td>
    </tr>
  );
}

export default TemplatesLoader;
