import React from "react";
import { useTranslation } from "react-i18next";
import { bool } from "prop-types";
import WarningIcon from "@material-ui/icons/Warning";

import classes from "./TooltipTitle.module.scss";

function TooltipTitle({ enabled }) {
  const { t } = useTranslation("video-playlists");

  return enabled ? (
    <>
      <div className={classes["tooltip-title-top"]}>
        <div className={classes["tooltip-title-top-icon"]}>
          <WarningIcon />
        </div>
        <div className={classes["tooltip-title-top-label"]}>
          <div>
            {t(
              "sections.playlist-channels.tooltips.broadcast-settings-unavailable"
            )}
          </div>
        </div>
      </div>
      <div className={classes["tooltip-title-bottom"]}>
        {t(
          "sections.playlist-channels.tooltips.disable-channel-to-open-settings"
        )}
      </div>
    </>
  ) : (
    <span>{t("sections.playlist-channels.broadcast-settings")}</span>
  );
}

TooltipTitle.propTypes = {
  enabled: bool
};

export default TooltipTitle;
