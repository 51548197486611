import React, { useState, useEffect, useRef } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ServersApi from "services/api/6-servers-api/servers-api";
import useApi from "hooks/useApi";
import useSocketIO from "hooks/useSocketIO";
import { SOCKET_EVENTS } from "data/socket-events";
import { getSnacks } from "../../../../../snacks/snacks";

import SpeedTestHeader from "./components/speed-test-header/SpeedTestHeader";
import RunTestButton from "./components/run-test-button/RunTestButton";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./SpeedTestTable.module.scss";

function SpeedTestTable({ server }) {
  const [speedTests, setSpeedTests] = useState([]);
  const [isError, setIsError] = useState(false);
  const [speedTestModalOpen, setSpeedTestModalOpen] = useState(false);
  const [
    speedTestInProgressOnServers,
    setSpeedTestInProgressOnServers
  ] = useState([]);
  const speedTestInProgressOnServersRef = useRef([]);
  const serverRef = useRef(null);
  const { t } = useTranslation("servers");
  const { REQUEST_SPEED_TEST_SUCCESS, REQUEST_SPEED_TEST_ERROR } = getSnacks(t);
  const { isSnapshotActionInProgress, isRebootInProgress } = server;
  const isActionInProgress = isSnapshotActionInProgress || isRebootInProgress;
  const isTestInProgress = speedTestInProgressOnServers.includes(server.id);
  const isRunTestButtonVisible = !(isActionInProgress || isTestInProgress);

  const openSpeedTestModal = () => {
    setSpeedTestModalOpen(true);
  };

  const closeSpeedTestModal = () => {
    setSpeedTestModalOpen(false);
  };

  const onFetchSpeedTestsSuccess = data => {
    setSpeedTests(data);
  };

  const onFetchSpeedTestsError = () => {
    setIsError(true);
  };

  const onRequestSpeedTestSuccess = () => {
    closeSpeedTestModal();
  };

  const [, , , fetchSpeedTests] = useApi(ServersApi.getSpeedTestResults, {
    requestOnMount: false,
    onSuccess: onFetchSpeedTestsSuccess,
    onError: onFetchSpeedTestsError
  });

  const [, requestSpeedTestLoader, , requestSpeedTest] = useApi(
    ServersApi.requestSpeedTest,
    {
      requestOnMount: false,
      onSuccess: onRequestSpeedTestSuccess,
      successMessage: REQUEST_SPEED_TEST_SUCCESS,
      errorMessage: REQUEST_SPEED_TEST_ERROR
    }
  );

  useSocketIO(SOCKET_EVENTS.SPEED_TEST_IN_PROGRESS, serverId => {
    if (!speedTestInProgressOnServersRef.current.includes(serverId)) {
      const servers = [...speedTestInProgressOnServersRef.current, serverId];
      speedTestInProgressOnServersRef.current = servers;
      setSpeedTestInProgressOnServers(servers);
    }
  });
  useSocketIO(SOCKET_EVENTS.SPEED_TEST_FINISHED, serverId => {
    if (speedTestInProgressOnServersRef.current.includes(serverId)) {
      const servers = speedTestInProgressOnServersRef.current.filter(
        s => s !== serverId
      );
      speedTestInProgressOnServersRef.current = servers;
      setSpeedTestInProgressOnServers(servers);
      if (serverRef.current === serverId) {
        fetchSpeedTests(serverId);
      }
    }
  });

  const handleRequestSpeedTest = () => {
    requestSpeedTest(server.id);
  };

  useEffect(() => {
    fetchSpeedTests(server.id);
    serverRef.current = server.id;
  }, [server]);

  return (
    <>
      <SpeedTestHeader isTestInProgress={isTestInProgress} />
      {speedTests.length ? (
        <table className={classes["table"]}>
          <thead>
            <tr className={classes["table-row-head"]}>
              <th className={classes["table-cell"]}>
                {t("speed-test.table.created-at")}
              </th>
              <th className={classes["table-cell"]}>
                {t("speed-test.table.upload")}
              </th>
              <th className={classes["table-cell"]}>
                {t("speed-test.table.download")}
              </th>
            </tr>
          </thead>
          <tbody>
            {speedTests.map(speedTest => (
              <tr
                key={`speedtest__${speedTest.id}`}
                className={classes["table-row-body"]}
              >
                <td className={classes["table-cell"]}>
                  {moment(speedTest.createdAt)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss")}
                </td>
                {!speedTest.errorMessage ? (
                  <>
                    <td className={classes["table-cell"]}>
                      {(speedTest.uploadSpeed * 8).toFixed(2)}&nbsp;MB/s
                    </td>
                    <td className={classes["table-cell"]}>
                      {(speedTest.downloadSpeed * 8).toFixed(2)}&nbsp;MB/s
                    </td>
                  </>
                ) : (
                  <td
                    className={classes["table-cell"]}
                    // @ts-ignore
                    colSpan="2"
                  >
                    <span className={classes["failed-test-info"]}>
                      {t("speed-test.table.test-failed")}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : isError ? (
        <div className={classes["speed-test-load-error"]}>
          {t("speed-test.tests-load-error")}
        </div>
      ) : (
        <div className={classes["no-speed-tests"]}>
          {t("speed-test.no-tests-info")}
        </div>
      )}

      <RunTestButton
        isVisible={isRunTestButtonVisible}
        label={t("speed-test.modal.run-button")}
        onClick={openSpeedTestModal}
      />

      <ConfirmationModal
        open={speedTestModalOpen}
        onClose={closeSpeedTestModal}
        onAction={handleRequestSpeedTest}
        actionName={t("speed-test.modal.run-button")}
        disabled={requestSpeedTestLoader}
        loading={requestSpeedTestLoader}
      >
        {t("speed-test.modal.question")}
      </ConfirmationModal>
    </>
  );
}

SpeedTestTable.propTypes = {
  server: object
};

export default SpeedTestTable;
