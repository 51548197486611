import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class PlaylistChannelsApi {
  getToken = () => cookies.get("token");

  getAllChannels = async serverInUseId => {
    const method = "get";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/channels`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return await axios({
      method,
      url,
      headers
    });
  };

  setPlaylistActiveInChannel = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/set-active-playlist`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  changeChannelSettings = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/settings`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  changeChannelState = async (serverInUseId, payload) => {
    const method = "post";
    const path = `/api/v1/idtservers/${serverInUseId}/playlists/change-channel-state`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
}

export default new PlaylistChannelsApi();
