import { useEffect, useRef } from "react";

function useTimeout() {
  const timeoutId = useRef(null);

  const setTimeOut = (callback, delay) => {
    timeoutId.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  return setTimeOut;
}

export default useTimeout;
