import React from "react";
import PropTypes from "prop-types";

import classes from "../Table.module.scss";

function TableHeader({ children }) {
  return (
    <thead className={classes["table-head-fixed"]}>
      <tr className={classes["table-head"]}>{children}</tr>
    </thead>
  );
}

TableHeader.propTypes = {
  children: PropTypes.node
};

export default TableHeader;
