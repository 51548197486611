import React from "react";
import { oneOf, string, bool, func, node } from "prop-types";
import { Button } from "@material-ui/core";
import clsx from "clsx";

import classes from "./CustomButton.module.scss";

function CustomButton({
  children,
  disabled = false,
  onClick = null,
  big = false,
  center = false,
  btnType = "action",
  customButtonClass = "",
  ...restProps
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={clsx(classes["custom-button"], {
        [classes["custom-button-big"]]: big,
        [classes["custom-button-center"]]: center,
        [classes["custom-button-delete"]]: btnType === "delete",
        [customButtonClass]: customButtonClass
      })}
      {...restProps}
    >
      {children}
    </Button>
  );
}

CustomButton.propTypes = {
  children: node,
  onClick: func,
  disabled: bool,
  big: bool,
  center: bool,
  btnType: oneOf(["action", "delete"]),
  customButtonClass: string
};

export default CustomButton;
