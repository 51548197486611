import React, { createContext, useState } from "react";

const ErrorContext = createContext({
  error: false,
  setError: null
});

export function ErrorContextProvider({ children }) {
  const [error, setError] = useState(false);

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
}

export default ErrorContext;
