import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import ErrorIcon from '@material-ui/icons/Error';

import ErrorContext from "contexts/ErrorContext";

import Modal from "components/ui/modals/modal/Modal";

import classes from "./IDTErrorModal.module.scss";

function IDTErrorModal() {
  const {error, setError} = useContext(ErrorContext)
  const { t } = useTranslation("common");
  return (
    <Modal open={error}>
      <Modal.Header onClose={() => {
        setError(false)
      }}/>
      <Modal.Content>
        <div className={classes['content']}>
          <ErrorIcon className={classes['icon']}/>
          <div className={classes['message-title']}>{t("idt-error-modal.title")}</div>
          <div className={classes['message']}>{t("idt-error-modal.message")}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );
}

export default IDTErrorModal;
