import React, { useEffect, useState } from "react";
import { object, array, func } from "prop-types";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import LoadError from "components/ui/load-error/LoadError";

import classes from "./RoleSelect.module.scss";

function RoleSelect({ roles, selectedUser, handleRoleChange }) {
  const [isError, setIsError] = useState(null);
  const { t } = useTranslation("access-management");

  useEffect(() => {
    setIsError(!roles.length);
  }, [roles]);

  const onRoleSelect = e => {
    const selectedRoleId = e.target.value;
    const selectedRoleName = roles.find(role => role.id === selectedRoleId)[
      "name"
    ];
    const selectedRole = {
      id: selectedRoleId,
      name: selectedRoleName
    };

    handleRoleChange(selectedRole);
  };

  return (
    <>
      {!isError && (
        <div className={classes["role"]}>
          <FormControl variant="outlined">
            <div className={classes["role-select-container"]}>
              <InputLabel
                htmlFor="user-role"
                classes={{ root: classes["role-select-label"] }}
              >
                {t("users.roles.role")}
              </InputLabel>
              <Select
                value={selectedUser.id ? selectedUser.Role.id : ""}
                onChange={onRoleSelect}
                inputProps={{
                  name: "user-role",
                  id: "user-role"
                }}
                variant="outlined"
                className={classes["role-select"]}
              >
                {roles.map(role => (
                  <MenuItem
                    key={role.id}
                    value={role.id}
                    classes={{ root: classes["menu-item"] }}
                  >
                    {t(
                      `users.roles.${role.name.replace("_", "-").toLowerCase()}`
                    )}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormControl>
        </div>
      )}

      <LoadError open={isError} module="roles" />
    </>
  );
}

RoleSelect.propTypes = {
  roles: array,
  selectedUser: object,
  handleRoleChange: func
};

export default RoleSelect;
