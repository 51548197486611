import React from "react";
import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";

import { startUpType } from "types";

import EndpointDetails from "./endpoint-details/EndpointDetails";
import CustomCheckbox from "components/ui/inputs/custom-checkbox/CustomCheckbox";

import classes from "../GroupsTree.module.scss";

function EndpointsTreeLabel({
  handleClick,
  checked,
  name,
  online,
  template,
  currentChannel,
  subtitles,
  fullscreen,
  volume,
  startUp,
  hasDuplicate,
  handleWarningIconClick,
}) {
  const { t } = useTranslation("common");

  return (
    <Accordion className={classes["accordion"]}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes["accordion-summary"]}
        classes={{
          content: classes["accordion-summary-content"],
          expanded: classes["accordion-summary-expanded"],
          expandIcon: classes["accordion-summary-expand-icon"],
        }}
        disableRipple
      >
        <Box className={classes["label"]} onClick={handleClick}>
          <CustomCheckbox
            checked={checked}
            rootClass={classes["small-checkbox"]}
          />
          <Typography
            className={clsx(
              classes["tree-view-typography"],
              classes["small-font"]
            )}
          >
            {name}
          </Typography>

          {hasDuplicate && (
            <Tooltip
              title={
                <div>
                  <div>{t("control:tree.tooltip.endpoint.top")}</div>
                  <div>{t("control:tree.tooltip.endpoint.bottom")}</div>
                </div>
              }
              placement="left"
              classes={{ tooltip: classes["tooltip"] }}
            >
              <WarningIcon
                className={clsx(
                  classes["label-warning-icon"],
                  classes["icon-position"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  handleWarningIconClick();
                }}
              />
            </Tooltip>
          )}
        </Box>
        <div
          className={
            classes[`accordion-summary-${online ? "online" : "offline"}`]
          }
        >
          {online ? t("online") : t("offline")}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes["details"]}>
        <EndpointDetails
          template={template}
          currentChannel={currentChannel}
          subtitles={subtitles}
          fullscreen={fullscreen}
          volume={volume}
          startUp={startUp}
        />
      </AccordionDetails>
    </Accordion>
  );
}

EndpointsTreeLabel.propTypes = {
  handleClick: func.isRequired,
  checked: bool.isRequired,
  name: string.isRequired,
  online: bool.isRequired,
  template: string.isRequired,
  currentChannel: string,
  subtitles: bool.isRequired,
  fullscreen: bool.isRequired,
  volume: string.isRequired,
  startUp: startUpType,
  hasDuplicate: bool,
  handleWarningIconClick: func,
};

export default EndpointsTreeLabel;
