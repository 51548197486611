import React from "react";

import NotInterestedIcon from "@material-ui/icons/NotInterested";

import classes from "./NoActivePlaylistUnloader.module.scss";

export default function NoActivePlaylistUnloader() {
  return (
    <div className={classes["unloader"]}>
      <NotInterestedIcon className={classes["not-interested-icon"]} />
    </div>
  );
}
