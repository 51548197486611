export function assignEndpointsRequestMessages(t) {
  const GET_ENDPOINTS_ERROR = {
    message: t("snacks.endpoints-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_GROUPS_ERROR = {
    message: t("snacks.endpoint-groups-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_ENDPOINTS_ERROR,
    GET_GROUPS_ERROR
  };
}
