const PREFIX = "snacks";

export function channelLineupRequestsMessages(t) {
  const GET_CHANNELS_ERROR = {
    message: t(`${PREFIX}.channels-list-could-not-be-loaded`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CHANNELS_RENUMBER_SUCCESS = {
    message: t(`${PREFIX}.channels-renumbered`),
    variant: "success",
    autoHideDuration: 2000
  };

  const CHANNELS_RENUMBER_ERROR = {
    message: t(`${PREFIX}.channels-could-not-be-renumbered`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CHANNEL_EDIT_SUCCESS = {
    message: t(`${PREFIX}.channel-updated`),
    variant: "success",
    autoHideDuration: 2000
  };

  const CHANNEL_EDIT_ERROR = {
    message: t(`${PREFIX}.channel-could-not-be-edited`),
    variant: "error",
    autoHideDuration: 3500
  };

  const LOGO_UPLOAD_SUCCESS = {
    message: t("snacks.logo-uploaded"),
    variant: "success",
    autoHideDuration: 2000
  };

  const LOGO_UPLOAD_ERROR = {
    message: t("snacks.logo-could-not-be-uploaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_LOGOS_ERROR = {
    message: t("snacks.logos-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_CHANNELS_ERROR,
    CHANNELS_RENUMBER_SUCCESS,
    CHANNELS_RENUMBER_ERROR,
    CHANNEL_EDIT_SUCCESS,
    CHANNEL_EDIT_ERROR,
    LOGO_UPLOAD_SUCCESS,
    LOGO_UPLOAD_ERROR,
    GET_LOGOS_ERROR
  };
}

export const defaultLogo = {
  name: "unknown",
  logo: "unknown.png",
  logoPath: "/images/default_channel.png"
};

export const CHANNEL_TYPES = {
  TV: "TV",
  ENCODER: "Encoder",
  USB: "USB",
  HTTP: "HTTP",
  YOUTUBE_PLAYLIST: "YouTube Playlist",
  YOUTUBE_VIDEO: "YouTube Video",
  UDP: "UDP",
  VLP_CHANNEL: "VPL Channel"
};
