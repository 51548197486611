export function assignChannelsRequestMessages(t) {
  const ALL_CHANNELS_ASSIGNED = {
    message: t("snacks.channels-assigned-to-group"),
    variant: "success",
    autoHideDuration: 2000
  };

  const ALL_CHANNELS_ASSIGNING_ERROR = {
    message: t("snacks.channels-could-not-be-assigned-to-group"),
    variant: "error",
    autoHideDuration: 2000
  };

  const ALL_CHANNELS_REMOVED = {
    message: t("snacks.channels-removed-from-group"),
    variant: "success",
    autoHideDuration: 2000
  };

  const ALL_CHANNELS_REMOVING_ERROR = {
    message: t("snacks.channels-could-not-be-removed-from-group"),
    variant: "error",
    autoHideDuration: 2000
  };

  const CHANNEL_ASSIGN_ERROR = {
    message: t("snacks.channel-could-not-be-assigned"),
    variant: "error",
    autoHideDuration: 3500
  };

  const CHANNEL_REMOVE_ERROR = {
    message: t("snacks.channel-could-not-be-removed-from-group"),
    variant: "error",
    autoHideDuration: 3500
  };

  const CHANNEL_SET_AS_DEFAULT_ERROR = {
    message: t("snacks.channel-could-not-be-set-as-default"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_CHANNELS_ERROR = {
    message: t("snacks.channels-list-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const GET_ENDPOINTS_ERROR = {
    message: t("snacks.endpoint-groups-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    ALL_CHANNELS_ASSIGNED,
    ALL_CHANNELS_ASSIGNING_ERROR,
    ALL_CHANNELS_REMOVED,
    ALL_CHANNELS_REMOVING_ERROR,
    CHANNEL_ASSIGN_ERROR,
    CHANNEL_REMOVE_ERROR,
    CHANNEL_SET_AS_DEFAULT_ERROR,
    GET_CHANNELS_ERROR,
    GET_ENDPOINTS_ERROR
  };
}
