const PREFIX = "sections.videos.snacks";

export function videosRequestsMessages(t) {
  const VIDEOS_LOAD_ERROR = {
    message: t(`${PREFIX}.videos-could-not-be-loaded`),
    variant: "error",
    autoHideDuration: 3500
  };
  const VIDEO_RENAME_SUCCESS = {
    message: t(`${PREFIX}.video-has-been-renamed`),
    variant: "success",
    autoHideDuration: 3500
  };
  const VIDEO_RENAME_ERROR = {
    message: t(`${PREFIX}.video-could-not-be-renamed`),
    variant: "error",
    autoHideDuration: 3500
  };
  const VIDEO_DELETE_SUCCESS = {
    message: t(`${PREFIX}.video-has-been-deleted`),
    variant: "success",
    autoHideDuration: 3500
  };
  const VIDEOS_DELETE_SUCCESS = {
    message: t(`${PREFIX}.videos-have-been-deleted`),
    variant: "success",
    autoHideDuration: 3500
  };
  const VIDEO_DELETE_ERROR = {
    message: t(`${PREFIX}.video-could-not-be-deleted`),
    variant: "error",
    autoHideDuration: 3500
  };
  const VIDEOS_DELETE_ERROR = {
    message: t(`${PREFIX}.videos-could-not-be-deleted`),
    variant: "error",
    autoHideDuration: 3500
  };
  const VIDEO_UPLOAD_SUCCESS = {
    message: t(`${PREFIX}.video-has-been-uploaded`),
    variant: "success",
    autoHideDuration: 3500
  };
  const VIDEO_UPLOAD_ERROR = {
    message: t(`${PREFIX}.video-could-not-be-uploaded`),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    VIDEOS_LOAD_ERROR,
    VIDEO_RENAME_SUCCESS,
    VIDEO_RENAME_ERROR,
    VIDEO_DELETE_SUCCESS,
    VIDEOS_DELETE_SUCCESS,
    VIDEO_DELETE_ERROR,
    VIDEOS_DELETE_ERROR,
    VIDEO_UPLOAD_SUCCESS,
    VIDEO_UPLOAD_ERROR
  };
}

export const getStatusTextAndSteps = t => status => {
  switch (status) {
    case -1:
      return {
        statusText: t("sections.videos.uploading-video-statuses.failed"),
        step: null
      };
    case 0:
      return {
        statusText: t("sections.videos.uploading-video-statuses.uploading"),
        step: 1
      };
    case 1:
    case 2:
    case 3:
    case 4:
      return {
        statusText: t("sections.videos.uploading-video-statuses.transcoding"),
        step: 2
      };
    case 5:
      return {
        statusText: t(
          "sections.videos.uploading-video-statuses.taking-screenshots"
        ),
        step: 3
      };
    case 6:
      return {
        statusText: t(
          "sections.videos.uploading-video-statuses.uploading-to-cloud"
        ),
        step: 4
      };
    case 100:
      return {
        statusText: t("sections.videos.uploading-video-statuses.uploaded"),
        step: 4
      };
    default:
      return {
        statusText: t("sections.videos.uploading-video-statuses.processing"),
        step: 1
      };
  }
};
