import React from "react";
import { bool } from "prop-types";
import { LinearProgress } from "@material-ui/core";

import classes from "./LinearLoader.module.scss";

function LinearLoader({ progressBar }) {
  return (
    <div className={classes["loader"]}>
      {progressBar && (
        <LinearProgress
          classes={{
            root: classes["linear-progress"],
            bar: classes["linear-progress-bar"]
          }}
        />
      )}
    </div>
  );
}

LinearLoader.propTypes = {
  progressBar: bool
};

export default LinearLoader;
