import React from "react";
import PropTypes from "prop-types";
import { DialogActions } from "@material-ui/core";

import classes from "../Modal.module.scss";

function ModalFooter({ children }) {
  return (
    <DialogActions className={classes["modal-footer"]}>
      {children}
    </DialogActions>
  );
}

ModalFooter.propTypes = {
  children: PropTypes.node
};

export default ModalFooter;
