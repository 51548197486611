import React, { useContext } from "react";
import clsx from "clsx";
import { SnackbarContent, Snackbar, IconButton } from "@material-ui/core";

import NotificationContext from "contexts/NotificationContext";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";

import classes from "./SnackBar.module.scss";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

export default function SnackBarInfo(props) {
  const {
    state: { open, autoHideDuration, variant, message },
    actions: { setNotificationVisibility }
  } = useContext(NotificationContext);
  const closeSnackbar = () => setNotificationVisibility(false);

  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={closeSnackbar}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes["message"]}>
            <Icon className={clsx(classes["icon"], classes["icon-variant"])} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...props}
      />
    </Snackbar>
  );
}
