import { useEffect, useState } from "react";
import moment from "moment";

function useBackupInfo(backup) {
  const [backupInfo, setBackupInfo] = useState(backup);

  useEffect(() => {
    const getSnapshotInfo = backup => {
      const timestamp = Number(backup.split("-")[1]);
      const dateTime = moment(timestamp, "X").format("YYYY-MM-DD HH:mm");

      setBackupInfo(dateTime);
    };

    getSnapshotInfo(backup);
  }, [backup]);

  return backupInfo;
}

export default useBackupInfo;
