import React, { useState, useEffect, useContext } from "react";
import { arrayOf, object, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { RiTv2Line } from "react-icons/ri";
import { Tooltip } from "@material-ui/core";

import AssignEndpointsApi from "services/api/2-system-manage-api/assign-endpoints-api";
import useApi from "hooks/useApi";
import { assignEndpointsRequestMessages } from "../../utils/utils";
import NotificationContext from "contexts/NotificationContext";

import EndpointStatus from "./endpoint-status/EndpointStatus";
import Table from "components/ui/table/Table";
import AssignCell from "components/ui/table/assign-cell/AssignCell";

import classes from "./GroupsEndpointsTable.module.scss";

function GroupsEndpointsTable({ endpoints, groups, refreshTable }) {
  const [state, setState] = useState({
    endpoints: [],
    groups: [],
  });

  const { t } = useTranslation("assign-pages");

  const { ENDPOINT_ASSIGN_ERROR, ENDPOINT_REMOVE_ERROR } =
    assignEndpointsRequestMessages(t);

  const [, , , updateEndpoint] = useApi(AssignEndpointsApi.updateEndpoint, {
    requestOnMount: false,
    showMessageOnError: false,
    onSuccess: refreshTable,
  });

  const {
    actions: { setSnackbar },
  } = useContext(NotificationContext);

  useEffect(() => {
    setState({ endpoints, groups });
  }, [endpoints, groups]);

  const assignEndpointToGroup = async ({
    ipAddress: endpointIpAddress,
    groupId,
  }) => {
    try {
      const endpoint = endpoints.find(
        ({ ipAddress }) => ipAddress === endpointIpAddress
      );
      if (endpoint) {
        await updateEndpoint({ endpoint, groupId });
      }
    } catch (error) {
      const { message, variant, autoHideDuration } = ENDPOINT_ASSIGN_ERROR;
      setSnackbar(message, variant, autoHideDuration);
    }
  };

  const removeEndpointFromGroup = async ({ ipAddress: endpointIpAddress }) => {
    try {
      const endpoint = endpoints.find(
        ({ ipAddress }) => ipAddress === endpointIpAddress
      );
      if (endpoint) {
        await updateEndpoint({ endpoint, groupId: "" });
      }
    } catch (error) {
      const { message, variant, autoHideDuration } = ENDPOINT_REMOVE_ERROR;
      setSnackbar(message, variant, autoHideDuration);
    }
  };

  const generateNoAssignInfo = (group) => {
    return !group["endpoints"].length ? (
      <Tooltip
        title={t("tooltip.no-endpoints-assigned")}
        placement="top-start"
        classes={{ tooltip: classes["tooltip-font"] }}
      >
        <div className={classes["no-entities"]}>
          <div className={classes["no-entities-triangle"]} />
          <div className={classes["no-entities-icon"]}>!</div>
        </div>
      </Tooltip>
    ) : null;
  };

  return (
    <div className={classes["table"]}>
      {state.groups.length > 0 && (
        <Table centered autoHeight>
          <Table.Head>
            <th className={classes["table-cell-header"]}>{t("endpoint")}</th>
            {state.groups.map((group) => (
              <th key={group.id} className={classes["table-group-names"]}>
                {group.name}
                {generateNoAssignInfo(group)}
              </th>
            ))}
          </Table.Head>
          <Table.Body>
            {state.endpoints.map((endpoint, endpointIndex) => {
              return (
                <Table.Row key={endpoint.pin}>
                  <td className={classes["table-cell-endpoint"]}>
                    <div className={classes["endpoint-info"]}>
                      <div className={classes["endpoint-info-icon"]}>
                        <RiTv2Line />
                      </div>
                      <div>
                        <div className={classes["endpoint-info-name"]}>
                          <span>{endpoint.name}</span>
                        </div>
                        <EndpointStatus
                          online={endpoint.online}
                          status={endpoint.status}
                        />
                      </div>
                    </div>
                  </td>
                  {state.groups.map((group) => {
                    const cell = `${group.id}-${endpointIndex}`;
                    return (
                      <AssignCell
                        key={cell}
                        groupId={group.id}
                        assignedEntityIds={group.endpoints.map(
                          ({ ipAddress }) => ipAddress
                        )}
                        entityName="ipAddress"
                        entityId={endpoint.ipAddress}
                        handleAssign={assignEndpointToGroup}
                        handleRemove={removeEndpointFromGroup}
                        handleDefault={false}
                      />
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

GroupsEndpointsTable.propTypes = {
  endpoints: arrayOf(object),
  groups: arrayOf(object),
  refreshTable: func,
};

export default GroupsEndpointsTable;
