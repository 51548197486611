import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import VideosApi from "services/api/3-content-api/videos-api";
import useApi from "hooks/useApi";
import useSocketIO from "hooks/useSocketIO";
import { SOCKET_EVENTS } from "../../../../../../../data/socket-events";
import { VIDEO_NAME_REGEX } from "data/constants";

import UploadVideoInput from "./components/upload-video-input/UploadVideoInput";
import UploadVideoIcon from "./components/upload-video-icon/UploadVideoIcon";
import VideoPlaceholder from "./components/video-palceholder/VideoPlaceholder";
import WarningModal from "./components/warning-modal/WarningModal";

import classes from "./UploadComponent.module.scss";

const Container = "div";

function UploadComponent({ refetchVideos, allVideos }) {
  const [showWarning, setShowWarning] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [uploadingVideoPlaceholders, setUploadingVideoPlaceholders] = useState(
    []
  );
  const uploadingVideos = useRef([]);
  const oversizedVideos = useRef([]);
  const invalidNamesVideos = useRef([]);
  const alreadyExistingVideos = useRef([]);
  const alreadyUploadingVideos = useRef([]);
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.videos.upload-component";

  const handleVideoTranscoding = data => {
    const updatingVideoIndex = uploadingVideos.current.findIndex(
      video =>
        video.name === data.fileName ||
        (video.name.includes(data.fileName) && [-1, 100].includes(data.status))
    );

    if ([-1, 100].includes(data.status)) {
      const shouldRefetchVideos = uploadingVideos.current.every(
        (video, index) => {
          return (
            [-1, 100].includes(video.status) || updatingVideoIndex === index
          );
        }
      );

      if (shouldRefetchVideos) {
        const updatedUploadingVideos = [...uploadingVideos.current];
        const updatedUploadingVideo = {
          ...updatedUploadingVideos[updatingVideoIndex],
          status: data.status
        };
        updatedUploadingVideos[updatingVideoIndex] = updatedUploadingVideo;

        const failedFileUploads = updatedUploadingVideos.filter(
          video => video.status === -1
        );
        setUploadingVideoPlaceholders(failedFileUploads);
        uploadingVideos.current = failedFileUploads;
        refetchVideos();
        return;
      }
    }

    if (updatingVideoIndex !== -1) {
      const updatedUploadingVideos = uploadingVideos.current.map(video => {
        if (video.name === data.fileName) {
          return {
            ...video,
            progress: data.progress,
            status: data.status,
            screenshotsTaken: data.screenshotsTaken,
            totalScreenshots: data.totalScreenshots
          };
        }
        return video;
      });
      setUploadingVideoPlaceholders(updatedUploadingVideos);
      uploadingVideos.current = updatedUploadingVideos;
    } else {
      const newUpdatingVideos = [
        ...uploadingVideos.current,
        {
          name: data.fileName,
          progress: data.progress,
          status: data.status
        }
      ];
      setUploadingVideoPlaceholders(newUpdatingVideos);
      uploadingVideos.current = newUpdatingVideos;
    }
  };

  useSocketIO(SOCKET_EVENTS.VIDEO__TRANSCODING, handleVideoTranscoding);

  const onDrop = async droppedFiles => {
    const maxVideoSize = 1000000000;
    const properSizeFiles = droppedFiles.filter(
      file => file.size <= maxVideoSize
    );
    const oversizedFiles = droppedFiles.filter(
      file => file.type.includes("video") && file.size > maxVideoSize
    );
    const filesWithInvalidNames = properSizeFiles.filter(
      file => !VIDEO_NAME_REGEX.test(file.name)
    );

    const checkIfOnList = file =>
      allVideos.some(({ name }) => name === file.name.split(".")[0]);

    const filesAlreadyExistingOnList = properSizeFiles.filter(file =>
      checkIfOnList(file)
    );

    const checkIfUploading = file =>
      uploadingVideos.current.some(({name}) => name === file.name);

    const filesAlreadyUploading = droppedFiles.filter(file =>
      checkIfUploading(file)
    );

    const filesWithCorrectNamesAndSizes = droppedFiles.filter(
      file =>
        file.size <= maxVideoSize &&
        VIDEO_NAME_REGEX.test(file.name) &&
        !checkIfOnList(file) &&
        !checkIfUploading(file)
    );

    oversizedVideos.current = oversizedFiles;
    invalidNamesVideos.current = filesWithInvalidNames;
    alreadyExistingVideos.current = filesAlreadyExistingOnList;
    alreadyUploadingVideos.current = filesAlreadyUploading;

    if (
      oversizedFiles.length ||
      filesWithInvalidNames.length ||
      filesAlreadyExistingOnList.length ||
      filesAlreadyUploading.length
    ) {
      openWarningModal();
    }

    uploadingVideos.current = [
      ...uploadingVideos.current,
      ...filesWithCorrectNamesAndSizes.reduce((uploadingFiles, file) => {
        if (!file.type.includes("video")) {
          return uploadingFiles;
        }
        return [
          ...uploadingFiles,
          { name: file.name, progress: file.progress, status: 0 }
        ];
      }, [])
    ].reverse();

    for (const file of filesWithCorrectNamesAndSizes) {
      if (!file.type.includes("video")) {
        displayWarning();
        continue;
      }

      await handleVideoUpload(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUploadError = ({ response }) => {
    if (response.status === 400) {
      alreadyExistingVideos.current = [{name: response.data.fileName, size: 0}];
      openWarningModal();
      // Remove video upload placeholder
      const uploadingVideoIndex = uploadingVideos.current.findIndex(
        video => video.name === response.data.uploadFileName
      );

      if (uploadingVideoIndex !== -1) {
        uploadingVideos.current.splice(uploadingVideoIndex, 1);
      }
    }
    
    //setUploadingVideoPlaceholders([]);
  };

  const [, , , uploadVideoToCloud] = useApi(VideosApi.uploadVideo, {
    requestOnMount: false,
    onError: handleUploadError,
    errorMessageOnStatus: {
      400: null
    }
  });

  const setShowProgress = videoName => progressEvent => {
    const uploadingVideoIndex = uploadingVideos.current.findIndex(
      video => video.name === videoName
    );

    if (uploadingVideoIndex !== -1) {
      const { loaded, total } = progressEvent;
      const uploadPercentage = Math.round((loaded / total) * 100);
      const uploadingVideo = {
        ...uploadingVideos.current[uploadingVideoIndex],
        progress: uploadPercentage
      };
      const updatedUploadingVideos = [...uploadingVideos.current];
      updatedUploadingVideos[uploadingVideoIndex] = uploadingVideo;

      setUploadingVideoPlaceholders(updatedUploadingVideos);
      uploadingVideos.current = updatedUploadingVideos;
    }
  };

  const handleVideoUpload = videoToBeUploaded => {
    const showProgress = setShowProgress(videoToBeUploaded.name);
    uploadVideoToCloud({ videoToBeUploaded, showProgress });
  };

  const displayWarning = () => {
    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 2500);
  };

  const openWarningModal = () => {
    setWarningModalOpen(true);
  };

  const closeWarningModal = () => {
    setWarningModalOpen(false);
    setTimeout(() => {
      oversizedVideos.current = [];
      invalidNamesVideos.current = [];
      alreadyExistingVideos.current = [];
      alreadyUploadingVideos.current = [];
    }, 1000);
  };

  return (
    <>
      <Container
        className={clsx(classes["upload-video-component"], {
          [classes["upload-video-component-active"]]: isDragActive,
          [classes["upload-video-component-warning"]]: showWarning
        })}
        {...getRootProps()}
      >
        <UploadVideoInput getInputProps={getInputProps} />
        <UploadVideoIcon
          isDragActive={isDragActive}
          showWarning={showWarning}
          prefix={PREFIX}
          t={t}
        />
      </Container>
      {uploadingVideoPlaceholders.map(
        ({ name, progress, status, screenshotsTaken, totalScreenshots }) => {
          return (
            <VideoPlaceholder
              key={`video--${name}`}
              name={name}
              progress={progress}
              status={status}
              screenshotsTaken={screenshotsTaken}
              totalScreenshots={totalScreenshots}
            />
          );
        }
      )}

      <WarningModal
        open={warningModalOpen}
        actionName="Ok"
        onClose={closeWarningModal}
        onAction={closeWarningModal}
        oversizedVideos={oversizedVideos.current}
        invalidNamesVideos={invalidNamesVideos.current}
        alreadyExistingVideos={alreadyExistingVideos.current}
        alreadyUploadingVideos={alreadyUploadingVideos.current}
      />
    </>
  );
}

export default UploadComponent;
