import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class MessagingApi {
  getToken = () => cookies.get("token");

  getAllMessages = async server => {
    const method = "get";
    const path = `/api/v1/idtservers/${server}/messages`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers
    });
  };

  addNewMessage = async (server, message) => {
    const method = "post";
    const path = `/api/v1/idtservers/${server}/messages`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        message
      }
    });
  };

  deleteMessage = async (server, id) => {
    const method = "delete";
    const path = `/api/v1/idtservers/${server}/messages`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        id
      }
    });
  };

  showMessage = async (server, mssgObj) => {
    const { text, ttl, endpoints, groups } = mssgObj;
    const method = "post";
    const path = `/api/v1/idtservers/${server}/messages/show`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        text,
        ttl,
        endpoints,
        groups
      }
    });
  };

  hideMessage = async (server, mssgObj) => {
    const { endpoints, groups } = mssgObj;
    const method = "post";
    const path = `/api/v1/idtservers/${server}/messages/hide`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        endpoints,
        groups
      }
    });
  };
}

export default new MessagingApi();
