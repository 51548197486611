import React, { useEffect } from "react";
import { func, bool } from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import EndpointGroupsApi from "services/api/2-system-manage-api/endpoint-groups-api";
import useApi from "hooks/useApi";
import { assignEndpointGroupsRequestMessages } from "../../utils/utils";

import Modal from "components/ui/modals/modal/Modal";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import AddGroupForm from "./components/add-group-form/AddGroupForm";

function AddGroupModal({ open, onSuccess, onClose }) {
  const { t } = useTranslation("endpoint-groups");
  const { ADDED, ADDING_ERROR } = assignEndpointGroupsRequestMessages(t);
  const [, addGroupLoading, , addGroup] = useApi(EndpointGroupsApi.addGroup, {
    requestOnMount: false,
    successMessage: ADDED,
    errorMessage: ADDING_ERROR,
    onSuccess
  });
  const {
    handleSubmit,
    register,
    errors,
    clearError,
    getValues,
    formState: { dirty, isValid }
  } = useForm({ mode: "onChange" });

  const disabled = !dirty || !isValid || addGroupLoading;

  const handleAddGroup = data => {
    addGroup(data);
    onClose();
  };

  const handlePressEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      const { name } = getValues();

      if (name && !errors.name) {
        handleAddGroup({ name });
      }
    }
  };

  useEffect(() => {
    if (open) {
      clearError("name");
    }
  }, [open, clearError]);

  return (
    <Modal open={open}>
      <Modal.Header loading={addGroupLoading} onClose={onClose}>
        {t("add-a-new-group")}
      </Modal.Header>
      <Modal.Content>
        <AddGroupForm
          register={register}
          errors={errors}
          handlePressEnter={handlePressEnter}
        />
      </Modal.Content>
      <Modal.Footer>
        <PrimaryButton
          type="submit"
          onClick={handleSubmit(handleAddGroup)}
          data-cy="add-endpoint-group-button"
          disabled={disabled}
        >
          {t("common:apply")}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

AddGroupModal.propTypes = {
  open: bool.isRequired,
  onSuccess: func.isRequired,
  onClose: func.isRequired
};

export default AddGroupModal;
