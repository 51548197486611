import React, { useState, useEffect, useRef } from "react";
import { object, func, arrayOf, string } from "prop-types";
import { Slide } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import PlaylistsApi from "services/api/3-content-api/playlists-api";
import { playlistsRequestsMessages } from "pages/content/video-playlist-channels/components/playlists/utils/utils";
import { convertStringToSeconds, convertSecondsToString } from "utils/utils";
import useApi from "hooks/useApi";

import TopBar from "./components/top-bar/TopBar";
import SelectedPlaylistVideos from "./components/selected-playlist-videos/SelectedPlaylistVideos";
import AddVideoModal from "./components/add-video-modal/AddVideoModal";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./EditSelectedPlaylist.module.scss";

function EditSelectedPlaylist({
  selectedPlaylist,
  toggleSelectedPlaylist,
  fetchPlaylists,
  updatingPlaylists,
  setUpdatingPlaylists,
  handleReorderPlaylistVideos,
  updatePlaylists
}) {
  const [slideStatus, setSlideStatus] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState({
    name: undefined,
    id: undefined
  });
  const [currentPlaylistVideos, setCurrentPlaylistVideos] = useState([]);
  const [videosModalOpen, setVideosModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [videosToBeAdded, setVideosToBeAdded] = useState([]);
  const [videoToBeRemoved, setVideoToBeRemoved] = useState({
    id: null,
    index: null,
    duration: null
  });
  const playlistDuration = useRef(null);
  const updating = updatingPlaylists.includes(selectedPlaylist.id);

  const { t } = useTranslation("video-playlists");
  const {
    REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS,
    REMOVE_VIDEO_FROM_PLAYLIST_ERROR,
    ADD_VIDEO_TO_PLAYLIST_SUCCESS,
    ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
    ADD_VIDEO_TO_PLAYLIST_ERROR,
    ADD_VIDEOS_TO_PLAYLIST_ERROR,
    REORDER_VIDEOS_IN_PLAYLIST_ERROR
  } = playlistsRequestsMessages(t);

  useEffect(() => {
    if (selectedPlaylist.name) {
      handleSlideIn();
      setPlaylist();
    } else {
      handleSlideOut();
    }
  }, [selectedPlaylist]);

  const setPlaylist = () => {
    playlistDuration.current = selectedPlaylist.totalduration;

    setCurrentPlaylist(selectedPlaylist);
    setCurrentPlaylistVideos(selectedPlaylist.videos);
  };

  const handleRemoveVideoFromPlaylist = ({ id, index, duration }) => {
    setVideoToBeRemoved({ id, index, duration });
    openDeleteModal();
  };

  const getUpdatedPlaylistDuration = () =>
    convertSecondsToString(
      convertStringToSeconds(playlistDuration.current) -
        convertStringToSeconds(videoToBeRemoved.duration)
    );

  const onRemoveVideoSuccess = () => {
    const updatedArray = currentPlaylistVideos;
    updatedArray.splice(videoToBeRemoved.index, 1);
    const updatedPlaylistDuration = getUpdatedPlaylistDuration();
    playlistDuration.current = updatedPlaylistDuration;

    setCurrentPlaylistVideos(updatedArray);
    updatePlaylists({
      ...selectedPlaylist,
      videos: updatedArray,
      totalduration: updatedPlaylistDuration
    });
    closeDeleteModal();
  };

  const [, removeVideoLoading, , removeVideoFromPlaylist] = useApi(
    PlaylistsApi.removeVideoFromPlaylist,
    {
      requestOnMount: false,
      successMessage: REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS,
      errorMessage: REMOVE_VIDEO_FROM_PLAYLIST_ERROR,
      onSuccess: onRemoveVideoSuccess
    }
  );

  const removeVideo = () => {
    const payload = {
      videoId: videoToBeRemoved.id,
      playlistId: currentPlaylist.id
    };

    removeVideoFromPlaylist(payload);
  };

  const onAddVideosToPlaylistSuccess = () => {
    fetchPlaylists();
    setVideosToBeAdded([]);
  };

  const [, , , addVideoToExistingPlaylist] = useApi(
    PlaylistsApi.addVideosToPlaylist,
    {
      requestOnMount: false,
      successMessage:
        videosToBeAdded.length === 1
          ? ADD_VIDEO_TO_PLAYLIST_SUCCESS
          : ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
      errorMessage:
        videosToBeAdded.length === 1
          ? ADD_VIDEO_TO_PLAYLIST_ERROR
          : ADD_VIDEOS_TO_PLAYLIST_ERROR,
      onSuccess: onAddVideosToPlaylistSuccess
    }
  );

  const addVideosToPlaylist = videos => {
    setVideosToBeAdded(videos.map(({ id }) => id));
  };

  useEffect(() => {
    if (videosToBeAdded.length > 0) {
      const payload = {
        fileIds: videosToBeAdded,
        playlistId: currentPlaylist.id
      };

      addVideoToExistingPlaylist(payload);
    }
  }, [videosToBeAdded]);

  const [, , , reorderPlaylistVideos] = useApi(
    PlaylistsApi.reorderPlaylistInIDT,
    {
      requestOnMount: false,
      errorMessage: REORDER_VIDEOS_IN_PLAYLIST_ERROR
    }
  );

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 150);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const openVideosModal = () => {
    setVideosModalOpen(true);
  };

  const closeVideosModal = () => {
    setVideosModalOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const closeSidePanel = () => {
    toggleSelectedPlaylist(selectedPlaylist);
  };

  return (
    <>
      <Slide direction="left" in={slideStatus}>
        <div className={classes["edit-playlist"]}>
          <TopBar
            playlistName={currentPlaylist.name}
            openAddVideoModal={openVideosModal}
            playlistPlaying={selectedPlaylist.playing}
            playlistAssignedToChannel={selectedPlaylist.assignedToChannel}
            onClose={closeSidePanel}
            updating={updating}
          />
          <SelectedPlaylistVideos
            videos={currentPlaylistVideos}
            playlistPlaying={selectedPlaylist.playing}
            playlistAssignedToChannel={selectedPlaylist.assignedToChannel}
            handleRemoveVideoFromPlaylist={handleRemoveVideoFromPlaylist}
            playlistId={selectedPlaylist.id}
            getPlaylists={fetchPlaylists}
            updatingPlaylists={updatingPlaylists}
            setUpdatingPlaylists={setUpdatingPlaylists}
            handleReorderPlaylistVideos={handleReorderPlaylistVideos}
            reorderPlaylistVideos={reorderPlaylistVideos}
            updating={updating}
          />
        </div>
      </Slide>

      <AddVideoModal
        open={videosModalOpen}
        onClose={closeVideosModal}
        actionName={t("common:add")}
        addVideosToPlaylist={addVideosToPlaylist}
      />

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={removeVideo}
        actionName={t("sections.playlists.remove")}
        disabled={removeVideoLoading}
        loading={removeVideoLoading}
      >
        <span>
          {t(
            "sections.playlists.are-you-sure-you-want-to-remove-this-video-from-playlist"
          )}
        </span>
      </ConfirmationModal>
    </>
  );
}

EditSelectedPlaylist.propTypes = {
  selectedPlaylist: object,
  toggleSelectedPlaylist: func.isRequired,
  fetchPlaylists: func.isRequired,
  updatingPlaylists: arrayOf(string).isRequired,
  setUpdatingPlaylists: func.isRequired,
  handleReorderPlaylistVideos: func.isRequired,
  updatePlaylists: func
};

export default EditSelectedPlaylist;
