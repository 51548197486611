import React from "react";
import { useTranslation } from "react-i18next";
import { func, number } from "prop-types";

import classes from "./SelectedEndpoints.module.scss";

function SelectedEndpoints({ numberOfSelected, onClick }) {
  const { t } = useTranslation("control");

  return (
    <div className={classes["endpoints-selected"]}>
      {!!numberOfSelected && (
        <div className={classes["endpoints-selected-label"]}>
          <span>
            {t("selected")}:&nbsp;{numberOfSelected}
          </span>
          <div className={classes["clear-button"]} onClick={onClick}>
            {t("unselect")}:&nbsp;{numberOfSelected}
          </div>
        </div>
      )}
    </div>
  );
}

SelectedEndpoints.propTypes = {
  numberOfSelected: number,
  onClick: func,
};

export default SelectedEndpoints;
