import React from "react";
import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";

import WarningIcon from "@material-ui/icons/Warning";

import { CHANNEL_STATES } from "../../../../../../../../utils/utils";

import classes from "./ToolTipTitle.module.scss";

function ToolTipTitle({ assignedtogroup, currentState, enabled }) {
  const { TRANSMIT } = CHANNEL_STATES;
  const { t } = useTranslation("video-playlists");
  let content;

  const ToolTipTitleTop = ({ label }) => (
    <div className={classes["tooltip-title-top"]}>
      <div className={classes["tooltip-title-top-icon"]}>
        <WarningIcon />
      </div>
      <div className={classes["tooltip-title-top-label"]}>
        <div>{label}</div>
        <div>{t("sections.playlist-channels.tooltips.cannot-be-disabled")}</div>
      </div>
    </div>
  );

  if (assignedtogroup) {
    content = (
      <>
        <ToolTipTitleTop
          label={t(
            "sections.playlist-channels.tooltips.channel-assigned-to-group"
          )}
        />
        <div className={classes["tooltip-title-bottom"]}>
          {t(
            "sections.playlist-channels.tooltips.to-disable-channel-remove-it-from-groups"
          )}
        </div>
      </>
    );
  } else if (currentState === TRANSMIT) {
    content = (
      <ToolTipTitleTop
        label={t("sections.playlist-channels.tooltips.channel-transmitting")}
      />
    );
  } else if (enabled) {
    content = (
      <span>{t("sections.playlist-channels.tooltips.disable-channel")}</span>
    );
  } else {
    content = (
      <span>{t("sections.playlist-channels.tooltips.enable-channel")}</span>
    );
  }

  return content;
}

ToolTipTitle.propTypes = {
  assignedtogroup: bool,
  currentState: string,
  enabled: bool
};

export default ToolTipTitle;
