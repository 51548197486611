export const imageNotLoaded = e => {
  const image = e.target;
  const imageContainer = e.target.parentNode;
  const galleryItemContainer = imageContainer.parentNode;
  const imgSrc = require("../utils/assets/image-not-found-error.png");

  galleryItemContainer.style.background = "#333";
  image.src = imgSrc;
};

export const getTextFromHTMLText = htmlText => {
  if (htmlText) {
    const startTextIndex = htmlText.indexOf(">");
    if (startTextIndex > -1) {
      const endTextIndex =
        htmlText.substr(startTextIndex + 1).indexOf("</div>") +
        startTextIndex +
        1;

      const text = htmlText.substring(startTextIndex + 1, endTextIndex);
      return text;
    }
  }
  return htmlText;
};
