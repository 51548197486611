import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class TvControlApi {
  getToken = () => cookies.get("token");

  setChannel = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/channel`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  setSubtitlesOff = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/subtitles-off`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
  setSubtitlesOn = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/subtitles-on`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
  setFullscreenOff = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/fullscreen-off`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
  setFullscreenOn = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/fullscreen-on`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  setTurnedOff = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/tv-off`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
  setTurnedOn = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/tv-on`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
  setVolume = async (serverInUseId, payload) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/endpointcontrol/volume`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
}

export default new TvControlApi();
