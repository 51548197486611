import React from "react";
import { Redirect } from "react-router-dom";
import { Cookies } from "react-cookie";
import usePermissions from "hooks/usePermissions";

const cookies = new Cookies();

const ProtectedRoute = ({
  component: Component,
  accessPermission = null,
  ...rest
}) => {
  const permissions = usePermissions();
  const hasToken = cookies.get("token") !== undefined;
  let redirectLink = "/";
  let hasPermission = hasToken;

  if (!hasToken) {
    return <Redirect to={"/"} />;
  }

  if (!!accessPermission) {
    if (hasToken) {
      redirectLink = "/dashboard/overview";
    }

    if (accessPermission instanceof Array) {
      hasPermission =
        hasToken && permissions.some((perm) => permissions.includes(perm));
    } else {
      hasPermission = hasToken && permissions.includes(accessPermission);
    }
  }

  if (!permissions.length) {
    return null;
  }

  return hasPermission ? (
    <Component {...rest} />
  ) : (
    <Redirect to={redirectLink} />
  );
};

export default ProtectedRoute;
