import React from "react";
import clsx from "clsx";
import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import classes from "./TimerReset.module.scss";

function TimerReset({ isResetEnabled, handleResetTimer }) {
  const { t } = useTranslation("control");

  return (
    <div className={classes["reset"]}>
      {isResetEnabled ? (
        <Tooltip
          title={t("messages.reset-timer")}
          placement="right"
          classes={{ tooltip: classes["tooltip-font"] }}
        >
          <RotateLeftIcon
            className={clsx(classes["reset-icon"], {
              [classes["reset-icon-enabled"]]: isResetEnabled,
              [classes["reset-icon-disabled"]]: !isResetEnabled
            })}
            onClick={handleResetTimer}
          />
        </Tooltip>
      ) : (
        <RotateLeftIcon
          className={clsx(
            classes["reset-icon"],
            classes["reset-icon-disabled"]
          )}
        />
      )}
    </div>
  );
}

TimerReset.propTypes = {
  isResetEnabled: bool.isRequired,
  handleResetTimer: func.isRequired
};

export default TimerReset;
