import React from "react";
import PropTypes from "prop-types";
import classes from "../Table.module.scss";

function TableBody({ children }) {
  return <tbody className={classes["table-body"]}>{children}</tbody>;
}

TableBody.propTypes = {
  children: PropTypes.node
};

export default TableBody;
