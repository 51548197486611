import { useState } from "react";

function useScreenshot(baseUrl) {
  const [screenshotSrc, setScreenshotSrc] = useState("");
  const [screenshotInfo, setScreenshotInfo] = useState({
    timestampScreenshotTaken: 0,
    width: 0,
    height: 0
  });

  const setScreenshotSrcAndInfo = screenshots => {
    if (!Object.keys(screenshots).length) return;

    const chId = Object.keys(screenshots)[0];
    const { timestampScreenshotTaken, path } = screenshots[chId];
    const url = `${baseUrl}${path}`;

    setScreenshotSrc(url);
    setScreenshotInfo(prevScreenshotInfo => ({
      ...prevScreenshotInfo,
      timestampScreenshotTaken
    }));
  };

  return {
    screenshotSrc,
    screenshotInfo,
    setScreenshotSrc,
    setScreenshotInfo,
    setScreenshotSrcAndInfo
  };
}

export default useScreenshot;
