import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";

import usePermissions from "hooks/usePermissions";
import { permissions } from "data/permissions";
import { CONTENT } from "data/urls";

import Videos from "../videos/Videos";
import Playlists from "../playlists/Playlists";
import PlaylistChannels from "../playlist-channels/PlaylistChannels";

import classes from "./RouteTabs.module.scss";

function RouteTabs() {
  const [currRoute, setCurrRoute] = useState(CONTENT.MANAGE_CHANNELS);
  const [redirectRoute, setRedirectRoute] = useState(CONTENT.MANAGE_CHANNELS);
  const history = useHistory();
  const { t } = useTranslation("video-playlists");
  const { pathname } = useLocation();
  const userPermissions = usePermissions();

  useEffect(() => {
    if (userPermissions.length) {
      if (
        userPermissions.includes(permissions.playlists.PLAYLISTS_PAGE_CHANNELS)
      ) {
        setRedirectRoute(CONTENT.MANAGE_CHANNELS);
      } else if (
        userPermissions.includes(permissions.playlists.PLAYLISTS_PAGE_PLAYLISTS)
      ) {
        setRedirectRoute(CONTENT.MANAGE_PLAYLISTS);
      } else {
        setRedirectRoute(CONTENT.MANAGE_VIDEOS);
      }
    }
  }, [userPermissions]);

  const handleCallToRouter = (_, value) => {
    history.push(value);
  };

  useEffect(() => {
    if (userPermissions.length) {
      if (pathname === "/content/video-playlist-channels") {
        if (
          userPermissions.includes(
            permissions.playlists.PLAYLISTS_PAGE_CHANNELS
          )
        ) {
          setCurrRoute(CONTENT.MANAGE_CHANNELS);
          history.push("/content/video-playlist-channels/channels");
        } else {
          setCurrRoute(CONTENT.MANAGE_PLAYLISTS);
          history.push("/content/video-playlist-channels/playlists");
        }
      } else {
        setCurrRoute(pathname);
      }
    }
  }, [pathname, userPermissions, history]);

  return (
    <div className={classes["router-box"]}>
      <Tabs
        classes={{ indicator: classes["nav-item-indicator"] }}
        value={currRoute}
        onChange={handleCallToRouter}
      >
        {userPermissions.includes(
          permissions.playlists.PLAYLISTS_PAGE_CHANNELS
        ) && (
          <Tab
            data-cy="playlist-channels-tab"
            className={classes["nav-item"]}
            label={t("tabs.playlist-channels")}
            value={CONTENT.MANAGE_CHANNELS}
          />
        )}
        {userPermissions.includes(
          permissions.playlists.PLAYLISTS_PAGE_PLAYLISTS
        ) && (
          <Tab
            data-cy="playlists-tab"
            className={classes["nav-item"]}
            label={t("tabs.playlists")}
            value={CONTENT.MANAGE_PLAYLISTS}
          />
        )}
        {userPermissions.includes(
          permissions.playlists.PLAYLISTS_PAGE_VIDEOS
        ) && (
          <Tab
            data-cy="videos-tab"
            className={classes["nav-item"]}
            classes={{
              selected: classes["nav-item-selected"]
            }}
            label={t("tabs.videos")}
            value={CONTENT.MANAGE_VIDEOS}
          />
        )}
      </Tabs>
      <Switch>
        {userPermissions.includes(
          permissions.playlists.PLAYLISTS_PAGE_CHANNELS
        ) && (
          <Route path={CONTENT.MANAGE_CHANNELS}>
            <PlaylistChannels />
          </Route>
        )}
        {userPermissions.includes(
          permissions.playlists.PLAYLISTS_PAGE_PLAYLISTS
        ) && (
          <Route path={CONTENT.MANAGE_PLAYLISTS}>
            <Playlists />
          </Route>
        )}
        {userPermissions.includes(
          permissions.playlists.PLAYLISTS_PAGE_VIDEOS
        ) && (
          <Route path={CONTENT.MANAGE_VIDEOS}>
            <Videos />
          </Route>
        )}
        <Redirect to={redirectRoute} />
      </Switch>
    </div>
  );
}

export default RouteTabs;
