import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import classes from "../GroupsEndpointsTable.module.scss";

function EndpointStatus({ online, status }) {
  const { t } = useTranslation(["common", "assign-pages"]);
  const isInProgress = status === "Rebooting" || status === "Starting up";
  const endpointStatus = t(
    `assign-pages:endpoint-statuses.${status.split(" ")[0].toLowerCase()}`
  );

  return (
    <div className={classes["endpoint-info-status"]}>
      <div
        className={clsx(classes["circle"], {
          [classes["circle-online"]]: online,
          [classes["circle-starting-up"]]: status === "Starting up",
          [classes["circle-rebooting"]]: status === "Rebooting"
        })}
      />
      <span className={classes["endpoint-info-status-label"]}>
        {isInProgress ? endpointStatus : online ? t("online") : t("offline")}
      </span>
    </div>
  );
}

export default EndpointStatus;
