import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { object, func, string } from "prop-types";

import classes from "./SideMenu.module.scss";

function SideMenu({
  video,
  handleClickAway,
  handleRenameClick,
  handleDeleteClick,
  renameTxt,
  deleteTxt
}) {
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes["videos-list-item-menu"]}>
        <ul className={classes["options-list"]}>
          <li className={classes["option"]} onClick={handleRenameClick(video)}>
            {renameTxt}
          </li>
          <li className={classes["option"]} onClick={handleDeleteClick(video)}>
            {deleteTxt}
          </li>
        </ul>
      </div>
    </ClickAwayListener>
  );
}

SideMenu.propTypes = {
  video: object.isRequired,
  handleClickAway: func.isRequired,
  handleRenameClick: func.isRequired,
  handleDeleteClick: func.isRequired,
  renameTxt: string,
  deleteTxt: string
};

export default SideMenu;
