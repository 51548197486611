import React from "react";
import {arrayOf, object, func} from "prop-types";
import {useTranslation} from "react-i18next";

import Location from "./components/location/Location";

import classes from "./LocationsPanel.module.scss";

function LocationsPanel({locations, handleLocationClick}) {
  const {t} = useTranslation("dashboard");

  return <div className={classes["locations"]}>
    {locations && locations.length > 0 ? (
      locations.map(location => (
        <Location
          key={location.id}
          location={location}
          handleLocationClick={handleLocationClick}
        />
      ))
    ) : (
      <div className={classes["no-locations"]}>
        {t("table.no-locations")}
      </div>
    )}
  </div>
}

LocationsPanel.propTypes = {
  locations: arrayOf(object),
  handleLocationClick: func
};

export default LocationsPanel;
