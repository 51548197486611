import React, { useState } from "react";
import { bool, func, string, number } from "prop-types";

import ShowMessageLabel from "./ui/show-message-label/ShowMessageLabel";
import Timer from "../../../../../../../../components/ui/inputs/timer/Timer";
import ShowMessageButtons from "./ui/show-message-buttons/ShowMessageButtons";

import classes from "./BottomSection.module.scss";

function BottomSection({
  loading,
  handleCheckBox,
  newMessage,
  hours,
  minutes,
  isChecked,
  setHours,
  setMinutes,
  handleSaveAndShow,
  handleShow,
  isMessageTooLong
}) {
  const [isTimerValid, setIsTimerValid] = useState(false);
  const bothZero = isChecked && !hours && !minutes;

  return (
    <div className={classes["bottom-section"]}>
      <div className={classes["message-duration"]}>
        <ShowMessageLabel
          handleCheckBox={handleCheckBox}
          newMessage={newMessage}
          isChecked={isChecked}
          isMessageTooLong={isMessageTooLong}
        />
        <Timer
          isChecked={isChecked}
          hours={hours}
          minutes={minutes}
          setHours={setHours}
          setMinutes={setMinutes}
          setIsTimerValid={setIsTimerValid}
        />
      </div>

      <ShowMessageButtons
        handleSaveAndShow={handleSaveAndShow}
        loading={loading}
        newMessage={newMessage}
        handleShow={handleShow}
        isTimerValid={isTimerValid}
        bothZero={bothZero}
        isChecked={isChecked}
        isMessageTooLong={isMessageTooLong}
      />
    </div>
  );
}

BottomSection.propTypes = {
  loading: bool,
  handleCheckBox: func,
  newMessage: string,
  hours: number,
  minutes: number,
  isChecked: bool,
  setHours: func,
  setMinutes: func,
  handleSaveAndShow: func,
  handleShow: func,
  isMessageTooLong: bool
};

export default BottomSection;
