import React from "react";
import { string, number } from "prop-types";

import useSprite from "hooks/useSprite";

function VideoSprite({
  spriteUrl,
  frameWidth = 385,
  frameHeight = 217,
  frames = 10,
  direction = "vertical",
  stepTime = 400
}) {
  const { spriteStyles, onSpriteMouseEnter, onSpriteMouseLeave } = useSprite({
    frameWidth,
    frameHeight,
    spriteUrl,
    frames,
    direction,
    stepTime
  });

  return (
    <div
      style={spriteStyles}
      onMouseEnter={onSpriteMouseEnter}
      onMouseLeave={onSpriteMouseLeave}
    ></div>
  );
}

VideoSprite.propTypes = {
  spriteUrl: string.isRequired,
  frameWidth: number,
  frameHeight: number,
  frames: number,
  direction: string,
  stepTime: number
};

export default VideoSprite;
