import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import PlaylistChannelsApi from "services/api/3-content-api/playlist-channels-api";
import PlaylistsApi from "services/api/3-content-api/playlists-api";
import useApi from "hooks/useApi";
import { VideoContext } from "contexts/VideoContext";
import { playlistChannelsRequestsMessages } from "./utils/utils";

import ChannelsTable from "./components/channels-table/ChannelsTable";
import EditSelectedChannel from "./components/edit-selected-channel/EditSelectedChannel";
import SetActivePlaylistModal from "./components/modals/set-active-playlist-modal/SetActivePlaylistModal";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./PlaylistChannels.module.scss";

const selectedChannelInitial = {
  channelname: undefined,
  id: undefined,
  currentPlaylistID: undefined
};

function PlaylistChannels() {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(
    selectedChannelInitial
  );
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [playlistsModalOpen, setPlaylistsModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [activeChannel, setActiveChannel] = useState({ id: undefined }); // channel to which a playlist is going to be assigned
  const [playlistActiveInChannel, setPlaylistActiveInChannel] = useState({
    id: undefined
  });
  const { setAvailablePlaylists } = useContext(VideoContext);
  const { t } = useTranslation("video-playlists");
  const {
    CHANNELS_LOAD_ERROR,
    PLAYLISTS_LOAD_ERROR,
    PLAYLIST_SET_AS_ACTIVE_SUCCESS,
    PLAYLIST_SET_AS_ACTIVE_ERROR,
    PLAYLIST_REMOVE_FROM_CHANNEL_SUCCESS,
    PLAYLIST_REMOVE_FROM_CHANNEL_ERROR
  } = playlistChannelsRequestsMessages(t);
  const previousPlaylistAssignedToChannelId = useRef("");

  const [, channelsLoading, , fetchChannels] = useApi(
    PlaylistChannelsApi.getAllChannels,
    {
      onSuccess: setChannels,
      errorMessage: CHANNELS_LOAD_ERROR
    }
  );

  const [, , , fetchPlaylists] = useApi(PlaylistsApi.getAllPlaylists, {
    errorMessage: PLAYLISTS_LOAD_ERROR,
    onSuccess: setAvailablePlaylists
  });

  useEffect(() => {
    if (selectedChannel.channelname) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [selectedChannel]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleSelectedChannel = channel => {
    if (selectedChannel.channelname === undefined) {
      setSelectedChannel(channel);
    } else if (
      selectedChannel.channelname !== undefined &&
      selectedChannel.id === channel.id
    ) {
      handleDrawerOpen();
      setTimeout(() => {
        setSelectedChannel(selectedChannelInitial);
      }, 200);
    } else {
      setSelectedChannel(channel);
    }
  };

  const openAssignPlaylistModal = () => {
    setPlaylistsModalOpen(true);
  };

  const closeAssignPlaylistModal = () => {
    setPlaylistsModalOpen(false);
  };

  const openRemovePlaylistModal = () => {
    setRemoveModalOpen(true);
  };

  const closeRemovePlaylistModal = () => {
    setRemoveModalOpen(false);
  };

  const handleSetActiveChannel = (channel, modal) => {
    previousPlaylistAssignedToChannelId.current =
      modal === "assign" ? channel.currentPlaylistID : "";

    if (drawerOpen) {
      handleDrawerOpen();
    }
    if (!!channel.name) {
      setSelectedChannel(selectedChannelInitial);
    }
    setActiveChannel(channel);
    setPlaylistActiveInChannel({ id: undefined });

    if (modal === "assign") {
      openAssignPlaylistModal();
    }

    if (modal === "remove") {
      openRemovePlaylistModal();
    }
  };

  const handleSetPlaylistActiveInChannel = playlist => {
    if (playlistActiveInChannel.id === playlist.id) {
      setPlaylistActiveInChannel({ id: undefined });
    } else {
      setPlaylistActiveInChannel(playlist);
    }
  };

  const onSetPlaylistAsActiveSuccess = () => {
    if (playlistActiveInChannel.id) {
      closeAssignPlaylistModal();
    } else {
      closeRemovePlaylistModal();
    }
    fetchChannels();
    fetchPlaylists();
  };

  const [
    ,
    setPlaylistAsActiveLoader,
    ,
    setPlaylistActiveInPlaylistChannel
  ] = useApi(PlaylistChannelsApi.setPlaylistActiveInChannel, {
    requestOnMount: false,
    successMessage: playlistActiveInChannel.id
      ? PLAYLIST_SET_AS_ACTIVE_SUCCESS
      : PLAYLIST_REMOVE_FROM_CHANNEL_SUCCESS,
    errorMessage: playlistActiveInChannel.id
      ? PLAYLIST_SET_AS_ACTIVE_ERROR
      : PLAYLIST_REMOVE_FROM_CHANNEL_ERROR,
    onSuccess: onSetPlaylistAsActiveSuccess
  });

  const assignPlaylistToChannel = () => {
    const playlistId = playlistActiveInChannel.id
      ? playlistActiveInChannel.id
      : "";
    const playlistChannelId = activeChannel.id;

    const payload = {
      playlistId,
      playlistChannelId
    };
    setPlaylistActiveInPlaylistChannel(payload);
  };

  const handleRemovePlaylistIconClick = channel => {
    handleSetActiveChannel(channel, "remove");
  };

  const removePlaylistFromChannel = () => {
    assignPlaylistToChannel();
  };

  useEffect(() => {
    if (!playlistsModalOpen) {
      previousPlaylistAssignedToChannelId.current = "";
    }
  }, [playlistsModalOpen]);

  return (
    <>
      <div className={classes["root"]}>
        <ChannelsTable
          channels={channels}
          setChannels={setChannels}
          selectedChannel={selectedChannel}
          toggleSelectedChannel={toggleSelectedChannel}
          drawerOpen={drawerOpen}
          handleSetActiveChannel={handleSetActiveChannel}
          fetchChannels={fetchChannels}
          channelsLoading={channelsLoading}
          onRemoveClick={handleRemovePlaylistIconClick}
        />
        <EditSelectedChannel
          selectedChannel={selectedChannel}
          toggleSelectedChannel={toggleSelectedChannel}
        />
      </div>

      <SetActivePlaylistModal
        open={playlistsModalOpen}
        onClose={closeAssignPlaylistModal}
        playlistActiveInChannel={playlistActiveInChannel}
        handleSetPlaylistActiveInChannel={handleSetPlaylistActiveInChannel}
        assignPlaylistToChannel={assignPlaylistToChannel}
        loading={setPlaylistAsActiveLoader}
      />

      <ConfirmationModal
        open={removeModalOpen}
        onClose={closeRemovePlaylistModal}
        onAction={removePlaylistFromChannel}
        actionName={t("common:remove")}
      >
        <div>
          {t("sections.playlist-channels.remove-playlist-from-channel")}
        </div>
      </ConfirmationModal>
    </>
  );
}

export default PlaylistChannels;
