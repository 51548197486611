import React from "react";
import { bool, string } from "prop-types";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ApplyButton.module.scss";

function ApplyButton({ label, disabled }) {
  return (
    <div className={classes["server-buttons"]}>
      <PrimaryButton type="submit" disabled={disabled}>
        {label}
      </PrimaryButton>
    </div>
  );
}

ApplyButton.propTypes = {
  label: string,
  disabled: bool
};

export default ApplyButton;
