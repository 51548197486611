import React from "react";
import { number, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import classes from "./ErrorLine.module.scss";

function ErrorLine({ showError, maxRenumberValue }) {
  const { t } = useTranslation("channel-list");

  return (
    <div className={classes["error-line"]}>
      <span
        className={clsx(classes["error-line-message"], {
          [classes["show-error"]]: showError
        })}
      >
        {t("warnings.number-too-large")}
        {maxRenumberValue}
      </span>
    </div>
  );
}

ErrorLine.propTypes = {
  showError: bool,
  maxRenumberValue: number
};

export default ErrorLine;
