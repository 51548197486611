import React from "react";
import PropTypes from "prop-types";

import FormField from "./form-field/FormField";
import FormInput from "./form-input/FormInput";
import FormLabel from "./form-label/FormLabel";
import FormTextarea from "./form-textarea/FormTextarea";

import classes from "./Form.module.scss";

function Form({ children, ...restProps }) {
  return (
    <form className={classes["form"]} {...restProps}>
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.node
};

Form.Field = FormField;
Form.Label = FormLabel;
Form.Input = FormInput;
Form.Textarea = FormTextarea;

export default Form;
