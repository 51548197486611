import React, { useState, useEffect } from "react";
import { string, bool, func } from "prop-types";
import { useTranslation } from "react-i18next";

import VideosApi from "services/api/3-content-api/videos-api";
import useApi from "hooks/useApi";
import { playlistsRequestsMessages } from "../../../../utils/utils";

import Modal from "components/ui/modals/modal/Modal";
import ModalVideosList from "./components/modal-videos-list/ModalVideosList";
import ModalButtons from "./components/modal-buttons/ModalButtons";
import ModalTitle from "./components/modal-title/ModalTitle";

function AddVideoModal({ open, onClose, actionName, addVideosToPlaylist }) {
  const [allVideos, setAllVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const { t } = useTranslation("video-playlists");
  const { VIDEOS_LOAD_ERROR } = playlistsRequestsMessages(t);
  const disabled = selectedVideos.length === 0;

  const [, , , fetchVideos] = useApi(VideosApi.getAllVideos, {
    requestOnMount: false,
    onSuccess: setAllVideos,
    errorMessage: VIDEOS_LOAD_ERROR
  });

  const addVideoToSelected = video => {
    if (selectedVideos.some(item => item.id === video.id)) {
      setSelectedVideos(
        selectedVideos.filter(selectedVideo => selectedVideo.id !== video.id)
      );
    } else {
      setSelectedVideos([...selectedVideos, video]);
    }
  };

  const handleAddVideosToPlaylist = () => {
    addVideosToPlaylist(selectedVideos);
  };

  const clearSelected = () => {
    setSelectedVideos([]);
  };

  useEffect(() => {
    if (open) {
      clearSelected();
      fetchVideos();
    }
  }, [open]);

  return (
    <Modal open={open}>
      <Modal.Header onClose={onClose}>
        <ModalTitle title={t("sections.playlists.add-video-to-playlist")} />
      </Modal.Header>
      <Modal.Content>
        <ModalVideosList
          videos={allVideos}
          selectedVideos={selectedVideos}
          addVideoToSelected={addVideoToSelected}
        />
      </Modal.Content>
      <Modal.Footer>
        <ModalButtons
          clearSelected={clearSelected}
          actionName={actionName}
          handleAddVideosToPlaylist={handleAddVideosToPlaylist}
          disabled={disabled}
          numberOfSelectedVideos={selectedVideos.length}
        />
      </Modal.Footer>
    </Modal>
  );
}

AddVideoModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  actionName: string.isRequired,
  addVideosToPlaylist: func.isRequired
};

export default AddVideoModal;
