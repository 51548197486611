import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";
import AccessManagementApi from "services/api/access-management-api/access-management-api";
import { companiesRequestsMessages } from "./utils/utils";
import LoadingContext from "contexts/LoadingContext";

import CompaniesTable from "./components/companies-table/CompaniesTable";
import CompanyDetails from "./components/company-details/CompanyDetails";

import classes from "./Companies.module.scss";

function Companies() {
  const [selectedCompany, setSelectedCompany] = useState({
    name: null,
    id: null
  });
  const [open, setOpen] = useState(true);
  const { t } = useTranslation("access-management");
  const { GET_COMPANIES_ERROR } = companiesRequestsMessages(t);
  const { setLoading: setProgressBarLoading } = useContext(LoadingContext);
  const [apiLoadSuccessful, setApiLoadSuccessful] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetSelectedCompany = () => {
    setSelectedCompany({ name: null, id: null });
  };

  const toggleSelectedCompany = company => {
    if (selectedCompany.name === null) {
      setSelectedCompany(company);
    } else if (
      selectedCompany.name !== null &&
      selectedCompany.id === company.id
    ) {
      setSelectedCompany({ name: null, id: null });
    } else {
      setSelectedCompany(company);
    }
  };

  const onLoadCompaniesSuccess = companies => {
    setApiLoadSuccessful(true);
    if (!!selectedCompany.id) {
      const fetchedSelectedCompany = companies.find(
        ({ id }) => id === selectedCompany.id
      );
      if (!!fetchedSelectedCompany) {
        setSelectedCompany(fetchedSelectedCompany);
      }
    }
  };

  const [companies = [], loading] = useApi(AccessManagementApi.getCompanies, {
    errorMessage: GET_COMPANIES_ERROR,
    onSuccess: onLoadCompaniesSuccess
  });

  useEffect(() => {
    setProgressBarLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (selectedCompany.name) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [selectedCompany]);

  return (
    <>
      <div className={classes.container}>
        <CompaniesTable
          companies={companies}
          selectedCompany={selectedCompany}
          toggleSelectedCompany={toggleSelectedCompany}
          open={open}
          apiLoadSuccessful={apiLoadSuccessful}
        />
        <CompanyDetails
          selectedCompany={selectedCompany}
          resetSelectedCompany={resetSelectedCompany}
        />
      </div>
    </>
  );
}

export default Companies;
