export const SOCKET_EVENTS = {
  VIDEO__TRANSCODING: "VIDEO__TRANSCODING",
  DOWNLOAD_VIDEO_TO_IDT_BEFORE_ADD_TO_PLAYLIST:
    "DOWNLOAD_VIDEO_TO_IDT_BEFORE_ADD_TO_PLAYLIST",
  DOWNLOAD_VIDEO_TO_IDT_AND_ADDED_TO_PLAYLIST:
    "DOWNLOAD_VIDEO_TO_IDT_AND_ADDED_TO_PLAYLIST",
  SPEED_TEST_IN_PROGRESS: "SPEED_TEST_IN_PROGRESS",
  SPEED_TEST_FINISHED: "SPEED_TEST_FINISHED",
  SERVER_BECAME_OFFLINE: "SERVER_BECAME_OFFLINE",
  ROLE_CHANGED: "ROLE_CHANGED",
  LOGOUT: "LOGOUT"
};
