import React, { useContext, useEffect, useState } from "react";
import { object, func } from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { VideoContext } from "contexts/VideoContext";
import { playlistChannelType } from "types";

import ChannelPlaylistPreview from "./components/channel-playlist-preview/ChannelPlaylistPreview";
import ChannelPlaylistInfo from "./components/channel-playlist-info/ChannelPlaylistInfo";
import ChannelSettingsIcon from "./components/channel-settings-icon/ChannelSettingsIcon";

import classes from "./ChannelItem.module.scss";

function ChannelItem({
  channel,
  setChannels,
  selectedChannel,
  toggleSelectedChannel,
  handleSetActiveChannel,
  toggleBroadcastModalOpen,
  setChannelToBeModified,
  onRemoveClick
}) {
  const [activePlaylist, setActivePlaylist] = useState({
    id: undefined,
    name: undefined
  });
  const { availablePlaylists } = useContext(VideoContext);
  const selected = selectedChannel.id === channel.id;
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.playlist-channels";

  useEffect(() => {
    const currentPlaylist = availablePlaylists.find(
      item => item.id === channel.currentPlaylistID
    );

    if (currentPlaylist !== undefined) {
      setActivePlaylist(currentPlaylist);
    }
  }, [availablePlaylists, channel]);

  const handleItemClick = e => {
    e.stopPropagation();
    toggleSelectedChannel(channel);
  };

  const getCurrentPlaylistInfo = () => {
    const { id, name } = activePlaylist;

    return id !== undefined && name !== undefined
      ? { name, assigned: true }
      : { name: t(`${PREFIX}.no-playlist-assigned`), assigned: false };
  };

  return (
    <div
      className={clsx(classes["channel-item"], {
        [classes["disabled-background"]]: !channel.enabled
      })}
      onClick={handleItemClick}
      {...{ open: selected }}
    >
      <ChannelPlaylistPreview
        channel={channel}
        getCurrentPlaylistInfo={getCurrentPlaylistInfo}
      />
      <ChannelPlaylistInfo
        channel={channel}
        setChannels={setChannels}
        handleSetActiveChannel={handleSetActiveChannel}
        getCurrentPlaylistInfo={getCurrentPlaylistInfo}
        onRemoveClick={onRemoveClick}
      />
      <ChannelSettingsIcon
        channel={channel}
        toggleBroadcastModalOpen={toggleBroadcastModalOpen}
        setChannelToBeModified={setChannelToBeModified}
      />
    </div>
  );
}

ChannelItem.propTypes = {
  channel: playlistChannelType,
  setChannels: func,
  selectedChannel: object.isRequired,
  toggleSelectedChannel: func.isRequired,
  handleSetActiveChannel: func.isRequired,
  toggleBroadcastModalOpen: func.isRequired,
  setChannelToBeModified: func.isRequired,
  onRemoveClick: func
};

export default ChannelItem;
