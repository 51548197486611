import React from "react";
import { string } from "prop-types";

import classes from "./EllipsisLoader.module.scss";

function EllipsisLoader({ color }) {
  const style = {
    background: color ? color : "#9e9e9e"
  };

  return (
    <div className={classes["lds-ellipsis"]}>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
    </div>
  );
}

EllipsisLoader.propTypes = {
  color: string
};

export default EllipsisLoader;
