import React, { useEffect, useState } from "react";
import { bool, func } from "prop-types";

import Modal from "components/ui/modals/modal/Modal";
import LanguageRadioSelector from "../../../language-select/LanguageRadioSelector";
import { useTranslation } from "react-i18next";

function LanguageModal({ languageOpen, toggleLanguageModal }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("edit-profile", { useSuspense: false });

  useEffect(() => {
    setOpen(languageOpen);
  }, [languageOpen]);

  const handleClose = () => {
    setOpen(false);
    toggleLanguageModal();
  };

  return (
    <Modal open={open}>
      <Modal.Header onClose={handleClose}>
        {t("language-modal.language-selection")}
      </Modal.Header>
      <Modal.Content>
        <LanguageRadioSelector />
      </Modal.Content>
    </Modal>
  );
}

LanguageModal.propTypes = {
  languageOpen: bool,
  toggleLanguageModal: func,
  toggleDialogOpen: func
};

export default LanguageModal;
