import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class EditProfileApi {
  getToken = () => cookies.get("token");

  editUser = async (_, payload) => {
    const method = `patch`;
    const path = `/api/v1/user/edit`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };
}

export default new EditProfileApi();
