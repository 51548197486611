import React from "react";
import { func, string } from "prop-types";

import classes from "./DeleteLine.module.scss";

function DeleteLine({ onClick, label }) {
  return (
    <div className={classes["delete-line"]} onClick={onClick}>
      {label}
    </div>
  );
}

DeleteLine.propTypes = {
  onClick: func.isRequired,
  label: string
};

export default DeleteLine;
