import React from "react";
import { func, bool } from "prop-types";
import { useTranslation } from "react-i18next";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./CreateBackupButton.module.scss";

function CreateBackupButton({
  onClick,
  isActionInProgress,
  isRebootInProgress
}) {
  const { t } = useTranslation("servers");

  return (
    <div className={classes["container"]}>
      {isRebootInProgress ? (
        <div className={classes["reboot-in-progress"]}>
          {t("server-rebooting")}...
        </div>
      ) : isActionInProgress ? (
        <div className={classes["action-in-progress"]}>
          <div>{t("backups.warnings.backup-in-progress")}</div>
          <div>{t("backups.warnings.no-action")}</div>
        </div>
      ) : (
        <div className={classes["create-backup-button"]}>
          <PrimaryButton
            onClick={onClick}
            disabled={isActionInProgress}
            primaryButtonClass={classes["run-button"]}
          >
            {t("backups.buttons.create-backup")}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

CreateBackupButton.propTypes = {
  onClick: func,
  isActionInProgress: bool,
  isRebootInProgress: bool
};

export default CreateBackupButton;
