import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { arrayOf, bool, func, number, object } from "prop-types";
import clsx from "clsx";

import useApi from "hooks/useApi";
import ServersApi from "services/api/6-servers-api/servers-api";
import { playlistsRequestsMessages } from "../../../../utils/utils";

import Modal from "components/ui/modals/modal/Modal";
import Table from "components/ui/table/Table";
import ServerSynchronized from "pages/servers/components/Table/components/server-synchronized/ServerSynchronized";
import ServerStatus from "pages/servers/components/Table/components/server-status/ServerStatus";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import PlaylistItemPlaceholder from "../playlist-item-placeholder/PlaylistItemPlaceholder";
import MissingVideosWarning from "./missing-videos-warning/MissingVideosWarning";

import classes from "./CopyModal.module.scss";

const placeholders = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function CopyModal({
  open,
  onClose,
  loading,
  selectedServerId,
  setSelectedServerId,
  openConfirmationModal,
  missingVideos,
  setMissingVideos
}) {
  const { t } = useTranslation(["video-playlists", "servers", "common"]);
  const { GET_SERVERS_ERROR } = playlistsRequestsMessages(t);
  const history = useHistory();

  const [servers = [], , , fetchServers] = useApi(ServersApi.getServers, {
    errorMessage: GET_SERVERS_ERROR,
    requestOnMount: false
  });

  useEffect(() => {
    if (!open) {
      setSelectedServerId(null);
    } else {
      fetchServers();
    }
    history.push("/content/video-playlist-channels/playlists");
  }, [open]);

  const handleCloseModal = () => {
    onClose();
    setTimeout(() => {
      setMissingVideos([]);
    }, 500);
  };

  return (
    <Modal open={open}>
      <Modal.Header loading={loading} onClose={onClose}>
        <div>{t("sections.playlists.copy-modal.header")}</div>
      </Modal.Header>
      <Modal.Content>
        <div
          className={clsx({
            [classes["table-container"]]: !missingVideos.length,
            [classes["warning-width"]]: missingVideos.length
          })}
        >
          {missingVideos.length ? (
            <MissingVideosWarning missingVideos={missingVideos} />
          ) : servers.length ? (
            <Table
              centered
              isInModal
              tableContainerClass={classes["top-border"]}
              fullWidth
            >
              <Table.Head>
                <th>{t("servers:name")}</th>
                <th>{t("servers:status")}</th>
                <th>{t("servers:synchronized")}</th>
              </Table.Head>
              <Table.Body>
                {servers.map(server => {
                  const selected = server.id === selectedServerId;
                  return (
                    <Table.Row
                      key={server.id}
                      selected={selected}
                      disabled={!server.isOnline}
                      onSelect={() => setSelectedServerId(server.id)}
                      className={classes["disabled"]}
                    >
                      <td className={classes["table-cell"]}>
                        <div className={classes["server-name-container"]}>
                          <div className={classes["server-name"]}>
                            {server.name.toUpperCase()}
                          </div>
                        </div>
                      </td>
                      <td className={classes["table-cell"]}>
                        <ServerStatus status={server.isOnline} />
                      </td>
                      <td className={classes["table-cell"]}>
                        <ServerSynchronized
                          synchronized={server.assetsSynchronizationStatus}
                        />
                      </td>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          ) : (
            placeholders.map(item => (
              <PlaylistItemPlaceholder
                key={item}
                customClass={classes["loader-row"]}
              />
            ))
          )}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <PrimaryButton
          disabled={!selectedServerId}
          onClick={
            missingVideos.length ? handleCloseModal : openConfirmationModal
          }
        >
          {missingVideos.length ? "Ok" : t("common:push")}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

CopyModal.propTypes = {
  open: bool,
  onClose: func,
  loading: bool,
  selectedServerId: number,
  setSelectedServerId: func,
  openConfirmationModal: func,
  missingVideos: arrayOf(object),
  setMissingVideos: func
};

export default CopyModal;
