import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AuthService from "services/auth";
import useApi from "hooks/useApi";
import useTimeout from "hooks/useTimeout";
import AccessManagementAPi from "services/api/access-management-api/access-management-api";
import AuthContext from "contexts/AuthContext";
import { ServerContext } from "contexts/ServerContext";

const COMPANIES_GET = "COMPANIES_GET";

function useLoginHandler() {
  const [companies, setCompanies] = useState([]);
  const [companiesLoadError, setCompaniesLoadError] = useState(false);
  const [credentials, setCredentials] = useState({
    user: null,
    token: "",
    relogged: false,
    delay: 0
  });
  const { user, token, relogged, delay } = credentials;
  const { push, location } = useHistory();
  const setTimeOut = useTimeout();
  const {
    state,
    actions: { setIsAuthenticated }
  } = useContext(AuthContext);
  const { serverInUse } = useContext(ServerContext);

  const handleCompaniesLoadError = () => {
    setCompaniesLoadError(true);
    setCompanies([]);
  };

  const [, , , fetchCompanies] = useApi(AccessManagementAPi.getCompanies, {
    requestOnMount: false,
    onSuccess: setCompanies,
    onError: handleCompaniesLoadError
  });

  const setLoginCredentials = data => {
    setCredentials({ ...credentials, ...data });
  };

  const redirect = () => {
    if (!relogged) {
      if (!serverInUse.hasOwnProperty("id")) {
        setTimeOut(() => push("/servers"), delay);
      } else {
        setTimeOut(() => push("/dashboard/overview"), delay);
      }
    } else {
      if (state.refreshPageAfterAuth) {
        const currentLocation = location.pathname;
        push("/reload");
        push(currentLocation);
      }
    }
  };

  useEffect(() => {
    if (user && token) {
      AuthService.setTokenInCookie(token);
      setIsAuthenticated(true);

      if (user.permissions.includes(COMPANIES_GET)) {
        fetchCompanies();
      } else {
        AuthService.setUserInStorage(user);
        redirect();
      }
    }
  }, [credentials]);

  useEffect(() => {
    if (user && user.permissions.includes(COMPANIES_GET)) {
      let updatedUser = {
        ...user,
        hasCompaniesAssigned: !!companies.length
      };

      if (companiesLoadError) {
        updatedUser = { ...updatedUser, companiesLoadError };
      }

      AuthService.setUserInStorage(updatedUser);
      redirect();
    }
  }, [companies]);

  return setLoginCredentials;
}

export default useLoginHandler;
