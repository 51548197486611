import React, { useRef } from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";

import classes from "./PrimaryButton.module.scss";

function PrimaryButton({ children, primaryButtonClass = null, ...restProps }) {
  const buttonRef = useRef();
  if (buttonRef.current) {
    // @ts-ignore
    buttonRef.current.blur();
  }

  return (
    <Button
      {...restProps}
      className={clsx(classes["btn"], {
        [primaryButtonClass]: primaryButtonClass
      })}
      ref={buttonRef}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
