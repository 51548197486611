import React from "react";
import { bool } from "prop-types";
import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./SetActionLoader.module.scss";

function SetActionLoader({ loading }) {
  return loading ? (
    <div className={classes["set-action-on-loader-container"]}>
      <div className={classes["set-action-on-loader"]}>
        <EllipsisLoader />
      </div>
    </div>
  ) : null;
}

SetActionLoader.propTypes = {
  loading: bool
};

export default SetActionLoader;
