import React, { useEffect, useState } from "react";
import { arrayOf, object } from "prop-types";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import moment from "moment";

import { BAR_COLORS } from "../../../../../../utils/utils";

import ChartLegend from "../../../chart-legend/ChartLegend";
import CustomTooltip from "../../../custom-tooltip/CustomTooltip";
import XAxisTick from "../../../x-axis-tick/XAxisTick";

import classes from "./Chart.module.scss";

function Chart({ data }) {
  const { ONLINE_FILL, OFFLINE_FILL, TOOLTIP_CURSOR } = BAR_COLORS;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data.length) {
      const dataArray = data.reverse().map(item => {
        return {
          ...item,
          date: moment(item.createdAt).local().format("DD MMM, hh:mm"),
          endpointsOffline: item.totalEndpoints - item.endpointsOnline
        };
      });

      setChartData(dataArray);
    }
  }, [data]);

  return (
    <div className={classes["endpoints-stats-chart"]}>
      {chartData.length ? (
        <>
          <ChartLegend entities="endpoints" />
          <BarChart width={700} height={200} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#000" />
            <XAxis
              dataKey="date"
              tick={false}
              height={60}
              // @ts-ignore
              tick={<XAxisTick />}
            />
            <YAxis type="number" domain={[0, 5]} />
            <Tooltip
              cursor={{ stroke: TOOLTIP_CURSOR, strokeWidth: 2 }}
              content={
                <CustomTooltip
                  offline="endpointsOffline"
                  online="endpointsOnline"
                />
              }
            />
            <ReferenceLine y={0} stroke="#000" />
            <Brush dataKey="date" height={20} stroke={ONLINE_FILL} />
            <Bar dataKey="endpointsOnline" stackId="a" fill={ONLINE_FILL} />
            <Bar dataKey="endpointsOffline" stackId="a" fill={OFFLINE_FILL} />
          </BarChart>
        </>
      ) : null}
    </div>
  );
}

Chart.propTypes = {
  data: arrayOf(object)
};

export default Chart;
