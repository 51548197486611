import React from "react";
import { string, bool, func } from "prop-types";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";

import classes from "./SidePanelHeader.module.scss";

function SidePanelHeader({
  title,
  loading = false,
  onClose,
  sidePanelHeaderClass = ""
}) {
  return (
    <div
      className={clsx(classes["selected-group-header"], {
        [sidePanelHeaderClass]: sidePanelHeaderClass
      })}
    >
      <div className={classes["title-loader-container"]}>
        <div className={classes["title"]}>{title}</div>
        {loading && (
          <div className={classes["loader"]}>
            <div className={classes["loader-container"]}>
              <EllipsisLoader />
            </div>
          </div>
        )}
      </div>
      <CloseIcon className={classes["close-icon"]} onClick={onClose} />
    </div>
  );
}

SidePanelHeader.propTypes = {
  title: string,
  loading: bool,
  onClose: func.isRequired,
  sidePanelHeaderClass: string
};

export default SidePanelHeader;
