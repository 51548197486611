import React from "react";
import { string, func } from "prop-types";

import CloseIcon from "@material-ui/icons/Close";

import classes from "./EditChannelTopBar.module.scss";

function EditChannelTopBar({ channelName, activePlaylistName, onClose }) {
  return (
    <div className={classes["edit-channel-top-bar"]}>
      <div className={classes["playlist-channel-container"]}>
        <div className={classes["selected-channel-name"]}>{channelName}</div>
        {activePlaylistName && (
          <div className={classes["active-playlist-name"]}>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;<span>{activePlaylistName}</span>
          </div>
        )}
      </div>

      <div className={classes["close-icon-container"]}>
        <CloseIcon className={classes["close-icon"]} onClick={onClose} />
      </div>
    </div>
  );
}

EditChannelTopBar.propTypes = {
  channelName: string,
  activePlaylistName: string,
  onClose: func.isRequired
};

export default EditChannelTopBar;
