import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext({
  userInContext: {},
  setUserInContext: null
});

export default function UserContextProvider({ children }) {
  const getUserDetailsFromLocalStorage = () => {
    const localData = localStorage.getItem("userDetails");
    return localData ? JSON.parse(localData) : {};
  };
  const [userInContext, setUserInContext] = useState(
    getUserDetailsFromLocalStorage()
  );

  useEffect(() => {
    localStorage.setItem("userDetails", JSON.stringify(userInContext));
  }, [userInContext]);

  return (
    <UserContext.Provider value={{ userInContext, setUserInContext }}>
      {children}
    </UserContext.Provider>
  );
}
