export function assignEndpointGroupsRequestMessages(t) {
  const GET_ENDPOINTS_ERROR = {
    message: t("snacks.endpoint-groups-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const ADDED = {
    message: t("snacks.new-group-added"),
    variant: "success",
    autoHideDuration: 2000
  };

  const ADDING_ERROR = {
    message: t("snacks.new-group-could-not-be-added"),
    variant: "error",
    autoHideDuration: 3500
  };

  const DELETED = {
    message: t("snacks.group-deleted"),
    variant: "success",
    autoHideDuration: 2000
  };

  const DELETING_ERROR = {
    message: t("snacks.group-could-not-be-deleted"),
    variant: "error",
    autoHideDuration: 3500
  };

  const EDITED = {
    message: t("group-updated"),
    variant: "success",
    autoHideDuration: 2000
  };

  const EDITTING_ERROR = {
    message: t("group-could-not-be-updated"),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    GET_ENDPOINTS_ERROR,
    ADDED,
    ADDING_ERROR,
    DELETED,
    DELETING_ERROR,
    EDITED,
    EDITTING_ERROR
  };
}
