import {
  shape,
  string,
  oneOf,
  number,
  bool,
  arrayOf,
  oneOfType,
} from "prop-types";

export const startUpType = oneOf(["DEFAULT", "LAST-USED"]);

export const messageType = shape({
  message: string.isRequired,
  variant: oneOf(["success", "warning", "error"]).isRequired,
  autoHideDuration: number,
});

export const channelOptionType = shape({
  id: string.isRequired,
  name: string.isRequired,
  logoPath: string.isRequired,
});

export const endpointType = shape({
  id: string.isRequired,
  name: string.isRequired,
  currentChannelID: string.isRequired,
  currentChannel: string.isRequired,
  subtitles: bool.isRequired,
  volume: string.isRequired,
  online: bool.isRequired,
  fullscreen: bool.isRequired,
  template: string.isRequired,
  bootmode: startUpType,
});

export const groupType = shape({
  id: string.isRequired,
  name: string.isRequired,
  checked: bool.isRequired,
  endpoints: arrayOf(endpointType).isRequired,
});

export const thumbPlacementType = oneOf([
  "bottom-end",
  "bottom-start",
  "bottom",
  "left-end",
  "left-start",
  "left",
  "right-end",
  "right-start",
  "right",
  "top-end",
  "top-start",
  "top",
]);

export const styleType = shape({
  top: oneOfType([number, string]),
  color: string,
  "text-align": oneOf(["left", "center", "right"]),
  "font-size": oneOfType([number, string]),
  "font-family": string,
  "font-weight": string,
});

export const playlistChannelType = shape({
  enabled: bool,
  id: string,
  channelname: string,
  currentPlaylistID: string,
  multicastaddress: string,
  type: string,
  multicastport: string,
  multicastttl: string,
  address: string,
  assignedtogroup: bool,
  port: number,
  lcn: string,
  logo: string,
  name: string,
  broadcasting: bool,
  state: oneOf(["disable", "enable", "stop", "transmit"]),
});

export const requestPayloadType = shape({
  groups: arrayOf(string),
  endpoints: arrayOf(string),
});
