import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import clsx from "clsx";

import useTimeout from "hooks/useTimeout";

import ExpandIcon from "./components/expand-icon/ExpandIcon";

import classes from "./DrawerNavLink.module.scss";

function DrawerNavLink({
  disabled,
  link,
  Icon,
  name,
  nested = false,
  isActivePaths = null,
  collapsable = false,
  open = false,
  ...restProps
}) {
  const [linkActive, setLinkActive] = useState(false);
  const setTimeOut = useTimeout();

  // Prevents from getting the error:
  // "Cannot update a component (`DrawerNavLink`) while rendering a different component (`Router.Consumer`)."
  const setLinkActiveWithTimeout = (flag) => {
    setTimeOut(() => setLinkActive(flag), 0);
  };

  const setIsActive = (match, location) => {
    if (!isActivePaths && match) {
      setLinkActiveWithTimeout(true);
      return true;
    } else if (isActivePaths) {
      const isActive = isActivePaths.includes(location.pathname);

      setLinkActiveWithTimeout(isActive);
      return isActive;
    }

    setLinkActiveWithTimeout(false);
    return false;
  };

  const handleOnClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <NavLink
      {...restProps}
      to={link}
      className={clsx({ [classes["link-disabled"]]: disabled })}
      activeClassName={classes["selected"]}
      isActive={setIsActive}
      onClick={handleOnClick}
    >
      <div className={classes["navlink-contents"]}>
        <ExpandIcon open={open} collapsable={collapsable} disabled={disabled} />
        <ListItem
          button
          disabled={disabled}
          className={clsx({ [classes["nested"]]: nested })}
        >
          <ListItemText
            primary={name}
            classes={{
              primary: !linkActive
                ? clsx(classes["small-font"], classes["not-selected"])
                : classes["small-font"],
            }}
          />
          <ListItemIcon
            className={clsx(classes["icon"], {
              [classes["not-selected"]]: !linkActive,
            })}
          >
            {Icon}
          </ListItemIcon>
        </ListItem>
      </div>
    </NavLink>
  );
}

export default DrawerNavLink;
