import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class VideosApi {
  getToken = () => cookies.get("token");

  getAllVideos = async () => {
    const method = "get";
    const path = `/api/v1/files?type=video`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return await axios({
      method,
      url,
      headers
    });
  };

  renameVideo = async (_, payload) => {
    const { videoId, name } = payload;
    const method = "patch";
    const path = `/api/v1/files/${videoId}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        name
      }
    });
  };

  deleteVideos = async (_, payload) => {
    const method = "delete";
    const path = `/api/v1/files`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return await axios({
      method,
      url,
      headers,
      data: payload
    });
  };

  uploadVideo = async (_, payload) => {
    const { videoToBeUploaded, showProgress } = payload;
    const method = "post";
    const path = "/api/v1/files";
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const formData = new FormData();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    };

    formData.append("file", videoToBeUploaded);

    return await axios({
      method,
      url,
      headers,
      onUploadProgress: showProgress,
      data: formData
    });
  };
}

export default new VideosApi();
