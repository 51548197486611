import React from "react";
import { func, object, number } from "prop-types";
import { useTranslation } from "react-i18next";

import { MAX_DEFAULT_LEN, MAX_PRODUCT_KEY_LEN } from "data/constants";
import usePageFeatures from "hooks/usePageFeatures";
import { permissions } from "data/permissions";
import {
  validateRequired,
  validateProductKey,
  getInputError,
  validateDefault
} from "utils/utils";
import CompanySelect from "components/ui/select/company-select/CompanySelect";

import Form from "components/ui/form/Form";

function AddServerForm({ register, errors, companyId, handleCompanySelect }) {
  const { t } = useTranslation("servers");
  const featuresAccess = usePageFeatures({
    companies: permissions.companies.COMPANIES_GET
  });

  return (
    <Form>
      <Form.Field>
        <Form.Label htmlFor="name">{t("name")}:</Form.Label>
        <Form.Input
          // @ts-ignore
          id="name"
          name="name"
          ref={register({
            ...validateRequired,
            maxLength: {
              value: MAX_DEFAULT_LEN,
              message: "common:form.max-length"
            },
            ...validateDefault
          })}
          error={getInputError(errors.name, t, MAX_DEFAULT_LEN)}
        />
      </Form.Field>

      <Form.Field>
        <Form.Label htmlFor="licence-key">{t("licence-key")}</Form.Label>
        <Form.Input
          // @ts-ignore
          id="licence-key"
          name="productKey"
          ref={register({
            ...validateRequired,
            ...validateProductKey
          })}
          error={getInputError(errors.productKey, t, MAX_PRODUCT_KEY_LEN)}
        />
      </Form.Field>

      {featuresAccess && featuresAccess.companies && (
        <CompanySelect
          ignoreRoles
          companyId={companyId}
          selectedRole={null}
          handleCompanySelect={handleCompanySelect}
        />
      )}
    </Form>
  );
}

AddServerForm.propTypes = {
  register: func,
  errors: object,
  companyId: number,
  handleCompanySelect: func
};

export default AddServerForm;
