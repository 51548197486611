import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, func } from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ServersApi from "services/api/6-servers-api/servers-api";
import useApi from "hooks/useApi";
import { backupsRequestsMessages } from "./utils/utils";
import { SYNC_STATUSES } from "../../../../utils/utils";

import BackupsList from "./components/backups-list/BackupsList";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./Backups.module.scss";

function Backups({ server, setSelectedServer, fetchServers }) {
  const [backups, setBackups] = useState([]);
  const [backupsSupported, setBackupsSupported] = useState(true);
  const [createBackupModalOpen, setCreateBackupModalOpen] = useState(false);
  const { t } = useTranslation("servers");
  const { CREATE_BACKUP_SUCCESS, CREATE_BACKUP_ERROR } =
    backupsRequestsMessages(t);
  const {
    id: serverId,
    isOnline,
    currentBranch,
    isSnapshotActionInProgress,
    assetsSynchronizationStatus,
    isRebootInProgress,
  } = server;
  const { IN_PROGRESS } = SYNC_STATUSES;

  const openCreateBackupModal = () => {
    setCreateBackupModalOpen(true);
  };

  const closeCreateBackupModal = () => {
    setCreateBackupModalOpen(false);
  };

  const onLoadBackupsSuccess = ({ snapshots: backups }) => {
    setBackups(backups);
  };

  const onLoadBackupsError = () => {
    setBackupsSupported(false);
  };

  const onCreateBackupSuccess = () => {
    closeCreateBackupModal();
    setSelectedServer({ ...server, isSnapshotActionInProgress: true });
    fetchServers();
  };

  const [, , status, fetchBackups] = useApi(ServersApi.getBackups, {
    requestOnMount: false,
    onSuccess: onLoadBackupsSuccess,
    onError: onLoadBackupsError,
    showMessageOnError: false,
  });

  const [, createBackupLoader, , createBackup] = useApi(
    ServersApi.createBackup,
    {
      requestOnMount: false,
      onSuccess: onCreateBackupSuccess,
      successMessage: CREATE_BACKUP_SUCCESS,
      errorMessage: CREATE_BACKUP_ERROR,
    }
  );

  const createServerBackup = () => {
    createBackup(serverId);
  };

  useEffect(() => {
    if (status === 500) {
      setBackupsSupported(false);
    }
  }, [status]);

  useEffect(() => {
    if (serverId) {
      fetchBackups(serverId);
    }
  }, [server]);

  useEffect(() => {
    return () => {
      setBackups([]);
    };
  }, []);

  return isOnline && assetsSynchronizationStatus !== IN_PROGRESS ? (
    <div className={classes["backups"]}>
      <Accordion classes={{ root: classes["backups-panel"] }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon className={classes["backups-panel-icon"]} />
          }
          classes={{ root: classes["backups-panel-summary"] }}
        >
          <div className={classes["backups-panel-summary-header"]}>
            {t("backups.header")}
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes["backups-panel-details"] }}>
          <BackupsList
            backups={backups}
            serverId={serverId}
            currentBranch={currentBranch}
            isActionInProgress={isSnapshotActionInProgress}
            isRebootInProgress={isRebootInProgress}
            isVisible={backupsSupported}
            openCreateBackupModal={openCreateBackupModal}
            fetchBackups={fetchBackups}
          />
        </AccordionDetails>
      </Accordion>

      <ConfirmationModal
        open={createBackupModalOpen}
        onClose={closeCreateBackupModal}
        onAction={createServerBackup}
        actionName={t("backups.buttons.create-backup")}
        disabled={createBackupLoader}
        loading={createBackupLoader}
      >
        {t("backups.modals.create-backup")}
      </ConfirmationModal>
    </div>
  ) : null;
}

Backups.propTypes = {
  server: object,
  setSelectedServer: func,
  fetchServers: func,
};

export default Backups;
