import React from "react";
import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import clsx from "clsx";

import classes from "../../ControlPanel.module.scss";

function NotSelectedOption({ customClass }) {
  const { t } = useTranslation("control");
  return (
    <div
      className={clsx(classes["not-selected-option"], {
        [customClass]: customClass,
      })}
    >
      {t("select-group-or-and-endpoints")}
    </div>
  );
}

NotSelectedOption.propTypes = {
  customClass: string,
};

export default NotSelectedOption;
