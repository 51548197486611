import React, { useEffect, useContext, useState } from "react";
import { arrayOf, object, bool, func, string } from "prop-types";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

import PlaylistsApi from "services/api/3-content-api/playlists-api";
import useApi from "hooks/useApi";
import { playlistsRequestsMessages } from "pages/content/video-playlist-channels/components/playlists/utils/utils";
import { VideoContext } from "contexts/VideoContext";
import { ServerContext } from "contexts/ServerContext";
import { renderThumbVertical, renderTrackVertical } from "utils/utils";

import ListDrawer from "components/ui/drawers/ListDrawer";
import PlaylistItem from "./components/playlist-item/PlaylistItem";
import CopyModal from "./components/copy-modal/CopyModal";
import PlaylistItemPlaceholder from "./components/playlist-item-placeholder/PlaylistItemPlaceholder";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

const placeholders = [1, 2, 3, 4, 5, 6, 7];

function PlaylistsTable({
  playlists,
  selectedPlaylist,
  toggleSelectedPlaylist,
  playlistsLoading,
  open,
  editedPlaylist,
  setEditedPlaylist,
  setPlaylistToBeDeleted,
  renameEditedPlaylist,
  toggleModalOpen,
  updatingPlaylists,
  fetchPlaylists
}) {
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [playlistToBeCopied, setPlaylistToBeCopied] = useState({
    id: undefined,
    name: undefined
  });
  const [selectedServerId, setSelectedServerId] = useState(null);
  const [missingVideos, setMissingVideos] = useState([]);

  const [openSideMenuId, setOpenSideMenuId] = useState("");

  const { setEscPressed } = useContext(VideoContext);
  const { t } = useTranslation("video-playlists");
  const { serverInUse } = useContext(ServerContext);
  const { COPY_PLAYLIST_TO_IDT_ERROR, COPY_PLAYLIST_TO_IDT_SUCCESS } =
    playlistsRequestsMessages(t);

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        setEditedPlaylist({ id: undefined, name: undefined });
        setOpenSideMenuId("");
        setEscPressed(true);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (
      playlistToBeCopied.id !== undefined &&
      playlistToBeCopied.name !== undefined
    ) {
      openCopyModal();
    }
  }, [playlistToBeCopied]);

  const onCopyPlaylistSuccess = data => {
    if (data.missingVideos.length) {
      setMissingVideos(data.missingVideos);
    } else {
      closeCopyModal();
    }
  };

  const [, copyLoading, , copyPlaylistToIDT] = useApi(
    PlaylistsApi.copyPlaylistToIDT,
    {
      requestOnMount: false,
      errorMessage: COPY_PLAYLIST_TO_IDT_ERROR,
      successMessage: COPY_PLAYLIST_TO_IDT_SUCCESS,
      onSuccess: onCopyPlaylistSuccess
    }
  );

  const copyPlaylistToSelectedIDT = () => {
    const payload = {
      copiedPlaylistName: playlistToBeCopied.name,
      sourcePlaylistId: playlistToBeCopied.id,
      destinationServerId: selectedServerId
    };

    copyPlaylistToIDT(payload);

    if (selectedServerId === serverInUse.id) {
      fetchPlaylists();
    }
  };

  const openCopyModal = () => {
    setCopyModalOpen(true);
  };

  const closeCopyModal = () => {
    setCopyModalOpen(false);
    setPlaylistToBeCopied({ id: undefined, name: undefined });
  };

  const openConfirmationModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmModalOpen(false);
  };

  const handleCopyPlaylistToSelectedIDT = () => {
    closeConfirmationModal();
    copyPlaylistToSelectedIDT();
  };

  useEffect(() => {
    if (!editedPlaylist.id) {
      setEscPressed(false);
    }
  }, [editedPlaylist, setEscPressed]);

  return (
    <>
      <ListDrawer open={open}>
        <Scrollbars
          autoHide
          autoHideTimeout={3000}
          autoHideDuration={1000}
          autoHeight
          autoHeightMax="calc(100vh - 9.5rem)"
          renderTrackVertical={renderTrackVertical}
          renderThumbVertical={renderThumbVertical}
        >
          {!playlistsLoading
            ? playlists.map(playlist => (
                <PlaylistItem
                  key={playlist.id}
                  playlist={playlist}
                  selectedPlaylist={selectedPlaylist}
                  toggleSelectedPlaylist={toggleSelectedPlaylist}
                  editedPlaylist={editedPlaylist}
                  setEditedPlaylist={setEditedPlaylist}
                  setPlaylistToBeDeleted={setPlaylistToBeDeleted}
                  toggleModalOpen={toggleModalOpen}
                  renameEditedPlaylist={renameEditedPlaylist}
                  updating={updatingPlaylists.includes(playlist.id)}
                  setPlaylistToBeCopied={setPlaylistToBeCopied}
                  setOpenSideMenuId={setOpenSideMenuId}
                  openSideMenuId={openSideMenuId}
                />
              ))
            : placeholders.map(item => <PlaylistItemPlaceholder key={item} />)}
        </Scrollbars>
      </ListDrawer>

      <CopyModal
        open={copyModalOpen}
        loading={copyLoading}
        onClose={closeCopyModal}
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        openConfirmationModal={openConfirmationModal}
        missingVideos={missingVideos}
        setMissingVideos={setMissingVideos}
      />

      <ConfirmationModal
        open={confirmModalOpen}
        onClose={closeConfirmationModal}
        onAction={handleCopyPlaylistToSelectedIDT}
        actionName={t("common:push")}
      >
        {t("sections.playlists.push-playlist-to-IDT")}
      </ConfirmationModal>
    </>
  );
}

PlaylistsTable.propTypes = {
  playlists: arrayOf(object).isRequired,
  selectedPlaylist: object,
  toggleSelectedPlaylist: func.isRequired,
  playlistsLoading: bool,
  open: bool.isRequired,
  editedPlaylist: object,
  setEditedPlaylist: func.isRequired,
  setPlaylistToBeDeleted: func,
  renameEditedPlaylist: func.isRequired,
  toggleModalOpen: func.isRequired,
  updatingPlaylists: arrayOf(string).isRequired,
  fetchPlaylists: func.isRequired
};

export default PlaylistsTable;
