import React, { useEffect, useRef } from "react";
import { func, object, string, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import clsx from "clsx";

import classes from "./AutocompleteInput.module.scss";

const MAX_LENGTH = 80;

function AutocompleteInput({
  params,
  placeholder,
  handleTextFieldChange,
  handleKeyPress,
  setIsMessageTooLong
}) {
  const { t } = useTranslation("common");
  const messageTooLong = useRef(false);
  const customParams = {
    ...params,
    inputProps: { ...params.inputProps }
  };

  const handleOnInput = e => {
    messageTooLong.current = e.target.value.length > MAX_LENGTH;
  };

  useEffect(() => {
    setIsMessageTooLong(messageTooLong.current);
  }, [messageTooLong.current]);

  return (
    <>
      <TextField
        {...customParams}
        variant="outlined"
        placeholder={placeholder}
        onChange={handleTextFieldChange}
        onKeyPress={handleKeyPress}
        fullWidth
        onInput={handleOnInput}
      />
      <span
        className={clsx(classes["warning-message"], {
          [classes["warning-message-show"]]: messageTooLong.current
        })}
      >
        {`${t("form.max-length")}: ${MAX_LENGTH}`}
      </span>
    </>
  );
}

AutocompleteInput.propTypes = {
  params: object,
  placeholder: string,
  handleTextFieldChange: func,
  handleKeyPress: func,
  setIsMessageTooLong: bool
};

export default AutocompleteInput;
