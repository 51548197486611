import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { getStatusTextAndSteps } from "pages/content/video-playlist-channels/components/videos/utils/utils";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AnimatedProgress from "../animated-progress/AnimatedProgress";

import classes from "./VideoPlaceholder.module.scss";

const Container = "div";
const Image = "div";
const Progress = "div";
const ProgressInfo = "div";
const Steps = "div";
const Status = "div";
const Percentage = "div";
const Background = "div";
const Screenshots = "div";
const VideoName = "div";

function VideoPlaceholder({
  name = "Some name",
  progress = 0,
  status = 0,
  screenshotsTaken,
  totalScreenshots
}) {
  const { t } = useTranslation("video-playlists");
  const { statusText, step } = getStatusTextAndSteps(t)(status);
  const totalSteps = 4;

  return (
    <Container className={clsx(classes["video-placeholder"])}>
      <Image className={classes["video-placeholder-image"]}>
        <Progress className={classes["progress"]}>
          {status === -1 && (
            <ErrorOutlineIcon
              className={clsx(classes["icon"], classes["icon-error"])}
            />
          )}
          {status === 100 && (
            <CheckCircleOutlineIcon
              className={clsx(classes["icon"], classes["icon-success"])}
            />
          )}

          {status !== 100 && status === -1 ? (
            <Steps className={classes["progress-steps"]}>
              {step}&nbsp;/&nbsp;{totalSteps}
            </Steps>
          ) : null}

          <ProgressInfo className={classes["progress-info"]}>
            <Status className={classes["progress-info-text"]}>
              {statusText}&nbsp;
            </Status>
            {[0, 1, 2, 3, 4].includes(status) && (
              <Percentage className={classes["progress-info-text"]}>
                {progress === 0 && status === 0 ? "" : `${progress}%`}
              </Percentage>
            )}

            {status === 5 && (
              <Screenshots className={classes["progress-info-text"]}>
                {screenshotsTaken}/{totalScreenshots}
              </Screenshots>
            )}
          </ProgressInfo>
        </Progress>
        <Background className={classes["info-background"]} />
        <div className={classes["video-placeholder-info"]}>
          <VideoName className={classes["video-info"]}>{name}</VideoName>
        </div>
      </Image>
      {status !== 100 && status !== -1 ? <AnimatedProgress /> : null}
    </Container>
  );
}

export default VideoPlaceholder;
