import React, { useEffect, useState } from "react";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Flag from "./components/flag/Flag";

import classes from "./LanguageSelect.module.scss";

const locales = [
  { code: "en-GB", translation: "English" },
  { code: "pl", translation: "Polski" },
  { code: "de", translation: "Deutsche" },
  { code: "fr", translation: "Français" },
  { code: "es", translation: "Español" },
];

function LanguageSelect() {
  const { i18n } = useTranslation("common", { useSuspense: false });
  const getDefaultLanguage = () => {
    let langCode = localStorage.getItem("i18nextLng") || i18n.language;
    let validCodes = locales.map((l) => l.code);
    if (!validCodes.includes(langCode)) return "en-GB";
    return langCode;
  };

  const [language, setLanguage] = useState(getDefaultLanguage());

  const changeLng = (e) => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  const renderFlags = () =>
    locales.map((item) => (
      <MenuItem
        value={item.code}
        className={classes["country-container"]}
        key={item.code}
      >
        <div className={classes["flag-container"]}>
          <Flag code={item.code} />
        </div>
        <div className={classes["lng-container"]}>{item.translation}</div>
      </MenuItem>
    ));

  useEffect(() => {
    if (!!i18n.language) {
      const lngObj = locales.find(({ code }) => i18n.language.includes(code));
      if (!!lngObj) {
        i18n.changeLanguage(lngObj.code);
        setLanguage(lngObj.code);
      } else {
        i18n.changeLanguage("en-GB");
        setLanguage("en-GB");
      }
    }
  }, [i18n.language]);

  return (
    <div className={classes["language-select"]}>
      <FormControl variant="outlined">
        <Select
          value={language}
          onChange={changeLng}
          inputProps={{
            name: "language",
            id: "language-select",
          }}
          // displayEmpty
          className={classes["language-select-form"]}
        >
          {renderFlags()}
        </Select>
      </FormControl>
    </div>
  );
}

export default LanguageSelect;
