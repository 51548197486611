import React from "react";
import { string, bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import Thumb from "components/ui/thumb/Thumb";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "../../Templates.module.scss";

function TemplatesTableRow({
  id,
  name,
  requires,
  orientation,
  imgSrc,
  disabled,
  handleClickAssign,
  handleShowTemplate,
  selectedTemplate,
  loading
}) {
  const { t } = useTranslation("control");
  const isVertical = orientation === "PORTRAIT";

  const TemplateNotAssigned = () => (
    <div className={classes["not-assigned"]}>
      <Tooltip
        title={t("templates.not-assigned.tooltip-title")}
        placement="top"
        classes={{ tooltip: classes["tooltip-font"] }}
      >
        <div className={classes["not-assigned-icon"]} />
      </Tooltip>
      <PrimaryButton
        primaryButtonClass={classes["assign-button"]}
        onClick={handleClickAssign(id)}
        disabled={loading}
      >
        {t("templates.not-assigned.label")}
      </PrimaryButton>
    </div>
  );

  return (
    <tr
      key={id}
      className={clsx(classes["table-row"], {
        [classes["disabled"]]: disabled
      })}
    >
      <td className={classes["table-col"]}>
        <div
          className={clsx(classes["thumb-container"], {
            [classes["thumb-container-disabled"]]: disabled
          })}
        >
          <Thumb
            imgSrc={imgSrc}
            alt="Logo"
            placement="right"
            placeholderClass={classes["placehoder"]}
            isVertical={isVertical}
          />
        </div>
      </td>
      <td className={classes["table-col"]}>
        <div className={classes["name-cell-container"]}>
          <div className={classes["name-cell"]}>
            <div>{name}</div>
            <div
              className={clsx(classes["name-cell-requires"], {
                [classes["name-cell-requires-disabled"]]: disabled
              })}
            >
              {requires}
            </div>
          </div>
          {disabled && <TemplateNotAssigned />}
          {!disabled && (
            <PrimaryButton
              onClick={() => {
                handleShowTemplate(id);
              }}
              disabled={loading}
              primaryButtonClass={classes["show-button"]}
            >
              {loading && selectedTemplate === id ? (
                <CircularProgress
                  size={15}
                  classes={{ circle: classes.loader }}
                />
              ) : (
                t("messages.show")
              )}
            </PrimaryButton>
          )}
        </div>
      </td>
    </tr>
  );
}

TemplatesTableRow.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  imgSrc: string.isRequired,
  requires: string.isRequired,
  orientation: string,
  disabled: bool,
  handleClickAssign: func,
  handleShowTemplate: func,
  selectedTemplate: string,
  loading: bool
};

export default TemplatesTableRow;
