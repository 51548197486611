import React from "react";
import { string, bool } from "prop-types";
import clsx from "clsx";

import defaultChannelLogo from "assets/images/dafault-logo/default_logo.png";

import classes from "./ImgUnloader.module.scss";
// Based on: https://github.com/facebook/create-react-app/issues/3437#issuecomment-473300768
const noImage = process.env.PUBLIC_URL + "/images/no-photo/no-photo.png";

function ImgUnloader({
  unloaderClass = null,
  imageClass = null,
  defaultLogo = false
}) {
  return (
    <div
      className={clsx(classes["unloader"], {
        [unloaderClass]: unloaderClass
      })}
    >
      <img
        src={defaultLogo ? defaultChannelLogo : noImage}
        className={clsx(classes["unloader-img"], {
          [imageClass]: imageClass,
          [classes["inverted"]]: !defaultLogo
        })}
        alt="channel logo"
      />
    </div>
  );
}

ImgUnloader.propTypes = {
  unloaderClass: string,
  imageClass: string,
  defaultLogo: bool
};

export default ImgUnloader;
