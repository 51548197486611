import React, { useEffect, useState, useContext } from "react";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import BuildSharpIcon from "@material-ui/icons/BuildSharp";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ServerContext } from "contexts/ServerContext";
import { permissions } from "data/permissions";
import { CONTENT } from "data/urls";
import usePermissions from "hooks/usePermissions";
import { ImTree } from "react-icons/im";
import { FiRadio } from "react-icons/fi";
import { CgTemplate } from "react-icons/cg";
import {
  RiPlayList2Line,
  RiSoundModuleLine,
  RiTv2Line,
  RiFilmLine,
} from "react-icons/ri";
import { AiOutlineDashboard, AiOutlineOrderedList } from "react-icons/ai";
import { BiServer } from "react-icons/bi";

import DrawerNavLink from "../persistentDrawer/components/drawer-nav-link/DrawerNavLink";

import classes from "./MultiLevelMenu.module.scss";

function MultiLevelMenu({ location }) {
  const [endpointsOpen, openEndpoints] = useState(false);
  const [controlOpen, openControl] = useState(false);
  const [serverSelected, setServerSelected] = useState(false);
  const { serverInUse } = useContext(ServerContext);
  const { t } = useTranslation("common", { useSuspense: false });
  const { pathname } = location;
  const userPermissions = usePermissions();

  const [endpointsDefaultLink, setEndpointsDefaultLink] = useState(
    "endpoints/control/tv-control"
  );
  const [contentDefaultLink, setContentDefaultLink] = useState(
    "/content/video-playlist-channels"
  );

  const [endpointsVisibility, setEndpointsVisibility] = useState(true);
  const [contentVisibility, setContentVisibility] = useState(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const endpointsPaths = ["endpoints"];
    const contentPaths = ["content", "template-builder"];
    const [, path] = pathname.split("/");

    if (endpointsPaths.includes(path)) {
      openEndpoints(true);
    } else {
      openEndpoints(false);
    }
    if (contentPaths.includes(path)) {
      openControl(true);
    } else {
      openControl(false);
    }
  }, [pathname]);

  useEffect(() => {
    setServerSelected(!!serverInUse.name);
  }, [serverInUse]);

  useEffect(() => {
    if (
      userPermissions.includes(
        permissions.endpointsControl.ENDPOINTS_CONTROL_VOLUME
      )
    ) {
      setEndpointsDefaultLink("/endpoints/control/tv-control");
    } else if (
      userPermissions.includes(permissions.endpointsGroups.ENDPOINTS_GROUPS_GET)
    ) {
      setEndpointsDefaultLink("/endpoints/groups");
    } else if (
      userPermissions.includes(
        permissions.endpointsGroups.CHANNELS_ASSIGN_TO_GROUP
      )
    ) {
      setEndpointsDefaultLink("/endpoints/channels");
    } else if (
      userPermissions.includes(permissions.endpointsGroups.TEMPLATES_GET)
    ) {
      setEndpointsDefaultLink("/endpoints/templates");
    } else if (userPermissions.length > 0) {
      setEndpointsVisibility(false);
    }

    if (
      (userPermissions.includes(permissions.playlists.PLAYLISTS_GET) ||
        userPermissions.includes(permissions.playlists.PLAYLISTS_VIDEOS_GET) ||
        userPermissions.includes(
          permissions.playlists.PLAYLISTS_CHANNELS_GET
        )) &&
      serverSelected
    ) {
      setContentDefaultLink("/content/video-playlist-channels");
    } else if (
      userPermissions.includes(permissions.endpointsGroups.CHANNELS_GET)
    ) {
      setContentDefaultLink("/content/channel-list");
    } else if (
      userPermissions.includes(permissions.templateBuilder.TEMPLATE_BUILDER_GET)
    ) {
      setContentDefaultLink("/template-builder");
    } else if (userPermissions.length > 0) {
      setContentVisibility(false);
    }
  }, [userPermissions, serverSelected]);

  /* eslint-enable react-hooks/exhaustive-deps */
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes["root"]}
    >
      {/* @ts-ignore */}
      <DrawerNavLink
        name={t("side-menu.dashboard.overview")}
        link={"/dashboard/locations"}
        Icon={<AiOutlineDashboard className={classes["icon-size"]} />}
      />
      {userPermissions.includes(permissions.servers.SERVERS_GET) && (
        // @ts-ignore
        <DrawerNavLink
          data-cy="nav-link-servers"
          name={t("side-menu.servers")}
          link={"/servers"}
          Icon={<BiServer className={classes["icon-size"]} />}
        />
      )}
      {endpointsVisibility && (
        <DrawerNavLink
          data-cy="nav-link-endpoints"
          disabled={!serverSelected}
          name={t("side-menu.endpoints.endpoints")}
          link={endpointsDefaultLink}
          Icon={<RiTv2Line className={classes["icon-size"]} />}
          isActivePaths={[
            "/endpoints/control/tv-control",
            "/endpoints/control/messages",
            "/endpoints/control/alerts",
            "/endpoints/control/templates",
            "/endpoints/groups/list",
            "/endpoints/groups/assign",
            "/endpoints/channel-list",
            "/endpoints/channels",
            "/endpoints/templates",
            "/endpoints/assign-endpoints",
          ]}
          collapsable
          open={endpointsOpen}
        />
      )}
      {endpointsVisibility && (
        <Collapse in={endpointsOpen} timeout="auto" /* unmountOnExit */>
          <List component="div" disablePadding>
            {userPermissions.includes(
              permissions.endpointsControl.ENDPOINTS_CONTROL_VOLUME
            ) && (
              <DrawerNavLink
                data-cy="nav-link-endpoint-control"
                nested
                disabled={!serverSelected}
                name={t("side-menu.endpoints.control")}
                link={"/endpoints/control/tv-control"}
                Icon={<RiSoundModuleLine className={classes["icon-size"]} />}
                isActivePaths={[
                  "/endpoints/control/tv-control",
                  "/endpoints/control/messages",
                  "/endpoints/control/alerts",
                  "/endpoints/control/templates",
                ]}
              />
            )}
            {userPermissions.includes(
              permissions.endpointsGroups.ENDPOINTS_GROUPS_GET
            ) && (
              <DrawerNavLink
                data-cy="nav-link-endpoint-groups"
                nested
                disabled={!serverSelected}
                name={t("side-menu.endpoints.groups")}
                link={"/endpoints/groups"}
                Icon={<ImTree className={classes["icon-size"]} />}
                className={classes["not-selected"]}
              />
            )}
            {userPermissions.includes(
              permissions.channels.CHANNELS_ASSIGN_TO_GROUP
            ) && (
              <DrawerNavLink
                data-cy="nav-link-assign-channels"
                nested
                disabled={!serverSelected}
                name={t("side-menu.endpoints.channels")}
                link={"/endpoints/channels"}
                Icon={<FiRadio className={classes["icon-size"]} />}
              />
            )}
            {userPermissions.includes(permissions.templates.TEMPLATES_GET) && (
              <DrawerNavLink
                data-cy="nav-link-assign-templates"
                nested
                disabled={!serverSelected}
                name={t("side-menu.endpoints.templates")}
                link={"/endpoints/templates"}
                Icon={<CgTemplate className={classes["icon-size"]} />}
              />
            )}
          </List>
        </Collapse>
      )}
      {contentVisibility && (
        <DrawerNavLink
          data-cy="nav-link-content"
          disabled={!serverSelected}
          name={t("side-menu.content.content")}
          link={contentDefaultLink}
          Icon={<RiFilmLine className={classes["icon-size"]} />}
          isActivePaths={[
            CONTENT.MANAGE_VIDEOS,
            CONTENT.MANAGE_PLAYLISTS,
            CONTENT.MANAGE_CHANNELS,
            CONTENT.CHANNELS_LIST,
            "/template-builder",
          ]}
          collapsable
          open={controlOpen}
        />
      )}
      {contentVisibility && (
        <Collapse in={controlOpen} timeout="auto">
          <List component="div" disablePadding>
            {(userPermissions.includes(permissions.playlists.PLAYLISTS_GET) ||
              userPermissions.includes(
                permissions.playlists.PLAYLISTS_VIDEOS_GET
              ) ||
              userPermissions.includes(
                permissions.playlists.PLAYLISTS_CHANNELS_GET
              )) && (
              <DrawerNavLink
                data-cy="nav-link-video-playlist-channels"
                nested
                disabled={!serverSelected}
                name={t("side-menu.content.video-playlist-channels")}
                link={"/content/video-playlist-channels"}
                Icon={<RiPlayList2Line className={classes["icon-size"]} />}
              />
            )}

            {userPermissions.includes(permissions.channels.CHANNELS_GET) && (
              <DrawerNavLink
                data-cy="nav-link-channel-list"
                nested
                disabled={!serverSelected}
                name={t("side-menu.content.channel-list")}
                link={"/content/channel-list"}
                Icon={<AiOutlineOrderedList className={classes["icon-size"]} />}
              />
            )}

            {userPermissions.includes(
              permissions.templateBuilder.TEMPLATE_BUILDER_GET
            ) && (
              // @ts-ignore
              <DrawerNavLink
                data-cy="nav-link-template-builder"
                nested
                name={t("side-menu.template-builder")}
                link={"/template-builder"}
                Icon={<BuildSharpIcon className={classes["icon-size"]} />}
              />
            )}
          </List>
        </Collapse>
      )}
    </List>
  );
}

export default withRouter(MultiLevelMenu);
