import React from "react";
import { string } from "prop-types";
import ScheduleSharpIcon from "@material-ui/icons/ScheduleSharp";

import classes from "./PlaylistDuration.module.scss";

function PlaylistDuration({ duration }) {
  return (
    <div className={classes["playlist-duration"]}>
      <div className={classes["timer-icon"]}>
        <ScheduleSharpIcon classes={{ root: classes["timer-icon"] }} />
      </div>
      {duration}
    </div>
  );
}

PlaylistDuration.propTypes = {
  duration: string
};

export default PlaylistDuration;
