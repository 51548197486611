module.exports.permissions = {
  servers: {
    SERVERS_GET: "SERVERS_GET",
    SERVERS_CREATE: "SERVERS_CREATE",
    SERVERS_EDIT: "SERVERS_EDIT",
    SERVERS_DELETE: "SERVERS_DELETE",
    SERVERS_SYNCHRONIZE: "SERVERS_SYNCHRONIZE",
    SERVERS_SNAPSHOTS: "SERVERS_SNAPSHOTS",
    SERVERS_SPEED_TEST: "SERVERS_SPEED_TEST",
    SERVERS_REBOOT: "SERVERS_REBOOT"
  },
  alerts: {
    ALERTS_GET: "ALERTS_GET",
    ALERTS_CREATE: "ALERTS_CREATE",
    ALERTS_EDIT: "ALERTS_EDIT",
    ALERTS_DELETE: "ALERTS_DELETE",
    ALERTS_SHOW: "ALERTS_SHOW",
    ALERTS_UPLOAD: "ALERTS_UPLOAD"
  },
  channels: {
    CHANNELS_ASSIGN_TO_GROUP: "CHANNELS_ASSIGN_TO_GROUP",
    CHANNELS_CHANGE_CHANNEL: "CHANNELS_CHANGE_CHANNEL",
    CHANNELS_GET: "CHANNELS_GET",
    CHANNELS_GET_LOGOS: "CHANNELS_GET_LOGOS",
    CHANNELS_CHANGE_POSITION: "CHANNELS_CHANGE_POSITION",
    CHANNELS_DELETE: "CHANNELS_DELETE",
    CHANNELS_CREATE: "CHANNELS_CREATE",
    CHANNELS_UPDATE: "CHANNELS_UPDATE",
    CHANNELS_SCHEDULE: "CHANNELS_SCHEDULE",
    CHANNELS_UPLOAD_LOGOS: "CHANNELS_UPLOAD_LOGOS",
    CHANNELS_CHANGE_LOGOS: "CHANNELS_CHANGE_LOGOS"
  },
  companies: {
    COMPANIES_GET: "COMPANIES_GET",
    COMPANIES_UPDATE: "COMPANIES_UPDATE",
    COMPANIES_CREATE: "COMPANIES_CREATE",
    COMPANIES_DELETE: "COMPANIES_DELETE"
  },
  endpoints: {
    ENDPOINTS_GET: "ENDPOINTS_GET",
    ENDPOINTS_UPDATE: "ENDPOINTS_UPDATE"
  },
  endpointsControl: {
    ENDPOINTS_CONTROL_CHANGE_CHANNEL: "ENDPOINTS_CONTROL_CHANGE_CHANNEL",
    ENDPOINTS_CONTROL_FULLSCREEN: "ENDPOINTS_CONTROL_FULLSCREEN",
    ENDPOINTS_CONTROL_REBOOT: "ENDPOINTS_CONTROL_REBOOT",
    ENDPOINTS_CONTROL_REFRESH: "ENDPOINTS_CONTROL_REFRESH",
    ENDPOINTS_CONTROL_STARTUP_MODE: "ENDPOINTS_CONTROL_STARTUP_MODE",
    ENDPOINTS_CONTROL_SUBTITLES: "ENDPOINTS_CONTROL_SUBTITLES",
    ENDPOINTS_CONTROL_TEMPLATES: "ENDPOINTS_CONTROL_TEMPLATES",
    ENDPOINTS_CONTROL_DISPLAY_ON: "ENDPOINTS_CONTROL_DISPLAY_ON",
    ENDPOINTS_CONTROL_VOLUME: "ENDPOINTS_CONTROL_VOLUME"
  },
  endpointsGroups: {
    ENDPOINTS_GROUPS_GET: "ENDPOINTS_GROUPS_GET",
    ENDPOINTS_GROUPS_CREATE: "ENDPOINTS_GROUPS_CREATE",
    ENDPOINTS_GROUPS_UPDATE: "ENDPOINTS_GROUPS_UPDATE",
    ENDPOINTS_GROUPS_DELETE: "ENDPOINTS_GROUPS_DELETE",
    ENDPOINTS_GROUPS_SET_DEFAULT: "ENDPOINTS_GROUPS_SET_DEFAULT"
  },
  files: {
    FILES_GET: "FILES_GET",
    FILES_CREATE: "FILES_CREATE",
    FILES_UPDATE: "FILES_UPDATE",
    FILES_DELETE: "FILES_DELETE"
  },
  messages: {
    MESSAGES_GET: "MESSAGES_GET",
    MESSAGES_CREATE: "MESSAGES_CREATE",
    MESSAGES_UPDATE: "MESSAGES_UPDATE",
    MESSAGES_DELETE: "MESSAGES_DELETE",
    MESSAGES_SHOW: "MESSAGES_SHOW"
  },
  playlists: {
    PLAYLISTS_GET: "PLAYLISTS_GET",
    PLAYLISTS_CREATE: "PLAYLISTS_CREATE",
    PLAYLISTS_UPDATE: "PLAYLISTS_UPDATE",
    PLAYLISTS_DELETE: "PLAYLISTS_DELETE",
    PLAYLISTS_CHANGE_VIDEO_POSITION: "PLAYLISTS_CHANGE_VIDEO_POSITION",
    PLAYLISTS_COPY_TO_SERVER: "PLAYLISTS_COPY_TO_SERVER",
    PLAYLISTS_CHANNEL_BROADCAST: "PLAYLISTS_CHANNEL_BROADCAST",
    PLAYLISTS_CHANNEL_TOGGLE: "PLAYLISTS_CHANNEL_TOGGLE",
    PLAYLISTS_CHANNEL_SET_ACTIVE: "PLAYLISTS_CHANNEL_SET_ACTIVE",
    PLAYLISTS_SETTINGS: "PLAYLISTS_SETTINGS",
    PLAYLISTS_CHANGE_CHANNEL_STATE: "PLAYLISTS_CHANGE_CHANNEL_STATE",
    PLAYLISTS_CHANNELS_GET: "PLAYLISTS_CHANNELS_GET",
    PLAYLISTS_VIDEOS_GET: "PLAYLISTS_VIDEOS_GET",
    PLAYLISTS_PAGE_VIDEOS: "PLAYLISTS_PAGE_VIDEOS",
    PLAYLISTS_PAGE_PLAYLISTS: "PLAYLISTS_PAGE_PLAYLISTS",
    PLAYLISTS_PAGE_CHANNELS: "PLAYLISTS_PAGE_CHANNELS"
  },
  roles: {
    ROLES_GET: "ROLES_GET"
  },
  templates: {
    TEMPLATES_GET: "TEMPLATES_GET",
    TEMPLATES_ADD_TO_GROUP: "TEMPLATES_ADD_TO_GROUP",
    TEMPLATES_REMOVE_FROM_GROUP: "TEMPLATES_REMOVE_FROM_GROUP"
  },
  templateBuilder: {
    TEMPLATE_BUILDER_CREATE_OR_EDIT: "TEMPLATE_BUILDER_CREATE_OR_EDIT",
    TEMPLATE_BUILDER_GET: "TEMPLATE_BUILDER_GET",
    TEMPLATE_BUILDER_ALERTS_GET: "TEMPLATE_BUILDER_ALERTS_GET",
    TEMPLATE_BUILDER_ALERTS_UPLOAD: "TEMPLATE_BUILDER_ALERTS_UPLOAD"
  },
  users: {
    USERS_GET: "USERS_GET",
    USERS_EDIT: "USERS_EDIT",
    USERS_CREATE: "USERS_CREATE",
    USER_CREATE_DEALER: "USER_CREATE_DEALER",
    USER_CREATE_CUSTOMER: "USER_CREATE_CUSTOMER",
    USER_CREATE_SITE_MANAGER: "USER_CREATE_SITE_MANAGER",
    USER_CREATE_CONTENT_EDITOR: "USER_CREATE_CONTENT_EDITOR"
  },
  task: {
    TASK_STATUS: "TASK_STATUS"
  },
  pages: {
    ACCESS_PAGE: "ACCESS_PAGE"
  }
};
