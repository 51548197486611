import React, { useEffect, useState } from "react";
import { func, string } from "prop-types";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";

import { CHANNEL_STATES } from "../../../../../../../../utils/utils";
import { playlistChannelType } from "types";

import classes from "./CurrentPlaylist.module.scss";

function CurrentPlaylist({
  handlePlaylistNameClick,
  getCurrentPlaylistInfo,
  currentState,
  channel,
  onRemoveClick
}) {
  const [isChannelTransmitting, setIsChannelTransmitting] = useState(false);
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.playlist-channels";
  const TOOLTIPS = `${PREFIX}.tooltips`;
  const { TRANSMIT } = CHANNEL_STATES;
  const currentPlaylist = getCurrentPlaylistInfo();

  const getTooltipTitle = () => {
    let tooltipTitle = "";

    if (!isChannelTransmitting) {
      if (currentPlaylist.assigned) {
        tooltipTitle = t(`${TOOLTIPS}.change-active-playlist`);
      } else {
        tooltipTitle = t(`${TOOLTIPS}.set-active-playlist`);
      }
    } else {
      tooltipTitle = t(
        `${TOOLTIPS}.channel-transmitting-playlist-cannot-be-changed`
      );
    }

    return tooltipTitle;
  };

  useEffect(() => {
    if (currentState === TRANSMIT) {
      setIsChannelTransmitting(true);
    } else {
      setIsChannelTransmitting(false);
    }
  }, [currentState]);

  return (
    <div
      className={classes["current-playlist"]}
      onClick={e => e.stopPropagation()}
    >
      <span className={classes["current-playlist-label"]}>
        {t(`${PREFIX}.active-playlist`)}
      </span>
      <Tooltip
        title={getTooltipTitle()}
        placement="right-start"
        classes={{
          tooltip: classes["tooltip-styles"]
        }}
      >
        <span
          className={clsx(classes["current-playlist-name"], {
            [classes["no-playlist-assigned"]]: !currentPlaylist.assigned,
            [classes[
              "current-playlist-name-transmitting"
            ]]: isChannelTransmitting
          })}
          onClick={handlePlaylistNameClick}
        >
          {currentPlaylist.name}
        </span>
      </Tooltip>

      {!isChannelTransmitting && currentPlaylist.assigned ? (
        <Tooltip
          title={t("sections.playlist-channels.tooltips.remove-palylist")}
          placement="right"
          classes={{ tooltip: classes["tooltip-styles"] }}
        >
          <DeleteIcon
            className={classes["delete-icon"]}
            onClick={() => {
              onRemoveClick(channel);
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
}

CurrentPlaylist.propTypes = {
  handlePlaylistNameClick: func.isRequired,
  getCurrentPlaylistInfo: func.isRequired,
  currentState: string,
  channel: playlistChannelType,
  onRemoveClick: func
};

export default CurrentPlaylist;
