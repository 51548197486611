export const getSnacks = t => {
  return {
    EDIT_PROFILE_SUCCESS: {
      message: t("snacks.profile-has-been-edited"),
      variant: "success",
      autoHideDuration: 2500
    },
    EDIT_PROFILE_ERROR: {
      message: t("snacks.profile-could-not-be-edited"),
      variant: "error",
      autoHideDuration: 3500
    }
  };
};
