import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";

const cookies = new Cookies();

class ServersApi {
  getToken = () => cookies.get("token");

  getServers = async () => {
    const method = "get";
    const path = `/api/v1/idtservers`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };

  addNewServer = async (_, newServer) => {
    const method = "post";
    const path = `/api/v1/idtservers`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: newServer,
    });
  };

  updateServer = async (_, updatedServer) => {
    const { id, productKey, name } = updatedServer;
    const method = "patch";
    const path = `/api/v1/idtservers/${id}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        productKey,
        name,
      },
    });
  };

  rebootServer = async (_, serverId) => {
    const method = "post";
    const path = `/api/v1/idx-runner/${serverId}/reboot`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  synchronizeServer = async (_, serverId) => {
    const method = "get";
    const path = `/api/v1/idtservers/${serverId}/synchronize`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  getBackups = async (_, serverId) => {
    const method = "get";
    const path = `/api/v1/idx-runner/${serverId}/snapshots`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  restoreBackup = async (_, payload) => {
    const { serverId, snapshotName } = payload;
    const method = "post";
    const path = `/api/v1/idx-runner/${serverId}/restore-snapshot`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: { snapshotName },
    });
  };

  deleteBackup = async (_, payload) => {
    const { serverId, snapshotName } = payload;
    const method = "delete";
    const path = `/api/v1/idx-runner/${serverId}/snapshot`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
      data: { snapshotName },
    });
  };

  createBackup = async (_, serverId) => {
    const method = "post";
    const path = `/api/v1/idx-runner/${serverId}/take-snapshot`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await axios({
      method,
      url,
      headers,
    });
  };

  getSpeedTestResults = async (_, serverId) => {
    const method = "get";
    const path = `/api/v1/idx-runner/${serverId}/speedtest/results`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };

  requestSpeedTest = async (_, serverId) => {
    const method = "post";
    const path = `/api/v1/idx-runner/${serverId}/speedtest/request`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };

  deleteServer = async (_, serverId) => {
    const method = "delete";
    const path = `/api/v1/idtservers/${serverId}`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };

  getNetworkInfo = async (_, serverId) => {
    const method = "get";
    const path = `/api/v1/idtservers/${serverId}/network-info`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = { Authorization: `Bearer ${token}` };

    return await axios({
      method,
      url,
      headers,
    });
  };
}

export default new ServersApi();
