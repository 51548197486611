import { useContext, useEffect } from "react";
import SocketIOContext from "../contexts/SocketIOContext";

function useSocketIO(event, callback) {
  const { websocket: ws } = useContext(SocketIOContext);

  useEffect(() => {
    if (ws) {
      ws.off(event);
      ws.on(event, callback);
    }

    return () => {
      ws.off(event);
    };
  }, [ws]);
}

export default useSocketIO;
