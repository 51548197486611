import React from "react";
import { func, bool, string } from "prop-types";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SyncIcon from "@material-ui/icons/Sync";
import moment from "moment";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./Synchronise.module.scss";

function Synchronise({
  openSyncModal,
  isActionInProgress,
  lastSynchronization
}) {
  const { t } = useTranslation("servers");

  return (
    <div className={classes["synchronise"]}>
      <div>
        <Tooltip
          title={t("synchronize-server-label")}
          placement="top-start"
          classes={{
            tooltip: classes["tooltip-font"]
          }}
        >
          <div className={classes["synchronise-label"]}>
            <SyncIcon className={classes["synchronise-label-icon"]} />
            <div className={classes["synchronise-label-text"]}>
              {t("synchronize-btn-text")}
            </div>
          </div>
        </Tooltip>

        <div className={classes["last-synch"]}>
          {t("last-synchronisation")}:&nbsp;
          {lastSynchronization ? (
            moment(lastSynchronization).local().format("YYYY-MM-DD HH:mm")
          ) : (
            <span>&ndash;</span>
          )}
        </div>
      </div>

      <PrimaryButton
        onClick={openSyncModal}
        disabled={isActionInProgress}
        primaryButtonClass={classes["run-button"]}
      >
        {t("speed-test.modal.run-button")}
      </PrimaryButton>
    </div>
  );
}

Synchronise.propTypes = {
  openSyncModal: func,
  isActionInProgress: bool,
  lastSynchronization: string
};

export default Synchronise;
