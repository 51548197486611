import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import classes from "./PreviewUnloader.module.scss";

function PreviewUnloader({ small = false, medium = false }) {
  const { t } = useTranslation("video-playlists");

  return (
    <div className={classes["unloader"]}>
      <div
        className={clsx(classes["unloader-text"], {
          [classes["unloader-text-small"]]: small,
          [classes["unloader-text-medium"]]: medium,
          [classes["unloader-text-large"]]: !small && !medium
        })}
      >
        - {t("sections.playlists.no-preview")} -
      </div>
    </div>
  );
}

export default PreviewUnloader;
