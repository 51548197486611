import React, { useState } from "react";
import { arrayOf, shape, string, func } from "prop-types";
import {
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import classes from "./EntitySelector.module.scss";

function EntitySelector({ options }) {
  const [index, setIndex] = useState(1);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    options[index].onClickHandler();
  };

  const handleMenuItemClick = (event, index) => {
    setIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const { label } = options[index];

  return (
    <div className={classes["entity-selector"]}>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        classes={{
          grouped: classes["button-color"]
        }}
      >
        <Button onClick={handleClick}>{label}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map(({ _key, label }, index) => (
                    <MenuItem
                      key={_key}
                      // selected={index === index}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

EntitySelector.propTypes = {
  options: arrayOf(
    shape({
      _key: string.isRequired,
      label: string.isRequired,
      onClickHandler: func.isRequired,
      type: string
    })
  )
};

export default EntitySelector;
