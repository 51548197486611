import React, { useContext } from "react";
import { bool, arrayOf, object, func } from "prop-types";
import { useTranslation } from "react-i18next";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";

import { ServerContext } from "contexts/ServerContext";

import ListDrawer from "components/ui/drawers/ListDrawer";
import Table from "components/ui/table/Table";
import CurrentlyUsedServer from "./components/currently-used-server/CurrentlyUsedServer";
import ServerStatus from "./components/server-status/ServerStatus";
import ServerSynchronized from "./components/server-synchronized/ServerSynchronized";

import { convertBytes } from "utils/utils";

import classes from "./ServersTable.module.scss";

function ServersTable({
  servers,
  selectedServer,
  toggleSelectedServer,
  open,
  displayCompanyName = false,
  isServerSelected
}) {
  const { t } = useTranslation("servers");
  const { serverInUse, setServerInUse } = useContext(ServerContext);
  const customMaxHeight = !isServerSelected ? "74vh" : null;

  const handleServerSelection = server => () => {
    toggleSelectedServer(server);
  };

  const checkIfUnknown = runner => !runner || runner === "Unknown";

  const getRunnerName = runner =>
    checkIfUnknown(runner) ? t("unknown") : runner;

  const handleSelectServer = server => _ => {
    if (server.isOnline) {
      setServerInUse(server);
    }
  };

  return (
    <ListDrawer open={open}>
      {servers.length ? (
        <Table
          centered
          data-cy="servers-table"
          customMaxHeight={customMaxHeight}
        >
          <Table.Head>
            <th className={classes["table-cell-5"]}>{t("in-use")}</th>
            <th
              className={clsx(
                classes["fixed-header"],
                classes["table-cell-25"]
              )}
            >
              {t("name")}
            </th>
            <th className={classes["table-cell-10"]}>{t("status")}</th>
            <th className={classes["table-cell-10"]}>{t("synchronized")}</th>
            <th className={classes["table-cell-10"]}>{t("runner-version")}</th>
          </Table.Head>
          <Table.Body>
            {servers.map((server, index) => {
              const selected = server.id === selectedServer.id;
              const isCurrentlyUsed = server.id === serverInUse.id;
              const isOnline = server.isOnline;
              const totalSpace = server.diskTotalSpace;
              const freeSpace = server.diskFreeSpace;
              const usage = 100 - (freeSpace / totalSpace) * 100;
              const barClasses = {
                barColorPrimary:
                  usage > 90
                    ? classes["bar-front-full"]
                    : usage > 75
                    ? classes["bar-front-medium"]
                    : classes["bar-front"],
                colorPrimary:
                  usage > 90
                    ? classes["bar-background-full"]
                    : usage > 75
                    ? classes["bar-background-medium"]
                    : classes["bar-background"]
              };

              return (
                <Table.Row
                  key={server.id}
                  selected={selected}
                  data-cy={`server-row-${index}`}
                >
                  <td
                    className={clsx(classes["table-cell"], {
                      [classes["disabled"]]: !server.isOnline
                    })}
                    onClick={handleSelectServer(server)}
                    data-cy="server-checker"
                  >
                    <CurrentlyUsedServer
                      isCurrentlyUsed={isCurrentlyUsed}
                      isOnline={isOnline}
                    />
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={handleServerSelection(server)}
                  >
                    <div className={classes["server-name-and-usage"]}>
                      <div className={classes["server-name-container"]}>
                        <div className={classes["server-name"]}>
                          {server.name}
                        </div>
                        {displayCompanyName && (
                          <div className={classes["server-company"]}>
                            {server.Company.name}
                          </div>
                        )}
                      </div>

                      <div
                        className={clsx(classes["disk-usage"], {
                          [classes["hide-usage"]]: !open
                        })}
                      >
                        {!isNaN(usage) ? (
                          <LinearProgress
                            variant="determinate"
                            value={usage}
                            classes={barClasses}
                          />
                        ) : null}
                        {!isNaN(usage) ? (
                          <div className={classes["disk-usage-values"]}>
                            <span>
                              {t("usage.free")}:&nbsp;{convertBytes(freeSpace)}
                            </span>
                            <span>
                              {t("usage.total")}:&nbsp;
                              {convertBytes(totalSpace)}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={handleServerSelection(server)}
                  >
                    <ServerStatus status={server.isOnline} />
                  </td>
                  <td
                    className={classes["table-cell"]}
                    onClick={handleServerSelection(server)}
                  >
                    <ServerSynchronized
                      synchronized={server.assetsSynchronizationStatus}
                    />
                  </td>
                  <td
                    className={clsx(classes["table-cell"], classes["dimmed"], {
                      [classes["unknown"]]: checkIfUnknown(
                        server.idxRunnerVersion
                      )
                    })}
                    onClick={handleServerSelection(server)}
                  >
                    {getRunnerName(server.idxRunnerVersion)}
                  </td>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <div className={classes["no-servers"]}>{t("no-servers-available")}</div>
      )}
    </ListDrawer>
  );
}

ServersTable.propTypes = {
  servers: arrayOf(object),
  selectedServer: object,
  toggleSelectedServer: func,
  open: bool,
  displayCompanyName: bool,
  isServerSelected: bool
};

export default ServersTable;
