import React, { createContext, useEffect, useState, useContext } from "react";
import AuthContext from "./AuthContext";
import { wsUrl } from "data/constants";
import io from "socket.io-client";
import NotificationContext from "./NotificationContext";
import { SOCKET_EVENTS } from "../data/socket-events";
// import { getTokenFromCookie } from "utils/utils";

const AUTH_DISCONNECTION_REASON = "io server disconnect";

const SocketIOContext = createContext({
  websocket: undefined,
  closeWebsocket: undefined
});

function socketIOLogger(...messages) {
  const css = "background: #222; color: #fff";
  console.log("%c### SOCKET.IO ###", css);
  for (const message of messages) {
    console.log(`%c### ${message} ###`, css);
  }
}

export function SocketIOContextProvider({ children }) {
  const [websocket, setWebsocket] = useState(undefined);
  const {
    state: { isAuthenticated },
    actions: { setIsAuthenticated }
  } = useContext(AuthContext);
  const {
    actions: { setSnackbar }
  } = useContext(NotificationContext);

  useEffect(() => {
    const setWebSocketInstance = address => {
      const socket = io(address, {
        path: "/wss",
        transports: ["websocket"]
        // query: {
        //   token: getTokenFromCookie()
        // }
      });

      socket.on("connect", function () {
        socketIOLogger("CONNECTED TO THE SERVER!");
      });

      socket.on("reconnect", function () {
        socketIOLogger("RECONNECTED TO THE SERVER!");
      });

      socket.on("disconnect", reason => {
        if (reason === AUTH_DISCONNECTION_REASON) {
          socketIOLogger("UNAUTHENTICATED", "DISCONNECTED");
          setIsAuthenticated(false);
        } else {
          socketIOLogger("DISCONNECTED");
        }
      });

      socket.on("error", function (error) {
        setSnackbar(`WS-Error: ${error}`, "error");
      });

      // @todo handle this event
      // When server goes offline a message will be received here
      // Message format: {serverId: 1, serverLicenceKey: "08dc-600a-6de3-be53-b4fe-db53"}
      socket.on(SOCKET_EVENTS.SERVER_BECAME_OFFLINE, msg => {
        console.log(
          `Received event ${
            SOCKET_EVENTS.SERVER_BECAME_OFFLINE
          } with message: ${JSON.stringify(msg)}`
        );
      });

      // @todo handle this event
      // When user is logged in and someone changes his role from the admin panel the user should be logged out to avoid issues.
      // tl;dr: When this event is triggered, clear all session data and show login page.
      socket.on(SOCKET_EVENTS.ROLE_CHANGED, () => {
        console.log(`Received event ${SOCKET_EVENTS.ROLE_CHANGED}`);
      });

      setWebsocket(socket);
    };
    if (isAuthenticated) {
      if (!websocket) {
        setWebSocketInstance(wsUrl);
      } else {
        socketIOLogger("TRYING TO RECONNECT...");
        if (websocket.io.skipReconnect) {
          setWebSocketInstance(wsUrl);
        } else {
          websocket.io.reconnect();
        }
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    return () => {
      closeWebsocket();
    };
  }, []);

  function closeWebsocket() {
    if (websocket) {
      socketIOLogger("CLOSING WEBSOCKET...");
      websocket.close();
    }
  }

  return (
    <SocketIOContext.Provider value={{ websocket, closeWebsocket }}>
      {children}
    </SocketIOContext.Provider>
  );
}

export default SocketIOContext;
