import React from "react";
import { func, object, string } from "prop-types";
import { BiMessageSquareDetail } from "react-icons/bi";

import classes from "./AutocompleteOption.module.scss";

function AutocompleteOption({
  option,
  deleteLabel,
  setMessageToBeDeleted,
  newMessage
}) {
  const handleDeleteMessage = e => {
    e.stopPropagation();
    setMessageToBeDeleted(option.id);
  };

  return newMessage !== undefined ? (
    <div className={classes.container}>
      <div className={classes["icon-message-container"]}>
        <BiMessageSquareDetail className={classes.icon} />
        <div className={classes.message}>{option.value}</div>
      </div>
      <div onClick={handleDeleteMessage} className={classes["delete-label"]}>
        {deleteLabel}
      </div>
    </div>
  ) : null;
}

AutocompleteOption.propTypes = {
  option: object,
  deleteLabel: string,
  setMessageToBeDeleted: func,
  newMessage: string
};

export default AutocompleteOption;
