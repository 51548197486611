const PREFIX = "backups.snacks";

export function backupsRequestsMessages(t) {
  const CREATE_BACKUP_SUCCESS = {
    message: t(`${PREFIX}.create-backup-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CREATE_BACKUP_ERROR = {
    message: t(`${PREFIX}.create-backup-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const RESTORE_BACKUP_SUCCESS = {
    message: t(`${PREFIX}.restore-backup-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const RESTORE_BACKUP_ERROR = {
    message: t(`${PREFIX}.restore-backup-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const DELETE_BACKUP_SUCCESS = {
    message: t(`${PREFIX}.delete-backup-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const DELETE_BACKUP_ERROR = {
    message: t(`${PREFIX}.delete-backup-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    CREATE_BACKUP_SUCCESS,
    CREATE_BACKUP_ERROR,
    RESTORE_BACKUP_SUCCESS,
    RESTORE_BACKUP_ERROR,
    DELETE_BACKUP_SUCCESS,
    DELETE_BACKUP_ERROR
  };
}
