import React, { useState, useEffect } from "react";
import { string } from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { SYNC_STATUSES } from "../../../../utils/utils";

import classes from "../../ServersTable.module.scss";

function ServerSynchronized({ synchronized }) {
  const [syncStatus, setSyncStatus] = useState("");
  const [textColor, setTextColor] = useState("");
  const { SYNCHRONIZED, NOT_SYNCHRONIZED, IN_PROGRESS } = SYNC_STATUSES;
  const { t } = useTranslation("common");

  useEffect(() => {
    if (synchronized === null || synchronized === NOT_SYNCHRONIZED) {
      setSyncStatus(t("no"));
      setTextColor("red");
    }
    if (synchronized === SYNCHRONIZED) {
      setSyncStatus(t("yes"));
      setTextColor("green");
    }
    if (synchronized === IN_PROGRESS) {
      setSyncStatus(`${t("in-progress")}`);
      setTextColor("orange");
    }
  }, [synchronized]);

  return (
    <div className={clsx(classes["server-synch"], classes[textColor])}>
      {syncStatus}
    </div>
  );
}

ServerSynchronized.propTypes = {
  synchronized: string
};

export default ServerSynchronized;
