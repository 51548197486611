import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./NoActivePlaylist.module.scss";

function NoActivePlaylist() {
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.playlist-channels";

  return (
    <div className={classes["no-active-playlist"]}>
      <span className={classes["no-active-playlist-text"]}>
        - {t(`${PREFIX}.no-active-playlist`)} -
      </span>
    </div>
  );
}

export default NoActivePlaylist;
