import React from "react";
import { object, func, bool, string } from "prop-types";

import PlaylistPreview from "./components/playlist-preview/PlaylistPreview";
import PlaylistInfo from "./components/playlist-info/PlaylistInfo";
import SideMenu from "./components/side-menu/SideMenu";

import MoreOptionsIcon from "./components/more-options-icon/MoreOptionsIcon";

import classes from "./PlaylistItem.module.scss";

function PlaylistItem({
  playlist,
  selectedPlaylist,
  toggleSelectedPlaylist,
  editedPlaylist,
  setEditedPlaylist,
  setPlaylistToBeDeleted,
  toggleModalOpen,
  renameEditedPlaylist,
  updating = false,
  setPlaylistToBeCopied,
  setOpenSideMenuId,
  openSideMenuId
}) {
  const selected = playlist.id === selectedPlaylist.id;

  const openSideMenu = id => {
    setOpenSideMenuId(id);
  };

  const closeSideMenu = () => {
    setOpenSideMenuId("");
  };

  const handleItemClick = e => {
    e.stopPropagation();

    if (!openSideMenuId) {
      toggleSelectedPlaylist(playlist);
    } else {
      closeSideMenu();
    }
  };

  const handleMoreIconClick = playlistId => e => {
    e.stopPropagation();

    if (!updating) {
      if (!openSideMenuId || openSideMenuId !== playlistId) {
        openSideMenu(playlistId);
      } else {
        closeSideMenu();
      }
    }
  };

  const handleClickAway = () => {
    closeSideMenu();
  };

  const handleRenameClick = () => {
    setEditedPlaylist(playlist);
  };

  const handleDeleteClick = () => {
    setPlaylistToBeDeleted(playlist);
    toggleModalOpen();
  };

  const handleCopyClick = () => {
    setPlaylistToBeCopied(playlist);
  };

  return (
    <div
      className={classes["playlist-item"]}
      onClick={handleItemClick}
      {...{ open: selected }}
    >
      <PlaylistPreview playlist={playlist} />
      <PlaylistInfo
        playlist={playlist}
        editedPlaylist={editedPlaylist}
        setEditedPlaylist={setEditedPlaylist}
        renameEditedPlaylist={renameEditedPlaylist}
        updating={updating}
        handleRenameClick={handleRenameClick}
        playlistPlaying={playlist.playing}
        playlistAssignedToChannel={playlist.assignedToChannel}
      />

      <div className={classes["side-menu-icon"]}>
        <MoreOptionsIcon
          playlistPlaying={playlist.playing}
          playlistAssignedToChannel={playlist.assignedToChannel}
          handleMoreIconClick={handleMoreIconClick(playlist.id)}
          updating={updating}
        />
      </div>
      <SideMenu
        handleClickAway={handleClickAway}
        handleRenameClick={handleRenameClick}
        handleDeleteClick={handleDeleteClick}
        menuOpen={openSideMenuId === playlist.id}
        playlistPlaying={playlist.playing}
        playlistAssignedToChannel={playlist.assignedToChannel}
        handleCopyClick={handleCopyClick}
      />
    </div>
  );
}

PlaylistItem.propTypes = {
  playlist: object.isRequired,
  selectedPlaylist: object,
  toggleSelectedPlaylist: func.isRequired,
  editedPlaylist: object,
  setEditedPlaylist: func.isRequired,
  setPlaylistToBeDeleted: func,
  toggleModalOpen: func.isRequired,
  renameEditedPlaylist: func.isRequired,
  updating: bool,
  setPlaylistToBeCopied: func.isRequired,
  setOpenSideMenuId: func,
  openSideMenuId: string
};

export default PlaylistItem;
