import React, { useState, useEffect, useContext } from "react";
import { object, func } from "prop-types";
import { Slide } from "@material-ui/core";

import { VideoContext } from "contexts/VideoContext";

import EditChannelTopBar from "./components/top-bar/EditChannelTopBar";
import NoActivePlaylist from "./components/no-active-playlist/NoActivePlaylist";
import ActivePlaylistVideos from "./components/active-playlist-videos/ActivePlaylistVideos";

import classes from "./EditSelectedChannel.module.scss";

function EditSelectedChannel({ selectedChannel, toggleSelectedChannel }) {
  const [slideStatus, setSlideStatus] = useState(false);
  const [playlistActiveInChannel, setPlaylistActiveInChannel] = useState({
    id: undefined,
    name: undefined,
    videos: []
  });
  // @ts-ignore
  const { availablePlaylists } = useContext(VideoContext);

  useEffect(() => {
    if (selectedChannel.channelname) {
      handleSlideIn();
      setActivePlaylist();
    } else {
      handleSlideOut();
    }
  }, [selectedChannel]);

  const setActivePlaylist = () => {
    const activePlaylist = getActivePlaylist();
    setPlaylistActiveInChannel(activePlaylist);
  };

  const getActivePlaylist = () => {
    const activePlaylist = availablePlaylists.find(
      playlist => playlist.id === selectedChannel.currentPlaylistID
    );

    return activePlaylist !== undefined ? activePlaylist : { id: undefined };
  };

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 150);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const closeSidePanel = () => {
    toggleSelectedChannel(selectedChannel);
  };

  return (
    <Slide direction="left" in={slideStatus} mountOnEnter unmountOnExit>
      <div className={classes["edit-channel"]}>
        <EditChannelTopBar
          channelName={selectedChannel.channelname}
          activePlaylistName={playlistActiveInChannel.name}
          onClose={closeSidePanel}
        />

        {playlistActiveInChannel.id === undefined ? (
          <NoActivePlaylist />
        ) : (
          <ActivePlaylistVideos videos={playlistActiveInChannel.videos} />
        )}
      </div>
    </Slide>
  );
}

EditSelectedChannel.propTypes = {
  selectedChannel: object.isRequired,
  toggleSelectedChannel: func.isRequired
};

export default EditSelectedChannel;
