import React from "react";
import clsx from "clsx";
import { func } from "prop-types";
import Divider from "@material-ui/core/Divider";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import InfoIcon from "@material-ui/icons/Info";
import TranslateIcon from "@material-ui/icons/Translate";
import { useTranslation } from "react-i18next";
import { RiBubbleChartLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import useHasPermission from "hooks/useHasPermission";
import { permissions } from "data/permissions";

import classes from "./EditProfileMenu.module.scss";

export default function EditProfileMenu({
  togglePersonalInfoModal,
  toggleLanguageModal,
  toggleEditProfileMenu,
  toggleAboutModalOpen,
  toggleLogoutConfirmation
}) {
  const { t } = useTranslation("edit-profile", { useSuspense: false });
  const hasPermissionToAccess = useHasPermission(permissions.pages.ACCESS_PAGE);

  const handleEditClick = () => {
    togglePersonalInfoModal();
    toggleEditProfileMenu();
  };

  const handleLanguageClick = () => {
    toggleLanguageModal();
    toggleEditProfileMenu();
  };

  const handleLogOutClick = () => {
    toggleLogoutConfirmation();
    toggleEditProfileMenu();
  };

  const handleAboutClick = () => {
    toggleAboutModalOpen();
    toggleEditProfileMenu();
  };

  return (
    <div className={classes["edit-profile-menu"]}>
      <div
        className={classes["edit-profile-menu-item"]}
        onClick={handleEditClick}
        data-cy="edit-profile-button"
      >
        <EditSharpIcon className={classes["icon"]} />
        <div className={classes["option"]}>{t("menu.edit-profile")}</div>
      </div>

      <div
        className={classes["edit-profile-menu-item"]}
        onClick={handleLanguageClick}
      >
        <TranslateIcon className={classes["icon"]} />
        <div className={classes["option"]}>{t("menu.language")}</div>
      </div>

      {hasPermissionToAccess && (
        <Link
          className={classes["edit-profile-menu-item"]}
          to="/access"
          onClick={() => toggleEditProfileMenu()}
        >
          <RiBubbleChartLine className={classes["icon"]} />
          <div className={classes["option"]}>
            {t("common:side-menu.access.access")}
          </div>
        </Link>
      )}

      <div
        className={classes["edit-profile-menu-item"]}
        onClick={handleLogOutClick}
      >
        <ExitToAppSharpIcon className={classes["icon"]} />
        <div className={classes["option"]}>{t("menu.log-out")}</div>
      </div>
      <Divider classes={{ root: classes["divider"] }} />
      <div
        className={clsx(
          classes["edit-profile-menu-item"],
          classes["grey-text"]
        )}
        onClick={handleAboutClick}
      >
        <InfoIcon className={classes["icon"]} />
        <div className={classes["option"]}>{t("menu.about")}</div>
      </div>
    </div>
  );
}

EditProfileMenu.propTypes = {
  togglePersonalInfoModal: func.isRequired,
  toggleLanguageModal: func.isRequired,
  toggleEditProfileMenu: func.isRequired,
  toggleAboutModalOpen: func.isRequired,
  toggleLogoutConfirmation: func.isRequired
};
