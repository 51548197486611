import React, { useEffect, useState } from "react";
import { arrayOf, object, func } from "prop-types";
import { Paper } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";

import AlertsListItem from "./list-item/AlertsListItem";

import classes from "./AlertsList.module.scss";

function AlertsList({ alerts, selectedAlert, handleSelectAlert }) {
  const [alertsList, setAlertsList] = useState([]);

  useEffect(() => {
    setAlertsList(alerts);
  }, [alerts]);

  return (
    <Paper className={classes["root"]}>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="70vh">
        {alertsList.map(alert => (
          <AlertsListItem
            key={alert.id}
            alert={alert}
            selectedAlert={selectedAlert}
            handleSelectAlert={handleSelectAlert}
          />
        ))}
      </Scrollbars>
    </Paper>
  );
}

AlertsList.propTypes = {
  alerts: arrayOf(object).isRequired,
  selectedAlert: object.isRequired,
  handleSelectAlert: func.isRequired
};

export default AlertsList;
