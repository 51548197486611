import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useTimeout from "hooks/useTimeout";

import LinearLoader from "components/layout-components/persistentDrawer/components/loader/LinearLoader";

import classes from "./LogoutPage.module.scss";

function LogoutPage() {
  const setTimeOut = useTimeout();
  const { t } = useTranslation("common");

  const redirectLink =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3005/#/applications"
      : process.env.REACT_APP_REDIRECT_URL;

  const openIDX = () => {
    setTimeOut(() => {
      const newWindow = window.open(
        redirectLink,
        "_self",
        "noopener,noreferrer"
      );
      if (newWindow) {
        newWindow.opener = null;
      }
    }, 2000);
  };

  useEffect(() => {
    openIDX();
  }, []);

  return (
    <div className={classes["logout-page"]}>
      <div className={classes["logout-page-title"]}>
        -&nbsp;{t("redirect-to-idx-info")}&nbsp;-
        <div className={classes["loader"]}>
          <LinearLoader progressBar={true} />
        </div>
      </div>
    </div>
  );
}

export default LogoutPage;
