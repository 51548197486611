import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

const Flag = ({ code, width = "25px", height = "15px" }) => {
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    if (code === "en-GB") {
      setCountryCode("gb");
    } else {
      setCountryCode(code);
    }
  }, [code]);

  return (
    <div>
      <ReactCountryFlag
        code={countryCode}
        svg
        styleProps={{
          width,
          height
        }}
      />
    </div>
  );
};

export default Flag;
