import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import PlaylistsApi from "services/api/3-content-api/playlists-api";
import { playlistsRequestsMessages } from "./utils/utils";
import useApi from "hooks/useApi";
import { VideoContext } from "contexts/VideoContext";

import PlaylistsTable from "./components/playlists-table/PlaylistsTable";
import EditSelectedPlaylist from "./components/edit-selected-playlist/EditSelectedPlaylist";
import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./Playlists.module.scss";

const playlistInitial = { name: undefined, id: undefined };

function Playlists() {
  const [allPlaylists, setAllPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(playlistInitial);
  const [editedPlaylist, setEditedPlaylist] = useState(playlistInitial);
  const [playlistToBeDeleted, setPlaylistToBeDeleted] =
    useState(playlistInitial);
  const [playlists, setPlaylists] = useState([]);
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [updatingPlaylists, setUpdatingPlaylists] = useState([]);
  const { t } = useTranslation("video-playlists");
  const {
    PLAYLISTS_LOAD_ERROR,
    RENAME_PLAYLIST_SUCCESS,
    RENAME_PLAYLIST_ERROR,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_ERROR
  } = playlistsRequestsMessages(t);
  const { setAvailablePlaylists } = useContext(VideoContext);

  const onFetchPlaylistsSuccess = data => {
    const transformedPlaylists = data.map(playlist => {
      const videosWithUniqueId = playlist.videos.map(video => ({
        ...video,
        uuid: uuid()
      }));
      return { ...playlist, videos: videosWithUniqueId };
    });
    setPlaylists(transformedPlaylists);
  };

  const [, playlistsLoading, , fetchPlaylists] = useApi(
    PlaylistsApi.getAllPlaylists,
    {
      errorMessage: PLAYLISTS_LOAD_ERROR,
      onSuccess: onFetchPlaylistsSuccess
    }
  );

  const updatePlaylistsInStateAndContext = updatedArray => {
    setAllPlaylists(updatedArray);
    setAvailablePlaylists(updatedArray);
  };

  const onRenamePlaylistSuccess = () => {
    const updatedArray = allPlaylists.map(playlist => {
      if (playlist.id === editedPlaylist.id) {
        playlist.name = newPlaylistName;
      }
      return playlist;
    });
    updatePlaylistsInStateAndContext(updatedArray);
    resetState();
  };

  const [, , , renamePlaylist] = useApi(PlaylistsApi.renamePlaylist, {
    requestOnMount: false,
    onSuccess: onRenamePlaylistSuccess,
    successMessage: RENAME_PLAYLIST_SUCCESS,
    errorMessage: RENAME_PLAYLIST_ERROR
  });

  const onDeletePlaylistSuccess = () => {
    const updatedArray = allPlaylists.filter(
      playlist => playlist.id !== playlistToBeDeleted.id
    );

    setSelectedPlaylist(playlistInitial);
    toggleModalOpen();
    if (!open) {
      setOpen(true);
    }
    setPlaylists(updatedArray);
    updatePlaylistsInStateAndContext(updatedArray);
  };

  const [, deletePlaylistLoading, , deleteOnePlaylist] = useApi(
    PlaylistsApi.deletePlaylist,
    {
      requestOnMount: false,
      onSuccess: onDeletePlaylistSuccess,
      successMessage: DELETE_PLAYLIST_SUCCESS,
      errorMessage: DELETE_PLAYLIST_ERROR
    }
  );

  const deletePlaylist = () => {
    deleteOnePlaylist({ id: playlistToBeDeleted.id });
  };

  const updatePlaylistAfterVideosReorder = (playlistId, videos) => {
    const playlistIndex = playlists.findIndex(
      playlist => playlist.id === playlistId
    );
    if (playlistIndex !== -1) {
      const updatedPlaylist = { ...playlists[playlistIndex], videos };
      const updatedPlaylists = [...playlists];
      updatedPlaylists[playlistIndex] = updatedPlaylist;

      setPlaylists(() => updatedPlaylists);
      setAvailablePlaylists(updatedPlaylists);
    }
  };

  const updatePlaylists = updatedPlaylist => {
    const updatedPlaylists = allPlaylists.map(playlist =>
      updatedPlaylist.id === playlist.id ? updatedPlaylist : playlist
    );

    updatePlaylistsInStateAndContext(updatedPlaylists);
  };

  useEffect(() => {
    if (playlists.length > 0) {
      setAllPlaylists(playlists);
      if (selectedPlaylist.id !== undefined) {
        const fetchedSelectedPlaylist = playlists.find(
          playlist => playlist.id === selectedPlaylist.id
        );
        if (fetchedSelectedPlaylist) {
          setSelectedPlaylist(fetchedSelectedPlaylist);
        }
      }
    }
  }, [playlists, selectedPlaylist.id]);

  useEffect(() => {
    if (selectedPlaylist.name) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [selectedPlaylist]);

  const renameEditedPlaylist = newName => {
    setNewPlaylistName(newName);
  };

  useEffect(() => {
    if (newPlaylistName !== "" && newPlaylistName !== editedPlaylist.name) {
      const payload = {
        id: editedPlaylist.id,
        name: newPlaylistName
      };

      renamePlaylist(payload);
    } else {
      resetState();
    }
  }, [newPlaylistName]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleModalOpen = () => {
    setDeleteConfirmationOpen(prev => !prev);
  };

  const toggleSelectedPlaylist = playlist => {
    if (selectedPlaylist.name === undefined) {
      setSelectedPlaylist(playlist);
    } else if (
      selectedPlaylist.name !== undefined &&
      selectedPlaylist.id === playlist.id
    ) {
      handleDrawerOpen();
      setTimeout(() => {
        setSelectedPlaylist(playlistInitial);
      }, 200);
    } else {
      setSelectedPlaylist(playlist);
    }
  };

  const resetState = () => {
    setEditedPlaylist(playlistInitial);
    setNewPlaylistName("");
  };

  return (
    <>
      <div className={classes["root"]}>
        <PlaylistsTable
          playlists={allPlaylists}
          selectedPlaylist={selectedPlaylist}
          toggleSelectedPlaylist={toggleSelectedPlaylist}
          playlistsLoading={playlistsLoading}
          open={open}
          editedPlaylist={editedPlaylist}
          setEditedPlaylist={setEditedPlaylist}
          setPlaylistToBeDeleted={setPlaylistToBeDeleted}
          renameEditedPlaylist={renameEditedPlaylist}
          toggleModalOpen={toggleModalOpen}
          updatingPlaylists={updatingPlaylists}
          fetchPlaylists={fetchPlaylists}
        />
        <EditSelectedPlaylist
          selectedPlaylist={selectedPlaylist}
          toggleSelectedPlaylist={toggleSelectedPlaylist}
          fetchPlaylists={fetchPlaylists}
          updatingPlaylists={updatingPlaylists}
          setUpdatingPlaylists={setUpdatingPlaylists}
          handleReorderPlaylistVideos={updatePlaylistAfterVideosReorder}
          updatePlaylists={updatePlaylists}
        />
      </div>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={toggleModalOpen}
        onAction={deletePlaylist}
        actionName={t("common:delete")}
        disabled={deletePlaylistLoading}
        loading={deletePlaylistLoading}
      >
        <span>
          {t(
            "sections.playlists.are-you-sure-you-want-to-delete-this-playlist"
          )}
        </span>
      </ConfirmationModal>
    </>
  );
}

export default Playlists;
