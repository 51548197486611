import React, { useRef, useState, useEffect } from "react";
import { timeAgo } from "utils/utils";
import { useTranslation } from "react-i18next";

function UpdatedTime({ timestamp }) {
  const [formattedTime, setFormattedTime] = useState("0s");
  const intervalId = useRef(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    intervalId.current = setInterval(() => {
      const formattedTime = timeAgo(timestamp + 1000);
      setFormattedTime(formattedTime);
    }, 1000);

    const newFormattedTime = timeAgo(timestamp);
    setFormattedTime(newFormattedTime);
    return () => {
      clearInterval(intervalId.current);
    };
  }, [timestamp]);

  return (
    <div style={{ color: `#999` }}>
      <span>{t("last-updated")}:&nbsp;</span>
      <span>{formattedTime}</span>
    </div>
  );
}

export default UpdatedTime;
