import React, { useState, useEffect } from "react";
import { func, object, bool } from "prop-types";
import { useTranslation } from "react-i18next";

import ChannelListApi from "services/api/2-system-manage-api/channel-list-api";
import useApi from "hooks/useApi";
import { channelLineupRequestsMessages, defaultLogo } from "../../utils/utils";

import Modal from "components/ui/modals/modal/Modal";
import LogosGallery from "./components/logos-gallery/LogosGallery";
import GalleryLoader from "./components/gallery-loader/GalleryLoader";
import UploadLogo from "../UploadLogo/UploadLogo";
import GalleryButtons from "./components/gallery-buttons/GalleryButtons";

import classes from "./LogoSelectGallery.module.scss";

function LogoSelectGallery({
  galleryOpen,
  channelLogo,
  closeGalleryModal,
  setNewLogo
}) {
  const [logos, setLogos] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [allLoaded, setAllLoaded] = useState(false);
  const [logoFile, setLogoFile] = useState({ name: "" });
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const { t } = useTranslation(["channel-list"]);
  const { LOGO_UPLOAD_SUCCESS, LOGO_UPLOAD_ERROR, GET_LOGOS_ERROR } =
    channelLineupRequestsMessages(t);
  let imgLoadedCount = 0;
  let imgErrorCount = 0;

  const onFetchLogosSuccess = data => {
    if (logos.length > 0 && data.length > logos.length) {
      setSelectedLogo(data[0].logoPath);
    }

    setLogos(data);
  };

  const [, logosLoading, , fetchLogos] = useApi(ChannelListApi.getLogos, {
    requestOnMount: false,
    errorMessage: GET_LOGOS_ERROR,
    onSuccess: onFetchLogosSuccess
  });

  const onUploadLogoSuccess = () => {
    closeUploadModal();
    setLogoFile({ name: "" });
    fetchLogos();
  };

  const [, uploadLoading, , uploadLogo] = useApi(ChannelListApi.uploadLogo, {
    requestOnMount: false,
    errorMessage: LOGO_UPLOAD_ERROR,
    successMessage: LOGO_UPLOAD_SUCCESS,
    onSuccess: onUploadLogoSuccess
  });

  useEffect(() => {
    if (open) {
      setAllLoaded(false);
    }
  }, [open]);

  useEffect(() => {
    setOpen(galleryOpen);
    setSelectedLogo(channelLogo.logoPath);
    if (galleryOpen) {
      fetchLogos();
    }
  }, [galleryOpen, channelLogo]);

  const handleClose = () => {
    closeGalleryModal();
  };

  const handleLogoClick = e => {
    setSelectedLogo(e.target.id);
  };

  const handleApply = () => {
    const newLogo = getNewLogo();

    closeGalleryModal();
    setNewLogo(newLogo);
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
  };

  const getNewLogo = () => {
    const newLogo = logos.find(logo => logo.logoPath === selectedLogo);

    return newLogo === undefined ? defaultLogo : newLogo;
  };

  const handleImageLoaded = () => {
    imgLoadedCount += 1;
    if (imgLoadedCount + imgErrorCount === logos.length) {
      setAllLoaded(true);
    }
  };

  const handleImageNotLoaded = e => {
    let image = e.target;
    let imageContainer = e.target.parentNode;
    let galleryItemContainer = imageContainer.parentNode;

    imgErrorCount += 1;
    image.style.display = "none";
    imageContainer.style.width = 0;
    galleryItemContainer.style.padding = 0;

    if (imgLoadedCount + imgErrorCount === logos.length) {
      setAllLoaded(true);
    }
  };

  return (
    <>
      <Modal open={open}>
        <Modal.Header onClose={handleClose}>
          <div className={classes["header-title"]}>{t("select-logo")}</div>
        </Modal.Header>
        <Modal.Content>
          <div className={classes["modal-content"]}>
            <LogosGallery
              isVisible={allLoaded && !logosLoading}
              logos={logos}
              selectedLogo={selectedLogo}
              handleLogoClick={handleLogoClick}
              handleImageLoaded={handleImageLoaded}
              handleImageNotLoaded={handleImageNotLoaded}
            />
            <GalleryLoader isVisible={!allLoaded || logosLoading} />
          </div>
        </Modal.Content>

        <Modal.Footer>
          <GalleryButtons
            openUploadModal={openUploadModal}
            disabled={!(allLoaded && !logosLoading)}
            handleApply={handleApply}
            allLoaded={allLoaded}
          />
        </Modal.Footer>
      </Modal>

      <UploadLogo
        open={uploadModalOpen}
        closeUploadModal={closeUploadModal}
        uploadLogo={uploadLogo}
        uploading={uploadLoading}
        logoFile={logoFile}
        setLogoFile={setLogoFile}
      />
    </>
  );
}

LogoSelectGallery.propTypes = {
  galleryOpen: bool,
  channelLogo: object,
  closeGalleryModal: func,
  setNewLogo: func
};

export default LogoSelectGallery;
