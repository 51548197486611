import React from "react";

import VideoContextProvider from "contexts/VideoContext";
import RouteTabs from "./components/route-tabs/RouteTabs";

function VideoPlaylistChannels() {
  return (
    <VideoContextProvider>
      <RouteTabs />
    </VideoContextProvider>
  );
}

export default VideoPlaylistChannels;
