import { useState } from "react";

/**
 * Styles type definition
 * @typedef {Object} Styles
 * @property {string|number} top
 * @property {string} color
 * @property {('left'|'center'|'right')} text-align
 * @property {string|number} font-size
 * @property {import("react").CSSProperties.fontFamily} font-family
 * @property {import("react").CSSProperties.fontWeight} font-weight
 */

/**
 * @param {Styles} styles
 */
const defaultStyles = {
  top: 0,
  color: "inherit",
  "text-align": "center",
  "font-size": 36,
  "font-family": "auto",
  "font-style": "normal",
  "font-weight": "normal",
  width: 1920,
  height: 1080,
  left: 0
};

function useStyles() {
  const [styles, setStyles] = useState(defaultStyles);

  const toBottom = () => {
    const top = +styles.top + 15;
    if (top < 1080 - styles["font-size"]) {
      setStyles(prevStyles => ({ ...prevStyles, top: +styles.top + 15 }));
    } else {
      setStyles(prevStyles => ({ ...prevStyles, top: 1080 - styles["font-size"] }));
    }
  };

  const toTop = () => {
    const top = +styles.top - 15;
    if (top > 0) {
      setStyles(prevStyles => ({ ...prevStyles, top: +styles.top - 15 }));
    } else {
      setStyles(prevStyles => ({ ...prevStyles, top: 0 }));
    }
  };

  const setTextAlign = align => {
    setStyles(prevStyles => ({ ...prevStyles, "text-align": align }));
  };

  const setAlignLeft = () => {
    setTextAlign("left");
  };
  const setAlignCenter = () => {
    setTextAlign("center");
  };
  const setAlignRight = () => {
    setTextAlign("right");
  };

  const setFontSize = value => {
    setStyles(prevStyles => ({ ...prevStyles, "font-size": value }));
  };

  const setColor = ({ hex }) => {
    setStyles(prevStyles => ({ ...prevStyles, color: hex }));
  };

  const setFontFamily = e => {
    const fontFamily = e.target.value;
    setStyles(prevStyles => ({ ...prevStyles, "font-family": fontFamily }));
  };

  const setFontWeight = e => {
    const fontWeight = e.target.value;
    setStyles(prevStyles => ({ ...prevStyles, "font-weight": fontWeight }));
  };

  const getStylesFromAlertObject = alert => {
    if (!alert) {
      return;
    }

    const styleObj = {
      'text-align': alert.textalign,
      color: alert.textcolor,
      'font-size': alert.fontsize, 
    }

    setStyles(prevStyles => {
      return { ...prevStyles, ...styleObj };
    });
  }

  const getStylesFromHTMLText = htmlText => {
    if (!htmlText) {
      return;
    }

    const startStylesIndex = htmlText.indexOf('"');
    if (startStylesIndex === -1) {
      setStyles(defaultStyles);
      return;
    }

    const endStylesIndex =
      htmlText.substr(startStylesIndex + 1).indexOf('"') +
      startStylesIndex +
      1;
    const stylesString = htmlText.substring(
      startStylesIndex + 1,
      endStylesIndex
    );

    const stylesArray = stylesString.split(";");
    const styleObj = stylesArray.reduce((acc, currStyle) => {
      const [key, value] = currStyle.split(":");

      if(['color', 'font-size', 'text-align'].includes(key)) {
        return acc;
      }
      if (key) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const indexOfPX = styleObj["top"] ? styleObj["top"].indexOf("px") : -1;
    if (indexOfPX > -1) {
      styleObj["top"] = +styleObj["top"].substring(0, indexOfPX);
    }

    const indexOfPXLeft = styleObj["left"]
      ? styleObj["left"].indexOf("px")
      : -1;
    if (indexOfPXLeft > -1) {
      styleObj["left"] = +styleObj["left"].substring(0, indexOfPXLeft);
    }

    const indexOfPXWidth = styleObj["width"]
      ? styleObj["width"].indexOf("px")
      : -1;
    if (indexOfPXWidth > -1) {
      styleObj["width"] = +styleObj["width"].substring(0, indexOfPXWidth);
    }

    const indexOfPXHeight = styleObj["height"]
      ? styleObj["height"].indexOf("px")
      : -1;
    if (indexOfPXHeight > -1) {
      styleObj["height"] = +styleObj["height"].substring(
        0,
        indexOfPXHeight
      );
    }
    if (styleObj["font-size"]) {
      const indexOfPXFS = styleObj["font-size"]
        ? styleObj["font-size"].indexOf("px")
        : -1;
      if (indexOfPXFS > -1) {
        styleObj["font-size"] = +styleObj["font-size"].substring(
          0,
          indexOfPXFS
        );
      }
    }

    setStyles(prevStyles => {
      return { ...prevStyles, ...styleObj };
    });
  };

  const convertStylesToString = () => {
    return Object.entries(styles).reduce((acc, [key, value]) => {
      if(['color', 'font-size', 'text-align'].includes(key)) {
        return acc;
      }
      else if (["top", "font-size", "left", "width", "height"].includes(key)) {
        acc += `${key}:${value}px;`;
      }
      else {
        acc += `${key}:${value};`;
      }
      return acc;
    }, "");
  };

  return {
    styles,
    setFunctions: {
      toBottom,
      toTop,
      setAlignLeft,
      setAlignCenter,
      setAlignRight,
      setFontSize,
      setColor,
      setFontFamily,
      setFontWeight
    },
    getStylesFromHTMLText,
    convertStylesToString,
    getStylesFromAlertObject,
  };
}

export default useStyles;
