import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import useApi from "../../hooks/useApi";
import ServerOfflineContext from "../../contexts/ServerOfflineContext";
import ServersApi from "../../services/api/6-servers-api/servers-api";
import { getSnacks } from "../../pages/servers/components/snacks/snacks";

import Modal from "components/ui/modals/modal/Modal";
import ServersTable from "../../pages/servers/components/Table/ServersTable";

import classes from "./IDTOfflineModal.module.scss";
import { Button } from "@material-ui/core";
import { ServerContext } from "../../contexts/ServerContext";

function IDTOfflineModal() {
  const [onlineServers, setOnlineServers] = useState([]);
  const [offlineServerInUse, setOfflineServerInUse] = useState({});
  const { serverOffline, setServerOffline } = useContext(ServerOfflineContext);
  const { serverInUse } = useContext(ServerContext);
  const { t } = useTranslation("common");
  const history = useHistory();

  const { GET_SERVERS_ERROR } = getSnacks(t);

  const [servers, , , fetchServers] = useApi(ServersApi.getServers, {
    errorMessage: GET_SERVERS_ERROR,
    showMessageOnError: false,
  });

  const handlePageReload = () => {
    history.go(0);
    setServerOffline(false);
  };

  useEffect(() => {
    const fetchOnlineServers = async () => {
      await fetchServers();
      const currentOnlineServers = servers.filter(
        ({ id, isOnline }) => isOnline && id !== serverInUse.id
      );
      setOnlineServers(currentOnlineServers);
    };
    if (serverOffline) {
      fetchOnlineServers();
      setOfflineServerInUse(serverInUse);
    } else {
      setOnlineServers([]);
      setOfflineServerInUse({});
    }
  }, [serverOffline]);

  const onlineServersAvailable = onlineServers.length > 0;
  const selectButtonDisabled = offlineServerInUse.id === serverInUse.id;

  return (
    <Modal open={serverOffline}>
      <Modal.Header
        onClose={() => {
          setServerOffline(false);
        }}
      />
      <Modal.Content>
        <div
          className={clsx(classes["content"], {
            [classes["no-online-servers"]]: !onlineServersAvailable,
          })}
        >
          <div className={classes["message-title"]}>
            {t("idt-offline-modal.title")}
          </div>
          <div className={classes["message"]}>
            {t("idt-offline-modal.description-1")}
            <span className={classes["try-again"]} onClick={handlePageReload}>
              {t("idt-offline-modal.try-again")}
            </span>
            .
          </div>
          {onlineServersAvailable && (
            <div className={classes["message"]}>
              {t("idt-offline-modal.description-2")}
            </div>
          )}
          <div className={classes["servers"]}>
            {onlineServersAvailable && (
              <ServersTable
                servers={onlineServers}
                toggleSelectedServer={() => {}}
                selectedServer={{ id: undefined }}
                open={false}
              />
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        {onlineServersAvailable && (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disabled={selectButtonDisabled}
            className={classes["btn"]}
            classes={{ outlinedPrimary: classes["btn-style"] }}
            onClick={handlePageReload}
          >
            {t("idt-offline-modal.select")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default IDTOfflineModal;
