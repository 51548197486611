import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import AlertsApi from "services/api/4-endpoint-control-api/alerts-api";
import useApi from "hooks/useApi";
import ControlContext from "contexts/ControlContext";

import EllipsisLoader from "components/ui/loaders/EllipsisLoader";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";
import { getSnacks } from "../utils/snacks";

import classes from "./AlertsTopBar.module.scss";

function AlertsTopBar() {
  const { t } = useTranslation("control");
  const { ALERTS_CLEARED, ALERTS_CLEARED_ERROR } = getSnacks(t);
  const { requestPayload } = useContext(ControlContext);

  const [, clearAlertsLoader, , clearAlerts] = useApi(AlertsApi.clearAlerts, {
    requestOnMount: false,
    successMessage: ALERTS_CLEARED,
    errorMessage: ALERTS_CLEARED_ERROR,
  });

  const handleClearAlerts = () => {
    clearAlerts(requestPayload);
  };

  return (
    <div className={classes["alerts-top-bar"]}>
      <div
        className={clsx(classes["loader"], {
          [classes["loader-visible"]]: clearAlertsLoader,
        })}
      >
        <EllipsisLoader />
      </div>
      <PrimaryButton size="large" onClick={handleClearAlerts}>
        {t("alerts.clear-all-alerts")}
      </PrimaryButton>
    </div>
  );
}

export default AlertsTopBar;
