import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import classes from "../Table.module.scss";

function TableRow({
  selected = false,
  children,
  onSelect = () => {},
  disabled = false,
  ...restProps
}) {
  return (
    <tr
      {...restProps}
      data-select={selected ? "selected" : "no-selected"}
      className={clsx(classes["table-row"], {
        [classes["disabled"]]: disabled
      })}
      onClick={onSelect}
      {...{ open: selected }}
    >
      {children}
    </tr>
  );
}

TableRow.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool
};

export default TableRow;
