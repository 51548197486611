import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import TreeItem from "@material-ui/lab/TreeItem";
import Scrollbars from "react-custom-scrollbars";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { groupType } from "types";
import {
  getAllGroupsIds,
  getAllEndpoints,
  findCommonElements,
} from "../../utils/utils";
import ControlContext from "contexts/ControlContext";
import DrawerContext from "contexts/DrawerContext";
import {
  focusElement,
  getInputError,
  validateDefault,
  is_Mac,
} from "utils/utils";
import { MAX_DEFAULT_LEN } from "data/constants";
import useDebounce from "hooks/useDebounce";

import GroupsTreeLabel from "./groups-tree-label/GroupsTreeLabel";
import EndpointsTreeLabel from "./endpoints-tree-label/EndpointsTreeLabel";
import EllipsisLoader from "components/ui/loaders/EllipsisLoader";
import MoreOptionsMenu from "./more-options-menu/MoreOptionsMenu";
import Form from "components/ui/form/Form";
import SelectedEndpoints from "./selected-endpoints/SelectedEndpoints";

import classes from "./GroupsTree.module.scss";

function GroupsTreeNew({
  groups,
  groupsLoading,
  selectedGroups,
  selectedEndpoints,
  setSelectedGroups,
  setSelectedEndpoints,
  handleWarningIconClick,
}) {
  const [allAvailableGroups, setAllAvailableGroups] = useState([]);
  const [selectedGroupsLocal, setSelectedGroupsLocal] = useState([]);
  const [selectedEndpointsLocal, setSelectedEndpointsLocal] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [expandedElements, setExpandedElements] = useState([]);
  const allTreeItemsIds = useRef([]);
  const { register, errors, setValue } = useForm({ mode: "onChange" });
  const [searchPhrase, setSearchPhrase] = useState("");
  const searchPhraseDebounced = useDebounce(searchPhrase, 1000);
  const { t } = useTranslation("control");
  const searchInputRef = useRef(null);
  const searchInputLabel = is_Mac ? "Option+S" : "Alt+S";
  const { duplicateEndpoints } = useContext(DrawerContext);

  const {
    setAvailableChannels,
    setAvailableTemplates,
    setHidden,
    forceChannelChangeMode,
  } = useContext(ControlContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (groupsLoading) {
      setAllChecked(false);
      setExpandedElements([]);
    }
  }, [groupsLoading]);

  useEffect(() => {
    if (!allTreeItemsIds.current.length) {
      allTreeItemsIds.current = groups.map(({ id }) => id);
    }
    setAllAvailableGroups(groups);
  }, [groups]);

  // *************** Endpoint search handlers ***************

  const handleOnChange = (e) => {
    setSearchPhrase(e.target.value);
  };

  const clearSearchPhrase = useCallback(() => {
    setSearchPhrase("");
    setValue("endpointSearch", "");
    focusElement(searchInputRef);
  }, [searchPhrase]);

  useEffect(() => {
    // Filter out groups with endpoints whose names contain searchPhraseDebounced
    if (searchPhraseDebounced) {
      if (!errors.endpointSearch) {
        const filteredGroups = groups.reduce((acc, group) => {
          const matchingEndpoints = group.endpoints.filter((endpoint) =>
            endpoint.name
              .toLowerCase()
              .includes(searchPhraseDebounced.toLowerCase())
          );

          if (matchingEndpoints.length) {
            const updatedGroup = { ...group, endpoints: matchingEndpoints };

            acc = [...acc, updatedGroup];
          }

          return acc;
        }, []);

        // set the filtered out groups in state
        setAllAvailableGroups(filteredGroups);

        // expand filtered out groups (by endpoint ids)
        setExpandedElements(filteredGroups.map(({ id }) => id));
      }
    } else {
      setAllAvailableGroups(groups);

      // Leave expanded only those groups that have endpoints selected
      const expandedGroups = selectedEndpointsLocal
        .map(({ groupId }) => groupId)
        .reduce(
          (acc, groupId) => (acc.includes(groupId) ? acc : [...acc, groupId]), // Removes duplicates
          []
        );

      setExpandedElements(expandedGroups);
    }
  }, [searchPhraseDebounced]);

  // *************** Click on Group/Endpoint handlers ***************

  // Group Click
  const onGroupClick = (groupId) => (event) => {
    event.preventDefault();

    const currentGroup = allAvailableGroups.find(({ id }) => id === groupId);
    const selectedEndpointsInThisGroup = selectedEndpoints.filter(
      (endpoint) => endpoint.groupId === groupId
    );

    const isGroupSelected =
      currentGroup.endpoints.length === selectedEndpointsInThisGroup.length ||
      selectedGroups.includes(groupId);

    if (isGroupSelected) {
      const updatedGroups = selectedGroups.filter(
        (selectedGroup) => selectedGroup !== groupId
      );
      const updatedEndpoints = selectedEndpoints.filter(
        (endp) => endp.groupId !== groupId
      );

      setSelectedGroups(updatedGroups);
      setSelectedEndpoints(updatedEndpoints);
    } else {
      const endpointsArray = currentGroup.endpoints.map(({ id }) => ({
        id,
        groupId,
      }));

      const updatedEndpoints = [...selectedEndpoints, ...endpointsArray].reduce(
        // Remove duplicates
        (acc, endpoint) =>
          acc.some((item) => item.id === endpoint.id)
            ? acc
            : [...acc, endpoint],
        []
      );

      setSelectedEndpoints(updatedEndpoints);

      setSelectedGroups((prev) => [...prev, groupId]);
    }
  };

  // Endpoint Click
  const onEndpointClick = (groupId, endpoint) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    const isEndpointSelected = selectedEndpoints.some(
      ({ id }) => id === endpoint.id
    );

    if (!isEndpointSelected) {
      setSelectedEndpoints((prev) => [...prev, { id: endpoint.id, groupId }]);
    } else {
      const updatedEndpoints = selectedEndpoints.filter(
        ({ id }) => id !== endpoint.id
      );
      const updatedGroups = selectedGroups.filter(
        (selectedGroup) => selectedGroup !== groupId
      );

      setSelectedEndpoints(updatedEndpoints);
      setSelectedGroups(updatedGroups);
    }
  };

  // *************** Check All/Expand All handlers ***************

  const checkAllGroupsAndEndpoints = () => {
    const allGroups = getAllGroupsIds(allAvailableGroups);
    const allEndpoints = getAllEndpoints(allAvailableGroups);

    setAllChecked(true);
    setSelectedGroups(allGroups);
    setSelectedEndpoints(allEndpoints);
  };

  const uncheckAllGroupsAndEndpoints = () => {
    setAllChecked(false);
    setSelectedGroups([]);
    setSelectedEndpoints([]);
  };

  const onAllCheck = () => {
    if (allChecked) {
      uncheckAllGroupsAndEndpoints();
    } else {
      checkAllGroupsAndEndpoints();
    }
  };

  const expandElements = (_, expandedIds) => {
    setExpandedElements(expandedIds);
  };

  const onToggleAllTreeItems = () => {
    if (expandedElements.length === allTreeItemsIds.current.length) {
      setExpandedElements([]);
    } else {
      setExpandedElements(allTreeItemsIds.current);
    }
  };

  const expandedAll = !!(
    allTreeItemsIds.current.length &&
    expandedElements.length === allTreeItemsIds.current.length
  );

  // ************* Finding common assets for selected groups/endpoints *************

  const findChannelsAndTemplatesForSelectedEndpoints = () => {
    // Find CHANNELS
    const commonChannels = findCommonElements(
      allAvailableGroups,
      selectedEndpointsLocal,
      "channelIds"
    );

    // Find TEMPLATES
    const commonTemplates = findCommonElements(
      allAvailableGroups,
      selectedEndpointsLocal,
      "templateIds"
    );

    setAvailableChannels(commonChannels);
    setAvailableTemplates(commonTemplates);
  };

  useEffect(() => {
    if (!pathname.includes("force-channel-change")) {
      const hidden = !(
        selectedGroupsLocal.length || selectedEndpointsLocal.length
      );

      if (allChecked) {
        if (allAvailableGroups.length !== selectedGroupsLocal.length) {
          setAllChecked(false);
        }
      } else {
        if (allAvailableGroups.length === selectedGroupsLocal.length) {
          setAllChecked(true);
        }
      }

      setHidden(hidden);
      findChannelsAndTemplatesForSelectedEndpoints();
    }
  }, [selectedGroupsLocal, selectedEndpointsLocal]);

  useEffect(() => {
    if (forceChannelChangeMode) {
      const allGroups = getAllGroupsIds(allAvailableGroups);
      const allEndpoints = getAllEndpoints(allAvailableGroups);

      setSelectedGroupsLocal(allGroups);
      setSelectedEndpointsLocal(allEndpoints);
    } else {
      setSelectedGroupsLocal(selectedGroups);
      setSelectedEndpointsLocal(selectedEndpoints);
    }
  }, [forceChannelChangeMode, selectedGroups, selectedEndpoints]);

  // *************** Setting group checkbox state ***************

  const setGroupChecked = (groupId) => {
    const currentGroup = allAvailableGroups.find(({ id }) => id === groupId);
    const selectedEndpointsInThisGroup = selectedEndpoints.filter(
      (endpoint) => endpoint.groupId === groupId
    );

    const checked =
      !!currentGroup.endpoints.length &&
      (currentGroup.endpoints.length === selectedEndpointsInThisGroup.length ||
        selectedGroupsLocal.includes(groupId));

    return checked;
  };

  // *************** Unselect endpoints handler ***************

  const unselectEndpoints = () => {
    setSelectedEndpoints([]);
    setSelectedEndpointsLocal([]);

    if (expandedElements.length) {
      setExpandedElements([]);
    }

    if (selectedGroups.length) {
      setSelectedGroups([]);
    }
    if (selectedGroupsLocal.length) {
      setSelectedGroupsLocal([]);
    }
  };

  // *************** Detect "Alt + S" and "Esc" pressed ***************

  const handleKeyDown = (event) => {
    if (event.altKey && event.keyCode === 83) {
      event.preventDefault();
      focusElement(searchInputRef);
    }

    if (event.key === "Escape") {
      setSearchPhrase("");
      setValue("endpointSearch", "");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes["search-container"]}>
          <SearchIcon classes={{ root: classes["search-magnifying-icon"] }} />
          <Form.Input
            // @ts-ignore
            id="endpoint-search"
            name="endpointSearch"
            ref={(e) => {
              register(e, {
                maxLength: {
                  value: MAX_DEFAULT_LEN,
                  message: "common:form.max-length",
                },
                ...validateDefault,
              });
              searchInputRef.current = e;
            }}
            error={getInputError(errors.endpointSearch, t, MAX_DEFAULT_LEN)}
            onChange={handleOnChange}
            customclass={classes["input-custom"]}
            search="true"
            placeholder={`${t("search-endpoints")}...`}
          />
          {!!searchPhrase ? (
            <CloseIcon
              classes={{ root: classes["search-close-icon"] }}
              onClick={clearSearchPhrase}
            />
          ) : (
            <span className={classes["search-alt-s"]}>
              &#91;{searchInputLabel}&#93;
            </span>
          )}
        </div>
        <MoreOptionsMenu
          allChecked={allChecked}
          onAllCheck={onAllCheck}
          expandedAll={expandedAll}
          onToggleAllTreeItems={onToggleAllTreeItems}
        />
      </div>
      <SelectedEndpoints
        numberOfSelected={selectedEndpointsLocal.length}
        onClick={unselectEndpoints}
      />
      <Scrollbars autoHeight autoHeightMax="80vh" autoHide>
        <div className={classes["tree-view-container"]}>
          <TreeView
            className={classes["tree-view"]}
            classes={{ root: classes["tree-view"] }}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expandedElements}
            onNodeToggle={expandElements}
          >
            {groupsLoading ? (
              <div className={classes["loader"]}>
                <EllipsisLoader />
              </div>
            ) : (
              allAvailableGroups.map((group) => {
                const disabled = !group.endpoints.length;
                const hasDuplicates = group.endpoints.some(
                  (endpoint) =>
                    endpoint.id ===
                    duplicateEndpoints
                      .flat()
                      .find(({ id }) => id === endpoint.id)?.id
                );
                return (
                  <TreeItem
                    nodeId={group.id}
                    key={group.id}
                    label={
                      <GroupsTreeLabel
                        name={group.name}
                        disabled={disabled}
                        checked={setGroupChecked(group.id)}
                        handleClick={onGroupClick(group.id)}
                        hasDuplicates={hasDuplicates}
                        handleWarningIconClick={handleWarningIconClick}
                      />
                    }
                  >
                    {group.endpoints.map((endpoint) => {
                      const hasDuplicate = duplicateEndpoints
                        .flat()
                        .some(({ id }) => id === endpoint.id);

                      return (
                        <TreeItem
                          nodeId={endpoint.id}
                          key={endpoint.id}
                          label={
                            <EndpointsTreeLabel
                              name={endpoint.name}
                              checked={selectedEndpointsLocal.some(
                                ({ id }) => id === endpoint.id
                              )}
                              online={endpoint.online}
                              template={endpoint.template}
                              currentChannel={endpoint.currentChannel}
                              subtitles={endpoint.subtitles || false}
                              fullscreen={endpoint.fullscreen}
                              volume={endpoint.volume}
                              startUp={endpoint.startUp}
                              handleClick={onEndpointClick(group.id, endpoint)}
                              hasDuplicate={hasDuplicate}
                              handleWarningIconClick={handleWarningIconClick}
                            />
                          }
                        />
                      );
                    })}
                  </TreeItem>
                );
              })
            )}
          </TreeView>
        </div>
      </Scrollbars>
      {forceChannelChangeMode && (
        <div className={classes["disabled-overlay"]} />
      )}
    </div>
  );
}

GroupsTreeNew.propTypes = {
  groups: arrayOf(groupType).isRequired,
  groupsLoading: bool,
  selectedGroups: arrayOf(string),
  selectedEndpoints: arrayOf(shape({ id: string, groupId: string })),
  setSelectedGroups: func,
  setSelectedEndpoints: func,
  handleWarningIconClick: func,
};

export default GroupsTreeNew;
