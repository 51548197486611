const PREFIX = "sections.playlists.snacks";

export function playlistsRequestsMessages(t) {
  const PLAYLISTS_LOAD_ERROR = {
    message: t(`${PREFIX}.playlists-could-not-be-loaded`),
    variant: "error",
    autoHideDuration: 3500
  };

  const CREATE_PLAYLIST_SUCCESS = {
    message: t(`${PREFIX}.playlist-has-been-created`),
    variant: "success",
    autoHideDuration: 3500
  };

  const CREATE_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.playlist-could-not-be-created`),
    variant: "error",
    autoHideDuration: 3500
  };

  const RENAME_PLAYLIST_SUCCESS = {
    message: t(`${PREFIX}.playlist-has-been-renamed`),
    variant: "success",
    autoHideDuration: 3500
  };

  const RENAME_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.playlist-could-not-be-renamed`),
    variant: "error",
    autoHideDuration: 3500
  };

  const DELETE_PLAYLIST_SUCCESS = {
    message: t(`${PREFIX}.playlist-has-been-deleted`),
    variant: "success",
    autoHideDuration: 3500
  };

  const DELETE_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.playlist-could-not-be-deleted`),
    variant: "error",
    autoHideDuration: 3500
  };

  const VIDEOS_LOAD_ERROR = {
    message: t(`${PREFIX}.videos-could-not-be-loaded`),
    variant: "error",
    autoHideDuration: 3500
  };

  const REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS = {
    message: t(`${PREFIX}.video-has-been-removed-from-playlist`),
    variant: "success",
    autoHideDuration: 3500
  };

  const REMOVE_VIDEO_FROM_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.video-could-not-be-removed-from-playlist`),
    variant: "error",
    autoHideDuration: 3500
  };

  const ADD_VIDEO_TO_PLAYLIST_SUCCESS = {
    message: t(`${PREFIX}.video-has-been-added-to-playlist`),
    variant: "success",
    autoHideDuration: 3500
  };

  const ADD_VIDEOS_TO_PLAYLIST_SUCCESS = {
    message: t(`${PREFIX}.videos-have-been-added-to-playlist`),
    variant: "success",
    autoHideDuration: 3500
  };

  const ADD_VIDEO_TO_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.video-could-not-be-added-to-playlist`),
    variant: "error",
    autoHideDuration: 3500
  };

  const ADD_VIDEOS_TO_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.videos-could-not-be-added-to-playlist`),
    variant: "error",
    autoHideDuration: 3500
  };

  const COPY_PLAYLIST_TO_IDT_ERROR = {
    message: t(`${PREFIX}.playlist-copy-to-idt-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  const COPY_PLAYLIST_TO_IDT_SUCCESS = {
    message: t(`${PREFIX}.playlist-copy-to-idt-success`),
    variant: "success",
    autoHideDuration: 3500
  };

  const GET_SERVERS_ERROR = {
    message: t("servers:snacks.servers-list-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  };

  const REORDER_VIDEOS_IN_PLAYLIST_ERROR = {
    message: t(`${PREFIX}.reorder-videos-in-playlist-error`),
    variant: "error",
    autoHideDuration: 3500
  };

  return {
    PLAYLISTS_LOAD_ERROR,
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_ERROR,
    RENAME_PLAYLIST_SUCCESS,
    RENAME_PLAYLIST_ERROR,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_ERROR,
    VIDEOS_LOAD_ERROR,
    REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS,
    REMOVE_VIDEO_FROM_PLAYLIST_ERROR,
    ADD_VIDEO_TO_PLAYLIST_SUCCESS,
    ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
    ADD_VIDEO_TO_PLAYLIST_ERROR,
    ADD_VIDEOS_TO_PLAYLIST_ERROR,
    COPY_PLAYLIST_TO_IDT_ERROR,
    COPY_PLAYLIST_TO_IDT_SUCCESS,
    GET_SERVERS_ERROR,
    REORDER_VIDEOS_IN_PLAYLIST_ERROR
  };
}
