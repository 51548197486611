import axios from "axios";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../../data/constants";
const cookies = new Cookies();

class AssignChannelsApi {
  getToken = () => cookies.get("token");

  assignChannelToGroup = async (serverInUseId, { channelId, groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/${channelId}/assign-to-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groupId
      }
    });
  };

  removeChannelFromGroup = async (serverInUseId, { channelId, groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/${channelId}/remove-from-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        groupId
      }
    });
  };

  setChannelAsDefault = async (serverInUseId, { channelId, groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/${groupId}/set-default-channel`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers,
      data: {
        channelId
      }
    });
  };

  assignAllChannelsToGroup = async (serverInUseId, { groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/${groupId}/assign-all-to-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers
    });
  };

  removeAllChannelsFromGroup = async (serverInUseId, { groupId }) => {
    const method = `post`;
    const path = `/api/v1/idtservers/${serverInUseId}/channels/${groupId}/remove-all-from-group`;
    const url = `${baseUrl}${path}`;
    const token = this.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return await axios({
      method,
      url,
      headers
    });
  };
}

export default new AssignChannelsApi();
