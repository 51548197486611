import React from "react";
import { bool } from "prop-types";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LockSharpIcon from "@material-ui/icons/LockSharp";

import classes from "./PlaylistAssigned.module.scss";

function PlaylistAssigned({ isAssigned }) {
  const { t } = useTranslation("video-playlists");

  return isAssigned ? (
    <div className={classes["lock-icon-container"]}>
      <Tooltip
        title={t("sections.playlists.tooltips.playlist-is-assigned")}
        placement="right-start"
        classes={{ tooltip: classes["tooltip-font"] }}
      >
        <LockSharpIcon classes={{ root: classes["lock-icon"] }} />
      </Tooltip>
    </div>
  ) : null;
}

PlaylistAssigned.propTypes = {
  isAssigned: bool
};

export default PlaylistAssigned;
