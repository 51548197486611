import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import Scrollbars from "react-custom-scrollbars";

import { ControlContextProvider } from "contexts/ControlContext";
import EndpointGroupsApi from "services/api/2-system-manage-api/endpoint-groups-api";
import useApi from "hooks/useApi";
import { groupMapper } from "./utils/utils";

import RouteTabs from "./components/route-tabs/RouteTabs";
import GroupsTreeNew from "./components/groups-tree/GroupsTreeNew";
import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./ControlPanel.module.scss";

function ControlPanel() {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [duplicatesInfoVisible, setDuplicatesInfoVisible] = useState(false);
  const [duplicatesListVisible, setDuplicatesListVisible] = useState(false);

  const { t } = useTranslation("control");

  const showDuplicatesInfo = () => {
    setDuplicatesInfoVisible(true);
  };

  const hideDuplicatesInfo = () => {
    setDuplicatesInfoVisible(false);
  };

  const showDuplicatesList = () => {
    setDuplicatesListVisible(true);
  };

  const toggleDuplicatesList = () => {
    setDuplicatesListVisible((prev) => !prev);
  };

  const onFetchGroupsSuccess = (data) => {
    const modifiedGroups = data ? data.map(groupMapper) : [];

    setGroups(modifiedGroups);
    setSelectedGroups([]);
    setSelectedEndpoints([]);
  };

  const handleWarningIconClick = () => {
    if (!duplicatesInfoVisible) {
      showDuplicatesList();
      showDuplicatesInfo();
    }
  };

  const [, groupsLoading, , refetchGroups] = useApi(
    EndpointGroupsApi.getEndpointGroups,
    {
      onSuccess: onFetchGroupsSuccess,
    }
  );

  return (
    <ControlContextProvider
      groups={groups}
      selectedGroups={selectedGroups}
      selectedEndpoints={selectedEndpoints}
      setGroups={setGroups}
      setSelectedGroups={setSelectedGroups}
    >
      <Scrollbars autoHeight autoHeightMax="100vh">
        <div className="header-container">
          <div className="header-first-col">
            <h1 className="header">{t("endpoint-control")}</h1>
            <PrimaryButton onClick={refetchGroups} disabled={groupsLoading}>
              <RefreshIcon className={classes["refresh-icon"]} />
            </PrimaryButton>
          </div>
        </div>
        <div className={classes["control-panel"]}>
          <div className={classes["control-panel-tree"]}>
            <GroupsTreeNew
              groups={groups}
              groupsLoading={groupsLoading}
              selectedGroups={selectedGroups}
              selectedEndpoints={selectedEndpoints}
              setSelectedGroups={setSelectedGroups}
              setSelectedEndpoints={setSelectedEndpoints}
              handleWarningIconClick={handleWarningIconClick}
            />
          </div>
          <div className={classes["control-panel-controls"]}>
            <RouteTabs
              duplicatesInfoVisible={duplicatesInfoVisible}
              duplicatesListVisible={duplicatesListVisible}
              showDuplicatesInfo={showDuplicatesInfo}
              hideDuplicatesInfo={hideDuplicatesInfo}
              toggleDuplicatesList={toggleDuplicatesList}
            />
          </div>
        </div>
      </Scrollbars>
    </ControlContextProvider>
  );
}

export default ControlPanel;
