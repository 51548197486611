import React, { useRef, useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@material-ui/core";
import clsx from "clsx";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { arrayOf, shape, string, func } from "prop-types";

import classes from "./SplitButton.module.scss";

function SplitButton({ options = [], disabled = false }) {
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClick = e => {
    setOpen(false);
    const onClick = options[index].onClickHandler;
    onClick && onClick(e);
  };

  const handleMenuItemClick = index => () => {
    setIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (options && !!options.length) {
      setIndex(0);
    }
  }, [options.length]);

  const { type, label } = options[index];

  return (
    <>
      <ButtonGroup
        disabled={disabled}
        variant="contained"
        classes={{
          grouped: clsx(classes["split-btn"], {
            [classes["split-btn-delete"]]: type === "delete"
          })
        }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>{label}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map(({ _key, label }, index) => (
                    <MenuItem
                      key={_key}
                      className={classes["menu-item"]}
                      onClick={handleMenuItemClick(index)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SplitButton.propTypes = {
  options: arrayOf(
    shape({
      _key: string.isRequired,
      label: string.isRequired,
      onClickHandler: func.isRequired,
      type: string
    })
  )
};

export default SplitButton;
