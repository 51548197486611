import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { bool, func } from "prop-types";
import clsx from "clsx";

import PrimarySwitch from "components/ui/switch/primary-switch/PrimarySwitch";

import classes from "./MoreOptionsMenu.module.scss";

function MoreOptionsMenu({
  allChecked,
  onAllCheck,
  expandedAll,
  onToggleAllTreeItems,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation("control");

  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <div className={classes["icon-container"]}>
        {!showMenu ? (
          <MoreVertIcon className={classes["icon-more"]} onClick={openMenu} />
        ) : (
          <CloseIcon className={classes["icon-close"]} onClick={closeMenu} />
        )}
      </div>

      <div
        className={clsx(classes["switches-container"], {
          [classes.open]: showMenu,
        })}
      >
        <div className={classes.switch}>
          <PrimarySwitch
            label={allChecked ? t("tree.uncheck-all") : t("tree.check-all")}
            checked={allChecked}
            onChange={onAllCheck}
          />
        </div>
        <div className={classes.switch}>
          <PrimarySwitch
            label={expandedAll ? t("tree.hide-all") : t("tree.expand-all")}
            checked={expandedAll}
            onChange={onToggleAllTreeItems}
          />
        </div>
      </div>
    </>
  );
}

MoreOptionsMenu.propTypes = {
  allChecked: bool,
  onAllCheck: func,
  expandedAll: bool,
  onToggleAllTreeItems: func,
};

export default MoreOptionsMenu;
