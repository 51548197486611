import React from "react";
import clsx from "clsx";

import classes from "./PlaylistItemPlaceholder.module.scss";

function PlaylistItemPlaceholder({ customClass = null }) {
  return (
    <div
      className={clsx(classes["playlist-item-placeholder"], {
        [customClass]: customClass
      })}
    />
  );
}

export default PlaylistItemPlaceholder;
