import React from "react";
import { arrayOf, object, func } from "prop-types";
import Scrollbars from "react-custom-scrollbars";

import ModalPlaylistItem from "./modal-playlist-item/ModalPlaylistItem";

import classes from "./ModalPlaylists.module.scss";

function ModalPlaylists({
  availablePlaylists,
  playlistActiveInChannel,
  handlePlaylistClick
}) {
  return (
    <Scrollbars autoHeight autoHide autoHeightMax="60vh">
      <div className={classes["modal-playlists"]}>
        {availablePlaylists.length > 0 &&
          availablePlaylists.map(playlist => (
            <ModalPlaylistItem
              key={playlist.id}
              playlist={playlist}
              selected={playlistActiveInChannel.id === playlist.id}
              handlePlaylistClick={handlePlaylistClick}
            />
          ))}
      </div>
    </Scrollbars>
  );
}

ModalPlaylists.propTypes = {
  availablePlaylists: arrayOf(object),
  playlistActiveInChannel: object,
  handlePlaylistClick: func.isRequired
};

export default ModalPlaylists;
