import React from "react";
import { useTranslation } from "react-i18next";
import { bool, func } from "prop-types";

import PrimaryButton from "components/ui/buttons/primary-button/PrimaryButton";

import classes from "./EditUserButtons.module.scss";

function EditUserButtons({ disabled, handleSubmit, onSubmit }) {
  const { t } = useTranslation("access-management");

  return (
    <div className={classes["edit-user-buttons"]}>
      <PrimaryButton
        type="submit"
        disabled={disabled}
        onClick={handleSubmit(onSubmit)}
      >
        {t("common:apply")}
      </PrimaryButton>
    </div>
  );
}

EditUserButtons.propTypes = {
  disabled: bool,
  handleSubmit: func,
  onSubmit: func
};

export default EditUserButtons;
