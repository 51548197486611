import React from "react";
import { arrayOf, bool, func, number, object, string } from "prop-types";
import { ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import classes from "./SideMenu.module.scss";

function SideMenu({
  open,
  group,
  handleClickAway,
  toggleAssign,
  closeSideMenu,
  pathname,
  allEnabledChannelsIds = [],
  numberOfTemplates = null
}) {
  const { t } = useTranslation("assign-pages");

  const channelsAssignedToGroupIds = group.channelids;
  const templatesAssignedToGroupIds = group.templateids;

  const assignAllDisabled =
    pathname === "/endpoints/channels"
      ? allEnabledChannelsIds.every(channelId =>
          channelsAssignedToGroupIds.includes(channelId)
        )
      : Array.from(new Set(templatesAssignedToGroupIds)).length ===
        numberOfTemplates;

  const removeAllDisabled =
    pathname === "/endpoints/channels"
      ? channelsAssignedToGroupIds.length === 0
      : templatesAssignedToGroupIds.length === 0;

  const handleMenuItemClick = actionName => _ => {
    toggleAssign(group, actionName);
    closeSideMenu();
  };

  return open ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes["side-menu"]}>
        <div
          className={clsx(classes["side-menu-option"], {
            [classes["disabled"]]: assignAllDisabled
          })}
          onClick={
            !assignAllDisabled ? handleMenuItemClick("assign") : () => null
          }
        >
          {t("table-header.assign-all-to-group")}
        </div>
        <div
          className={clsx(classes["side-menu-option"], {
            [classes["disabled"]]: removeAllDisabled
          })}
          onClick={
            !removeAllDisabled ? handleMenuItemClick("remove") : () => null
          }
        >
          {t("table-header.remove-all-from-group")}
        </div>
      </div>
    </ClickAwayListener>
  ) : null;
}

SideMenu.propTypes = {
  open: bool,
  group: object,
  handleClickAway: func,
  toggleAssign: func,
  closeSideMenu: func,
  pathname: string,
  allEnabledChannelsIds: arrayOf(string),
  numberOfTemplates: number
};

export default SideMenu;
