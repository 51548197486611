import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { object, bool, func } from "prop-types";
import clsx from "clsx";
import ScheduleSharpIcon from "@material-ui/icons/ScheduleSharp";
import Img from "react-image";
import { Tooltip } from "@material-ui/core";

import VideoSprite from "pages/content/video-playlist-channels/components/videos/components/video-item/components/video-sprite/VideoSprite";
import PreviewUnloader from "components/ui/preview-unloader/PreviewUnloader";

import classes from "./ModalPlaylistItem.module.scss";

function ModalPlaylistItem({ playlist, selected, handlePlaylistClick }) {
  const [playlistPreview, setPlaylistPreview] = useState({
    url: undefined,
    isSprite: undefined
  });
  const { t } = useTranslation("video-playlists");

  const TooltipTitle = () => (
    <div>
      <div>
        {t("sections.playlist-channels.tooltips.playlist-already-assigned-top")}
      </div>
      <div>
        {t(
          "sections.playlist-channels.tooltips.playlist-already-assigned-bottom"
        )}
      </div>
    </div>
  );

  useEffect(() => {
    const getPlaylistPreview = () => {
      const firstVideo = playlist.videos[0];
      const preview = {
        url: firstVideo !== undefined ? firstVideo.previewUrl : "",
        isSprite: firstVideo !== undefined ? firstVideo.sprite : false
      };

      setPlaylistPreview(preview);
    };

    getPlaylistPreview();
  }, [playlist]);

  const handleItemClick = () => {
    if (!playlist.assignedToChannel) {
      handlePlaylistClick(playlist);
    }
  };

  return (
    <div
      className={clsx(classes["modal-playlist-item"], {
        [classes["item-selected"]]: selected
      })}
      onClick={handleItemClick}
    >
      <div
        className={clsx(classes["channel-preview"], {
          [classes["color-fade"]]: playlist.assignedToChannel
        })}
      >
        {playlistPreview.isSprite ? (
          <VideoSprite
            spriteUrl={playlistPreview.url}
            frameWidth={272.7}
            frameHeight={153.5}
          />
        ) : (
          <Img
            src={playlistPreview.url}
            className={classes["image"]}
            unloader={<PreviewUnloader medium />}
          />
        )}
      </div>
      <div className={classes["info-background"]}></div>
      <div className={classes["modal-playlist-item-info"]}>
        <div className={classes["playlist-name"]}>{playlist.name}</div>
        <div className={classes["playlist-duration"]}>
          <div className={classes["timer-icon"]}>
            <ScheduleSharpIcon classes={{ root: classes["timer-icon"] }} />
          </div>
          {playlist.totalduration}
        </div>
      </div>

      {playlist.assignedToChannel && (
        <div className={classes["assigned"]}>
          <Tooltip
            title={<TooltipTitle />}
            classes={{ tooltip: classes["tooltip"] }}
            placement="top-start"
          >
            <div className={classes["assigned-label"]}>
              {t("sections.playlist-channels.assigned")}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

ModalPlaylistItem.propTypes = {
  playlist: object,
  selected: bool,
  handlePlaylistClick: func.isRequired
};

export default ModalPlaylistItem;
