import React from "react";
import { Switch } from "@material-ui/core";

import classes from "./IDTSwitch.module.scss";

function IDTSwitch(props) {
  return (
    <Switch
      focusVisibleClassName={classes["focus-visible"]}
      disableRipple
      classes={{
        root: classes["root"],
        switchBase: classes["switch-base"],
        thumb: classes["thumb"],
        track: classes["track"],
        checked: classes["checked"],
        colorSecondary: classes["color-secondary"],
        disabled: classes["disabled"]
      }}
      {...props}
    />
  );
}

export default IDTSwitch;
