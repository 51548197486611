import React from "react";
import { string, bool, func, object } from "prop-types";
import clsx from "clsx";
import { InputAdornment } from "@material-ui/core";

import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";

import classes from "./InputArrows.module.scss";

function InputArrows({
  unit,
  isChecked,
  handleArrowClick,
  plusObject,
  minusObject
}) {
  return (
    <InputAdornment position="end">
      <div className={classes["time-unit"]}>{unit}</div>
      <div
        className={clsx(classes["icons-container"], {
          [classes["no-pointer"]]: !isChecked
        })}
      >
        <KeyboardArrowUpSharpIcon
          className={classes["up-icon"]}
          onClick={() => handleArrowClick(plusObject)}
        />
        <KeyboardArrowDownSharpIcon
          className={classes["down-icon"]}
          id="down"
          onClick={() => handleArrowClick(minusObject)}
        />
      </div>
    </InputAdornment>
  );
}

InputArrows.propTypes = {
  unit: string,
  isChecked: bool,
  handleArrowClick: func,
  plusObject: object,
  minusObject: object
};

export default InputArrows;
