import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import TvControlApi from "services/api/tv-control-api/tv-control-api";
import ControlContext from "contexts/ControlContext";
import { tvControlSetRequestsMessages } from "../../utils/utils";
import { ENDPOINT_CONTROL } from "data/urls";

import NotSelectedOption from "../not-selected-option/NotSelectedOption";
import ChangeChannel from "./change-channel/ChangeChannel";
import ChangeVolume from "./change-volume/ChangeVolume";
import SetActionOn from "./set-action-on/SetActionOn";

import classes from "./TvControl.module.scss";

function TvControl() {
  const { hidden, setGroups, availableChannels, requestPayload } =
    useContext(ControlContext);

  const { push } = useHistory();
  const { t } = useTranslation("control");

  const {
    FULLSCREEN_ON_SUCCESS,
    FULLSCREEN_OFF_SUCCESS,
    TURN_ON_SUCCESS,
    TURN_OFF_SUCCESS,
    SUBTITLES_ON_SUCCESS,
    SUBTITLES_OFF_SUCCESS,
  } = tvControlSetRequestsMessages(t);

  return (
    <div className={classes.container}>
      {!hidden ? (
        <Scrollbars autoHeight autoHeightMax="64vh">
          <div className={classes["tv-control"]}>
            <div className={classes["tv-control-controls"]}>
              <ChangeChannel
                setGroups={setGroups}
                availableChannels={availableChannels}
                requestPayload={requestPayload}
              />
              <Paper className={classes["box"]}>
                <div className={classes["box-title"]}>
                  {t("tv-control.set-on-off")}
                </div>
                <div className={classes["box-content"]}>
                  <SetActionOn
                    requestPayload={requestPayload}
                    offApiMethod={TvControlApi.setFullscreenOff}
                    onApiMethod={TvControlApi.setFullscreenOn}
                    label={t("tv-control.fullscreen")}
                    fieldName="fullscreen"
                    setGroups={setGroups}
                    onReqSuccessMessage={FULLSCREEN_ON_SUCCESS}
                    offReqSuccessMessage={FULLSCREEN_OFF_SUCCESS}
                  />
                  <SetActionOn
                    requestPayload={requestPayload}
                    offApiMethod={TvControlApi.setSubtitlesOff}
                    onApiMethod={TvControlApi.setSubtitlesOn}
                    label={t("tv-control.subtitles")}
                    fieldName="subtitles"
                    setGroups={setGroups}
                    onReqSuccessMessage={SUBTITLES_ON_SUCCESS}
                    offReqSuccessMessage={SUBTITLES_OFF_SUCCESS}
                  />
                  <SetActionOn
                    requestPayload={requestPayload}
                    offApiMethod={TvControlApi.setTurnedOff}
                    onApiMethod={TvControlApi.setTurnedOn}
                    label={t("tv-control.turn-on-off")}
                    fieldName="online"
                    onReqSuccessMessage={TURN_ON_SUCCESS}
                    offReqSuccessMessage={TURN_OFF_SUCCESS}
                  />
                </div>
              </Paper>
              <ChangeVolume
                requestPayload={requestPayload}
                setGroups={setGroups}
              />
            </div>
          </div>
        </Scrollbars>
      ) : (
        <NotSelectedOption customClass={classes["not-selected-custom"]} />
      )}

      <Paper className={clsx(classes["box"], classes.advanced)}>
        <span
          className={clsx(classes["box-title"], [classes["box-title-custom"]])}
        >
          {t("tv-control.advanced-settings")}
        </span>

        <NavigateNextIcon
          className={classes.icon}
          onClick={() => {
            push(ENDPOINT_CONTROL.SETTINGS);
          }}
        />
      </Paper>
    </div>
  );
}

export default TvControl;
