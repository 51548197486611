import React from "react";
import { bool, node } from "prop-types";
import { Drawer } from "@material-ui/core";
import clsx from "clsx";

import classes from "./ListDrawer.module.scss";

function ListDrawer({ open, children = null }) {
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes["drawer"], {
        [classes["drawer-open"]]: open,
        [classes["drawer-close"]]: !open
      })}
      classes={{
        paper: clsx(classes["background"], {
          [classes["drawer-open"]]: open,
          [classes["drawer-close"]]: !open
        })
      }}
      open={open}
    >
      {children}
    </Drawer>
  );
}

ListDrawer.propTypes = {
  open: bool.isRequired,
  children: node
};

export default ListDrawer;
