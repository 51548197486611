import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import auth from "services/auth";
import useApi from "hooks/useApi";
import NotificationContext from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";
import AuthContext from "contexts/AuthContext";
import SocketIOContext from "contexts/SocketIOContext";
import { ROLE_NAMES } from "data/roles";
import useSocketIO from "hooks/useSocketIO";
import { SOCKET_EVENTS } from "data/socket-events";
import useTimeout from "hooks/useTimeout";

import EditProfileMenu from "./components/edit-profile-menu/EditProfileMenu";
import PersonalInfoModal from "./components/personal-info-modal/PersonalInfoModal";
import LanguageModal from "./components/language-modal/LanguageModal";
import AboutModal from "./components/about-modal/AboutModal";
import LogoutConfirmation from "./components/logout-confirmation/LogoutConfirmation";
import UserInfo from "./components/user-info/UserInfo";

import classes from "./User.module.scss";

function User() {
  const [editProfileMenuOpen, setEditProfileMenuOpen] = useState(false);
  const [personalInfoOpen, setPersonalInfoOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const { t } = useTranslation(["common", "access-management"]);
  const {
    actions: { setSnackbar }
  } = useContext(NotificationContext);
  const { userInContext } = useContext(UserContext);
  const {
    actions: { setIsAuthenticated }
  } = useContext(AuthContext);
  const { closeWebsocket } = useContext(SocketIOContext);
  const history = useHistory();
  const ROLES_PREFIX = "access-management:users.roles";
  const userName = userInContext.fullName;
  const userRole = t(`${ROLES_PREFIX}.${ROLE_NAMES[userInContext.roleName]}`);
  const setTimeOut = useTimeout();

  const [, , , logout] = useApi(auth.logout, {
    requestOnMount: false,
    onSuccess: () => {},
    onError: () => {},
    showMessageOnError: false
  });

  const toggleEditProfileMenu = () => {
    setEditProfileMenuOpen(prev => !prev);
  };

  const togglePersonalInfoModal = () => {
    setPersonalInfoOpen(prev => !prev);
  };

  const toggleLanguageModal = () => {
    setLanguageOpen(prev => !prev);
  };

  const toggleLogoutConfirmation = () => {
    setLogoutConfirmationOpen(prev => !prev);
  };

  const toggleAboutModalOpen = () => {
    setAboutModalOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setEditProfileMenuOpen(false);
  };

  const logOut = () => {
    logout();
    auth.handleLogOut();
    closeWebsocket();
    setIsAuthenticated(false);
    history.push("/");
  };

  const handleChangeRoleEvt = () => {
    setSnackbar(t("role-changed-evt"), "warning", 3000);
    setTimeOut(() => {
      logOut();
    }, 3000);
  };

  const handleLogoutEvt = () => {
    setSnackbar(t("logout-evt"), "warning", 3000);
    setTimeOut(() => {
      logOut();
    }, 3000);
  };

  useSocketIO(SOCKET_EVENTS.ROLE_CHANGED, handleChangeRoleEvt);
  useSocketIO(SOCKET_EVENTS.LOGOUT, handleLogoutEvt);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes["user"]}>
          <UserInfo
            userName={userName}
            userRole={userRole}
            toggleEditProfileMenu={toggleEditProfileMenu}
          />
          {editProfileMenuOpen && (
            <EditProfileMenu
              togglePersonalInfoModal={togglePersonalInfoModal}
              toggleLanguageModal={toggleLanguageModal}
              toggleEditProfileMenu={toggleEditProfileMenu}
              toggleAboutModalOpen={toggleAboutModalOpen}
              toggleLogoutConfirmation={toggleLogoutConfirmation}
            />
          )}
          <PersonalInfoModal
            personalInfoOpen={personalInfoOpen}
            togglePersonalInfoModal={togglePersonalInfoModal}
          />
          <LanguageModal
            languageOpen={languageOpen}
            toggleLanguageModal={toggleLanguageModal}
          />
        </div>
      </ClickAwayListener>

      <LogoutConfirmation
        open={logoutConfirmationOpen}
        onClose={toggleLogoutConfirmation}
        onAction={logOut}
      />

      <AboutModal open={aboutModalOpen} onClose={toggleAboutModalOpen} />
    </>
  );
}

export default User;
