import React from "react";
import { arrayOf, bool, func, object, string } from "prop-types";
import WarningIcon from "@material-ui/icons/Warning";
import { useTranslation } from "react-i18next";

import { convertBytes } from "utils/utils";

import ConfirmationModal from "components/ui/modals/confirmation-modal/ConfirmationModal";

import classes from "./WarningModal.module.scss";

const VideoNamesList = ({ list, info, oversized = false }) => (
  <div className={classes["videos"]}>
    <div className={classes["videos-header"]}>
      <WarningIcon className={classes["videos-header-icon"]} />
      <span>{info}</span>
    </div>
    <ul className={classes["videos-list"]}>
      {list.map(({ name, size }) => (
        <li key={name} className={classes["videos-list-item"]}>
          <span className={classes["videos-list-item-name"]}>{name}</span>
          {oversized && (
            <span className={classes["videos-list-item-size"]}>
              {convertBytes(size)}
            </span>
          )}
        </li>
      ))}
    </ul>
  </div>
);

function WarningModal({
  open,
  actionName,
  onClose,
  onAction,
  oversizedVideos,
  invalidNamesVideos,
  alreadyExistingVideos,
  alreadyUploadingVideos,
}) {
  const { t } = useTranslation("video-playlists");
  const oversizedWarningInfo =
    oversizedVideos.length === 1
      ? t("sections.videos.upload-component.warning-modal.oversized-singular")
      : t("sections.videos.upload-component.warning-modal.oversized-plural");

  const invalidNamesInfo =
    invalidNamesVideos.length === 1
      ? t(
          "sections.videos.upload-component.warning-modal.invalid-name-singular"
        )
      : t("sections.videos.upload-component.warning-modal.invalid-name-plural");

  const alreadyExistingVideosInfo =
    alreadyExistingVideos.length === 1
      ? t(
          "sections.videos.upload-component.warning-modal.video-already-exists-singular"
        )
      : t(
          "sections.videos.upload-component.warning-modal.video-already-exists-plural"
        );
  // TODO: Translations
  const alreadyUploadingVideosInfo =
  alreadyUploadingVideos.length === 1
      ? t(
          "sections.videos.upload-component.warning-modal.video-already-uploading-singular"
        )
      : t(
          "sections.videos.upload-component.warning-modal.video-already-uploading-plural"
        );

  const showDividerOne =
    oversizedVideos.length > 0 && invalidNamesVideos.length > 0;
  const showDividerTwo =
    (oversizedVideos.length > 0 || invalidNamesVideos.length > 0) &&
    alreadyExistingVideos.length > 0;

  return (
    <ConfirmationModal
      open={open}
      actionName={actionName}
      onClose={onClose}
      onAction={onAction}
    >
      <div className={classes["warning-modal-content"]}>
        {oversizedVideos.length > 0 && (
          <VideoNamesList
            list={oversizedVideos}
            info={oversizedWarningInfo}
            oversized
          />
        )}
        {showDividerOne && <div className={classes["divider"]} />}
        {invalidNamesVideos.length > 0 && (
          <VideoNamesList list={invalidNamesVideos} info={invalidNamesInfo} />
        )}
        {showDividerTwo && <div className={classes["divider"]} />}
        {alreadyExistingVideos.length > 0 && (
          <VideoNamesList
            list={alreadyExistingVideos}
            info={alreadyExistingVideosInfo}
          />
        )}
        {alreadyUploadingVideos.length > 0 && (
          <VideoNamesList
            list={alreadyUploadingVideos}
            info={alreadyUploadingVideosInfo}
          />
        )}
      </div>
    </ConfirmationModal>
  );
}

WarningModal.propTypes = {
  open: bool,
  actionName: string,
  onClose: func,
  onAction: func,
  oversizedVideos: arrayOf(object),
  invalidNamesVideos: arrayOf(object),
  alreadyExistingVideos: arrayOf(object)
};

export default WarningModal;
