import i18next from "i18next";

export const getSnacks = () => ({
  MESSAGE_SHOWN: {
    message: i18next.t("control:messages.snacks.message-has-been-shown"),
    variant: "success",
    autoHideDuration: 2000
  },
  MESSAGE_SHOWN_ERROR: {
    message: i18next.t("control:messages.snacks.message-could-not-be-shown"),
    variant: "error",
    autoHideDuration: 3500
  },
  MESSAGE_SAVED_AND_SHOWN: {
    message: "Message has been saved and shown",
    variant: "success",
    autoHideDuration: 2000
  },
  MESSAGES_CLEARED: {
    message: i18next.t("control:messages.snacks.messages-have-been-cleared"),
    variant: "success",
    autoHideDuration: 2000
  },
  MESSAGES_CLEARED_ERROR: {
    message: i18next.t("control:messages.snacks.messages-could-not-be-cleared"),
    variant: "error",
    autoHideDuration: 3500
  },
  MESSAGE_DELETED: {
    message: i18next.t("control:messages.snacks.message-has-been-deleted"),
    variant: "success",
    autoHideDuration: 2000
  },
  MESSAGE_DELETED_ERROR: {
    message: i18next.t("control:messages.snacks.message-could-not-be-deleted"),
    variant: "error",
    autoHideDuration: 3500
  },
  MESSAGES_LOAD_ERROR: {
    message: i18next.t("control:messages.snacks.messages-could-not-be-loaded"),
    variant: "error",
    autoHideDuration: 3500
  }
});
