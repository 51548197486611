import React from "react";
import { string } from "prop-types";

import classes from "./SelectedFileName.module.scss";

function SelectedFileName({ fileName }) {
  return <span className={classes["file-name"]}>{fileName}</span>;
}

SelectedFileName.propTypes = {
  fileName: string
};

export default SelectedFileName;
