import React, { useEffect, useState } from "react";
import { object, func } from "prop-types";
import { useForm } from "react-hook-form";
import { Slide } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";
import { assignEndpointGroupsRequestMessages } from "../../utils/utils";
import EndpointGroupsApi from "services/api/2-system-manage-api/endpoint-groups-api";
import { validateIDTName, getInputError, validateRequired } from "utils/utils";

import Form from "components/ui/form/Form";
import SidePanelHeader from "components/ui/headers/side-panel-header/SidePanelHeader";
import GroupDetails from "./components/group-details/GroupDetails";
import ApplyButton from "./components/apply-button/ApplyButton";
import DeleteLine from "components/ui/buttons/delete-line/DeleteLine";

import classes from "./EditSelectedGroup.module.scss";
import { MAX_IDTNAME_LEN } from "data/constants";

const EditGroupContainer = "div";
const Actions = "div";

function EditSelectedGroup({
  selectedGroup = { name: null },
  setSelectedGroup,
  resetSelectedGroup,
  fetchAllEndpointGroups,
  openConfirmationModal,
  setGroupToBeDeleted
}) {
  const [slideStatus, setSlideStatus] = useState(false);
  const [isInvalidCharacter, setIsInvalidCharacter] = useState({
    name: false
  });
  const { t } = useTranslation("endpoint-groups");

  const {
    handleSubmit,
    register,
    formState: { dirty, isValid },
    reset,
    errors
  } = useForm({
    defaultValues: {
      name: selectedGroup.name
    },
    mode: "onChange"
  });

  const { EDITED, EDITTING_ERROR } = assignEndpointGroupsRequestMessages(t);

  const [, editGroupLoading, , editGroup] = useApi(
    EndpointGroupsApi.updateGroup,
    {
      requestOnMount: false,
      successMessage: EDITED,
      errorMessage: EDITTING_ERROR,
      onSuccess: fetchAllEndpointGroups
    }
  );

  useEffect(() => {
    if (selectedGroup.name) {
      handleSlideIn();
    } else {
      handleSlideOut();
    }
    reset({ name: selectedGroup.name });
    setIsInvalidCharacter({ name: false });
  }, [selectedGroup.name]);

  const handleSlideIn = () => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 250);
  };

  const handleSlideOut = () => {
    setSlideStatus(false);
  };

  const handleCancel = () => {
    handleSlideOut();
    resetSelectedGroup();
  };

  const onSubmit = data => {
    const group = { ...selectedGroup, ...data };
    setSelectedGroup(group);
    editGroup(group);
  };

  const handleDeleteGroup = () => {
    setGroupToBeDeleted(selectedGroup);
    openConfirmationModal();
  };

  return (
    <Slide direction="left" in={slideStatus}>
      <EditGroupContainer className={classes["edit-selected-group"]}>
        <SidePanelHeader
          title={t("edit-selected-group")}
          loading={editGroupLoading}
          onClose={handleCancel}
        />
        <Form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className={classes["form"]}
        >
          <Form.Field horizontal>
            <Form.Label>{t("name")}</Form.Label>
            <Form.Input
              // @ts-ignore
              type="text"
              id="name"
              name="name"
              ref={register({
                ...validateRequired,
                ...validateIDTName
              })}
              error={getInputError(errors.name, t, MAX_IDTNAME_LEN)}
              data-cy="edit-endpoint-group-name-input"
            />
          </Form.Field>

          <GroupDetails selectedGroup={selectedGroup} />

          <Actions className={classes["form-actions"]}>
            <DeleteLine
              label={t("common:delete")}
              onClick={handleDeleteGroup}
              data-cy="delete-endpoint-group-button"
            />
            <ApplyButton
              dirty={dirty}
              isValid={isValid}
              isInputValueInvalid={isInvalidCharacter.name}
              label={t("common:apply")}
              data-cy="edit-endpoint-group-button"
            />
          </Actions>
        </Form>
      </EditGroupContainer>
    </Slide>
  );
}

EditSelectedGroup.propTypes = {
  selectedGroup: object,
  setSelectedGroup: func,
  resetSelectedGroup: func,
  fetchAllEndpointGroups: func,
  openConfirmationModal: func,
  setGroupToBeDeleted: func
};

export default EditSelectedGroup;
