import React, { useEffect, useState } from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { func, string, bool } from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { CHANNEL_STATES } from "../../../../../../../../utils/utils";
import { playlistChannelType } from "types";

import ChannelStateLoader from "./loader/ChannelStateLoader";

import classes from "./ChannelState.module.scss";

function ChannelState({
  channel,
  newState,
  currentState,
  setNewState,
  loading
}) {
  const [isTransmitting, setIsTransmitting] = useState(null);
  const { t } = useTranslation("video-playlists");
  const PREFIX = "sections.playlist-channels.state-slider";
  const { TRANSMIT, STOP } = CHANNEL_STATES;
  const disabled = !channel.enabled;

  const handleButtonClick = state => _ => {
    if (!loading) {
      setNewState(state);
    }
  };

  const getBtnClass = btnName => ({
    root: clsx(classes[`${btnName}-btn`], {
      [classes["active"]]:
        btnName === TRANSMIT ? isTransmitting : !isTransmitting
    })
  });

  useEffect(() => {
    setIsTransmitting(currentState === TRANSMIT);
  }, [currentState]);

  return (
    <div
      className={classes["channel-state"]}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <ButtonGroup
        variant="contained"
        color="primary"
        classes={{
          root: classes["channel-state-buttons"],
          disabled: classes["disabled"]
        }}
        disabled={disabled}
      >
        <Button classes={getBtnClass(STOP)} onClick={handleButtonClick(STOP)}>
          {loading && newState === STOP ? (
            <ChannelStateLoader />
          ) : isTransmitting ? (
            t(`${PREFIX}.stop`)
          ) : (
            t(`${PREFIX}.stopped`)
          )}
        </Button>
        <Button
          classes={getBtnClass(TRANSMIT)}
          onClick={!isTransmitting ? handleButtonClick(TRANSMIT) : null}
        >
          {loading && newState === TRANSMIT ? (
            <ChannelStateLoader />
          ) : isTransmitting ? (
            t(`${PREFIX}.transmitting`)
          ) : (
            t(`${PREFIX}.transmit`)
          )}
        </Button>
      </ButtonGroup>
    </div>
  );
}

ChannelState.propTypes = {
  channel: playlistChannelType,
  newState: string,
  currentState: string,
  setNewState: func,
  loading: bool
};

export default ChannelState;
